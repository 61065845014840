import React from "react";
import Button, { ButtonProps } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import { MenuButton } from "@chakra-ui/react";

export interface ButtonSingleChoiceProps extends ButtonProps {
  text: string;
  onRemove?: () => void;
  dataTestId?: string;
  hasValue: boolean;
  asMenuButton?: boolean;
  isChevronDisplayed?: boolean;
  size?: string;
}

const ButtonSingleChoice = React.forwardRef(function ButtonSingleChoice(
  {
    text,
    onRemove,
    dataTestId,
    hasValue,
    asMenuButton = false,
    isChevronDisplayed = true,
    variant = "filter",
    size,
    ...props
  }: ButtonSingleChoiceProps,
  ref
) {
  const showRemove = hasValue && onRemove;
  const ButtonComponent = asMenuButton ? MenuButton : Button;

  return (
    <ButtonComponent
      ref={ref}
      as={asMenuButton ? Button : undefined}
      pr={showRemove ? 2 : 3}
      pl={3}
      variant={variant}
      {...(hasValue &&
        variant === "filter" && {
          borderColor: "textPrimary",
          color: "textPrimary",
        })}
      data-testid={dataTestId}
      rightIcon={
        !showRemove && isChevronDisplayed ? (
          <Icon id="Ui/ChevronDownSm" color="inherit" />
        ) : showRemove ? (
          <Button
            leftIcon={<Icon id="Ui/CrossSm" color="inherit" />}
            onClick={(e) => {
              e.stopPropagation();
              onRemove?.();
            }}
            variant="ghost"
            colorScheme="white"
            _hover={{
              bg: "whiteAlpha.300",
              color: "textPrimary",
            }}
            p={1}
            ml={-1}
            borderRadius="base"
            color="inherit"
            minW="unset"
            h="unset"
          />
        ) : undefined
      }
      size={size ?? "sm"}
      {...props}
    >
      {text}
    </ButtonComponent>
  );
});

export default ButtonSingleChoice;
