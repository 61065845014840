import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useClipboardContext } from "domains/commons/contexts/ClipboardProvider";
import { FmFileImage } from "domains/file-manager/interfacesV2";
import ButtonDroppableUpload, {
  ButtonDroppableUploadProps,
} from "domains/image/components/ButtonDroppableUpload";
import SearchModal, {
  SearchModalProps,
  SearchModalTab,
} from "domains/search/components/SearchModal";
import { useSearchContext } from "domains/search/contexts/SearchProvider";
import Button from "domains/ui/components/Button";

import { Center, HStack } from "@chakra-ui/react";

export interface ModalLibraryAssetSelectProps<T extends string[] = []> {
  isOpen: boolean;
  onClose: () => void;
  onSelect?: (asset: FmFileImage) => void;
  onMultiSelect?: (assets: FmFileImage[]) => void;
  selectionMax?: number;
  modelId?: string;
  assetFilterTypeProps?: SearchModalProps["assetFilterTypeProps"];
  tabs: (NonNullable<SearchModalProps["tabs"]>[number] | T[number])[];
  buttonDroppableUploadProps?: ButtonDroppableUploadProps & {
    onPaste: (imageData: string) => void;
  };
  additionalTabs?: SearchModalProps<T>["additionalTabs"];
}

export default function ModalLibraryAssetSelect<T extends string[] = []>({
  isOpen,
  onClose: __onClose,
  onSelect,
  onMultiSelect,
  selectionMax,
  modelId,
  assetFilterTypeProps,
  tabs,
  buttonDroppableUploadProps,
  additionalTabs,
}: ModalLibraryAssetSelectProps<T>) {
  const { clearCache } = useSearchContext();
  const [query, setQuery] = useState("");
  const [assetId, setAssetId] = useState<string | undefined>(undefined);
  const [aiBoost, setAiBoost] = useState(false);
  const [tabId, setTabId] = useState<SearchModalTab | "upload" | T[number]>(
    tabs[0]
  );
  const [assetSelection, setAssetSelection] = useState<FmFileImage[]>([]);

  const onClose = useCallback(() => {
    __onClose();
    setQuery("");
    setAssetId(undefined);
    setAiBoost(false);
    setTabId(tabs[0]);
    setAssetSelection([]);
    clearCache();
  }, [__onClose, clearCache, tabs]);

  // ----------------------------------

  const handleSubmit = useCallback(() => {
    onClose();
    onMultiSelect?.(assetSelection);
  }, [onClose, onMultiSelect, assetSelection]);

  const handleSelectionChange = useCallback(
    (assets: FmFileImage[]) => {
      setAssetSelection(assets);
    },
    [setAssetSelection]
  );

  // ----------------------------------

  const allTabs = useMemo(() => {
    return [...tabs, ...(buttonDroppableUploadProps ? ["upload"] : [])] as (
      | SearchModalTab
      | "upload"
    )[];
  }, [tabs, buttonDroppableUploadProps]);

  const allAdditionalTabs: SearchModalProps<T>["additionalTabs"] =
    useMemo(() => {
      return buttonDroppableUploadProps
        ? [
            ...(additionalTabs ?? []),
            {
              id: "upload",
              title: "Upload",
              children: (_) => (
                <UploadTab
                  buttonDroppableUploadProps={buttonDroppableUploadProps}
                />
              ),
            },
          ]
        : additionalTabs ?? undefined;
    }, [additionalTabs, buttonDroppableUploadProps]);

  // ----------------------------------

  return (
    <SearchModal
      isOpen={isOpen}
      onClose={onClose}
      query={query}
      setQuery={setQuery}
      assetId={assetId}
      setAssetId={setAssetId}
      aiBoost={aiBoost}
      setAiBoost={setAiBoost}
      tabId={tabId}
      setTabId={setTabId}
      onSelectionChange={onMultiSelect ? handleSelectionChange : undefined}
      onSelect={onSelect}
      selectionMax={selectionMax}
      allowEmptySearch
      initialSort="createdAtTSDesc"
      forceModelIds={modelId ? [modelId] : undefined}
      tabs={allTabs}
      assetFilterTypeProps={{
        ...assetFilterTypeProps,
        image: assetFilterTypeProps?.image ?? {
          assetType: "image",
          showVectorization: false,
          avoidPersistedState: true,
        },
      }}
      SelectionActionsComponent={() => (
        <SelectionActionsComponent
          onClose={onClose}
          handleSubmit={handleSubmit}
          assetSelection={assetSelection}
        />
      )}
      additionalTabs={allAdditionalTabs}
    />
  );
}

function UploadTab({
  buttonDroppableUploadProps,
}: {
  buttonDroppableUploadProps: NonNullable<
    ModalLibraryAssetSelectProps["buttonDroppableUploadProps"]
  >;
}) {
  const { addImagePasteListener, removeImagePasteListener } =
    useClipboardContext();

  useEffect(() => {
    const onImagePastedCallback = async (images: string[]) => {
      if (images.length > 0) {
        buttonDroppableUploadProps.onPaste(images[0]);
      }
    };

    addImagePasteListener(onImagePastedCallback);
    return () => {
      removeImagePasteListener(onImagePastedCallback);
    };
  }, [
    addImagePasteListener,
    buttonDroppableUploadProps,
    removeImagePasteListener,
  ]);

  return (
    <Center w="100%" h="100%">
      <ButtonDroppableUpload
        w="50%"
        h="50%"
        alignItems="center"
        justifyContent="center"
        {...buttonDroppableUploadProps}
      />
    </Center>
  );
}

function SelectionActionsComponent({
  onClose,
  handleSubmit,
  assetSelection,
}: {
  onClose: () => void;
  handleSubmit: () => void;
  assetSelection: FmFileImage[];
}) {
  return (
    <HStack spacing={2}>
      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button
        variant="primary"
        isDisabled={!assetSelection.length}
        onClick={handleSubmit}
      >
        Confirm
      </Button>
    </HStack>
  );
}
