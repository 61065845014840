import Icon from "domains/ui/components/Icon";

import { Box, Tooltip } from "@chakra-ui/react";

import TopIconButton from "./ElementTopIconButton";

interface ElementLowResWarningProps {
  lowResMax: number;
}

export default function ElementLowResWarning({
  lowResMax,
}: ElementLowResWarningProps) {
  const lowResOptimalMax = Math.ceil(lowResMax / 512) * 512;
  return (
    <Tooltip
      label={`Low-Resolution Image Detected. For optimal training quality, please use images that are ${lowResOptimalMax}x${lowResOptimalMax}px or larger. Low-res images affect model performance. You can upscale training images using the "..." menu.`}
    >
      <Box>
        <TopIconButton pointerEvents="none">
          <Icon id="Ui/AlertWarning" color="warning.500" h="14px" />
        </TopIconButton>
      </Box>
    </Tooltip>
  );
}
