import {
  MouseEventHandler,
  PropsWithChildren,
  RefObject,
  useCallback,
  useState,
} from "react";
import React from "react";
import {
  useAssetCanvasLink,
  useAssetExpandLink,
  useAssetReloadParametersLink,
  useAssetUpscaleLink,
} from "domains/assets/hooks/useAssetLink";
import { FmFileImage } from "domains/file-manager/interfacesV2";
import useRouter from "domains/navigation/hooks/useRouter";
import { IconButton } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import MenuTitle from "domains/ui/components/Menu/MenuTitle";
import { NsfwIndicator } from "domains/ui/components/NsfwIndicator";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import _ from "lodash";

import {
  Box,
  Center,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Portal,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { ActionType, FmImageCardActionsProps } from "./Actions";
import VaryButton from "./VaryButton";

export type CardContentProps<C extends object = object> = {
  file: FmFileImage;
  variant: "image" | "skybox" | "texture";
  isVaryEnabled?: boolean;
  onVary?: () => void;
  cardWidth: number;
  containerRef: RefObject<HTMLDivElement>;
  isHovered?: boolean;
  isBlurred?: boolean;
  isPinned?: boolean;
  isWithoutActions?: boolean;
  onClick?: (file: FmFileImage) => void;
  onActionClick?: (type: ActionType) => void;
  onPinClick?: (file: FmFileImage, isPinned: boolean) => Promise<void>;

  actionsProps: C;
  ActionsComponent?: React.FunctionComponent<FmImageCardActionsProps<C>>;
};

function CardContentWithoutMemo<C extends object = object>({
  file,
  variant,
  isVaryEnabled = false,
  onVary,
  cardWidth,
  containerRef,
  isHovered = false,
  isBlurred = false,
  isPinned = false,
  isWithoutActions = false,
  onClick,
  onPinClick,
  onActionClick,
  actionsProps,
  ActionsComponent,
}: CardContentProps<C>) {
  const router = useRouter();
  const [isPinning, setIsPinning] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { revealAsset } = useUserContext();

  const asset = file.meta;
  const linkReloadAllParameters = useAssetReloadParametersLink(asset);
  const linkUpscale = useAssetUpscaleLink(asset);
  const linkEditInCanvas = useAssetCanvasLink(asset);
  const linkExpand = useAssetExpandLink(asset);
  const isDraft =
    variant === "skybox" && asset.metadata.type === "skybox-base-360";
  const isPinnable = !!onPinClick;

  // ----------------------------------

  const handlePinClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (onPinClick) {
        try {
          setIsPinning(true);
          await onPinClick(file, !isPinned);
        } finally {
          setIsPinning(false);
        }
      }
    },
    [isPinned, onPinClick, file]
  );

  // ----------------------------------

  const handleMoreClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      Track(AnalyticsEvents.CardAction.ClickedMore);
      if (onClick) onClick(file);
      if (onActionClick) onActionClick("more");
    },
    [onClick, file, onActionClick]
  );

  const handleRevealClick = useCallback<MouseEventHandler<SVGElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      revealAsset(file.id);
    },
    [revealAsset, file.id]
  );

  // ----------------------------------

  return (
    <Box pos="relative" w="100%" h="100%" pointerEvents="none">
      <HStack
        pos="absolute"
        top={0}
        right={0}
        minH="52px"
        p={cardWidth > 400 ? 3 : 2}
        pointerEvents="auto"
      >
        {isVaryEnabled && (
          <VaryButton
            isVisible={(isHovered || isOpen) && !isBlurred}
            asset={asset}
            cardWidth={cardWidth}
            onVary={onVary}
          />
        )}

        {!isWithoutActions && (
          <Menu
            computePositionOnMount
            eventListeners={{ scroll: false }}
            isLazy
            isOpen={isOpen}
            onClose={onClose}
            placement="right-start"
            variant="blurred"
          >
            <MenuButton
              as={IconButton}
              {...(isHovered || isOpen
                ? { visibility: "visible" }
                : { visibility: "hidden" })}
              borderRadius="lg"
              aria-label="More"
              icon={<Icon id="Ui/Dots" h="3.5px" pointerEvents="none" />}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                isOpen ? onClose() : onOpen();
              }}
              size="sm"
              variant="blurred"
            />

            <Portal containerRef={containerRef}>
              <MenuList
                zIndex="popover"
                maxH={600}
                data-outside-click-excluded
                onClick={(e) => e.stopPropagation()}
              >
                {linkReloadAllParameters && (
                  <>
                    <MenuTitle variant="blurred" text="USE" />
                    <MenuItem
                      w="150px"
                      iconId="Ui/RefreshAlt"
                      iconH="12px"
                      internalLink={linkReloadAllParameters}
                      onClick={(e) => {
                        e.stopPropagation();
                        Track(AnalyticsEvents.CardAction.ReloadedParameters);
                        if (onActionClick) onActionClick("reloadParameters");
                      }}
                      text="All Parameters"
                    />
                  </>
                )}

                {ActionsComponent && (
                  <ActionsComponent
                    {...actionsProps}
                    asset={asset}
                    top={
                      !linkReloadAllParameters ? (
                        <MenuTitle variant="blurred" text="USE" />
                      ) : undefined
                    }
                    onActionClick={onActionClick}
                    bottom={
                      !linkReloadAllParameters ? <MenuDivider /> : undefined
                    }
                  />
                )}

                {!!linkReloadAllParameters && <MenuDivider />}

                {linkUpscale || linkEditInCanvas || linkExpand ? (
                  <>
                    <MenuTitle variant="blurred" text="EDIT" />

                    {linkUpscale && (
                      <MenuItem
                        w="150px"
                        iconId="Nav/Upscale/Outline"
                        iconH="12px"
                        internalLink={linkUpscale}
                        onClick={(e) => {
                          e.stopPropagation();
                          Track(AnalyticsEvents.CardAction.ClickedUpscale);
                          if (onActionClick) onActionClick("upscale");
                        }}
                        text="Enhance"
                      />
                    )}
                    {linkEditInCanvas && (
                      <MenuItem
                        w="150px"
                        iconId="Nav/Canvas/Outline"
                        iconH="14px"
                        internalLink={linkEditInCanvas}
                        onClick={(e) => {
                          e.stopPropagation();
                          Track(AnalyticsEvents.CardAction.ClickedRetouch);
                          if (onActionClick) onActionClick("retouch");
                        }}
                        target="_blank"
                        text="Retouch"
                      />
                    )}
                    {linkExpand && (
                      <MenuItem
                        w="150px"
                        iconId="Nav/Expand/Outline"
                        iconH="14px"
                        internalLink={linkExpand}
                        onClick={(e) => {
                          e.stopPropagation();
                          Track(AnalyticsEvents.CardAction.ClickedExpand);
                          if (onActionClick) onActionClick("expand");
                        }}
                        text="Expand"
                      />
                    )}

                    <MenuItem
                      w="150px"
                      iconId="Ui/Dots"
                      iconH="3px"
                      onClick={handleMoreClick}
                      text="More"
                    />

                    <MenuDivider />
                  </>
                ) : undefined}

                <MenuTitle variant="blurred" text="SEARCH" />
                <MenuItem
                  w="150px"
                  iconId="Ui/Search"
                  iconH="12px"
                  internalLink={{
                    pathname: router.pathname,
                    query: {
                      ..._.omit(router.query, "searchQuery"),
                      searchAssetId: asset?.id,
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    Track(AnalyticsEvents.CardAction.ClickedSearchSimilar);
                    if (onActionClick) onActionClick("searchSimilar");
                  }}
                  text="Similar Images"
                />
              </MenuList>
            </Portal>
          </Menu>
        )}

        {isPinnable && (isPinned || isHovered || isOpen) && (
          <IconButton
            aria-label={isPinned ? "unpin image" : "pin image"}
            size="sm"
            variant="blurred"
            isLoading={isPinning}
            icon={
              <Icon
                id={isPinned ? "Ui/Pin/Solid" : "Ui/Pin/Outline"}
                h="12px"
              />
            }
            onClick={handlePinClick}
          />
        )}

        {isDraft && <HighlightTag>Preview</HighlightTag>}
      </HStack>

      {isBlurred && (
        <Box
          pos="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          pointerEvents="auto"
        >
          <NsfwIndicator onClick={handleRevealClick} />
        </Box>
      )}

      <Box
        top={0}
        right={0}
        bottom={0}
        left={0}
        pointerEvents="none"
        {...(isOpen && !isHovered ? { bgColor: "rgba(0,0,0,0.3)" } : {})}
      />
    </Box>
  );
}

export default React.memo(
  CardContentWithoutMemo
) as typeof CardContentWithoutMemo;

// ------------------------------------

const HighlightTag = React.memo(function HighlightTag({
  children,
}: PropsWithChildren) {
  return (
    <Center
      h="28px"
      px={2}
      bg="rgba(37, 37, 37, 0.35)"
      borderRadius="lg"
      pointerEvents="none"
      backdropFilter="blur(8px)"
    >
      <Text color="textPrimary" size="body.bold.sm">
        {children}
      </Text>
    </Center>
  );
});
