import React, { PropsWithChildren, ReactNode } from "react";

import { HStack, Image, Text } from "@chakra-ui/react";

interface HeaderProps extends PropsWithChildren {
  title: string | ReactNode;
  thumbnail?: string;
}

export default function Header({ title, thumbnail, children }: HeaderProps) {
  return (
    <HStack
      align="center"
      justify="space-between"
      w="100%"
      pt={5}
      pb={2}
      px={9}
    >
      <HStack align="center" justify="start" gap={3}>
        {thumbnail && (
          <Image
            w="64px"
            h="64px"
            borderRadius={"lg"}
            objectFit="cover"
            alt="header thumbnail"
            src={thumbnail + "&height=64"}
          />
        )}
        <Text size="title.lg">{title}</Text>
      </HStack>

      <HStack align="center" justify="start" spacing={3}>
        {children}
      </HStack>
    </HStack>
  );
}
