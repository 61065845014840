import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

export interface AssetDataDeletion {
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
  isCanvas?: boolean;
}

export default function AssetDataDeletion({
  asset,
  isCanvas = false,
}: AssetDataDeletion) {
  const { selectedTeam } = useTeamContext();

  if (asset?.softDeletionOn === undefined || !selectedTeam.isFreePlan) {
    return null;
  }

  return (
    <Button
      internalLink={{
        pathname: "/team",
        query: {
          tab: "plans",
        },
      }}
      tooltip={`Your subscription ended. Subscribe to a plan to prevent data deletion.`}
      variant="primary"
      colorScheme="warning"
      size="xs"
      leftIcon={<Icon id="Ui/Star" h="12px" />}
      onClick={() =>
        Track(
          isCanvas
            ? AnalyticsEvents.DataDeletion.ClickedCanvasDataDeletion
            : AnalyticsEvents.DataDeletion.ClickedAssetDataDeletion
        )
      }
    >
      Pending Data Deletion
    </Button>
  );
}
