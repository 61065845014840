import { useEffect, useState } from "react";
import Icon from "domains/ui/components/Icon";
import ScenarioInput from "domains/ui/components/ScenarioInput";

import { useDebounce } from "@react-hook/debounce";

export interface SearchProps {
  onSearch: (value: string) => void;
  placeholder?: string;
  dataTestId?: string;
}

/**
 * Description of the component
 *
 * @link <url to figma if exist>
 * @link <url to storybook if exist>
 */
const Search = ({ onSearch, placeholder, dataTestId }: SearchProps) => {
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useDebounce(search, 500);

  useEffect(() => {
    onSearch(debouncedSearch);
  }, [debouncedSearch, onSearch]);

  return (
    <ScenarioInput
      value={search}
      setValue={(value: string) => {
        setSearch(value);
        setDebouncedSearch(value);
      }}
      placeholder={placeholder ?? "Search"}
      dataTestId={dataTestId}
      leftComponent={<Icon id={"Ui/Search"} mb={0.5} color={"textTertiary"} />}
    />
  );
};

export default Search;
