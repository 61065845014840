import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  isAssetInferenceTexture,
  isAssetSkybox,
  isAssetUpscaledTexture,
} from "domains/assets/utils/isType";
import Deffered from "domains/commons/components/Deffered";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import { extractFirstQueryParam } from "domains/commons/misc";
import {
  FileImageType,
  mapAssetsToImagesFiles,
} from "domains/file-manager/interfaces";
import { localStorageService } from "domains/infra/local-storage/localStorageService";
import useRouter from "domains/navigation/hooks/useRouter";
import { appShortcuts } from "domains/shortcuts/components/appShortcuts";
import { ShortcutsModal } from "domains/shortcuts/components/ShortcutsModal";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Aside, { AsideSection } from "domains/ui/components/Aside";
import Icon from "domains/ui/components/Icon";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "domains/ui/components/Modal";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import {
  GetAssetsByAssetIdApiResponse,
  useGetAssetsByAssetIdQuery,
  useGetModelsByModelIdQuery,
  useGetModelsInferencesByModelIdAndInferenceIdQuery,
} from "infra/api/generated/api";

import {
  Box,
  Center,
  Flex,
  Grid,
  HStack,
  Skeleton,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import AssetZoomAside from "./Aside";
import AssetZoomContent from "./Content";
import AssetZoomHeader, { HEADER_HEIGHT } from "./Header";
import AssetZoomImageList, { useImageListHeight } from "./ImageList";
import AssetZoomSkyboxViewer from "./SkyboxViewer";
import AssetZoomTextureViewer from "./TextureViewer";

export interface AssetZoomProps {
  assets: FileImageType[];
  hasMore: boolean;
  loadMore: () => void | undefined;
  originalAssets?: boolean;
  onDelete?: (assets: FileImageType[]) => void;
  onAction?: () => void;
  priorityLevel?: number;
}

export default function AssetZoom({
  assets,
  hasMore,
  loadMore,
  originalAssets,
  onDelete,
  onAction,
  priorityLevel = 0,
}: AssetZoomProps) {
  const router = useRouter();
  const { selectedProject } = useTeamContext();
  const {
    isOpen: isShortcutsOpen,
    onClose: onShortcutsClose,
    onToggle: onShortcutsToggle,
  } = useDisclosure();
  const [isComparing, setIsComparing] = usePersistedState(
    PersistedStateKey.ASSET_ZOOM_IS_COMPARING,
    {
      defaultValue: false,
      forceValue: router.pathname === "/enhance",
    }
  );
  const [isAsideOpen, setIsAsideOpen] = usePersistedState(
    PersistedStateKey.ASSET_ZOOM_ASIDE_OPEN,
    {
      defaultValue: true,
    }
  );
  const [isGalleryOpen, setIsGalleryOpen] = usePersistedState(
    PersistedStateKey.ASSET_ZOOM_GALLERY_OPEN,
    { defaultValue: true }
  );

  const [assetFromList, setAssetFromList] = useState<
    GetAssetsByAssetIdApiResponse["asset"] | undefined
  >();
  const [assetId, setAssetId] = useState<string | undefined>();
  const debouncedAssetId = useDebounce(assetId, 500, {
    resetBeforeDebounce: true,
    preventFor: (val) => !val,
  });

  const queryAssetId = extractFirstQueryParam(router.query.openAssetId);
  const isOpen = !!(queryAssetId && assetId);

  const remoteAsset = useGetAssetsByAssetIdQuery(
    debouncedAssetId
      ? {
          assetId: debouncedAssetId,
          projectId: selectedProject.id,
          originalAssets: originalAssets ? "true" : undefined,
        }
      : skipToken
  );

  const asset = useMemo(() => {
    if (assetId && assetId === remoteAsset?.data?.asset.id) {
      return remoteAsset.data.asset;
    } else if (assetId && assetId === assetFromList?.id) {
      return assetFromList;
    } else {
      return undefined;
    }
  }, [assetId, remoteAsset?.data?.asset, assetFromList]);

  /** @deprecated
   * the old way of doing it was openAssetId = ${modelId}|${inferenceId}|${assetIndex}
   * now we do it with just the assetId
   * but we need to keep this for people who bookmarked the old url
   */
  const [deprecatedAsset, setDeprecatedAsset] = useState<
    | {
        modelId: string;
        inferenceId: string;
        assetIndex: number;
      }
    | undefined
  >();

  const deprecatedInference =
    useGetModelsInferencesByModelIdAndInferenceIdQuery(
      deprecatedAsset && debouncedAssetId
        ? {
            modelId: deprecatedAsset.modelId ?? "",
            inferenceId: deprecatedAsset.inferenceId ?? "",
            projectId: selectedProject.id,
          }
        : skipToken
    );

  const { data: inferenceData } =
    useGetModelsInferencesByModelIdAndInferenceIdQuery(
      debouncedAssetId && asset?.metadata.modelId && asset?.metadata.inferenceId
        ? {
            modelId: asset.metadata.modelId,
            inferenceId: asset.metadata.inferenceId,
            projectId: selectedProject.id,
          }
        : skipToken
    );

  const inference = useMemo(() => {
    if (
      inferenceData &&
      inferenceData.inference.id === asset?.metadata.inferenceId
    ) {
      return inferenceData.inference;
    }
    return undefined;
  }, [inferenceData, asset]);

  const { data: modelData } = useGetModelsByModelIdQuery(
    debouncedAssetId && asset?.metadata.modelId
      ? { modelId: asset.metadata.modelId, projectId: selectedProject.id }
      : skipToken
  );

  const model = useMemo(() => {
    if (modelData && modelData.model.id === asset?.metadata.modelId) {
      return modelData.model;
    }
    return undefined;
  }, [modelData, asset]);

  const isDisplayingSkyboxViewer = isAssetSkybox(asset);
  const isDisplayingTextureViewer =
    isAssetInferenceTexture(asset) ||
    (isAssetUpscaledTexture(asset) && !isComparing) ||
    asset?.metadata.type === "texture";

  // ----------------------------------

  const resetIsComparing = useCallback(() => {
    if (router.pathname === "/enhance") {
      setIsComparing(true);
    } else if (router.pathname === "/textures/enhance") {
      setIsComparing(false);
    } else {
      setIsComparing(
        localStorageService.get(PersistedStateKey.ASSET_ZOOM_IS_COMPARING) ??
          false
      );
    }
  }, [setIsComparing, router.pathname]);

  const close = useCallback(() => {
    onShortcutsClose();
    delete router.query.openAssetId;
    void router.push(
      {
        query: router.query,
      },
      undefined,
      { scroll: false }
    );
  }, [onShortcutsClose, router]);

  const switchImage = useCallback(
    (direction: "next" | "previous") => {
      const currentIndex = assets.findIndex((item) => item.id === asset?.id);
      if (
        currentIndex === -1 ||
        (currentIndex === 0 && direction === "previous") ||
        (currentIndex === assets.length - 1 && direction === "next")
      ) {
        return;
      }
      const targetAsset =
        assets[currentIndex + (direction === "previous" ? -1 : 1)];
      if (!targetAsset) {
        return;
      }
      setAssetFromList(targetAsset.meta);
      setAssetId(targetAsset.id);
      router.query.openAssetId = targetAsset.id;
      void router.push(
        {
          query: router.query,
        },
        undefined,
        { scroll: false }
      );
    },
    [assets, asset, router]
  );

  // ----------------------------------

  const handleToggleComparing = useCallback(() => {
    setIsComparing((prev) => !prev);
  }, [setIsComparing]);

  const handleRemove = useCallback(() => {
    if (!asset) {
      return;
    }
    onDelete?.(mapAssetsToImagesFiles([asset]));
    const currentIndex = assets.findIndex((item) => item.id === asset.id);
    if (currentIndex === 0 && assets.length === 1) {
      close();
    } else if (currentIndex === assets.length - 1) {
      switchImage("previous");
    } else {
      switchImage("next");
    }
  }, [switchImage, close, assets, asset, onDelete]);

  const handleFullscreenToggle = useCallback(() => {
    if (isAsideOpen || isGalleryOpen) {
      setIsAsideOpen(false);
      setIsGalleryOpen(false);
    } else {
      setIsAsideOpen(true);
      setIsGalleryOpen(true);
    }
  }, [setIsAsideOpen, setIsGalleryOpen, isAsideOpen, isGalleryOpen]);

  // ----------------------------------

  useEffect(() => {
    if (deprecatedInference && deprecatedInference.data && deprecatedAsset) {
      const loadedAssetId =
        deprecatedInference.data.inference.images[deprecatedAsset.assetIndex]
          .id;
      if (loadedAssetId) {
        router.query.openAssetId = loadedAssetId;
        void router.push(
          {
            query: router.query,
          },
          undefined,
          { scroll: false }
        );
      }
    }
  }, [deprecatedInference, deprecatedAsset, router]);

  useEffect(() => {
    if (!queryAssetId) {
      setAssetId(undefined);
      setDeprecatedAsset(undefined);
      return;
    }

    // get all elements with data-asset-zoom-priority-level
    // if any of them is greater than priorityLevel, return
    const elements = document.querySelectorAll(
      `[data-asset-zoom-priority-level]`
    );
    const maxPriorityLevel = Math.max(
      ...Array.from(elements).map((el) =>
        parseInt(el.getAttribute("data-asset-zoom-priority-level") ?? "0")
      )
    );
    if (maxPriorityLevel > priorityLevel) {
      return;
    }

    const loadedAssetId = queryAssetId as string;
    if (loadedAssetId.includes("|")) {
      const [modelId, inferenceId, assetIndex] = loadedAssetId.split("|");
      if (modelId && inferenceId && assetIndex) {
        Track(AnalyticsEvents.AssetZoom.UsedDeprecatedOpenAssetId);
        setAssetId(undefined);
        setDeprecatedAsset({
          modelId,
          inferenceId,
          assetIndex: parseInt(assetIndex),
        });
      }
    } else {
      if (loadedAssetId !== assetId) {
        const targetAsset = assets.find((item) => item.id === loadedAssetId);
        if (targetAsset) {
          setAssetFromList(targetAsset.meta);
        } else {
          setAssetFromList(undefined);
        }
        setAssetId((prevAssetId) => {
          if (!prevAssetId) {
            resetIsComparing();
          }
          return loadedAssetId;
        });
        setDeprecatedAsset(undefined);
      }
    }
  }, [assets, assetId, queryAssetId, resetIsComparing, priorityLevel]);

  useHotkeys(
    appShortcuts.assetPage.shortcuts.close.shortcut,
    close,
    { enabled: !!assetId },
    [close]
  );
  useHotkeys(
    appShortcuts.assetPage.shortcuts.previousItem.shortcut,
    () => switchImage("previous"),
    { enabled: !!assetId },
    [switchImage]
  );
  useHotkeys(
    appShortcuts.assetPage.shortcuts.nextItem.shortcut,
    () => switchImage("next"),
    { enabled: !!assetId },
    [switchImage]
  );

  useHotkeys(
    appShortcuts.global.shortcuts.shortcutHelp.shortcut,
    () => {
      onShortcutsToggle();
    },
    { enabled: !!assetId }
  );

  return (
    <Modal
      autoFocus={false}
      closeOnEsc
      isOpen={isOpen}
      onClose={close}
      size="full"
    >
      <ModalOverlay />
      <ModalContent>
        {isOpen && (
          <ModalBody p={0} data-testid="asset-zoom-display">
            <Deffered
              timeout={50}
              placeholder={
                <AssetZoomPlaceholder
                  onCloseRequest={close}
                  isGalleryOpen={isGalleryOpen}
                  isAsideOpen={isAsideOpen}
                />
              }
              content={
                <>
                  <ShortcutsModal
                    isOpen={isShortcutsOpen}
                    onClose={onShortcutsClose}
                    shortcuts={appShortcuts}
                  />
                  <AssetZoomHeader
                    onCloseRequest={close}
                    onRemove={handleRemove}
                    onAction={onAction}
                    model={model}
                    inference={inference}
                    asset={asset}
                  />

                  <Flex w="100%" h={`calc(100vh - ${HEADER_HEIGHT}px)`}>
                    <Flex direction="column" flex={1} h="100%">
                      {asset && isDisplayingTextureViewer && (
                        <AssetZoomTextureViewer
                          asset={asset}
                          isComparing={isComparing}
                          onToggleComparing={handleToggleComparing}
                        />
                      )}
                      {asset && isDisplayingSkyboxViewer && (
                        <AssetZoomSkyboxViewer
                          imageUrl={asset.url}
                          assetId={asset.id}
                          isDraft={asset.metadata.type === "skybox-base-360"}
                          onFullscreenToggle={handleFullscreenToggle}
                        />
                      )}
                      {!isDisplayingSkyboxViewer &&
                        !isDisplayingTextureViewer && (
                          <AssetZoomContent
                            inference={inference}
                            asset={asset}
                            onSwitchImageClick={switchImage}
                            isComparing={isComparing}
                            onToggleComparing={handleToggleComparing}
                          />
                        )}

                      <AssetZoomImageList
                        currentAsset={asset}
                        assets={assets}
                        hasMore={hasMore}
                        loadMore={loadMore}
                        isOpen={isGalleryOpen}
                        onSetIsOpen={setIsGalleryOpen}
                      />
                    </Flex>

                    <AssetZoomAside
                      model={model}
                      inference={inference}
                      asset={asset}
                      isOpen={isAsideOpen}
                      onSetIsOpen={(isOpen) => setIsAsideOpen(isOpen)}
                    />
                  </Flex>
                </>
              }
            />
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
}

// ------------------------------------

interface AssetZoomPlaceholderProps {
  isGalleryOpen: boolean;
  isAsideOpen: boolean;
  onCloseRequest: () => void;
}

function AssetZoomPlaceholder({
  isGalleryOpen,
  isAsideOpen,
  onCloseRequest,
}: AssetZoomPlaceholderProps) {
  const imageListHeight = useImageListHeight();
  return (
    <>
      <AssetZoomHeader isSkeleton onCloseRequest={onCloseRequest} />

      <Flex w="100%" h={`calc(100vh - ${HEADER_HEIGHT}px)`}>
        <Flex direction="column" flex={1} h="100%">
          <Grid flex={1} overflow="hidden" w="100%" bgColor="black">
            <Center
              overflow="hidden"
              maxW="80%"
              h="80%"
              m="auto"
              aspectRatio="1/1"
            >
              <Box w="100%" aspectRatio="1/1">
                <Skeleton w="100%" h="100%" />
              </Box>
            </Center>
          </Grid>

          {isGalleryOpen && (
            <Box
              pos="relative"
              w="100%"
              h={`${imageListHeight}px`}
              borderTopWidth={1}
              bgColor="secondary.900"
            >
              <HStack
                pos="absolute"
                top={0}
                left={0}
                align="stretch"
                h="100%"
                p={1.5}
              >
                <Skeleton w={`${imageListHeight - 12}px`} />
                <Skeleton w={`${imageListHeight - 12}px`} />
                <Skeleton w={`${imageListHeight - 12}px`} />
                <Skeleton w={`${imageListHeight - 12}px`} />
                <Skeleton w={`${imageListHeight - 12}px`} />
                <Skeleton w={`${imageListHeight - 12}px`} />
                <Skeleton w={`${imageListHeight - 12}px`} />
                <Skeleton w={`${imageListHeight - 12}px`} />
              </HStack>
            </Box>
          )}
        </Flex>

        <Aside
          id="assetZoomPlaceholder"
          title="Info"
          icon={<Icon id="Ui/Info" h="22px" />}
          open={isAsideOpen}
          onOpenToggle={() => {}}
        >
          <AsideSection id="placeholder">
            <VStack align="start" spacing={3}>
              <Skeleton w="70px" h="14px" />
              <Skeleton w="180px" h="12px" />
            </VStack>

            <VStack align="start" spacing={3}>
              <Skeleton w="50px" h="14px" />
              <VStack align="start" w="full" spacing={2}>
                <Skeleton w="90%" h="12px" />
                <Skeleton w="92%" h="12px" />
                <Skeleton w="88%" h="12px" />
                <Skeleton w="75%" h="12px" />
              </VStack>
            </VStack>

            <VStack align="start" spacing={3}>
              <Skeleton w="50px" h="14px" />
              <VStack align="start" w="full" spacing={2}>
                <Skeleton w="60%" h="12px" />
                <Skeleton w="68%" h="12px" />
                <Skeleton w="55%" h="12px" />
                <Skeleton w="62%" h="12px" />
              </VStack>
            </VStack>
          </AsideSection>
        </Aside>
      </Flex>
    </>
  );
}
