import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import useTeamHasData from "domains/teams/hooks/useTeamHasData";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { Box } from "@chakra-ui/react";

const DAYS_FOR_ENDING_SOON_WARNING = 5;

export default function SubscriptionWarning() {
  const { selectedTeam } = useTeamContext();
  const { subscription } = usePlanContext();
  const { hasData, isLoading: isDataLoading } = useTeamHasData();

  const planEndingDays =
    subscription?.plan !== "free" &&
    subscription?.renewal?.plan === "free" &&
    moment
      .utc(subscription.renewal.date)
      .subtract(DAYS_FOR_ENDING_SOON_WARNING, "days")
      .isBefore(moment.utc())
      ? moment.utc(subscription.renewal.date).diff(moment.utc(), "days")
      : undefined;

  const isEndingPlanDisplayed = typeof planEndingDays === "number";
  const isPendingDeletionDisplayed =
    !isDataLoading && selectedTeam.isFreePlan && hasData;
  const isUpgradeDisplayed =
    !isDataLoading &&
    selectedTeam.isFreePlan &&
    !hasData &&
    selectedTeam.userRole === "admin";

  if (
    !isEndingPlanDisplayed &&
    !isPendingDeletionDisplayed &&
    !isUpgradeDisplayed
  ) {
    return null;
  }

  return (
    <>
      {isEndingPlanDisplayed && (
        <Button
          tooltip={`Your Scenario subscription expires ${formatDays(
            planEndingDays
          )}. Your data will then be staged for deletion.`}
          variant="primary"
          colorScheme="warning"
          size="xs"
          leftIcon={<Icon id="Ui/Star" h="12px" />}
          onClick={() =>
            Track(AnalyticsEvents.Navigation.ClickedSubscriptionEndingSoon, {
              remainingDays: planEndingDays,
            })
          }
          internalLink={{
            pathname: "/team",
            query: {
              tab: "plans",
            },
          }}
        >
          <FormattedMessage
            id="layout.topbar.endingSoon"
            defaultMessage="Ending Soon"
          />
        </Button>
      )}

      {isPendingDeletionDisplayed && (
        <Button
          tooltip={`Your subscription ended. Subscribe to a plan to prevent data deletion.`}
          variant="primary"
          colorScheme="warning"
          size="sm"
          leftIcon={<Icon id="Ui/Star" h="12px" />}
          onClick={() =>
            Track(AnalyticsEvents.DataDeletion.ClickedTopBarDataDeletion)
          }
          internalLink={{
            pathname: "/team",
            query: {
              tab: "plans",
            },
          }}
        >
          Pending Data Deletion
        </Button>
      )}

      {isUpgradeDisplayed && (
        <Button
          variant="primary"
          data-testid="topbar-plans-button"
          size="sm"
          onClick={() => Track(AnalyticsEvents.Navigation.ClickedUpgrade)}
          internalLink={{
            pathname: "/team",
            query: {
              tab: "plans",
            },
          }}
        >
          <FormattedMessage
            id="layout.topbar.upgrade"
            defaultMessage="Upgrade"
          />
        </Button>
      )}

      <Box w="1px" h="14px" mx={1} bg="border.500" />
    </>
  );
}

// ------------------------------------

function formatDays(days: number) {
  if (days === 0) return "today";
  else if (days === 1) return "tomorrow";
  else return `in ${days} days`;
}
