import Button from "domains/ui/components/Button";

import { Checkbox, Divider, HStack, Text } from "@chakra-ui/react";

interface SelectionBarSectionLeftProps {
  count: number;
  max?: number;
  onSelectAllClick?: () => void;
  onClearClick?: () => void;
}

export default function SelectionBarSectionLeft({
  count,
  max,
  onSelectAllClick,
  onClearClick,
}: SelectionBarSectionLeftProps) {
  return (
    <HStack wrap="nowrap" spacing={3}>
      <Checkbox
        pointerEvents="none"
        colorScheme="primary"
        isChecked
        size="xl"
        variant="lightCircular"
      />

      <Text
        textColor="textPrimary"
        whiteSpace="nowrap"
        data-testid="file-manager-number-images-selected"
        size="body.bold.lg"
      >
        {`${count} selected`}
      </Text>

      {max !== undefined && (
        <Text textColor="textSecondary" whiteSpace="nowrap" size="body.md">
          {`(${max} max)`}
        </Text>
      )}

      {(onSelectAllClick || onClearClick) && (
        <Divider h={6} orientation="vertical" />
      )}

      {onSelectAllClick && (
        <Button
          variant="secondary"
          onClick={onSelectAllClick}
          data-testid="file-manager-select-all-button"
        >
          Select All
        </Button>
      )}

      {onClearClick && (
        <Button
          variant="secondary"
          onClick={onClearClick}
          isDisabled={count === 0}
          data-testid="file-manager-clear-button"
        >
          Clear
        </Button>
      )}
    </HStack>
  );
}
