import { PropsWithChildren, useCallback } from "react";
import Icon from "domains/ui/components/Icon";

import { Box, Collapse, HStack, Text, VStack } from "@chakra-ui/react";

import { useAsideContext } from "./context";

export interface AsideSectionProps extends PropsWithChildren {
  id: string;
  title?: string;
  topRightComponent?: JSX.Element;
}

export default function AsideSection({
  id,
  title,
  topRightComponent,
  children,
}: AsideSectionProps) {
  const { collapsedSections, toggleCollapsedSection } = useAsideContext();
  const isCollapsed = collapsedSections.includes(id);

  const handleCollapseClick = useCallback(() => {
    toggleCollapsedSection(id);
  }, [toggleCollapsedSection, id]);

  if (!children) return null;

  return (
    <VStack
      align="stretch"
      flex={0}
      borderBottomWidth={1}
      borderBottomColor="border.500"
      spacing={0}
    >
      {!!title && (
        <HStack
          h="52px"
          px={6}
          cursor="pointer"
          onClick={handleCollapseClick}
          spacing={3}
        >
          <HStack justify="center" w="10px">
            <Icon
              id={isCollapsed ? "Ui/ChevronRight" : "Ui/ChevronDown"}
              h={isCollapsed ? "10px" : "6px"}
              color="textSecondary"
            />
          </HStack>
          <Text flex={1} color="textSecondary" size="body.bold.md">
            {title}
          </Text>
          {topRightComponent && (
            <Box id="section-top-right-component">{topRightComponent}</Box>
          )}
        </HStack>
      )}
      <Collapse in={!isCollapsed}>
        <VStack align="stretch" pt={title ? 0 : 5} pb={5} px={6} spacing={5}>
          {children}
        </VStack>
      </Collapse>
    </VStack>
  );
}
