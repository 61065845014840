import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

export const Modal = defineMultiStyleConfig({
  baseStyle: {
    dialog: {
      maxW: "600px",
    },
    dialogContainer: {
      zIndex: "skipLink",
    },
  },

  variants: {
    inputs: {
      dialog: {
        bg: "secondary.900",
      },
      footer: {
        p: 2,
        gap: 3,
      },
    },
    action: definePartsStyle({
      dialogContainer: {
        alignItems: "center",
        bgColor: "blackAlpha.600",
        overflow: "hidden",
      },
      dialog: {
        bg: "secondary.900",
        borderWidth: 1,
        borderColor: "border.500",
        borderRadius: "3xl",
        maxW: "80vw",
        w: "700px",
        maxH: "90vh",
        overflow: "auto",
      },
      header: {
        alignItems: "start",
        w: "100%",
        px: 4,
        py: 3,
        borderColor: "border.500",
        borderBottomWidth: 1,
      },
      footer: {
        borderColor: "border.500",
        borderTopWidth: 1,
        gap: 2,
      },
    }),
    modern: definePartsStyle({
      dialogContainer: {
        alignItems: "center",
        bgColor: "blackAlpha.600",
        overflow: "hidden",
      },
      dialog: {
        alignItems: "center",
        bg: "secondary.900",
        borderWidth: "1px",
        borderColor: "border.500",
        borderRadius: "20",
        padding: "12",
        w: "80vw",
        maxH: "90vh",
        overflow: "auto",
      },
      header: {
        mb: 3,
        fontSize: 28,
        fontWeight: 600,
        p: 0,
        color: "textPrimary",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        flexDir: "column",
      },
      body: {
        p: 0,
        fontSize: 16,
        fontWeight: 400,
        "& > *": {
          color: "textSecondary",
        },
        textAlign: "center",
      },
      footer: {
        zIndex: "docked",
        mt: 12,
        flexDir: "column",
        p: 0,
        w: "100%",
        gap: 3,
        button: {
          w: "100%",
        },
      },
    }),
    search: definePartsStyle({
      overlay: {
        backdropFilter: "blur(15px)",
        bgColor: "blackAlpha.600",
      },
      dialogContainer: {
        h: "100vh",
        w: "100vw",
        justifyContent: "center",
        alignItems: "start",
      },
      dialog: {
        maxW: "unset",
        w: "600px",
        bgColor: "blackAlpha.600",
        borderRadius: "3xl",
        backdropFilter: "blur(55px)",
        p: 6,
        borderWidth: 1,
        mt: "20vh",
      },
      body: {
        p: 0,
      },
    }),
  },

  sizes: {
    "5xl": definePartsStyle({
      dialog: defineStyle({
        maxW: "800px",
      }),
    }),
  },
});
