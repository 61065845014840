import { VectorizationForm } from "domains/assets/hooks/useAssetVectorize";

export const VECTORIZATION_PRESETS = [
  "custom",
  "bw",
  "poster",
  "photo",
  "asset",
  "pixelart",
] as const;
export type VectorizationPreset = (typeof VECTORIZATION_PRESETS)[number];
export const VECTORIZATION_PRESET_LABELS: {
  [key in VectorizationPreset]: string;
} = {
  custom: "None",
  bw: "Black & White",
  poster: "Poster",
  photo: "Photo",
  asset: "Asset",
  pixelart: "Pixel Art",
};

// ------------------------------------

export const VECTORIZATION_MODES = ["spline", "none", "polygon"] as const;
export type VectorizationMode = (typeof VECTORIZATION_MODES)[number];
export const VECTORIZATION_MODE_LABELS: {
  [key in VectorizationMode]: string;
} = {
  spline: "Spline",
  none: "Pixel",
  polygon: "Polygon",
};

// ------------------------------------

export const VECTORIZATION_COLOR_MODES = ["color", "bw"] as const;
export type VectorizationColorMode = (typeof VECTORIZATION_COLOR_MODES)[number];
export const VECTORIZATION_COLOR_MODE_LABELS: {
  [key in VectorizationColorMode]: string;
} = {
  color: "Color",
  bw: "Black & White",
};

// ------------------------------------

export const VECTORIZATION_PRESET_DEFAULT_VALUES: {
  [key in Exclude<VectorizationPreset, "custom">]: Partial<VectorizationForm>;
} = {
  bw: {
    colorMode: "bw",
    filterSpeckle: 4,
    mode: "spline",
    cornerThreshold: 60,
    lengthThreshold: 4.0,
    spliceThreshold: 45,
  },
  poster: {
    colorMode: "color",
    filterSpeckle: 4,
    colorPrecision: 8,
    layerDifference: 16,
    mode: "spline",
    cornerThreshold: 60,
    lengthThreshold: 4.0,
    spliceThreshold: 45,
  },
  photo: {
    colorMode: "color",
    filterSpeckle: 10,
    colorPrecision: 8,
    layerDifference: 48,
    mode: "spline",
    cornerThreshold: 180,
    lengthThreshold: 4.0,
    spliceThreshold: 45,
  },
  asset: {
    colorMode: "color",
    filterSpeckle: 4,
    colorPrecision: 6,
    layerDifference: 16,
    mode: "spline",
    cornerThreshold: 60,
    lengthThreshold: 4.0,
    spliceThreshold: 45,
  },
  pixelart: {
    colorMode: "color",
    filterSpeckle: 1,
    colorPrecision: 8,
    layerDifference: 0,
    mode: "none",
  },
};

export const VECTORIZATION_DEFAULT_VALUES: VectorizationForm = {
  preset: "custom",
  isRemoveBackground: false,
  mode: "spline",
  colorMode: "color",
  filterSpeckle: 4,
  colorPrecision: 6,
  layerDifference: 16,
  cornerThreshold: 60,
  lengthThreshold: 4.0,
  spliceThreshold: 45,
  reference: undefined,
};
