import React from "react";
import { blinkAnimation } from "domains/commons/style";
import Icon from "domains/ui/components/Icon";

import {
  BoxProps,
  CircularProgress,
  CircularProgressLabel,
  Text,
  VStack,
} from "@chakra-ui/react";

export interface ButtonDroppableUploadProps extends Omit<BoxProps, "onPaste"> {
  type?: "image" | "file";
  dropText?: React.ReactNode;
  message?: React.ReactNode;
  isDraggingHover: boolean;
  isLoading: boolean;
  loadingProgress?: number;
  onClick: () => void;
  onDrop: (event: React.DragEvent<HTMLElement>) => void;
  onDragEnter: (event: React.DragEvent<HTMLElement>) => void;
  onDragLeave: (event: React.DragEvent<HTMLElement>) => void;
  onDragOver: (event: React.DragEvent<HTMLElement>) => void;
}

export default function ButtonDroppableUpload({
  type,
  dropText,
  message,
  isDraggingHover,
  onClick,
  onDrop,
  isLoading,
  loadingProgress,
  onDragEnter,
  onDragLeave,
  onDragOver,
  ...props
}: ButtonDroppableUploadProps) {
  return (
    <VStack
      w="100%"
      px={8}
      py={4}
      borderWidth={1}
      borderStyle="dashed"
      borderColor={isDraggingHover ? "primary.500" : "border.500"}
      borderRadius="xl"
      _hover={{
        bgColor: "whiteAlpha.200",
      }}
      pointerEvents={isLoading ? "none" : undefined}
      cursor={"pointer"}
      bgColor="whiteAlpha.50"
      data-testid="drag-and-drop-image-upload-area"
      onClick={onClick}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      spacing={2}
      {...props}
    >
      {isLoading ? (
        <CircularProgress
          color="textPrimary"
          animation={
            loadingProgress !== undefined
              ? `${blinkAnimation} 1s linear infinite`
              : undefined
          }
          capIsRound
          isIndeterminate={loadingProgress === undefined}
          size="50px"
          thickness="5px"
          trackColor="whiteAlpha.200"
          value={loadingProgress}
        >
          {loadingProgress !== undefined && (
            <CircularProgressLabel>{`${loadingProgress}%`}</CircularProgressLabel>
          )}
        </CircularProgress>
      ) : (
        <>
          <Icon id={type === "file" ? "Ui/RandomFile" : "Ui/ImgFile"} h={9} />
          <Text color="textSecondary" textAlign="center" size="body.md">
            <span>
              {dropText ??
                ((type === "file" && "Drag & drop file") ||
                  "Drag & drop, paste")}
            </span>
            <br />
            <span>{" or "}</span>
            <Text
              as="span"
              color="primary.500"
              textDecoration="underline"
              size="body.bold.md"
            >
              {type === "file" ? "import it" : "upload images"}
            </Text>
          </Text>

          {message && (
            <Text color="textSecondary" textAlign="center" size="body.sm">
              {message}
            </Text>
          )}
        </>
      )}
    </VStack>
  );
}
