import { memo, useMemo } from "react";
import MenuListMultipleChoice from "domains/ui/components/Menu/MenuListMultipleChoice";
import { NSFW_LABELS, NSFW_TYPES } from "domains/user/constants/Nsfw";
import { useUserContext } from "domains/user/contexts/UserProvider";

export interface FilterNsfwProps {
  value: string[];
  setValue: (value: string[]) => void;
  facets?: {
    [key: string]: number;
  };
}

function getLabel(label: string, count?: number) {
  if (!count) {
    return label;
  }
  return `${label} (${count})`;
}

export default memo(function FilterNsfw({
  value,
  setValue,
  facets,
}: FilterNsfwProps) {
  const { userSettings } = useUserContext();
  const isNsfwFilterDisplayedInSearch = useMemo(
    () => !!userSettings?.["search-filters-display-nsfw"],
    [userSettings]
  );

  const options = useMemo(() => {
    if (!isNsfwFilterDisplayedInSearch) {
      return [];
    }
    return NSFW_TYPES.map((nsfw) => ({
      value: nsfw,
      label: getLabel(NSFW_LABELS[nsfw], facets?.[nsfw]),
    }));
  }, [facets, isNsfwFilterDisplayedInSearch]);

  if (!isNsfwFilterDisplayedInSearch) {
    return null;
  }

  return (
    <MenuListMultipleChoice
      label="NSFW"
      options={options}
      setSelectedValues={setValue}
      selectedValues={value}
      withoutSearch
    />
  );
});
