import { useCallback, useState } from "react";
import { useJobSessionContext } from "domains/jobs/contexts/JobSessionProviderV2";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { GetJobIdApiResponse } from "infra/api/generated/api";

interface CancelButtonProps {
  job: GetJobIdApiResponse["job"] | undefined;
}

export default function CancelButton({ job }: CancelButtonProps) {
  const { cancelJob } = useJobSessionContext();
  const [isCanceling, setIsCanceling] = useState<boolean>(false);
  const isDisplayed = !!(job?.status === "queued");

  const handleClick = useCallback(async () => {
    if (!job?.jobId) return;
    try {
      setIsCanceling(true);
      await cancelJob(job?.jobId);
    } finally {
      setIsCanceling(false);
    }
  }, [cancelJob, job?.jobId]);

  if (!isDisplayed) return null;

  return (
    <Button
      leftIcon={<Icon id="Ui/Cross" h="8px" />}
      size="sm"
      variant="secondaryV2"
      isLoading={isCanceling}
      onClick={!isCanceling ? handleClick : undefined}
    >
      Cancel
    </Button>
  );
}
