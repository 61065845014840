import { useMemo } from "react";

import {
  Box,
  ButtonProps,
  PlacementWithLogical,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";

interface DoubleOptInButtonProps extends ButtonProps {
  body: JSX.Element | ((onClose: () => void) => JSX.Element);

  header?: JSX.Element;
  placement: PlacementWithLogical;
  isDisabled?: boolean;
}

const DoubleOptInButton = ({
  children,
  body,
  header,
  placement,
  isDisabled,
}: DoubleOptInButtonProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const bodyContent = useMemo(() => {
    if (typeof body === "function") {
      return body(onClose);
    }
    return body;
  }, [body, onClose]);

  return (
    <Popover
      isLazy
      isOpen={!isDisabled && isOpen}
      onClose={!isDisabled ? onClose : undefined}
      onOpen={!isDisabled ? onOpen : undefined}
      placement={placement}
    >
      <PopoverTrigger>
        <Box>{children}</Box>
      </PopoverTrigger>
      <Portal>
        <Box
          sx={{
            ".chakra-popover__popper": {
              zIndex: 1_000_000_000,
            },
          }}
        >
          <PopoverContent
            w={"max-content"}
            color="white"
            bgColor={"background.500"}
          >
            {header && <PopoverHeader>{header}</PopoverHeader>}
            <PopoverBody zIndex={"docked"}>{bodyContent}</PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
};

export default DoubleOptInButton;
