import React, { useCallback } from "react";
import { UseAssetRemoveBackgroundReturnValue } from "domains/assets/hooks/useAssetRemoveBackground";
import ColorPicker from "domains/ui/components/ColorPicker";
import Icon from "domains/ui/components/Icon";
import ScenarioInput from "domains/ui/components/ScenarioInput";

import {
  Box,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from "@chakra-ui/react";

interface RemoveBackgroundParamsProps {
  form: UseAssetRemoveBackgroundReturnValue["form"];
  setValue: UseAssetRemoveBackgroundReturnValue["setValue"];
}

export default function RemoveBackgroundParams({
  form,
  setValue,
}: RemoveBackgroundParamsProps) {
  const handleSelectColor = useCallback(
    (color: string, onClose: () => void) => {
      setValue("backgroundColor", color);
      onClose();
    },
    [setValue]
  );

  return (
    <VStack align={"start"} w={"100%"} spacing={5}>
      <Popover placement="auto">
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <ScenarioInput
                cursor={"pointer"}
                setValue={(_) => {}}
                leftComponent={
                  <Box
                    className="checkerboard"
                    overflow="hidden"
                    w="5"
                    h="5"
                    borderRadius="full"
                    bgColor={"white"}
                  >
                    <Box
                      className={
                        form.backgroundColor ? undefined : "checkerboard"
                      }
                      w="5"
                      h="5"
                      bgColor={
                        typeof form.backgroundColor === "string"
                          ? form.backgroundColor
                          : `rgba(0, 0, 0, 0)`
                      }
                    />
                  </Box>
                }
                rightComponent={
                  <IconButton
                    aria-label="Remove background color"
                    colorScheme="secondary"
                    icon={
                      <Icon
                        id="Ui/Trash"
                        color={
                          form.backgroundColor ? "textSecondary" : "transparent"
                        }
                        h="16px"
                      />
                    }
                    onClick={(e) => {
                      setValue("backgroundColor", "");
                      e.stopPropagation();
                    }}
                    size="xs"
                    variant="ghost"
                    {...(form.backgroundColor
                      ? {}
                      : {
                          _hover: {},
                        })}
                    pointerEvents={form.backgroundColor ? undefined : "none"}
                  />
                }
                value={form.backgroundColor as string}
                placeholder={"Transparent"}
              />
            </PopoverTrigger>
            <PopoverContent borderWidth={0}>
              <ColorPicker
                withAlpha
                color={form.backgroundColor ?? "#000000"}
                onChange={(color) => setValue("backgroundColor", color)}
                onSubmit={(color) => handleSelectColor(color, onClose)}
              />
            </PopoverContent>
          </>
        )}
      </Popover>
    </VStack>
  );
}
