import { FmFileImage } from "domains/file-manager/interfacesV2";
import Icon from "domains/ui/components/Icon";

import {
  Center,
  CircularProgress,
  CircularProgressLabel,
  keyframes,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

import CancelButton from "./CancelButton";

interface CardPlaceholderProps {
  file: FmFileImage;
  cardMinEdge: number;
  isCancelable?: boolean;
}

export default function CardPlaceholder({
  file,
  cardMinEdge,
  isCancelable = false,
}: CardPlaceholderProps) {
  const isVerySmall = cardMinEdge < 100;

  if (file.status === "placeholder") {
    const placeholderText = file.meta.tags.includes("no-dimensions")
      ? "? x ?"
      : `${file.width} x ${file.height}`;
    return (
      <Text
        color="secondary.700"
        textAlign="center"
        size={isVerySmall ? "body.sm" : "body.md"}
      >
        {placeholderText}
      </Text>
    );
  }

  if (file.status === "queued" || file.status === "warming-up") {
    return (
      <>
        <Center
          pos="relative"
          w="60px"
          maxW={`${cardMinEdge / 2}px`}
          h="60px"
          maxH={`${cardMinEdge / 2}px`}
          bg="linear-gradient(315deg, #1A1A1A, #2F2F2F, #434343, #2F2F2F, #1A1A1A, #2F2F2F, #434343, #2F2F2F, #1A1A1A)"
          bgSize="200% 200%"
          borderRadius="full"
          animation={`${animation} 5s linear infinite`}
          data-testid="asset-gallery-grid-cell-queued-status"
        >
          <Icon
            id={file.status === "queued" ? "Ui/Hourglass" : "Ui/Warm"}
            color="textPrimary"
            h="22px"
            maxW="50%"
            maxH="50%"
          />
        </Center>

        {!isVerySmall && (
          <VStack w="full" spacing={3}>
            <Text color="textPrimary" size="body.md">
              {file.status === "queued" ? "Queued" : "Initializing"}
            </Text>
            {isCancelable && (
              <CancelButton jobId={file.meta.metadata.parentJobId} />
            )}
          </VStack>
        )}
      </>
    );
  }

  if (file.status === "processing") {
    return (
      <>
        {file.meta.metadata.progressPercent !== undefined ? (
          <CircularProgress
            color="textPrimary"
            data-testid="asset-gallery-grid-cell-progress-status"
            size={`calc(min(${cardMinEdge / 2}px, 60px) + ${
              isVerySmall ? "3px" : "5px"
            })`}
            thickness={isVerySmall ? "3px" : "5px"}
            trackColor="rgba(255, 255, 255, 0.2)"
            value={file.meta.metadata.progressPercent}
          >
            <CircularProgressLabel>
              {`${Math.round(file.meta.metadata.progressPercent)}%`}
            </CircularProgressLabel>
          </CircularProgress>
        ) : (
          <Spinner
            w="60px"
            maxW={`${cardMinEdge / 2}px`}
            h="60px"
            maxH={`${cardMinEdge / 2}px`}
            color="textPrimary"
            data-testid="asset-gallery-grid-cell-progress-status"
            emptyColor="rgba(255, 255, 255, 0.2)"
            speed="0.75s"
            thickness={isVerySmall ? "3px" : "5px"}
          />
        )}

        {!isVerySmall && <Text size="body.md">Generating</Text>}
      </>
    );
  }

  return (
    <>
      <Icon id="Ui/AlertOctagon" color="textPrimary" maxW="50%" maxH="50%" />
      {!isVerySmall && (
        <Text color="textPrimary" textAlign="center" size="body.md">
          Generation failed
        </Text>
      )}
    </>
  );
}

// ------------------------------------

const animation = keyframes`
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
`;
