import React, { useCallback, useMemo } from "react";
import { isAssetSkybox, isAssetTexture } from "domains/assets/utils/isType";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { capitalizeFirstWord } from "domains/commons/misc";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { appShortcuts } from "domains/shortcuts/components/appShortcuts";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import Divider from "domains/ui/components/Divider";
import Icon from "domains/ui/components/Icon";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { useHandleApiError } from "infra/api/error";
import {
  GetAssetsByAssetIdApiResponse,
  usePrivacyAssetByAssetIdMutation,
} from "infra/api/generated/api";

export interface ButtonPublishProps {
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}

export default function ButtonPublish({ asset }: ButtonPublishProps) {
  const { successToast } = useScenarioToast();
  const { selectedTeam, selectedProject } = useTeamContext();
  const handleApiError = useHandleApiError();
  const [changePrivacyTrigger, { isLoading: isChangingPrivacy }] =
    usePrivacyAssetByAssetIdMutation();
  const { featureFlags } = useUserContext();
  const isAvailable = !!(
    selectedTeam.isPublic && featureFlags?.includes("feature-explore-publish")
  );

  const assetId = useMemo(() => asset?.id, [asset]);
  const assetType =
    (isAssetTexture(asset) && "texture") ||
    (isAssetSkybox(asset) && "skybox") ||
    "image";
  const isPublished = asset?.privacy === "public";

  const handlePublish = useCallback(async () => {
    if (!assetId) return;
    try {
      await changePrivacyTrigger({
        projectId: selectedProject.id,
        assetId: assetId,
        body: {
          privacy: !isPublished ? "public" : "private",
        },
      }).unwrap();
      successToast({
        title: !isPublished
          ? `${capitalizeFirstWord(assetType)} published`
          : `${capitalizeFirstWord(assetType)} unpublished`,
      });
    } catch (error) {
      handleApiError(
        error,
        !isPublished
          ? `Error publishing ${assetType}`
          : `Error unpublishing ${assetType}`
      );
    }
  }, [
    isPublished,
    assetId,
    assetType,
    changePrivacyTrigger,
    handleApiError,
    selectedProject.id,
    successToast,
  ]);

  useHotkeys(
    appShortcuts.assetPage.shortcuts.publish.shortcut,
    () => handlePublish(),
    { enabled: isAvailable }
  );

  if (!isAvailable || !assetId) return null;

  return (
    <>
      <Button
        variant="secondary"
        tooltip={isPublished ? "Unpublish" : undefined}
        tooltipProps={{ placement: "bottom" }}
        size="sm"
        isLoading={isChangingPrivacy}
        onClick={handlePublish}
        leftIcon={
          <Icon
            id={!isPublished ? "Ui/Pin/Outline" : "Ui/Pin/Solid"}
            h="16px"
          />
        }
        {...(isPublished
          ? {
              textColor: "primary.500",
              bgColor: "primary.800",
              borderWidth: 0,
              _hover: {
                bgColor: "primary.700",
              },
            }
          : {})}
        data-group
      >
        {!isPublished ? "Publish" : "Published"}
      </Button>

      <Divider mx={1} dividerProps={{ w: "1px", h: "14px" }} />
    </>
  );
}
