import { useCallback, useMemo, useRef, useState } from "react";
import AssetDownloadModal from "domains/assets/components/AssetsDownloadModal";
import useAssetRemoveBackground from "domains/assets/hooks/useAssetRemoveBackground";
import ButtonAddAssetToCollection from "domains/collections/components/ButtonAddAssetToCollection";
import { FmSelectionActionsProps } from "domains/file-manager/components/FileManagerV2/FmSelectionActions";
import {
  FileImageType,
  mapAssetsToImagesFiles,
} from "domains/file-manager/interfaces";
import { FmFileImage } from "domains/file-manager/interfacesV2";
import { downloadAllImageFiles } from "domains/file-manager/utils/downloadImageFiles";
import { mapFmFileImagesToFileImages } from "domains/file-manager/utils/mapFmFileToFile";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { GetAssetsApiResponse } from "infra/api/generated/api";

import SelectionActionDelete from "./SelectionActionDelete";
import SelectionActionDownload from "./SelectionActionDownload";
import SelectionActionEditTags from "./SelectionActionEditTags";
import SelectionActionMore from "./SelectionActionMore";
import SelectionActionRemoveBg from "./SelectionActionRemoveBg";

export type SelectionActionsCustomProps = {
  ignoreActions?: ("editTags" | "editCollections" | "removeBg")[];
  isDeleting: boolean;
  onDeleteClick: (files: FmFileImage[]) => void;
};

export type SelectionActionsProps = FmSelectionActionsProps<
  "image",
  SelectionActionsCustomProps
>;

export default function SelectionActions({
  selectedFiles,
  isDisplayed = false,
  ignoreActions = [],
  isDeleting = false,
  onDeleteClick,
}: SelectionActionsProps) {
  const { successToast, errorToast } = useScenarioToast();
  const { handleRemoveBackgroundAsset } = useAssetRemoveBackground();
  const [filesToDownload, setFilesToDownload] = useState<FileImageType[]>([]);
  const [isRemovingBg, setIsRemovingBg] = useState<boolean>(false);
  const buttonEditTagsRef = useRef<HTMLButtonElement>(null);
  const buttonDeleteRef = useRef<HTMLButtonElement>(null);
  const buttonRemoveBgRef = useRef<HTMLButtonElement>(null);

  const isLoading = isDeleting || isRemovingBg;

  const assetsForCollections = useMemo(
    () => selectedFiles.map((item) => item.meta),
    [selectedFiles]
  );

  // ----------------------------------

  const handleRemoveBgClick = useCallback(
    async (files: FileImageType[]) => {
      setIsRemovingBg(true);

      const filesWithBackgroundRemoved: GetAssetsApiResponse["assets"] = [];
      for (const file of files) {
        const data = await handleRemoveBackgroundAsset({
          assetId: file.id,
          trackingExtraParams: {
            origin: "bulk remove background",
          },
          withToast: false,
          isBulk: file.id !== files[files.length - 1].id,
        });
        if (data) {
          filesWithBackgroundRemoved.push(data);
        } else {
          break;
        }
      }

      if (filesWithBackgroundRemoved.length > 0) {
        await downloadAllImageFiles(
          mapAssetsToImagesFiles(filesWithBackgroundRemoved)
        );
      }

      if (filesWithBackgroundRemoved.length === files.length) {
        successToast({
          title: "Backgrounds removed",
        });
      } else if (filesWithBackgroundRemoved.length > 0) {
        errorToast({
          title:
            "Some backgrounds could not be removed. Successfully processed images have been downloaded.",
        });
      } else {
        errorToast({
          title: "Error removing backgrounds",
        });
      }

      setIsRemovingBg(false);
    },
    [setIsRemovingBg, handleRemoveBackgroundAsset, successToast, errorToast]
  );

  return (
    <>
      <AssetDownloadModal
        onClose={() => setFilesToDownload([])}
        files={filesToDownload}
      />

      <SelectionActionMore
        selectedFiles={selectedFiles}
        isDisplayed={isDisplayed}
        onDownloadClick={(files) =>
          setFilesToDownload(mapFmFileImagesToFileImages(files))
        }
        onEditTagsClick={
          !ignoreActions.includes("editTags")
            ? () => buttonEditTagsRef.current?.click()
            : undefined
        }
        onRemoveBgClick={
          !ignoreActions.includes("removeBg")
            ? () => buttonRemoveBgRef.current?.click()
            : undefined
        }
        onDeleteClick={() => buttonDeleteRef.current?.click()}
      />

      <SelectionActionDelete
        selectedFiles={selectedFiles}
        isDisplayed={isDisplayed}
        isDisabled={isLoading}
        isLoading={isDeleting}
        buttonRef={buttonDeleteRef}
        onClick={(selectedFiles) => onDeleteClick(selectedFiles)}
      />

      <SelectionActionDownload
        selectedFiles={selectedFiles}
        isDisplayed={isDisplayed}
        isDisabled={isLoading}
        onClick={(selectedFiles) =>
          setFilesToDownload(mapFmFileImagesToFileImages(selectedFiles))
        }
      />

      {!ignoreActions.includes("removeBg") && (
        <SelectionActionRemoveBg
          selectedFiles={selectedFiles}
          isDisplayed={isDisplayed}
          isDisabled={isLoading}
          isLoading={isRemovingBg}
          buttonRef={buttonRemoveBgRef}
          onClick={(selectedFiles) =>
            handleRemoveBgClick(mapFmFileImagesToFileImages(selectedFiles))
          }
        />
      )}

      {!ignoreActions.includes("editTags") && (
        <SelectionActionEditTags
          selectedFiles={selectedFiles}
          isDisplayed={isDisplayed}
          buttonRef={buttonEditTagsRef}
        />
      )}

      {!ignoreActions.includes("editCollections") && (
        <ButtonAddAssetToCollection
          assets={assetsForCollections}
          menuPlacement="top"
        />
      )}
    </>
  );
}
