import { useEffect, useMemo, useRef, useState } from "react";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  GetAssetsByAssetIdApiResponse,
  useGetAssetsByAssetIdQuery,
  useLazyGetAssetsByAssetIdQuery,
} from "infra/api/generated/api";

import { skipToken } from "@reduxjs/toolkit/dist/query";

interface UseAssetReferenceParentsArgs {
  type: "reference";
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}

interface UseAssetControlParentsArgs {
  type: "control";
  parentAssetId: string | undefined;
}

type UseAssetParentsArgs =
  | UseAssetReferenceParentsArgs
  | UseAssetControlParentsArgs;

export default function useAssetDirectParent(args: UseAssetParentsArgs) {
  const { type, asset, parentAssetId } = {
    asset: undefined,
    parentAssetId: undefined,
    ...args,
  };
  const { selectedProject } = useTeamContext();

  const assetId = useMemo(() => {
    if (type === "reference") {
      return asset?.metadata.parentId;
    }
    if (type === "control") {
      return parentAssetId;
    }
    return undefined;
  }, [asset, parentAssetId, type]);

  const { currentData: data } = useGetAssetsByAssetIdQuery(
    assetId
      ? {
          assetId,
          projectId: selectedProject.id,
        }
      : skipToken
  );

  return useMemo(() => {
    if (!data || !assetId || data.asset.id !== assetId) {
      return undefined;
    }
    return data.asset;
  }, [assetId, data]);
}

// ------------------------------------

type Parents = {
  image: GetAssetsByAssetIdApiResponse["asset"];
  modeMap?: GetAssetsByAssetIdApiResponse["asset"];
};

export function useAssetParents(args: UseAssetParentsArgs) {
  const { type, asset, parentAssetId } = {
    asset: undefined,
    parentAssetId: undefined,
    ...args,
  };
  const { selectedProject } = useTeamContext();
  const directParent = useAssetDirectParent(args);
  const [getAssetTrigger] = useLazyGetAssetsByAssetIdQuery();
  const [parents, setParents] = useState<Parents | undefined>();
  const parentsRef = useRef<Parents>();

  useEffect(() => {
    if (
      !directParent ||
      directParent.metadata.type !== "detection" ||
      !directParent.metadata.parentId ||
      (type === "reference" &&
        (!asset || !asset.metadata.type.startsWith("inference-controlnet"))) ||
      (type === "control" && !parentAssetId)
    ) {
      parentsRef.current = directParent
        ? {
            image: directParent,
            modeMap: undefined,
          }
        : undefined;
      setParents(parentsRef.current);
      return;
    }

    if (directParent.metadata.parentId === parentsRef?.current?.image.id) {
      parentsRef.current = {
        image: parentsRef.current.image,
        modeMap: directParent,
      };
      setParents(parentsRef.current);
      return;
    }

    if (parentsRef?.current) {
      parentsRef.current = undefined;
      setParents(parentsRef.current);
    }

    getAssetTrigger({
      projectId: selectedProject.id,
      assetId: directParent.metadata.parentId,
    })
      .unwrap()
      .then((detectionParentData) => {
        parentsRef.current = {
          image: detectionParentData.asset,
          modeMap: directParent,
        };
      })
      .catch((_err) => {
        parentsRef.current = undefined;
      })
      .finally(() => {
        setParents(parentsRef.current);
      });
  }, [
    getAssetTrigger,
    asset,
    directParent,
    selectedProject.id,
    type,
    parentAssetId,
  ]);

  return parents;
}
