import { WheelEventHandler } from "react";

/** Prevent scrolling on input number because it trigger increment/decrement
 * improved solution from https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number/69497807#69497807
 */
export const disabledOnWheel: WheelEventHandler<HTMLInputElement> = (e) => {
  const target = e.target as HTMLInputElement;

  target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();

  // Refocus immediately, on the next tick (after the current
  // function is done)
  setTimeout(() => {
    target.focus();
  }, 0);
};
