import { ReframeForm } from "domains/reframe/interfaces/Reframe";
import Slider from "domains/ui/components/Slider";
import WithLabelAndTooltip from "domains/ui/components/WithLabelAndTooltip";

interface InputControlsProps {
  form: ReframeForm;
  onFormChange: <T extends keyof ReframeForm>(
    key: T,
    value: ReframeForm[T]
  ) => void;
}

const InputControls = ({ form, onFormChange }: InputControlsProps) => {
  return (
    <>
      <WithLabelAndTooltip
        label="Input Scale"
        tooltip="Adjust the size of the input image without affecting the output dimensions"
      >
        <Slider
          min={1}
          max={100}
          step={1}
          value={Math.round(form.inputScale * 100)}
          withNumberInput
          onChange={(value) => {
            onFormChange("inputScale", Math.round(value) / 100);
          }}
          valueStyleProps={{
            w: "5ch",
          }}
        />
      </WithLabelAndTooltip>

      <WithLabelAndTooltip label="Overlap" tooltip="This area will be changed">
        <Slider
          min={0}
          max={100}
          step={1}
          value={form.overlapPercentage}
          withNumberInput
          onChange={(value) => onFormChange("overlapPercentage", value)}
          valueText={`${Math.round(form.overlapPercentage)}%`}
        />
      </WithLabelAndTooltip>
    </>
  );
};

export default InputControls;
