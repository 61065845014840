import React, { PropsWithChildren, RefObject } from "react";
import { FmFile, FmFileType } from "domains/file-manager/interfacesV2";

import { Box, HStack, Spinner, Text } from "@chakra-ui/react";

import { FmCardProps } from "./FmCard";

export type FmGridProps<
  T extends FmFileType = FmFileType,
  P extends object = object
> = {
  files: FmFile<T>[];

  isSkeleton?: boolean;

  isSelectable?: boolean;
  isSelectionForced?: boolean;
  isSelectionMaxReached?: boolean;
  selection: string[];
  onSelect: FmCardProps<T>["onSelect"];

  columnsCount: number;

  onCardClick?: (file: FmFile<T>) => void;
  withLink?: boolean;

  hasMore?: boolean;
  onEndReached?: () => void;

  scrollRef: RefObject<HTMLDivElement> | undefined;
  containerRef: RefObject<HTMLDivElement>;
} & P;

// ------------------------------------

export interface FmGridContainerProps extends PropsWithChildren {
  hasSelection?: boolean;
  hasMore?: boolean;
  loadingText?: React.ReactNode;
}

export default React.memo(
  React.forwardRef<HTMLDivElement, FmGridContainerProps>(
    function FmGridContainer(
      {
        hasSelection = false,
        hasMore = false,
        loadingText = "Loading",
        children,
      },
      ref
    ) {
      return (
        <Box
          ref={ref}
          w="full"
          minH="1px"
          pb={hasSelection ? "90px" : "0px"}
          data-outside-click-excluded="true"
          data-testid="asset-gallery-grid"
        >
          {children}

          {hasMore && (
            <HStack
              key="loader"
              alignSelf={"center"}
              w="100%"
              maxW="max-content"
              my={6}
              marginX="auto"
            >
              <Text fontSize={"1.5em"}>{loadingText}</Text>
              <Spinner />
            </HStack>
          )}
        </Box>
      );
    }
  )
);
