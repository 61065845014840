import { useCallback, useState } from "react";
import { FileImageType } from "domains/file-manager/interfaces";
import ModelTrain from "domains/models/components/ModelTrain";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "domains/ui/components/Modal";
import { PostModelsApiResponse } from "infra/api/generated/api";

import { Text } from "@chakra-ui/react";

export interface ModalTrainModelProps {
  isOpen: boolean;
  onClose: () => void;
  initialAssets?: FileImageType[];
}

export default function ModalTrainModel({
  isOpen,
  onClose,
  initialAssets,
}: ModalTrainModelProps) {
  const { errorToast } = useScenarioToast();
  const [modelId, setModelId] = useState<string | undefined>();

  // ----------------------------------

  const handleCancelClick = useCallback(() => {
    setModelId(undefined);
    onClose();
  }, [setModelId, onClose]);

  const handleTrain = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCreateModel = useCallback(
    (id: PostModelsApiResponse["model"]["id"]) => {
      setModelId(id);
    },
    [setModelId]
  );

  const handleAlreadyTrained = useCallback(() => {
    errorToast({ title: "Model already trained" });
    onClose();
  }, [onClose, errorToast]);

  // ----------------------------------

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={handleCancelClick}
      variant="action"
    >
      <ModalOverlay data-outside-click-excluded="true" />
      <ModalContent
        overflow="hidden"
        w="90%"
        maxW="90%"
        h="90vh"
        maxH="90vh"
        p={0}
        // eslint-disable-next-line
        // @ts-ignore
        containerProps={{ "data-outside-click-excluded": true }}
      >
        <ModalCloseButton />
        <ModalHeader>
          <Text flex={1} textAlign="left" size="body.bold.lg">
            Train a Model
          </Text>
        </ModalHeader>

        <ModalBody overflow="hidden" w="100%" p={0}>
          {isOpen && (
            <ModelTrain
              id={modelId}
              isInsideModal
              initialAssets={initialAssets}
              onTrain={handleTrain}
              onCreateModel={handleCreateModel}
              onAlreadyTrained={handleAlreadyTrained}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
