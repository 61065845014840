import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

export function isAssetSkybox(
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined
) {
  return ["skybox-base-360", "upscale-skybox"].includes(
    asset?.metadata.type ?? ""
  );
}

export function isAssetInferenceTexture(
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined
) {
  return [
    "inference-txt2img-texture",
    "inference-img2img-texture",
    "inference-reference-texture",
    "inference-controlnet-texture",
  ].includes(asset?.metadata.type ?? "");
}

export function isAssetTexture(
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined
) {
  return (asset?.metadata.type ?? "").includes("texture");
}

export function isAssetUpscaledTexture(
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined
) {
  return ["upscale-texture"].includes(asset?.metadata.type ?? "");
}
