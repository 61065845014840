import { ComponentStyleConfig, defineStyleConfig } from "@chakra-ui/react";

export const Switch: ComponentStyleConfig = defineStyleConfig({
  baseStyle: {
    track: {
      borderWidth: 1,
      borderColor: "border.500",
      borderRadius: "full",
      _checked: {
        bg: "primary.500",
      },
      bg: "whiteAlpha.100",
    },
  },
});
