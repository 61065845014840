import Button from "domains/ui/components/Button";

export interface HeaderClearSessionProps {
  onClear: () => void;
  isDisabled?: boolean;
}

export default function HeaderClearSession({
  onClear,
  isDisabled,
}: HeaderClearSessionProps) {
  return (
    <Button
      onClick={onClear}
      variant="secondary"
      tooltip="Clear the current session. Images won't be deleted"
      size="sm"
      isDisabled={isDisabled}
    >
      Clear Session
    </Button>
  );
}
