import { ReferenceImage } from "domains/inference/interfaces/InferenceGenerator";

export type UpscaleForm = {
  style: UpscaleStyle;
  preset: UpscalePreset | undefined;
  prompt: string;
  negativePrompt: string;
  promptFidelity: number;
  imageFidelity: number;
  creativity: number;
  fractality: number;
  detailsLevel: number;
  seed: string | undefined;
  scalingFactor: number;
  creativityDecay: number;
  tileStyle: boolean;
  styleImages: ReferenceImage[];
  styleImagesFidelity: number;
  overrideEmbeddings: boolean;
  targetWidth: number | undefined;
  reference:
    | {
        src: string;
        assetId?: string;
        dimensions?: { width: number; height: number };
      }
    | undefined;
};

export const UPSCALE_STYLES = [
  "standard",
  "cartoon",
  "anime",
  "comic",
  "minimalist",
  "photography",
  "3d-rendered",
] as const;
export type UpscaleStyle = (typeof UPSCALE_STYLES)[number];
export const UPSCALE_STYLE_LABELS: {
  [key in UpscaleStyle]: string;
} = {
  standard: "Standard",
  cartoon: "Cartoon",
  anime: "Anime",
  comic: "Comic",
  minimalist: "Minimalist",
  photography: "Photorealistic",
  "3d-rendered": "3D Rendered",
};

export const UPSCALE_PRESETS = ["precise", "balanced", "creative"] as const;
export type UpscalePreset = (typeof UPSCALE_PRESETS)[number];
export const UPSCALE_PRESET_LABELS: {
  [key in UpscalePreset]: string;
} = {
  precise: "Precise",
  balanced: "Balanced",
  creative: "Creative",
};

export type UpscaleStyleDefaultValues = {
  [key in UpscalePreset]: Omit<
    UpscaleForm,
    | "prompt"
    | "negativePrompt"
    | "seed"
    | "scalingFactor"
    | "creativityDecay"
    | "tileStyle"
    | "styleImages"
    | "styleImagesFidelity"
    | "overrideEmbeddings"
    | "targetWidth"
    | "reference"
  >;
};

export const UPSCALE_DEFAULT_VALUES: {
  [key in UpscaleStyle]: UpscaleStyleDefaultValues;
} = {
  standard: {
    precise: {
      style: "standard",
      preset: "precise",
      promptFidelity: 60,
      imageFidelity: 80,
      creativity: 0,
      fractality: 0,
      detailsLevel: 0,
    },
    balanced: {
      style: "standard",
      preset: "balanced",
      promptFidelity: 60,
      imageFidelity: 60,
      creativity: 55,
      fractality: 40,
      detailsLevel: 7,
    },
    creative: {
      style: "standard",
      preset: "creative",
      promptFidelity: 60,
      imageFidelity: 60,
      creativity: 80,
      fractality: 50,
      detailsLevel: 15,
    },
  },
  cartoon: {
    precise: {
      style: "cartoon",
      preset: "precise",
      promptFidelity: 60,
      imageFidelity: 80,
      creativity: 0,
      fractality: 0,
      detailsLevel: 0,
    },
    balanced: {
      style: "cartoon",
      preset: "balanced",
      promptFidelity: 60,
      imageFidelity: 50,
      creativity: 45,
      fractality: 30,
      detailsLevel: 7,
    },
    creative: {
      style: "cartoon",
      preset: "creative",
      promptFidelity: 60,
      imageFidelity: 50,
      creativity: 65,
      fractality: 35,
      detailsLevel: 15,
    },
  },
  anime: {
    precise: {
      style: "anime",
      preset: "precise",
      promptFidelity: 60,
      imageFidelity: 80,
      creativity: 0,
      fractality: 0,
      detailsLevel: 0,
    },
    balanced: {
      style: "anime",
      preset: "balanced",
      promptFidelity: 60,
      imageFidelity: 50,
      creativity: 45,
      fractality: 40,
      detailsLevel: 7,
    },
    creative: {
      style: "anime",
      preset: "creative",
      promptFidelity: 60,
      imageFidelity: 60,
      creativity: 80,
      fractality: 50,
      detailsLevel: 15,
    },
  },
  comic: {
    precise: {
      style: "comic",
      preset: "precise",
      promptFidelity: 60,
      imageFidelity: 80,
      creativity: 0,
      fractality: 0,
      detailsLevel: 0,
    },
    balanced: {
      style: "comic",
      preset: "balanced",
      promptFidelity: 60,
      imageFidelity: 50,
      creativity: 45,
      fractality: 40,
      detailsLevel: 7,
    },
    creative: {
      style: "comic",
      preset: "creative",
      promptFidelity: 70,
      imageFidelity: 60,
      creativity: 65,
      fractality: 50,
      detailsLevel: 15,
    },
  },
  minimalist: {
    precise: {
      style: "minimalist",
      preset: "precise",
      promptFidelity: 60,
      imageFidelity: 80,
      creativity: 0,
      fractality: 0,
      detailsLevel: 0,
    },
    balanced: {
      style: "minimalist",
      preset: "balanced",
      promptFidelity: 60,
      imageFidelity: 60,
      creativity: 45,
      fractality: 40,
      detailsLevel: 5,
    },
    creative: {
      style: "minimalist",
      preset: "creative",
      promptFidelity: 60,
      imageFidelity: 70,
      creativity: 70,
      fractality: 50,
      detailsLevel: 10,
    },
  },
  photography: {
    precise: {
      style: "photography",
      preset: "precise",
      promptFidelity: 60,
      imageFidelity: 80,
      creativity: 0,
      fractality: 0,
      detailsLevel: 0,
    },
    balanced: {
      style: "photography",
      preset: "balanced",
      promptFidelity: 60,
      imageFidelity: 60,
      creativity: 40,
      fractality: 40,
      detailsLevel: 7,
    },
    creative: {
      style: "photography",
      preset: "creative",
      promptFidelity: 60,
      imageFidelity: 60,
      creativity: 80,
      fractality: 50,
      detailsLevel: 15,
    },
  },
  "3d-rendered": {
    precise: {
      style: "3d-rendered",
      preset: "precise",
      promptFidelity: 60,
      imageFidelity: 80,
      creativity: 0,
      fractality: 0,
      detailsLevel: 0,
    },
    balanced: {
      style: "3d-rendered",
      preset: "balanced",
      promptFidelity: 60,
      imageFidelity: 50,
      creativity: 45,
      fractality: 40,
      detailsLevel: 7,
    },
    creative: {
      style: "3d-rendered",
      preset: "creative",
      promptFidelity: 60,
      imageFidelity: 60,
      creativity: 75,
      fractality: 50,
      detailsLevel: 15,
    },
  },
};
