import { useCallback, useMemo, useState } from "react";
import { getImageDimensions } from "domains/commons/utils/getImageDimensions";
import { ReferenceImage } from "domains/inference/interfaces/InferenceGenerator";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { DEFAULT_SKYBOX_UPSCALE_FORM } from "domains/skyboxes/context/SkyboxUpscaleProvider";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import { removeCuFromType } from "infra/api/ExludeCU";
import {
  GetAssetsByAssetIdApiResponse,
  GetJobIdApiResponse,
  PostSkyboxUpscale360InferencesApiArg,
  useLazyGetAssetsByAssetIdQuery,
  usePostSkyboxUpscale360InferencesMutation,
} from "infra/api/generated/api";
import { useLazyGetAssetsBulkQuery } from "infra/store/apiSlice";
import _ from "lodash";

export type SkyboxUpscaleForm = {
  reference:
    | {
        src: string;
        assetId: string;
        dimensions?: {
          width: number;
          height: number;
        };
      }
    | undefined;
  prompt: string;
  negativePrompt: string;
  seed: string | undefined;
  scalingFactor: number;
  targetWidth: number | undefined;
  styleImages: ReferenceImage[];
  styleFidelity: number;
  detailsLevel: number;
};

export const SKYBOX_UPSCALE_DEFAULT_FORM: SkyboxUpscaleForm = {
  reference: undefined,
  prompt: "",
  negativePrompt: "",
  seed: undefined,
  scalingFactor: 2,
  targetWidth: undefined,
  styleImages: [],
  styleFidelity: 25,
  detailsLevel: 15,
};

// ------------------------------------

export interface UseSkyboxUpscaleReturnValue {
  form: SkyboxUpscaleForm;
  setValue: <K extends keyof SkyboxUpscaleForm>(
    key: K,
    value: SkyboxUpscaleForm[K]
  ) => void;
  setForm: React.Dispatch<React.SetStateAction<SkyboxUpscaleForm>>;
  setReference: (
    image: string,
    assetId?: string,
    assetMeta?: GetAssetsByAssetIdApiResponse["asset"]
  ) => void;
  importAsset: (assetId: string) => Promise<void>;
  reloadUpscale: (assetId: string) => Promise<void>;
  upscale: (props: {
    assetId?: string;
    assetWidth?: number;
    trackingExtraParams: Record<string, unknown>;
  }) => Promise<GetJobIdApiResponse["job"] | undefined>;
  isUpscaleLoading?: boolean;
}

export default function useSkyboxUpscale({
  onValueChange,
}: {
  onValueChange?: (key: string) => void;
} = {}): UseSkyboxUpscaleReturnValue {
  const { selectedProject } = useTeamContext();
  // const { toggleCollapsedSection } = useSectionsContext();
  const [triggerSkyboxUpscale] = usePostSkyboxUpscale360InferencesMutation();
  const [getAssetById] = useLazyGetAssetsByAssetIdQuery();
  const [getAssetsBulkTrigger] = useLazyGetAssetsBulkQuery();
  const { infoToast, errorToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { showLimitModal } = usePlanContext();
  const [isUpscaleLoading, setIsUpscaleLoading] = useState<boolean>(false);
  const [form, setForm] = useState<SkyboxUpscaleForm>({
    ...SKYBOX_UPSCALE_DEFAULT_FORM,
  });

  // ----------------------------------

  const setValue = useCallback(
    <K extends keyof SkyboxUpscaleForm>(
      key: K,
      value: SkyboxUpscaleForm[K]
    ) => {
      setForm((form) => {
        return { ...form, [key]: value };
      });
    },
    []
  );

  const setReference = useCallback(
    async (
      image: string,
      assetId?: string,
      assetMeta?: GetAssetsByAssetIdApiResponse["asset"]
    ) => {
      if (!image || !assetId) {
        setValue("reference", undefined);
        return;
      }

      const { width, height } = await (async () => {
        if (assetMeta?.metadata.width && assetMeta?.metadata.height) {
          return {
            width: assetMeta.metadata.width,
            height: assetMeta.metadata.height,
          };
        } else if (image) {
          return await getImageDimensions(image);
        } else {
          return { width: undefined, height: undefined };
        }
      })();

      setValue("reference", {
        src: image,
        assetId,
        dimensions: width ? { width, height } : undefined,
      });

      if (assetMeta) {
        setValue("prompt", assetMeta.metadata.prompt ?? "");
        setValue("negativePrompt", assetMeta.metadata.negativePrompt ?? "");
        onValueChange?.("prompt");
        onValueChange?.("negativePrompt");
      }
    },
    [onValueChange, setValue]
  );

  const importAsset = useCallback(
    async (assetId: string) => {
      const asset = await getAssetById({
        projectId: selectedProject.id,
        assetId,
      });
      const importedAsset = asset?.data?.asset;
      if (!importedAsset) {
        return;
      }

      const styleAssetIds = importedAsset.metadata.styleImages ?? [];
      const referenceImageAssetIds = _.compact([...styleAssetIds]);

      const referenceImageAssets =
        referenceImageAssetIds.length > 0
          ? await getAssetsBulkTrigger({
              projectId: selectedProject.id,
              body: {
                assetIds: referenceImageAssetIds,
              },
            }).unwrap()
          : { assets: [] };

      const styleImages = styleAssetIds.length
        ? referenceImageAssets.assets.filter((asset) =>
            styleAssetIds.includes(asset.id)
          )
        : [];

      await setReference(importedAsset.url, importedAsset.id, importedAsset);
      setForm((form) => ({
        ...form,
        prompt: importedAsset.metadata.prompt ?? "",

        negativePrompt: importedAsset.metadata.negativePrompt ?? "",
        styleImages: styleImages.map((asset) => ({
          src: asset.url,
          assetId: asset.id,
        })),
        styleFidelity:
          importedAsset.metadata.styleFidelity !== undefined
            ? importedAsset.metadata.styleFidelity
            : SKYBOX_UPSCALE_DEFAULT_FORM.styleFidelity,
      }));

      if (importedAsset.metadata.prompt) {
        onValueChange?.("prompt");
      }
      if (importedAsset.metadata.negativePrompt) {
        onValueChange?.("negativePrompt");
      }
      if (styleImages.length) {
        onValueChange?.("styleImages");
      }
    },
    [
      getAssetById,
      selectedProject.id,
      getAssetsBulkTrigger,
      setReference,
      onValueChange,
    ]
  );

  const reloadUpscale = useCallback(
    async (assetId: string) => {
      const asset = await getAssetById({
        projectId: selectedProject.id,
        assetId,
      });
      const importedAsset = asset?.data?.asset;
      if (!importedAsset) {
        return;
      }

      const parentId = importedAsset.metadata.parentId;
      if (!parentId) {
        return;
      }
      const styleAssetIds = importedAsset.metadata.styleImages ?? [];
      const referenceImageAssetIds = _.compact([parentId, ...styleAssetIds]);

      const referenceImageAssets = await getAssetsBulkTrigger({
        projectId: selectedProject.id,
        body: {
          assetIds: referenceImageAssetIds,
        },
      }).unwrap();

      const parentImage = parentId
        ? referenceImageAssets.assets.find((asset) => asset.id === parentId)
        : undefined;
      const styleImages = styleAssetIds.length
        ? referenceImageAssets.assets.filter((asset) =>
            styleAssetIds.includes(asset.id)
          )
        : [];

      if (!parentImage) {
        return;
      }

      await setReference(parentImage.url, parentImage.id, parentImage);
      setForm((form) => ({
        ...form,
        prompt: importedAsset.metadata.prompt ?? "",
        negativePrompt: importedAsset.metadata.negativePrompt ?? "",
        scalingFactor: importedAsset.metadata.scalingFactor ?? 2,
        seed: importedAsset.metadata.seed ?? DEFAULT_SKYBOX_UPSCALE_FORM.seed,
        targetWidth:
          importedAsset.metadata.targetWidth ??
          SKYBOX_UPSCALE_DEFAULT_FORM.targetWidth,

        styleImages: styleImages.map((asset) => ({
          src: asset.url,
          assetId: asset.id,
        })),
        styleFidelity:
          importedAsset.metadata.styleFidelity !== undefined
            ? importedAsset.metadata.styleFidelity
            : SKYBOX_UPSCALE_DEFAULT_FORM.styleFidelity,
        detailsLevel:
          importedAsset.metadata.detailsLevel ??
          DEFAULT_SKYBOX_UPSCALE_FORM.detailsLevel,
      }));

      if (importedAsset.metadata.prompt) {
        onValueChange?.("prompt");
      }
      if (importedAsset.metadata.negativePrompt) {
        onValueChange?.("negativePrompt");
      }
      if (styleImages.length) {
        onValueChange?.("styleImages");
      }
    },
    [
      getAssetById,
      selectedProject.id,
      getAssetsBulkTrigger,
      setReference,
      onValueChange,
    ]
  );

  // ----------------------------------

  const upscale = useCallback(
    async ({
      assetId,
      assetWidth,
      trackingExtraParams,
    }: {
      assetId?: string;
      assetWidth?: number;
      trackingExtraParams: Record<string, unknown>;
    }) => {
      try {
        const body = getSkyboxUpscaleParamsBody({
          form,
          assetId,
          assetWidth,
        });
        if (!body) return;

        setIsUpscaleLoading(true);
        infoToast({
          title: "Enhancing skybox",
        });

        const upscaleResponse = await triggerSkyboxUpscale({
          projectId: selectedProject.id,
          body,
        })
          .unwrap()
          .then(removeCuFromType);

        Track(AnalyticsEvents.Skybox.UpscaledSkybox, {
          ...body,
          ...trackingExtraParams,
        });

        return upscaleResponse.job;
      } catch (error: any) {
        handleApiError(error, "Error enhancing skybox", {
          quota: () => {
            if (_.get(error, "data.details.remainingSeconds")) {
              showLimitModal("planCooldown", {
                timeout: error.data.details.remainingSeconds,
                type: "upscale",
              });
            } else if (
              _.get(error, "data.reason").includes(
                "You have reached your plan's limit."
              )
            ) {
              showLimitModal("notEnoughCreativeUnits");
            } else {
              errorToast({
                title: "Error enhancing skybox",
                description: _.get(error, "data.reason"),
              });
            }
          },
        });
      } finally {
        setIsUpscaleLoading(false);
      }
    },
    [
      form,
      selectedProject.id,
      infoToast,
      errorToast,
      handleApiError,
      showLimitModal,
      triggerSkyboxUpscale,
    ]
  );

  // ----------------------------------

  return useMemo(
    () => ({
      form,
      setForm,
      setValue,
      setReference,
      importAsset,
      reloadUpscale,
      upscale,
      isUpscaleLoading,
    }),
    [
      form,
      setValue,
      setReference,
      importAsset,
      reloadUpscale,
      upscale,
      isUpscaleLoading,
    ]
  );
}

// ------------------------------------

export function getSkyboxUpscaleParamsBody({
  form,
  assetId,
  assetWidth,
}: {
  form: SkyboxUpscaleForm;
  assetId?: string;
  assetWidth?: number;
}): PostSkyboxUpscale360InferencesApiArg["body"] | undefined {
  const image = assetId ?? form.reference?.assetId;
  const imageWidth = assetWidth ?? form.reference?.dimensions?.width ?? 1_024;

  if (!image) return;
  const styleImagesIds = form.styleImages.map(
    (styleImage) => styleImage.assetId
  );

  return {
    image,
    prompt: form.prompt,
    negativePrompt: form.negativePrompt,
    seed: form.seed ? parseInt(form.seed) : undefined,
    targetWidth: form.targetWidth ?? imageWidth * form.scalingFactor,
    ...(styleImagesIds.length > 0 && {
      styleImages: styleImagesIds,
      styleFidelity: form.styleFidelity,
    }),
  };
}
