import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { AppRootState } from "infra/store/store";

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

export function updateQueryDataByUpdatingTags(
  type: "asset" | "model",
  id: string,
  tagsToAdd: string[],
  tagsToDelete: string[],
  {
    dispatch,
    getState,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    getState: () => AppRootState;
  }
) {
  const undoList: (() => void)[] = [];
  const undo = () => {
    undoList.forEach((undo) => undo());
  };

  for (const {
    endpointName,
    originalArgs,
  } of apiSlice.util.selectInvalidatedBy(getState(), [
    API_TAGS.search,
    ...(type === "asset"
      ? [
          {
            type: API_TAGS.asset,
            id: `assetId:${id}`,
          },
        ]
      : [
          {
            type: API_TAGS.model,
            id: `modelId:${id}`,
          },
        ]),
  ]) as SelectedInvalidatedByTag[]) {
    if (
      (endpointName === "getAssetsByAssetId" && type === "asset") ||
      (endpointName === "getModelsByModelId" && type === "model")
    ) {
      const update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (!draft) {
            return;
          }

          if (type === "asset" && "asset" in draft) {
            draft.asset.tags = getNewTags(
              draft.asset.tags,
              tagsToAdd,
              tagsToDelete
            );
          } else if (type === "model" && "model" in draft) {
            draft.model.tags = getNewTags(
              draft.model.tags,
              tagsToAdd,
              tagsToDelete
            );
          }
        })
      );
      undoList.push(update.undo);
    }
  }

  for (const {
    endpointName,
    originalArgs,
  } of apiSlice.util.selectInvalidatedBy(getState(), [
    API_TAGS.search,
    ...(type === "asset" ? [API_TAGS.asset] : [API_TAGS.model]),
  ]) as SelectedInvalidatedByTag[]) {
    if (endpointName === "getAssets") {
      const update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (!draft) {
            return;
          }

          const index = draft.assets.findIndex((item) => item.id === id);
          if (index === -1) {
            return;
          }

          draft.assets[index].tags = getNewTags(
            draft.assets[index].tags,
            tagsToAdd,
            tagsToDelete
          );
        })
      );
      undoList.push(update.undo);
    }

    if (endpointName === "getModels") {
      const update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (!draft) {
            return;
          }

          const index = draft.models.findIndex((item) => item.id === id);
          if (index === -1) {
            return;
          }

          draft.models[index].tags = getNewTags(
            draft.models[index].tags,
            tagsToAdd,
            tagsToDelete
          );
        })
      );
      undoList.push(update.undo);
    }

    if (
      endpointName === "getAssetsBulk" &&
      type === "asset" &&
      originalArgs.body.assetIds.includes(id)
    ) {
      const update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (!draft) {
            return;
          }

          const index = draft.assets.findIndex((item) => item.id === id);
          if (index === -1) {
            return;
          }

          draft.assets[index].tags = getNewTags(
            draft.assets[index].tags,
            tagsToAdd,
            tagsToDelete
          );
        })
      );
      undoList.push(update.undo);
    }
  }

  return { undo };
}

function getNewTags(
  currentTags: string[],
  toAdd: string[],
  toDelete: string[]
) {
  const newTags = currentTags.filter((tag) => !toDelete.includes(tag));
  newTags.push(...toAdd);
  newTags.sort();
  return newTags;
}
