export const classNameToHumanReadable = (name: string) => {
  switch (name) {
    case "custom":
      return "Custom LoRA ✨";
    case "":
      return "Don't Specify";
    case "art-style":
      return "Art style";
    case "object":
      return "Object";
    case "object/2-wheels":
      return "Wheels";
    case "animal-and-wildlife/domestic":
      return "Pets";
    case "object/automotive":
      return "Automotive";
    case "event":
      return "Event";
    case "object/toy":
      return "Toy";
    case "photography":
      return "Photography";
    case "architecture":
      return "Architecture";
    case "people":
      return "Person";
    case "animal-and-wildlife":
      return "Animals/Wildlife";
    case "characters-npcs-mobs":
      return "Characters/NPCs/Mobs";
    case "gui":
      return "Digital Assets";
    case "props":
      return "Props";
    case "resources":
      return "Resources";
    case "vehicles":
      return "Vehicles";
    case "weapons-clothing-gears":
      return "Weapons/Clothing/Gears";
    case "worlds-maps-buildings-environments":
      return "Worlds/Maps/Buildings/Environments";
    case "avatars":
      return "Avatars/People";
    default:
      return name;
  }
};
