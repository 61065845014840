import React, { useCallback, useState } from "react";
import Button from "domains/ui/components/Button";
import ColorPicker from "domains/ui/components/ColorPicker";
import { ColorPalette } from "domains/ui/components/ColorPicker/defaultColorPalettes";
import Icon from "domains/ui/components/Icon";

import {
  Box,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

interface ControlColorsProps {
  newColorPalette: ColorPalette;
  setNewColorPalette: (newColorPalette: ColorPalette) => void;
}

export default function ControlColors({
  newColorPalette,
  setNewColorPalette,
}: ControlColorsProps) {
  const [newColor, setNewColor] = useState<string | undefined>();

  const handleRemoveColor = useCallback(
    (index: number) => {
      const tmpColors = [...newColorPalette.colors];
      void tmpColors.splice(index, 1);
      setNewColorPalette({
        ...newColorPalette,
        colors: tmpColors,
      });
    },
    [newColorPalette, setNewColorPalette]
  );

  const handleSelectNewColor = useCallback(
    (color: string) => {
      setNewColorPalette({
        ...newColorPalette,
        colors: [...newColorPalette.colors, color],
      });
      setNewColor(undefined);
    },
    [newColorPalette, setNewColorPalette]
  );

  return (
    <HStack wrap={"wrap"} gap={1} spacing={0}>
      {newColorPalette.colors.map((color, index) => (
        <Box
          key={color + index}
          w={"28px"}
          h={"28px"}
          borderRadius={"md"}
          cursor={"pointer"}
          bgColor={color}
          onClick={() => handleRemoveColor(index)}
        />
      ))}
      <Popover placement="auto">
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                variant={"secondary"}
                leftIcon={<Icon id="Ui/Plus" />}
                h={"28px"}
                w={"28px"}
                minW={"unset"}
                p={0}
              />
            </PopoverTrigger>
            <PopoverContent w="228px" border={"none"}>
              <ColorPicker
                color={newColor ?? "#000000"}
                withEyeDropper
                onChange={(color) => setNewColor(color)}
                onSubmit={(color) => {
                  handleSelectNewColor(color);
                  onClose();
                }}
              />
            </PopoverContent>
          </>
        )}
      </Popover>
    </HStack>
  );
}
