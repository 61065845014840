import { useCallback, useMemo } from "react";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import { FilterAuthor } from "domains/file-manager/components/FileManagerImage/HeaderFilterAuthor";
import { useUserContext } from "domains/user/contexts/UserProvider";

interface UseAssetFilterAuthorArgs {
  forcedValue?: FilterAuthor;
  avoidPersistedState?: boolean;
}

export default function useAssetFilterAuthor({
  forcedValue,
  avoidPersistedState,
}: UseAssetFilterAuthorArgs = {}) {
  const { user } = useUserContext();
  const [author, setAuthor] = usePersistedState<FilterAuthor>(
    avoidPersistedState
      ? undefined
      : PersistedStateKey.FILE_MANAGER_FILTER_AUTHOR,
    { defaultValue: avoidPersistedState ? "all" : "me" }
  );

  const fmHeaderFilterAuthorProps = useMemo(
    () =>
      forcedValue
        ? undefined
        : {
            value: author,
            onChange: (val: FilterAuthor) => setAuthor(val),
          },
    [forcedValue, author, setAuthor]
  );

  const authorQueryArgs = useMemo(
    () => ({
      authorId: (() => {
        if ((forcedValue ?? author) === "me") {
          return user?.id;
        } else if ((forcedValue ?? author) === "all") {
          return undefined;
        } else {
          return forcedValue ?? author;
        }
      })(),
    }),
    [forcedValue, author, user?.id]
  );

  const reset = useCallback(() => {
    setAuthor(avoidPersistedState ? "all" : "me");
  }, [avoidPersistedState, setAuthor]);

  return {
    fmHeaderFilterAuthorProps,
    authorQueryArgs,
    reset,
  };
}
