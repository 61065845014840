import ButtonSingleChoice from "./ButtonSingleChoice";

export interface MenuButtonMultipleChoiceProps {
  text: string;
  onRemove?: () => void;
  dataTestId?: string;
  hasValue: boolean;
  isDisabled?: boolean;
}

export default function MenuButtonMultipleChoice({
  text,
  onRemove,
  dataTestId,
  hasValue,
  isDisabled,
}: MenuButtonMultipleChoiceProps) {
  return (
    <ButtonSingleChoice
      asMenuButton
      text={text}
      onRemove={onRemove}
      dataTestId={dataTestId}
      hasValue={hasValue}
      isDisabled={isDisabled}
    />
  );
}
