import {
  getSkyboxUpscaleParamsBody,
  SkyboxUpscaleForm,
} from "domains/assets/hooks/useSkyboxUpscale";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useGetSkyboxUpscaleCostQuery } from "infra/store/apiSlice";
import _ from "lodash";

import { skipToken } from "@reduxjs/toolkit/dist/query";

export function useSkyboxUpscaleCu(form: SkyboxUpscaleForm): {
  isCuLoading: boolean;
  cuCost?: number;
} {
  const { selectedProject } = useTeamContext();
  const debouncedForm = useDebounce(form, 500);

  const body = debouncedForm.reference?.assetId
    ? getSkyboxUpscaleParamsBody({
        form: debouncedForm,
      })
    : undefined;

  const { data: cuCostData, isFetching: isCuLoading } =
    useGetSkyboxUpscaleCostQuery(
      body ? { projectId: selectedProject.id, body } : skipToken
    );

  return {
    cuCost: cuCostData?.creativeUnitsCost,
    isCuLoading:
      isCuLoading || (!!cuCostData && !_.isEqual(form, debouncedForm)),
  };
}
