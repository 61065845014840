import React from "react";
import Button, { ButtonProps } from "domains/ui/components/Button";
import Icon, { IconId } from "domains/ui/components/Icon";

import { BoxProps, HStack, Text, TextProps, VStack } from "@chakra-ui/react";

type Type = {
  iconId: IconId;
  color: TextProps["color"];
  bgColor: BoxProps["bgColor"];
};

const TYPES: { [key: string]: Type } = {
  error: {
    iconId: "Ui/AlertError",
    color: "danger.500",
    bgColor: "dangerSoft",
  },
  success: {
    iconId: "Ui/AlertSuccess",
    color: "success.500",
    bgColor: "successSoft",
  },
  warning: {
    iconId: "Ui/AlertWarning",
    color: "warning.500",
    bgColor: "warningSoft",
  },
  info: {
    iconId: "Ui/AlertInfo",
    color: "info.500",
    bgColor: "infoSoft",
  },
};

export interface AlertProps extends Omit<BoxProps, "title"> {
  type: "error" | "success" | "warning" | "info";
  title: React.ReactNode;
  message?: string | string[] | React.ReactNode;
  actionLabel?: string;
  actionProps?: ButtonProps;
  altActionLabel?: string;
  altActionProps?: ButtonProps;
  variant?: "default" | "discrete";
}

export default function Alert({
  type,
  title,
  message,
  actionLabel,
  actionProps,
  altActionLabel,
  altActionProps,
  variant = "default",
  ...props
}: AlertProps) {
  const data = TYPES[type as keyof typeof TYPES];

  const isDiscrete = variant === "discrete";
  const padding = isDiscrete ? 2 : 4;
  const spacing = isDiscrete ? 2 : 4;
  const iconSize = isDiscrete ? { w: 4, h: 4 } : {};
  const fontSize = isDiscrete ? "sm" : "body.bold.lg";
  const buttonSize = isDiscrete ? "xs" : "sm";

  return (
    <HStack
      align="start"
      p={padding}
      borderRadius="xl"
      bgColor={isDiscrete ? undefined : data.bgColor}
      spacing={spacing}
      {...props}
    >
      <Icon id={data.iconId} color={data.color} mt={1} {...iconSize} />
      <VStack align="start" spacing={2}>
        <Text fontSize={fontSize} data-testid={`${type}-alert-title`}>
          {title}
        </Text>

        {typeof message === "string" && (
          <Text
            color="textSecondary"
            data-testid={`${type}-alert-message`}
            size="body.lg"
          >
            {message}
          </Text>
        )}

        {message instanceof Array && (
          <VStack align="stretch" spacing={0}>
            {message.map((str, idx) => (
              <HStack key={`${idx}`} align="baseline" spacing={0}>
                <HStack justify="center" w="30px">
                  <Text color="textSecondary" size="body.lg">
                    •
                  </Text>
                </HStack>
                <Text color="textSecondary" size="body.lg">
                  {str}
                </Text>
              </HStack>
            ))}
          </VStack>
        )}

        {message instanceof Object && message}

        {(!!actionLabel || !!altActionLabel) && (
          <HStack spacing={2}>
            {!!actionLabel && (
              <Button
                colorScheme="white"
                size={buttonSize}
                variant="underline"
                {...actionProps}
              >
                {actionLabel}
              </Button>
            )}

            {actionLabel && altActionLabel ? (
              <Icon id="Ui/Dot" h="4px" />
            ) : undefined}

            {!!altActionLabel && (
              <Button
                colorScheme="white"
                size={buttonSize}
                variant="underline"
                {...altActionProps}
              >
                {altActionLabel}
              </Button>
            )}
          </HStack>
        )}
      </VStack>
    </HStack>
  );
}
