import React from "react";
import ModalLibraryAssetSelect, {
  ModalLibraryAssetSelectProps,
} from "domains/image/components/ModalLibraryAssetSelect";
import Button from "domains/ui/components/Button";

import { useDisclosure } from "@chakra-ui/react";

export interface SelectLibraryAssetProps {
  isDisabled?: boolean;
  onSelect?: ModalLibraryAssetSelectProps["onSelect"];
  onMultiSelect?: ModalLibraryAssetSelectProps["onMultiSelect"];
  assetFilterTypeProps?: ModalLibraryAssetSelectProps["assetFilterTypeProps"];
  tabs: ModalLibraryAssetSelectProps["tabs"];
}

export default function SelectLibraryAsset({
  isDisabled,
  onSelect,
  onMultiSelect,
  assetFilterTypeProps,
  tabs,
}: SelectLibraryAssetProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        variant="secondary"
        size="sm"
        w="100%"
        isDisabled={isDisabled}
        onClick={onOpen}
      >
        Select from Library
      </Button>

      <ModalLibraryAssetSelect
        isOpen={isOpen}
        onClose={onClose}
        onSelect={onSelect}
        onMultiSelect={onMultiSelect}
        assetFilterTypeProps={assetFilterTypeProps}
        tabs={tabs}
      />
    </>
  );
}
