import { useEffect, useMemo } from "react";
import useRouter from "domains/navigation/hooks/useRouter";
import Loading from "domains/ui/components/Loading";
import _ from "lodash";

import { Box, Center } from "@chakra-ui/react";
import { useAuth, useClerk, useSignIn } from "@clerk/nextjs";

const publicRoutes = ["/unsubscribe", "/down"];
const loggedOutOnlyRoutes = ["/login", "/see-you-soon"];

const deferred = {} as any;

export const getAccessToken = async (): Promise<string | undefined> => {
  if (deferred.getToken) {
    return await deferred.getToken();
  }
  return undefined;
};

export function AuthRedirectGuard({ children }: { children: React.ReactNode }) {
  const { userId: clerkUserId, getToken, isLoaded, actor } = useAuth();
  const { handleRedirectCallback, setActive } = useClerk();
  const { signIn } = useSignIn();
  const router = useRouter();
  const isPublicRoute = useMemo(() => {
    return publicRoutes.includes(router.pathname);
  }, [router.pathname]);
  const isLoggedOutOnlyRoute = useMemo(() => {
    return loggedOutOnlyRoutes.includes(router.pathname);
  }, [router.pathname]);

  useEffect(() => {
    deferred.getToken = getToken;
  }, [getToken]);

  useEffect(() => {
    if (!isLoaded || !router.isReady) return;

    if (isPublicRoute) {
      return;
    }

    if (isLoggedOutOnlyRoute && !!clerkUserId) {
      void router.replace({
        pathname: "/",
      });
    }

    if (!isLoggedOutOnlyRoute && !clerkUserId) {
      const referral = router.query.referral;
      void router.replace({
        pathname: "/login",
        query: referral ? { referral } : {},
      });
    }
  }, [isLoaded, clerkUserId, router, isLoggedOutOnlyRoute, isPublicRoute]);

  /** Do not flash the public route if the user is authenticated before redirection */
  const willRedirect =
    (!isPublicRoute && isLoggedOutOnlyRoute === !!clerkUserId) ||
    router.query.handleLoginRedirect;

  useEffect(() => {
    if (signIn && router.isReady && router.query.handleLoginRedirect) {
      if (
        signIn?.firstFactorVerification?.error?.code ===
        "external_account_not_found"
      ) {
        void handleRedirectCallback(
          {
            transferable: true,
          },
          async (path) => {
            window.location.href = path;
          }
        );
      } else {
        void router.push({
          pathname: router.pathname,
          query: _.omit(router.query, "handleLoginRedirect"),
        });
      }
    }
  }, [signIn, handleRedirectCallback, router]);

  useEffect(() => {
    void (async () => {
      if (router.query.__clerk_ticket) {
        await signIn?.create({
          strategy: "ticket",
          ticket: router.query.__clerk_ticket as string,
        });
        if (signIn?.status === "complete" && signIn?.createdSessionId) {
          await setActive({
            session: signIn?.createdSessionId,
          });
        }
      }
    })();
  }, [router.query.__clerk_ticket, setActive, signIn]);

  return !isLoaded || willRedirect ? (
    <Center h="100vh">
      <Loading />
    </Center>
  ) : (
    <>
      {!!actor && (
        <Box
          pos="fixed"
          zIndex="99999999999"
          w="100vw"
          h="100vh"
          borderWidth="6px"
          borderColor="danger.500"
          pointerEvents="none"
          bgColor="transparent"
        />
      )}
      {children}
    </>
  );
}
