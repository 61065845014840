import { generatePalette } from "palette-by-numbers";

import type { DeepPartial, Theme } from "@chakra-ui/react";

/** extend additional color here */
const extendedColors: DeepPartial<
  Record<string, Theme["colors"]["blackAlpha"] | string>
> = {
  primary: generatePalette("#0088CC"),
  primarySoft: "#002233",

  secondary: {
    "50": "#F2F2F2",
    "100": "#DBDBDB",
    "200": "#C4C4C4",
    "300": "#ADADAD",
    "400": "#969696",
    "500": "#808080",
    "600": "#666666",
    "700": "#4D4D4D",
    "800": "#333333",
    "900": "#1A1A1A",
  },

  background: generatePalette("#121212"),
  backgroundSecondary: generatePalette("#202020"),
  backgroundTertiary: generatePalette("#1A1A1A"),
  backgroundQuaternary: generatePalette("#343434"),

  textPrimary: "#FFFFFF",
  textSecondary: "#B3B3B3",
  textTertiary: "#737373",
  textPrimaryInversed: "#222222",

  success: generatePalette("#00875A"),
  successSoft: "#112216",
  danger: generatePalette("#DD441D"),
  dangerSoft: "#3A0E03",
  warning: generatePalette("#FFAB00"),
  warningSoft: "#3D2900",
  info: generatePalette("#0088CC"),
  infoSoft: "#002233",

  white: {
    "50": "#444444",
    "100": "#666666",
    "200": "#888888",
    "300": "#AAAAAA",
    "400": "#CCCCCC",
    "500": "#FFFFFF",
    "600": "#CCCCCC",
    "700": "#AAAAAA",
    "800": "#888888",
    "900": "#666666",
  },
  black: generatePalette("#000000"),

  gray: {
    "50": "#F2F2F2",
    "100": "#DBDBDB",
    "200": "#C4C4C4",
    "300": "#ADADAD",
    "400": "#969696",
    "500": "#808080",
    "600": "#333333",
    "700": "#2c2c2c",
    "800": "#1b1b1b",
    "900": "#1A1A1A",
  },

  // colors that we need to change or process mentally later.
  gray1: "#313131",
  gray2: "#575757",
  gray3: "#141414",
  gray4: "#252525",
  lightDark: "#808080",

  border: generatePalette("#333333"),
  borderSecondary: generatePalette("#3F3F3F"),
  borderAlpha: generatePalette("#555555"),

  whiteAlpha: {
    "50": "rgba(255, 255, 255, 0.04)",
    "100": "rgba(255, 255, 255, 0.08)",
    "200": "rgba(255, 255, 255, 0.12)",
    "300": "rgba(255, 255, 255, 0.15)",
    "400": "rgba(255, 255, 255, 0.24)",
    "500": "rgba(255, 255, 255, 0.36)",
    "600": "rgba(255, 255, 255, 0.48)",
    "700": "rgba(255, 255, 255, 0.64)",
    "800": "rgba(255, 255, 255, 0.80)",
    "900": "rgba(255, 255, 255, 0.92)",
  },

  blackAlpha: {
    "50": "rgba(0, 0, 0, 0.04)",
    "100": "rgba(0, 0, 0, 0.06)",
    "200": "rgba(0, 0, 0, 0.08)",
    "300": "rgba(0, 0, 0, 0.16)",
    "400": "rgba(0, 0, 0, 0.24)",
    "500": "rgba(0, 0, 0, 0.36)",
    "600": "rgba(0, 0, 0, 0.50)",
    "700": "rgba(0, 0, 0, 0.64)",
    "800": "rgba(0, 0, 0, 0.80)",
    "900": "rgba(0, 0, 0, 0.92)",
  },

  softRed: "#FB3509",
};

/** override chakra colors here */
const overriddenChakraColors: DeepPartial<Theme["colors"]> = {};

export const colors = {
  ...overriddenChakraColors,
  ...extendedColors,
};
