import _ from "lodash";
import { Paths } from "type-fest";

import { MutationDefinition } from "@reduxjs/toolkit/dist/query";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";

export const chunkQuery = <T, P extends Paths<T>>(
  trigger: MutationTrigger<MutationDefinition<T, any, any, any>>,
  keyToChunk: P,
  chunkSize: number
) => {
  return (arg: T): any => {
    const arrayToChunk = _.get(arg, keyToChunk);
    const chunks = [];
    for (let i = 0; i < arrayToChunk.length; i += chunkSize) {
      chunks.push(arrayToChunk.slice(i, i + chunkSize));
    }
    const responses: ReturnType<typeof trigger>[] = [];
    for (const chunk of chunks) {
      // NOTE This should be "as T"  instead of "as any" but it's not working
      const newArg = _.cloneDeep(arg) as any;
      _.set(newArg, keyToChunk, chunk);
      const response = trigger(newArg);
      responses.push(response);
    }
    return {
      data: responses,
      unwrap: async () => {
        return Promise.allSettled(
          responses.map((response) => response.unwrap())
        );
      },
    };
  };
};
