import { useEffect, useMemo, useState } from "react";
import {
  FileImageType,
  mapAssetsToImagesFiles,
} from "domains/file-manager/interfaces";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useQueries } from "infra/api/hooks/useQueries";
import { apiSlice } from "infra/store/apiSlice";
import _ from "lodash";

export function useUrlsByAssetIds(assetIds: string[]) {
  const { assetsByIds } = useAssetsByIds({ assetIds });
  return useMemo(() => {
    return _.map(assetsByIds, (item) => {
      if (!item || item.meta.metadata.type === "vectorization") {
        return item?.meta.url;
      }
      return `${item.meta.url}&format=jpeg&quality=80`;
    });
  }, [assetsByIds]);
}

export function useAssetsByIds({
  assetIds,
  fetchUntilReady = false,
  originalAssets = false,
}: {
  assetIds: string[];
  fetchUntilReady?: boolean;
  originalAssets?: boolean;
}) {
  const { selectedProject } = useTeamContext();
  const [files, setFiles] = useState<{
    [key: string]: FileImageType | undefined;
  }>({});
  const [areAllAssetsReady, setAreAllAssetsReady] = useState<boolean>(false);
  const [chunks, setChunks] = useState<string[][]>([]);

  const uniqueAssetIds = useMemo(() => [...new Set(assetIds)], [assetIds]);

  useEffect(() => {
    const chunkSize = 100;
    const newChunks: string[][] = [];
    uniqueAssetIds.forEach((id) => {
      // if the id is already in a chunk, skip it
      if (chunks.some((chunk) => chunk.includes(id))) {
        return;
      }
      // if the last chunk is full, create a new one
      if (
        newChunks.length === 0 ||
        newChunks[newChunks.length - 1].length === chunkSize
      ) {
        newChunks.push([]);
      }
      // add the id to the last chunk
      newChunks[newChunks.length - 1].push(id);
    });
    const newValue = [...chunks, ...newChunks];
    if (!_.isEqual(chunks, newValue)) {
      setChunks(newValue);
    }
  }, [uniqueAssetIds, chunks]);

  const getAssetsByIdsQueries = useQueries(
    apiSlice.endpoints.getAssetsBulk,
    chunks.map((chunk) => ({
      projectId: selectedProject.id,
      originalAssets: originalAssets ? "true" : undefined,
      body: { assetIds: chunk },
    })),
    {
      pollingInterval: fetchUntilReady && !areAllAssetsReady ? 5_000 : 0,
    }
  );

  const assets = useMemo(() => _.compact(_.values(files)), [files]);

  // ----------------------------------

  useEffect(() => {
    const fulfilledQueries = getAssetsByIdsQueries.filter(
      (query) => query.status === "fulfilled"
    );
    const isAllFulfilled =
      getAssetsByIdsQueries.length === fulfilledQueries.length;

    if (!isAllFulfilled) {
      return;
    }

    const newAssets = getAssetsByIdsQueries
      .map((query) => query.data?.assets || [])
      .flat();

    setAreAllAssetsReady(
      newAssets.every(
        (asset) => asset.status === "success" || asset.status === "error"
      ) &&
        newAssets
          .filter((asset) => uniqueAssetIds.includes(asset.id))
          .map((asset) => asset.id)
          .sort()
          .join() === [...uniqueAssetIds].sort().join()
    );

    const newFiles = fulfilledQueries.reduce<typeof files>((memo, query) => {
      (query.originalArgs.body.assetIds ?? []).forEach((assetId) => {
        if (uniqueAssetIds.includes(assetId)) {
          memo[assetId] = mapAssetsToImagesFiles(
            _.compact([
              (query.data?.assets ?? []).find((asset) => asset.id === assetId),
            ])
          )[0];
        }
      });
      return memo;
    }, {});
    setFiles((prev) => {
      if (_.isEqual(prev, newFiles)) {
        return prev;
      }
      return newFiles;
    });
  }, [uniqueAssetIds, getAssetsByIdsQueries]);

  const isLoading = useMemo(() => {
    return getAssetsByIdsQueries.some((query) => query.isLoading);
  }, [getAssetsByIdsQueries]);

  return {
    assetsByIds: files,
    assets,
    isLoading,
  };
}
