import { PostModelsInferencesByModelIdApiArg } from "infra/api/generated/api";

export type InferenceType =
  PostModelsInferencesByModelIdApiArg["body"]["parameters"]["type"];

export const INFERENCE_TYPES: InferenceType[] = [
  "txt2img",
  "img2img",
  "controlnet_img2img",
  "img2img_ip_adapter",
  "reference",
  "controlnet_reference",
  "controlnet",
  "controlnet_ip_adapter",
  "inpaint",
  "controlnet_inpaint",
  "controlnet_inpaint_ip_adapter",
  "inpaint_ip_adapter",
  "txt2img_ip_adapter",
];

export const INFERENCE_TYPE_WITH_SECOND_REFERENCE_IMAGE: InferenceType[] = [
  "controlnet_img2img",
  "controlnet_reference",
  "controlnet_inpaint",

  "img2img_ip_adapter",
  "controlnet_ip_adapter",
];

export const TEXTURE_INFERENCE_TYPES = [
  "txt2img_texture",
  "reference_texture",
  "img2img_texture",
  "controlnet_texture",
] as const;

export type TextureInferenceType = (typeof TEXTURE_INFERENCE_TYPES)[number];

// ------------------------------------

export const IP_ADAPTER_TYPES = [
  "txt2img_ip_adapter_character",
  "txt2img_ip_adapter_style",
  "img2img_ip_adapter_character",
  "img2img_ip_adapter_style",
  "controlnet_ip_adapter_character",
  "controlnet_ip_adapter_style",
] as const;

export type IpAdapterType = (typeof IP_ADAPTER_TYPES)[number];

export type InferenceWithIpAdapterType =
  | Exclude<
      InferenceType,
      "txt2img_ip_adapter" | "img2img_ip_adapter" | "controlnet_ip_adapter"
    >
  | IpAdapterType;

// ------------------------------------

export const INFERENCE_TYPE_LABELS: Record<
  InferenceType | IpAdapterType,
  string
> = {
  txt2img: "Text to Image",
  img2img: "Image to Image",
  controlnet_img2img: "Image to Image + ControlNet",
  img2img_ip_adapter: "Image to Image + IP Adapter",
  reference: "Reference Only",
  controlnet_reference: "Reference Only + ControlNet",
  controlnet: "ControlNet",
  controlnet_ip_adapter: "ControlNet + IP Adapter",
  inpaint: "Inpaint",
  outpaint: "Outpaint",
  controlnet_inpaint: "Inpaint + ControlNet",
  controlnet_inpaint_ip_adapter: "Inpaint + ControlNet + IP Adapter",
  inpaint_ip_adapter: "Inpaint + IP Adapter",
  txt2img_ip_adapter: "IP Adapter",
  img2img_texture: "Image to Image",
  reference_texture: "Reference Only",
  txt2img_texture: "Text to Image",
  controlnet_texture: "ControlNet",
  txt2img_ip_adapter_character: "Character Reference",
  txt2img_ip_adapter_style: "Style Reference",
  img2img_ip_adapter_character: "Image to Image + Character",
  img2img_ip_adapter_style: "Image to Image + Style",
  controlnet_ip_adapter_character: "ControlNet + Character",
  controlnet_ip_adapter_style: "ControlNet + Style",
};
