export type Dimension = {
  width?: number;
  height?: number;
};

export async function getImageDimensions(
  imageData: string | HTMLCanvasElement
) {
  if (imageData instanceof HTMLCanvasElement) {
    return {
      width: imageData.width,
      height: imageData.height,
    };
  }

  return new Promise<Required<Dimension>>((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height,
      });
    };
    image.onerror = reject;
    image.src = imageData;
  });
}

// ------------------------------------

export function getImagesRatioDiff(compare1: Dimension, compare2: Dimension) {
  const compare1Ratio =
    compare1.width && compare1.height ? compare1.width / compare1.height : 0;
  const compare2Ratio =
    compare2.width && compare2.height ? compare2.width / compare2.height : 0;
  return compare1Ratio && compare2Ratio
    ? Math.min(compare1Ratio, compare2Ratio) /
        Math.max(compare1Ratio, compare2Ratio)
    : undefined;
}
