import React, { useEffect, useMemo } from "react";
import {
  UPSCALE_PRESET_LABELS,
  UPSCALE_PRESETS,
  UPSCALE_STYLE_LABELS,
  UPSCALE_STYLES,
} from "domains/assets/constants/upscale";
import { UseAssetUpscaleReturnValue } from "domains/assets/hooks/useAssetUpscale";
import { disabledOnWheel } from "domains/forms/disabledOnWheel";
import ButtonSectionTopIcon from "domains/inference/components/InferenceGenerator/Sidebar/ButtonSectionTopIcon";
import ReferenceImages from "domains/inference/components/InferenceGenerator/Sidebar/FragmentReferenceImages";
import SidebarSection from "domains/inference/components/InferenceGenerator/Sidebar/Section";
import SectionNegativePrompt from "domains/inference/components/InferenceGenerator/Sidebar/SectionNegativePrompt";
import SectionPrompt from "domains/inference/components/InferenceGenerator/Sidebar/SectionPrompt";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import ControlButtonSelect from "domains/ui/components/ControlButtonSelect";
import ControlSelect from "domains/ui/components/ControlSelect";
import ControlSwitch from "domains/ui/components/ControlSwitch";
import Divider from "domains/ui/components/Divider";
import Icon from "domains/ui/components/Icon";
import ScenarioInput from "domains/ui/components/ScenarioInput";
import Slider from "domains/ui/components/Slider";
import WithLabelAndTooltip from "domains/ui/components/WithLabelAndTooltip";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import _ from "lodash";

import { VStack } from "@chakra-ui/react";

const SCALING_FACTORS = [1, 2, 4, 8, 16];
const SCALING_FACTORS_TEXTURES = [1, 2, 4];

interface UpscaleParamsProps {
  width: number;
  height: number;
  isCanvas?: boolean;
  isAssetZoom?: boolean;
  form: UseAssetUpscaleReturnValue["form"];
  setValue: UseAssetUpscaleReturnValue["setValue"];
  referenceAssetId?: string;
  type?: "image" | "texture";
}

export default function UpscaleParams({
  form,
  setValue,
  width,
  height,
  isCanvas,
  isAssetZoom,
  referenceAssetId,
  type = "image",
}: UpscaleParamsProps) {
  const { maxUpscalingFactor: maxPlanUpscalingFactor } = usePlanContext();
  const { subscription } = usePlanContext();
  const { featureFlags } = useUserContext();
  const hasAllStylesFeatureFlag = featureFlags?.includes(
    "feature-upscale-style"
  );

  const availableStyles = useMemo(() => {
    if (hasAllStylesFeatureFlag) {
      return UPSCALE_STYLES;
    }
    return UPSCALE_STYLES.filter((style) => style !== "anime");
  }, [hasAllStylesFeatureFlag]);

  const scalingFactorsForType = useMemo(() => {
    return type === "texture" ? SCALING_FACTORS_TEXTURES : SCALING_FACTORS;
  }, [type]);

  const maxScalingFactor = useMemo(() => {
    return [1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, ...scalingFactorsForType]
      .sort((a, b) => a - b)
      .reverse()
      .find((value) => {
        const finalWidth = width * value;
        const finalHeight = height * value;
        const totalPixels = finalWidth * finalHeight;
        return (
          totalPixels <= 268_435_456 && // 268M pixels (16,384 x 16,384)
          finalWidth <= 16_384 &&
          finalHeight <= 16_384
        );
      });
  }, [width, height, scalingFactorsForType]);

  const scalingFactors = useMemo(
    () =>
      _.chain(scalingFactorsForType)
        .union(
          maxScalingFactor !== undefined && maxScalingFactor < 2
            ? [maxScalingFactor]
            : []
        )
        .sortBy()
        .value(),
    [maxScalingFactor, scalingFactorsForType]
  );

  const scalingOptions = useMemo(
    () =>
      scalingFactors.map((value) => {
        const isAvailable =
          maxScalingFactor !== undefined && value <= maxScalingFactor;
        const isAllowedInPlan = value <= maxPlanUpscalingFactor;
        const isLimitedToEnterprise =
          type === "texture" &&
          value === 4 &&
          subscription?.plan !== "enterprise";
        return {
          label: `${value}x`,
          value,
          isDisabled: !isAvailable || !isAllowedInPlan || isLimitedToEnterprise,
          tooltip:
            (!isAvailable &&
              "Enhance output cannot exceed 268M pixels (16,384 x 16,384 px)") ||
            (isLimitedToEnterprise && "Contact us to upscale textures 4x") ||
            (!isAllowedInPlan && `Upgrade your plan to upscale ${value}x`) ||
            undefined,
          premiumWrapper:
            (!isAllowedInPlan || isLimitedToEnterprise) && isAvailable,
        };
      }),
    [
      maxScalingFactor,
      maxPlanUpscalingFactor,
      scalingFactors,
      type,
      subscription,
    ]
  );

  // ----------------------------------

  useEffect(() => {
    if (maxScalingFactor === undefined) {
      return;
    }

    if (
      form.scalingFactor > maxScalingFactor ||
      form.scalingFactor > maxPlanUpscalingFactor ||
      !scalingFactors.includes(form.scalingFactor)
    ) {
      const newScalingFactor = Math.min(
        maxScalingFactor,
        maxPlanUpscalingFactor
      );
      setValue("scalingFactor", newScalingFactor);
    }
  }, [
    form.scalingFactor,
    scalingFactors,
    maxPlanUpscalingFactor,
    maxScalingFactor,
    setValue,
  ]);

  // ----------------------------------

  return (
    <>
      <VStack
        align="stretch"
        w="100%"
        spacing={2}
        {...(!isCanvas && !isAssetZoom
          ? {
              px: 6,
              py: 4,
            }
          : {})}
      >
        <ControlSelect
          title="Style"
          tooltip="Selecting a custom style will optimize settings for the output image to match the selected style"
          value={form.style}
          setValue={(newValue) =>
            setValue("style", newValue as typeof form.style)
          }
          options={availableStyles.map((style) => ({
            label: UPSCALE_STYLE_LABELS[style],
            value: style,
          }))}
        />

        <ControlButtonSelect
          title="Scaling Factor"
          guide="upscale-parameter-scaling-factor"
          options={scalingOptions}
          selectedOption={form.scalingFactor}
          onOptionSelected={(newValue) => {
            setValue("scalingFactor", newValue);
          }}
        />

        <ControlButtonSelect
          title="Preset"
          tooltip="Selecting a preset will adjust the creativity level of your enhancement"
          options={UPSCALE_PRESETS.map((preset) => ({
            label: UPSCALE_PRESET_LABELS[preset],
            value: preset,
          }))}
          selectedOption={form.preset}
          onOptionSelected={(newValue) => setValue("preset", newValue)}
        />
      </VStack>

      {!isCanvas && !isAssetZoom && (
        <>
          <Divider />
          <SidebarSection
            headerProps={{
              title: "Style Images",
              id: "styleImages",
              tooltip:
                "Upload up to 10 images for the AI to learn from and shape the style of your final output.",

              topRight:
                form.styleImages.length > 0 ? (
                  <ButtonSectionTopIcon
                    tooltip="Remove All"
                    tooltipProps={{ placement: "right" }}
                    onClick={() => setValue("styleImages", [])}
                  >
                    <Icon id="Ui/TrashMd" h="14px" />
                  </ButtonSectionTopIcon>
                ) : undefined,
            }}
          >
            <VStack align="stretch" w="100%" spacing={2}>
              <ReferenceImages
                images={form.styleImages}
                setImages={(newValue) => setValue("styleImages", newValue)}
                maxImages={10}
                trackingEvent={AnalyticsEvents.Upscale.ImportedReferenceImage}
                trackingProperties={{}}
              />

              {form.styleImages.length > 0 && (
                <>
                  <WithLabelAndTooltip
                    label="Style Fidelity"
                    tooltip="Higher fidelity keeps the enhanced image's style closely aligned with the style images"
                  >
                    <Slider
                      withNumberInput
                      max={100}
                      min={0}
                      step={1}
                      value={Math.round(form.styleImagesFidelity)}
                      onChange={(newValue) => {
                        setValue("styleImagesFidelity", newValue);
                      }}
                    />
                  </WithLabelAndTooltip>

                  <ControlSwitch
                    title="Style Tiling"
                    tooltip="Style Tiling segments the style images into tiles according to the Fractality parameter, possibly ensuring a more consistent enhancement"
                    isChecked={form.tileStyle}
                    setIsChecked={(newValue) => {
                      setValue("tileStyle", newValue);
                    }}
                  />
                </>
              )}
            </VStack>
          </SidebarSection>

          <Divider />
          <SectionPrompt
            id="prompt"
            prompt={form.prompt}
            setPrompt={(newValue) => setValue("prompt", newValue)}
            guide="upscale-parameter-prompts"
            withRandom={false}
            referenceAssetId={referenceAssetId}
            placeholder={
              type === "texture"
                ? "Describe your texture, and let prompt tools generate, complete, or translate it."
                : undefined
            }
          />

          <Divider />
          <SectionNegativePrompt
            id="negativePrompt"
            prompt={form.negativePrompt}
            setPrompt={(newValue) => setValue("negativePrompt", newValue)}
            guide="upscale-parameter-prompts"
          />

          <Divider />
          <SidebarSection
            headerProps={{
              title: "Settings",
              id: "settings",
              guide: "upscale-parameter-others",
            }}
          >
            <VStack align="stretch" w="100%" spacing={2}>
              <WithLabelAndTooltip
                label="Prompt Fidelity"
                tooltip="Higher strength prioritizes the prompt over the image"
              >
                <Slider
                  withNumberInput
                  max={100}
                  min={0}
                  step={1}
                  value={Math.round(form.promptFidelity)}
                  onChange={(newValue) => {
                    setValue("promptFidelity", newValue);
                  }}
                />
              </WithLabelAndTooltip>

              <WithLabelAndTooltip
                label="Image Fidelity"
                tooltip="Higher fidelity keeps the enhanced image closer to the original structure of the input image"
              >
                <Slider
                  withNumberInput
                  max={100}
                  min={0}
                  step={1}
                  value={Math.round(form.imageFidelity)}
                  onChange={(newValue) => {
                    setValue("imageFidelity", newValue);
                  }}
                />
              </WithLabelAndTooltip>

              <WithLabelAndTooltip
                label="Creativity"
                tooltip="Higher creativity levels introduce extra imaginative elements to enhanced images"
              >
                <Slider
                  withNumberInput
                  max={100}
                  min={0}
                  step={1}
                  value={Math.round(form.creativity)}
                  onChange={(newValue) => {
                    setValue("creativity", newValue);
                  }}
                />
              </WithLabelAndTooltip>

              <WithLabelAndTooltip
                label="Fractality"
                tooltip="Fractality will add small fractal-like details, possibly repeating intricate patterns and repeating these details across tiles"
              >
                <Slider
                  withNumberInput
                  max={100}
                  min={0}
                  step={1}
                  value={Math.round(form.fractality)}
                  onChange={(newValue) => {
                    setValue("fractality", newValue);
                  }}
                />
              </WithLabelAndTooltip>

              <WithLabelAndTooltip
                label="Detail Intensity"
                tooltip="Enhances or reduces the intensity of details"
              >
                <Slider
                  withNumberInput
                  max={50}
                  min={-50}
                  step={1}
                  value={Math.round(form.detailsLevel)}
                  onChange={(newValue) => {
                    setValue("detailsLevel", newValue);
                  }}
                />
              </WithLabelAndTooltip>

              <WithLabelAndTooltip
                label="Seed"
                tooltip="Using a fixed seed can help maintain consistency while adjusting settings"
              >
                <ScenarioInput
                  value={form.seed ?? ""}
                  setValue={(newValue) => {
                    setValue("seed", newValue.length ? newValue : undefined);
                  }}
                  placeholder="Random"
                  type="number"
                  onWheel={disabledOnWheel}
                />
              </WithLabelAndTooltip>
            </VStack>
          </SidebarSection>

          <Divider />
          <SidebarSection
            headerProps={{
              title: "Advanced",
              id: "advancedSettings",
            }}
          >
            <VStack align="stretch" w="100%" spacing={2}>
              <WithLabelAndTooltip
                label="Creativity Decay"
                tooltip="The degree of creativity retained at each stage of the enhancement process (only applies for scale factors above 2)"
              >
                <Slider
                  withNumberInput
                  max={100}
                  min={0}
                  step={1}
                  value={Math.round(form.creativityDecay)}
                  onChange={(newValue) => {
                    setValue("creativityDecay", newValue);
                  }}
                />
              </WithLabelAndTooltip>

              <ControlSwitch
                title="Override Style Embeddings"
                tooltip="Removes any additional custom embeddings used to guide the 'Style'"
                isChecked={form.overrideEmbeddings}
                setIsChecked={(newValue) => {
                  setValue("overrideEmbeddings", newValue);
                }}
              />
            </VStack>
          </SidebarSection>
        </>
      )}
    </>
  );
}
