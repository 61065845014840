import { ColorPalette } from "domains/ui/components/ColorPicker/defaultColorPalettes";

import { Box } from "@chakra-ui/react";

export default function ColorPaletteThumbnail({
  colorPalette,
}: {
  colorPalette: ColorPalette;
}) {
  const colors = colorPalette.colors;
  const lineWidth = 100 / colors.length;
  return (
    <>
      {colors.map((color, index) => {
        return (
          <Box
            key={`color-palette-${color}-line-${index}`}
            w={`${lineWidth}%`}
            h={"100%"}
            bgColor={color}
          />
        );
      })}
    </>
  );
}
