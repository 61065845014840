import React from "react";
import Button, { ButtonProps } from "domains/ui/components/Button";

export type TopIconButtonProps = ButtonProps;

const TopIconButton = React.forwardRef(function TopIconButton(
  props: TopIconButtonProps,
  ref
) {
  return (
    <Button
      ref={ref}
      display="flex"
      alignItems="center"
      pos="relative"
      w="28px"
      minW="28px"
      h="28px"
      p={0}
      variant="secondaryAlt"
      {...props}
    />
  );
});

export default TopIconButton;
