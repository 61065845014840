import { useCallback, useState } from "react";
import { useJobSessionContext } from "domains/jobs/contexts/JobSessionProviderV2";
import Button, { ButtonProps } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import { Box } from "@chakra-ui/react";

interface CancelButtonProps {
  jobId: string | undefined;
}

export default function CancelButton({ jobId }: CancelButtonProps) {
  const { cancelJob } = useJobSessionContext();
  const [isCanceling, setIsCanceling] = useState<boolean>(false);
  const isDisplayed = !!jobId;

  const handleClick = useCallback<NonNullable<ButtonProps["onClick"]>>(
    async (e) => {
      e.stopPropagation();
      if (!jobId) return;
      try {
        setIsCanceling(true);
        await cancelJob(jobId);
      } finally {
        setIsCanceling(false);
      }
    },
    [cancelJob, jobId]
  );

  if (!isDisplayed) return null;

  return (
    <Box display="none" _groupHover={{ display: "block" }}>
      <Button
        leftIcon={<Icon id="Ui/Cross" h="8px" />}
        size="sm"
        variant="secondaryV2"
        isLoading={isCanceling}
        onClick={!isCanceling ? handleClick : undefined}
      >
        Cancel
      </Button>
    </Box>
  );
}
