import { useEffect } from "react";
import useDeepMemo from "domains/commons/hooks/useDeepMemo";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
  EndpointDefinitions,
  QueryDefinition,
} from "@reduxjs/toolkit/dist/query";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import { ApiEndpointQuery } from "@reduxjs/toolkit/dist/query/core/module";

/**
 * Allows running a query on a collection of data
 * @param query The RTK ApiEndpointQuery to run
 * @param params a list of params that will be run by the query
 * @returns the rtk query result (same as useGetXQuery())
 */
export function useQueries<
  Q extends QueryDefinition<any, any, any, any>,
  D extends EndpointDefinitions
>(
  query: ApiEndpointQuery<Q, D>,
  params: Parameters<(typeof query)["initiate"]>[0][],
  options?: Parameters<(typeof query)["initiate"]>[0]["subscriptionOptions"]
) {
  const dispatch = useDispatch();
  const queriesParams = useDeepMemo(params);
  const subscriptionOptions = useDeepMemo(options);

  useEffect(() => {
    const results: QueryActionCreatorResult<any>[] = queriesParams.map(
      (param) =>
        dispatch(
          query.initiate(param as any, {
            subscriptionOptions,
          }) as any
        )
    );

    return () => {
      for (const result of results) {
        result.unsubscribe();
      }
    };
  }, [dispatch, query, queriesParams, subscriptionOptions]);

  return useSelector(
    (state) => queriesParams.map((param) => query.select(param)(state as any)),
    (next, prev) => _.isEqual(next, prev)
  );
}
