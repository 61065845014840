import {
  ALLOWED_TAG_CHARACTERS,
  MAX_TAG_LENGTH,
} from "domains/tags/constants/tags";

export function isTagValid(tag: string): {
  isValid: boolean;
  message: string;
} {
  if (tag.length === 0) {
    return {
      isValid: false,
      message: "Tag cannot be empty",
    };
  }

  if (tag.length > MAX_TAG_LENGTH) {
    return {
      isValid: false,
      message: `Tag cannot be longer than ${MAX_TAG_LENGTH} characters`,
    };
  }

  if (!ALLOWED_TAG_CHARACTERS.test(tag)) {
    return {
      isValid: false,
      message:
        "Tags may only include letters, numbers, spaces and these specific characters: . _ : / = + - @ & !",
    };
  }

  return {
    isValid: true,
    message: "",
  };
}
