import { TrainForm } from "domains/models/interfaces/train";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Icon from "domains/ui/components/Icon";
import { TagPremium } from "domains/ui/components/TagPremium";

import {
  Box,
  HStack,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderProps,
  SliderTrack,
  Tag,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";

export interface ModelTrainBottomProps {
  trainingImagesCount: number;
  maxTrainingImages: number;
  form: TrainForm | undefined;
}

export default function ModelTrainBottom({
  trainingImagesCount,
  maxTrainingImages,
  form,
}: ModelTrainBottomProps) {
  const { selectedTeam } = useTeamContext();
  const realMaxTrainingImages = (() => {
    if (form?.type === "flux.1-lora") {
      return 50;
    } else if (form?.type === "sd-xl-lora" && form?.preset === "style") {
      return 50;
    } else if (form?.type === "sd-xl-lora" && form?.preset === "subject") {
      return 25;
    } else {
      return 100;
    }
  })();
  const nextPlan = (() => {
    if (realMaxTrainingImages === maxTrainingImages) {
      return undefined;
    } else if (selectedTeam.isFreePlan) {
      return "Creator";
    } else if (selectedTeam.plan === "cu-creator") {
      return "Pro";
    } else if (selectedTeam.plan === "cu-pro") {
      return "Team";
    } else {
      return undefined;
    }
  })();
  const isMaxDatasetExceeded = trainingImagesCount > maxTrainingImages;
  const colorScheme: SliderProps["colorScheme"] = (() => {
    if (isMaxDatasetExceeded) return "danger";
    else if (trainingImagesCount < 5) return "warning";
    else return "primary";
  })();

  return (
    <VStack
      align="stretch"
      px={6}
      py={5}
      borderColor="border.500"
      borderTopWidth={1}
      spacing={3}
    >
      <HStack spacing={2}>
        <Text color="textPrimary" size="body.lg">
          {`Dataset: ${trainingImagesCount} ${
            trainingImagesCount > 1 ? "images" : "image"
          }`}
        </Text>
        {isMaxDatasetExceeded && (
          <Tooltip label="Remove some images to be able to train">
            <Tag colorScheme="danger">Image limit exceeded</Tag>
          </Tooltip>
        )}
      </HStack>

      <HStack overflowY="visible" h="14px">
        <Slider
          colorScheme={colorScheme}
          max={maxTrainingImages}
          min={0}
          value={trainingImagesCount}
          variant="unstyled"
        >
          <SliderTrack pointerEvents="none">
            <SliderFilledTrack />
          </SliderTrack>

          <SliderMark mt="-8px" value={5}>
            <Box pos="relative" w="1px" h="16px" bgColor="textTertiary" />
          </SliderMark>
        </Slider>

        {nextPlan && (
          <TagPremium
            label="Upgrade"
            tooltip={`Upgrade your plan to upload up to ${realMaxTrainingImages} images`}
          />
        )}
      </HStack>

      <HStack spacing={2}>
        <Text color="textSecondary" whiteSpace="nowrap" size="body.sm">
          {"Min: 5"}
        </Text>
        <Icon id="Ui/Dot" h="4px" color="textTertiary" />
        <Text color="textSecondary" whiteSpace="nowrap" size="body.sm">
          {`Max: ${maxTrainingImages}`}
        </Text>
      </HStack>
    </VStack>
  );
}
