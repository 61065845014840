import { KeyboardEvent, useCallback, useRef } from "react";
import WithLabelAndTooltip from "domains/ui/components/WithLabelAndTooltip";

import {
  Box,
  BoxProps,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";

export default function SliderStepsNicolas({
  title,
  tooltip,
  value,
  setValue,
  min,
  max,
  step,
  steps,
  inputProps,
  inputBoxWrapperProps,
  placeholder,
  "data-testid": dataTestId,
}: {
  title: string;
  tooltip?: string;
  value: number | undefined;
  setValue: (value: number | undefined) => void;
  min: number;
  max: number;
  step?: number;
  steps?: number[];
  inputProps?: NumberInputProps;
  inputBoxWrapperProps?: BoxProps;
  placeholder?: string;
  "data-testid"?: string;
}) {
  const thumbRef = useRef<HTMLDivElement>(null);

  const getClosestStep = useCallback(
    (value: number) => {
      if (!steps) {
        return value;
      }
      return steps.reduce((prev, curr) =>
        Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
      );
    },
    [steps]
  );

  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (!steps) {
        return;
      }
      if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
        const closestStep = getClosestStep(value ?? min);
        const stepIndex = steps.indexOf(closestStep);
        const newStep =
          steps[e.key === "ArrowLeft" ? stepIndex - 1 : stepIndex + 1];
        if (newStep !== undefined) {
          setValue(newStep);
        }
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [steps, value, min, setValue, getClosestStep]
  );

  return (
    <WithLabelAndTooltip tooltip={tooltip} label={title}>
      <HStack>
        <Slider
          m={2}
          colorScheme="whiteAlpha"
          data-testid={dataTestId}
          focusThumbOnChange={false}
          max={max}
          min={min}
          onChange={(newValue) => {
            if (steps) {
              const closestStep = getClosestStep(newValue);
              setValue(closestStep);
            } else {
              setValue(newValue);
            }
          }}
          onChangeStart={() => {
            thumbRef.current?.focus();
          }}
          step={step}
          value={value ?? min}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb
            ref={thumbRef}
            {...(steps
              ? {
                  onKeyDown: handleOnKeyDown,
                }
              : {})}
          />
        </Slider>

        <Box mr={-2.5} {...inputBoxWrapperProps}>
          <NumberInput
            w="full"
            max={max}
            min={min}
            onChange={(_, newValue) => {
              setValue(isNaN(newValue) ? undefined : newValue);
            }}
            placeholder="Auto"
            size="xs"
            value={value ?? ""}
            variant="ghost"
            {...inputProps}
          >
            <NumberInputField placeholder={placeholder} />
          </NumberInput>
        </Box>
      </HStack>
    </WithLabelAndTooltip>
  );
}
