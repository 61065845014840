import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import { extractFirstQueryParam } from "domains/commons/misc";
import useRouter from "domains/navigation/hooks/useRouter";
import { PlanId } from "domains/teams/interfaces/Pricing";
import { Project, Team } from "domains/teams/interfaces/Team";
import Loading from "domains/ui/components/Loading";
import { useUserContext } from "domains/user/contexts/UserProvider";
import {
  GetTeamsApiResponse,
  GetTeamsByTeamIdApiResponse,
  useGetProjectsQuery,
  useGetTeamsByTeamIdQuery,
  useGetTeamsQuery,
} from "infra/api/generated/api";
import _ from "lodash";

import { Center } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const DEFAULT_TEAM: Team = {
  id: "",
  name: "Private Organization",
  plan: "free",
  userRole: "admin",
  usersCount: 1,
  projects: [],
  isFetching: true,
  isFreePlan: true,
  isPublic: false,
};

const DEFAULT_PROJECT: Project = {
  id: "",
  teamId: "",
  name: "-",
  userRole: "admin",
  usersCount: 1,
  users: [],
  apiKeys: [],
  isFetching: true,
};

export const MAP_OLD_PLANS_TO_NEW: {
  [key in GetTeamsApiResponse["teams"][number]["plan"] | "custom"]: PlanId;
} = {
  free: "free",
  "cu-creator": "cu-creator",
  "cu-pro": "cu-pro",
  "cu-team": "cu-team",
  enterprise: "enterprise",
  essential: "custom",
  pro: "custom",
  premium: "custom",
  custom: "custom",
};

interface TeamProviderProps {
  teams: Team[];
  selectedTeam: Team;
  selectedTeamDetails: GetTeamsByTeamIdApiResponse["team"] | undefined;
  redirectToTeam: (team: Team, withRedirect?: boolean) => void;
  refetchTeamDetails: () => void;
  isLoadingTeamDetails: boolean;

  isCreateTeamModalOpen: boolean;
  showCreateTeamModal: () => void;
  hideCreateTeamModal: () => void;

  projects: Project[];
  selectedProject: Project;
  redirectToProject: (project: Project, withRedirect?: boolean) => void;
  refetchProjects: () => void;
  hasNoProject: boolean;

  isCreateProjectModalOpen: boolean;
  showCreateProjectModal: () => void;
  hideCreateProjectModal: () => void;
}

export const TeamContext = createContext<TeamProviderProps>({
  teams: [],
  selectedTeam: DEFAULT_TEAM,
  selectedTeamDetails: undefined,
  redirectToTeam: () => {},
  refetchTeamDetails: () => {},
  isLoadingTeamDetails: false,

  isCreateTeamModalOpen: false,
  showCreateTeamModal: () => {},
  hideCreateTeamModal: () => {},

  projects: [],
  selectedProject: DEFAULT_PROJECT,
  redirectToProject: () => {},
  refetchProjects: () => {},
  hasNoProject: false,

  isCreateProjectModalOpen: false,
  showCreateProjectModal: () => {},
  hideCreateProjectModal: () => {},
});

export function TeamProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const router = useRouter();
  const { user } = useUserContext();
  const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] =
    useState<boolean>(false);
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] =
    useState<boolean>(false);
  const [persistedTeam, setPersistedTeam] = usePersistedState<Team | undefined>(
    PersistedStateKey.SELECTED_TEAM,
    { defaultValue: undefined }
  );
  const [persistedProject, setPersistedProject] = usePersistedState<
    Project | undefined
  >(PersistedStateKey.SELECTED_PROJECT, { defaultValue: undefined });

  const routeTeamId = extractFirstQueryParam(router.query.teamId);
  const routeProjectId = extractFirstQueryParam(router.query.projectId);

  const {
    currentData: getTeamsData,
    refetch: refetchTeams,
    isFetching: isFetchingTeams,
  } = useGetTeamsQuery(
    user?.id
      ? ({
          userId: user.id,
        } as any)
      : skipToken
  );

  const teams: Team[] = useMemo(() => {
    return (getTeamsData?.teams ?? [])
      .map((team) => ({
        id: decodeURI(team.id),
        name: team.name,
        plan: MAP_OLD_PLANS_TO_NEW[team.plan] ?? "free",
        userRole: team.context.userRole,
        usersCount: team.context.usersCount,
        projects: team.projects.map((project) => ({
          id: project.id,
          name: project.name,
        })),
        isFetching: false,
        isFreePlan: team.plan === "free",
        isPublic: ["HLDbd0XuQPWKWFx9nWKmrA", "bftve3VtQgmpXxwAPUFWTQ"].includes(
          team.id
        ),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [getTeamsData?.teams]);

  const selectedTeam = useMemo(() => {
    const teamId = routeTeamId || persistedTeam?.id;
    if (teams.length) {
      return teams.find((team) => team.id === teamId) ?? teams[0];
    } else if (persistedTeam && persistedTeam?.id === teamId) {
      return persistedTeam;
    } else {
      return DEFAULT_TEAM;
    }
  }, [routeTeamId, teams, persistedTeam]);

  const {
    data: teamData,
    refetch: refetchTeamDetails,
    isLoading: isLoadingTeamDetails,
  } = useGetTeamsByTeamIdQuery(
    selectedTeam.id && !isFetchingTeams
      ? { teamId: selectedTeam.id }
      : skipToken
  );
  const selectedTeamDetails = teamData?.team;

  // ------

  const {
    data: projectsData,
    refetch: refetchProjects,
    error: errorInProjects,
  } = useGetProjectsQuery(
    user?.id && selectedTeam.id
      ? ({
          userId: user.id,
          teamId: selectedTeam.id,
        } as any)
      : skipToken
  );

  const isProjectsLoaded = !!(
    projectsData?.projects || (errorInProjects as any)?.status === 403
  );

  const projects: Project[] = useMemo(() => {
    if (!user?.id) return [];
    return projectsData?.projects
      ? projectsData.projects
          .map((project) => ({
            id: decodeURI(project.id),
            teamId: decodeURI(project.teamId),
            name: project.name,
            userRole:
              parseProjectRole(
                project.users.find((projectUser) => projectUser.id === user.id)
                  ?.role
              ) ?? "admin",
            usersCount: project.users.length,
            users: _.uniqBy(
              [
                ...project.users.map((member) => ({
                  id: member.id,
                  email: member.email,
                  role: member.role,
                })),
              ],
              "id"
            ),
            apiKeys: project.keys,
            isFetching: false,
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
      : [];
  }, [projectsData?.projects, user?.id]);

  const selectedProject = useMemo(() => {
    const projectId = routeProjectId || persistedProject?.id;
    if (isProjectsLoaded) {
      return (
        projects.find((project) => project.id === projectId) ??
        projects[0] ??
        DEFAULT_PROJECT
      );
    } else if (
      persistedProject?.teamId === selectedTeam.id &&
      persistedProject?.id === projectId
    ) {
      return persistedProject;
    } else {
      return DEFAULT_PROJECT;
    }
  }, [
    selectedTeam.id,
    routeProjectId,
    projects,
    persistedProject,
    isProjectsLoaded,
  ]);

  const hasNoProject = isProjectsLoaded && !selectedProject.id;
  const isLoading = !!user && (!selectedTeam.id || !teams.length);

  // ----------------------------------

  const redirectToProject = useCallback(
    (project: Project, withRedirect = true) => {
      if (selectedProject.id === project.id) {
        return;
      }

      if (withRedirect) {
        window.location.href = `/?teamId=${project.teamId}&projectId=${project.id}`;
      } else if (selectedProject.id !== project.id) {
        void router.push({
          query: {
            ...router.query,
            teamId: project.teamId,
            projectId: project.id,
          },
        });
      }
    },
    [selectedProject.id, router]
  );

  const redirectToTeam = useCallback(
    (team: Team, withRedirect = true) => {
      if (selectedTeam.id === team.id) {
        return;
      }

      if (withRedirect) {
        window.location.href = "/?teamId=" + team.id;
      } else if (selectedTeam.id !== team.id) {
        void router.push({
          query: {
            ..._.omit(router.query, "projectId"),
            teamId: team.id,
          },
        });
      }
    },
    [selectedTeam.id, router]
  );

  // ----------------------------------

  useEffect(() => {
    if (
      !!user &&
      !!getTeamsData &&
      !isFetchingTeams &&
      !getTeamsData?.teams.length
    ) {
      const timeout = setTimeout(() => {
        void refetchTeams();
      }, 1_000);
      return () => clearTimeout(timeout);
    }
  }, [getTeamsData, refetchTeams, isFetchingTeams, user]);

  useEffect(() => {
    if (selectedTeam.id && !_.isEqual(selectedTeam, persistedTeam)) {
      setPersistedTeam(selectedTeam);
    }
  }, [persistedTeam, selectedTeam, setPersistedTeam]);

  useEffect(() => {
    if (selectedProject.id && !_.isEqual(selectedProject, persistedProject)) {
      setPersistedProject(selectedProject);
    }
  }, [persistedProject, selectedProject, setPersistedProject]);

  useEffect(() => {
    if (!router.isReady) return;

    const nextQuery = {
      teamId: routeTeamId,
      projectId: routeProjectId,
    };
    const currentQuery = {
      ...nextQuery,
    };
    const selectedTeamId = selectedTeam.id;
    const selectedProjectId = selectedProject.id;

    if (selectedTeamId && nextQuery.teamId !== selectedTeamId) {
      nextQuery.teamId = selectedTeamId;
    }

    if (selectedProjectId && nextQuery.projectId !== selectedProjectId) {
      nextQuery.projectId = selectedProjectId;
    }

    if (!_.isEqual(currentQuery, nextQuery)) {
      void router.replace({
        query: _.omitBy(
          {
            ..._.omit(router.query, "teamId", "projectId"),
            teamId: nextQuery.teamId || undefined,
            projectId: nextQuery.projectId || undefined,
          },
          _.isUndefined
        ),
      });
    }
  }, [
    router,
    routeTeamId,
    routeProjectId,
    selectedTeam.id,
    selectedProject.id,
  ]);

  // ----------------------------------

  if (isLoading) {
    return (
      <Center h="100vh">
        <Loading />
      </Center>
    );
  }

  return (
    <TeamContext.Provider
      value={{
        teams,
        selectedTeam,
        selectedTeamDetails,
        redirectToTeam,
        refetchTeamDetails,
        isLoadingTeamDetails,

        isCreateTeamModalOpen,
        showCreateTeamModal: () => setIsCreateTeamModalOpen(true),
        hideCreateTeamModal: () => setIsCreateTeamModalOpen(false),

        projects,
        selectedProject,
        redirectToProject,
        refetchProjects,
        hasNoProject,

        isCreateProjectModalOpen,
        showCreateProjectModal: () => setIsCreateProjectModalOpen(true),
        hideCreateProjectModal: () => setIsCreateProjectModalOpen(false),
      }}
    >
      {children}
    </TeamContext.Provider>
  );
}

export function useTeamContext() {
  return useContext<TeamProviderProps>(TeamContext);
}

// ------------------------------------

function parseProjectRole(role: string | undefined) {
  if (role && ["admin", "editor", "reader"].includes(role)) {
    return role as Project["userRole"];
  } else if (role) {
    return "reader";
  } else {
    return undefined;
  }
}
