import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Mutex } from "async-mutex";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { removeCuFromType } from "infra/api/ExludeCU";
import { usePostEmbedInferencesMutation } from "infra/api/generated/api";
import _ from "lodash";

import {
  instantMeiliSearch,
  InstantMeiliSearchObject,
} from "@meilisearch/instant-meilisearch";

const mutex = new Mutex();

interface SearchProviderProps {
  getAllIndexes: (type: "asset" | "model") => {
    [key: string]: string | undefined;
  };
  getIndexes: (
    type: "asset" | "model",
    opts?: { privacy?: ("public" | "private")[] }
  ) => string[];
  searchClient: InstantMeiliSearchObject["searchClient"] | undefined;
  getQueryEmbedding: (query: string) => Promise<
    | {
        query: string;
        embedding: number[];
      }
    | undefined
  >;
  clearCache: () => void;
  getIsPublicIndex: (index: string) => boolean;
}

export const SearchContext = createContext<SearchProviderProps>({
  getAllIndexes: () => ({}),
  getIndexes: () => [],
  searchClient: undefined,
  getQueryEmbedding: async () => undefined,
  clearCache: () => {},
  getIsPublicIndex: () => false,
});

export function SearchProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const { selectedTeamDetails, selectedProject } = useTeamContext();
  const [searchClient, setSearchClient] = useState<
    InstantMeiliSearchObject["searchClient"] | undefined
  >();
  const queryEmbeddingsRef = useRef<{
    [key: string]: number[];
  }>({});
  const [getEmbeddingTrigger] = usePostEmbedInferencesMutation();

  useEffect(() => {
    if (!selectedTeamDetails?.tenantToken) return;
    const { searchClient } = instantMeiliSearch(
      process.env.NEXT_PUBLIC_MEILISEARCH_URL ?? "",
      selectedTeamDetails.tenantToken
    );
    setSearchClient(searchClient);
  }, [selectedTeamDetails]);

  const getAllIndexes = useCallback(
    (type: "asset" | "model") => {
      if (type === "model") {
        return {
          private: selectedTeamDetails?.searchIndexes?.models?.private,
          public: selectedTeamDetails?.searchIndexes?.models?.public,
        };
      }
      return {
        private: selectedTeamDetails?.searchIndexes?.assets?.private,
        public: selectedTeamDetails?.searchIndexes?.assets?.public,
      };
    },
    [selectedTeamDetails]
  );

  const getIndexes = useCallback(
    (
      type: "asset" | "model",
      {
        privacy = ["public", "private"],
      }: { privacy?: ("public" | "private")[] } = {
        privacy: ["public", "private"],
      }
    ) => {
      const indexes = _.pick(getAllIndexes(type), privacy);
      return _.compact(_.values(indexes));
    },
    [getAllIndexes]
  );

  const getIsPublicIndex = useCallback(
    (index: string) => {
      const publicIndexes = [
        getAllIndexes("asset").public,
        getAllIndexes("model").public,
      ];
      return publicIndexes.includes(index.split(":")[0]);
    },
    [getAllIndexes]
  );

  const getQueryEmbedding = useCallback(
    async (query: string) => {
      await mutex.acquire();
      if (queryEmbeddingsRef.current[query]) {
        mutex.release();
        return {
          query,
          embedding: queryEmbeddingsRef.current[query],
        };
      }
      try {
        const data = await getEmbeddingTrigger({
          projectId: selectedProject.id,
          body: {
            text: query,
          },
        })
          .unwrap()
          .then(removeCuFromType);
        queryEmbeddingsRef.current[query] = data.embedding;
      } catch (_) {
      } finally {
        mutex.release();
      }
      return {
        query,
        embedding: queryEmbeddingsRef.current[query],
      };
    },
    [getEmbeddingTrigger, selectedProject.id]
  );

  const clearCache = useCallback(() => {
    if (searchClient) {
      searchClient.clearCache();
    }
  }, [searchClient]);

  return (
    <SearchContext.Provider
      value={{
        getAllIndexes,
        getIndexes,
        searchClient,
        getQueryEmbedding,
        clearCache,
        getIsPublicIndex,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export function useSearchContext() {
  return useContext<SearchProviderProps>(SearchContext);
}
