import React, { PropsWithChildren } from "react";
import { GuideKeys } from "domains/guide/constants/guides";
import Button from "domains/ui/components/Button";
import { PremiumButtonWrapper } from "domains/ui/components/PremiumBadge";
import WithLabelAndTooltip from "domains/ui/components/WithLabelAndTooltip";

import { Box, HStack, Tooltip } from "@chakra-ui/react";

interface ControlButtonSelectProps<T> {
  title: string;
  tooltip?: string;
  options: {
    value: T;
    label: string;
    isDisabled?: boolean;
    tooltip?: string;
    premiumWrapper?: boolean;
  }[];
  selectedOption: T;
  onOptionSelected: (selectedOption: T) => void;
  guide?: GuideKeys;
}

export function ButtonSelect({
  isDisabled,
  tooltip,
  premiumWrapper,
  isSelected,
  onClick,
  children,
}: {
  isDisabled?: boolean;
  tooltip?: string;
  premiumWrapper?: boolean;
  isSelected: boolean;
  onClick: () => void;
} & PropsWithChildren) {
  return (
    <Tooltip isDisabled={!tooltip} label={tooltip}>
      <Box w="100%" minW="1px">
        <PremiumButtonWrapper shouldShowWrapper={premiumWrapper ?? false}>
          <Button
            onClick={onClick}
            variant={"secondary"}
            size={"sm"}
            w={"100%"}
            isActive={isSelected}
            textColor={"textSecondary"}
            _active={{
              textColor: "textPrimary",
              borderWidth: 2,
              borderColor: "primary.500",
            }}
            isDisabled={isDisabled}
          >
            {children}
          </Button>
        </PremiumButtonWrapper>
      </Box>
    </Tooltip>
  );
}

export default function ControlButtonSelect<T = string | number>({
  title,
  tooltip,
  options,
  selectedOption,
  onOptionSelected,
  guide,
}: ControlButtonSelectProps<T>) {
  return (
    <WithLabelAndTooltip
      label={title}
      tooltip={tooltip}
      direction="column"
      guide={guide}
    >
      <HStack justify={"space-between"} w={"100%"} spacing={2}>
        {options.map((option) => (
          <ButtonSelect
            key={option.label}
            isDisabled={option.isDisabled}
            tooltip={option.tooltip}
            premiumWrapper={option.premiumWrapper}
            isSelected={selectedOption === option.value}
            onClick={() => onOptionSelected(option.value)}
          >
            {option.label}
          </ButtonSelect>
        ))}
      </HStack>
    </WithLabelAndTooltip>
  );
}
