import { PropsWithChildren, useEffect, useState } from "react";

interface DefferedProps extends PropsWithChildren {
  timeout: number;
  content?: React.ReactNode;
  placeholder?: React.ReactNode;
}

export default function Deffered({
  timeout,
  children,
  content,
  placeholder,
}: DefferedProps) {
  const [isDeffered, setIsDeffered] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsDeffered(true), timeout);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [timeout]);

  if (!isDeffered) return placeholder ? <>{placeholder}</> : null;
  else return <>{content ?? children}</>;
}
