import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useDeepMemo from "domains/commons/hooks/useDeepMemo";
import _ from "lodash";

import { FmFile, FmFileType } from "../interfacesV2";

interface UseSelectionArg<T extends FmFileType> {
  max?: number;
  onSelectionChange?: (files: FmFile<T>[]) => void;
}

export function useSelection<T extends FmFileType = FmFileType>(
  files: FmFile<T>[],
  { max, onSelectionChange }: UseSelectionArg<T> = {}
) {
  const [selection, setSelection] = useState<string[]>([]);
  const prevSelectionRef = useRef<string[]>(selection);
  const [lastSelected, setLastSelected] = useState<string | null>(null);
  const fileIds = useDeepMemo(
    useMemo(() => files.map((file) => file.id), [files])
  );

  // ----------------------------------

  const handleSelectAll = useCallback(() => {
    setSelection((selection) => {
      const newSelected = new Set(selection);
      for (const fileId of fileIds) {
        if (max && newSelected.size >= max) {
          break;
        } else {
          newSelected.add(fileId);
        }
      }
      setLastSelected(null);
      return [...newSelected];
    });
  }, [max, fileIds]);

  const handleClearSelection = () => {
    setSelection([]);
    setLastSelected(null);
  };

  const handleSelectRange = useCallback(
    (from: string, to: string) => {
      setSelection((selection) => {
        const newSelected = new Set(selection);
        const fromIndex = fileIds.indexOf(from);
        const toIndex = fileIds.indexOf(to);

        // Shouldn't happen
        if (toIndex === -1 || fromIndex === -1) return selection;

        const [startIndex, endIndex] =
          fromIndex < toIndex ? [fromIndex, toIndex] : [toIndex, fromIndex];
        for (let i = startIndex; i <= endIndex; i++) {
          if (max && newSelected.size >= max) {
            break;
          } else {
            newSelected.add(fileIds[i]);
          }
        }

        return [...newSelected];
      });

      setLastSelected(to);
    },
    [fileIds, max]
  );

  const handleSelect = useCallback(
    (id: string, { shiftPressed = false }: { shiftPressed?: boolean } = {}) => {
      if (!shiftPressed || !lastSelected) {
        setSelection((selection) => {
          const newSelected = new Set(selection);
          if (selection.includes(id)) {
            newSelected.delete(id);
            setLastSelected(null);
          } else {
            newSelected.add(id);
            setLastSelected(id);
          }

          return [...newSelected];
        });
      } else {
        handleSelectRange(lastSelected, id);
      }
    },
    [handleSelectRange, lastSelected]
  );

  // ----------------------------------

  // When files are updated, we want to remove from selection all files that might not exists anymore
  useEffect(() => {
    let hasChanged = false;
    const newSelected = new Set(selection);
    newSelected.forEach((id) => {
      if (!fileIds.includes(id)) {
        hasChanged = true;
        newSelected.delete(id);
      }
    });
    if (hasChanged) {
      setSelection([...newSelected]);
    }
  }, [selection, fileIds]);

  useEffect(() => {
    if (_.isEqual(prevSelectionRef.current, selection)) {
      return;
    }
    if (onSelectionChange) {
      onSelectionChange(files.filter((file) => selection.includes(file.id)));
    }
    prevSelectionRef.current = selection;
  }, [files, selection, onSelectionChange]);

  // ----------------------------------

  return {
    selection,
    setSelection,
    handleSelect,
    handleSelectAll,
    handleClearSelection,
    handleSelectRange,
    lastSelected,
  };
}
