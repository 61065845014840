import React from "react";
import Icon from "domains/ui/components/Icon";

import { Box, Flex, HStack, Input, Spinner, Text } from "@chakra-ui/react";

export interface TagProps {
  isDraggable?: boolean;
  dragHelper?: "left" | "right";
  dragHelperComponent?: React.ReactNode;
  isDragging?: boolean;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  isEditing?: boolean;
  label: string;
  onInputKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onRemoveClick?: () => void;
  onResetClick?: () => void;
  isLoading?: boolean;
  canEdit?: boolean;
}

const Tag = React.forwardRef<HTMLDivElement, TagProps>(
  (
    {
      isDraggable,
      dragHelper,
      dragHelperComponent,
      isDragging,
      onClick,
      isEditing,
      label,
      onInputKeyDown,
      onRemoveClick,
      onResetClick,
      isLoading,
      canEdit = true,
    },
    ref
  ) => (
    <>
      {dragHelper === "left" && dragHelperComponent}

      <HStack
        ref={ref}
        pos="relative"
        px={1.5}
        py={1}
        opacity={isDragging ? "0.5" : "1"}
        borderRadius="md"
        wordBreak="break-word"
        cursor={isDraggable ? "grab" : "default"}
        bgColor="whiteAlpha.100"
        onClick={onClick}
      >
        {isEditing ? (
          <Input h={8} px={2} defaultValue={label} onKeyDown={onInputKeyDown} />
        ) : (
          <Text size={"body.md"}>{label}</Text>
        )}

        {canEdit && (
          <Flex
            align="center"
            h="100%"
            p={1}
            borderRadius="base"
            cursor="pointer"
            bgColor="whiteAlpha.100"
            onClick={!isEditing ? onRemoveClick : onResetClick}
          >
            {!isEditing ? (
              <Icon id="Ui/CrossSm" />
            ) : (
              <Icon id="Ui/Revert" height="8px" />
            )}
          </Flex>
        )}

        {isLoading && (
          <Flex
            pos={"absolute"}
            top={0}
            left={0}
            align="center"
            justify="center"
            w="100%"
            h="100%"
            borderRadius="base"
            bgColor="blackAlpha.700"
          >
            <Box
              __css={{
                div: {
                  "--spinner-size": "8px",
                },
              }}
            >
              <Spinner w="8px" h="8px" />
            </Box>
          </Flex>
        )}
      </HStack>

      {dragHelper === "right" && dragHelperComponent}
    </>
  )
);

export default Tag;
