import { memo, useMemo } from "react";
import MenuListMultipleChoice from "domains/ui/components/Menu/MenuListMultipleChoice";

const RESTRICT_OPTIONS = [
  "name",
  "metadata.prompt",
  "metadata.negativePrompt",
  "description",
  "metadata.seed",
];

export type RestrictOption = (typeof RESTRICT_OPTIONS)[number];

export const ALLOWED_RESTRICT_VALUES: {
  [key in
    | "image"
    | "skybox"
    | "texture"
    | "canvas"
    | "model"]: RestrictOption[];
} = {
  image: [
    "metadata.prompt",
    "metadata.negativePrompt",
    "description",
    "metadata.seed",
  ],
  skybox: [
    "metadata.prompt",
    "metadata.negativePrompt",
    "description",
    "metadata.seed",
  ],
  texture: [
    "metadata.prompt",
    "metadata.negativePrompt",
    "description",
    "metadata.seed",
  ],
  canvas: ["name", "description"],
  model: ["name"],
};

const OPTION_LABELS: {
  [key in RestrictOption]: string;
} = {
  name: "Name",
  "metadata.prompt": "Prompt",
  "metadata.negativePrompt": "Negative Prompt",
  description: "Description",
  "metadata.seed": "Seed",
};

export interface FilterRestrictsProps {
  type: "image" | "skybox" | "texture" | "canvas" | "model";
  value: RestrictOption[];
  setValue: (value: RestrictOption[]) => void;
}

export default memo(function FilterRestricts({
  type,
  value: initialValue,
  setValue,
}: FilterRestrictsProps) {
  const value = useMemo(() => {
    return initialValue.filter((option) =>
      ALLOWED_RESTRICT_VALUES[type].includes(option)
    );
  }, [initialValue, type]);

  return (
    <MenuListMultipleChoice
      label="Limit Search To"
      options={ALLOWED_RESTRICT_VALUES[type].map((option) => ({
        value: option,
        label: OPTION_LABELS[option],
      }))}
      setSelectedValues={setValue}
      selectedValues={value}
      withoutSearch
    />
  );
});
