import Link from "next/link";

import { Flex, Heading, Text, VStack } from "@chakra-ui/react";

export interface CanvasListEmptyStateProps {}

const CanvasListEmptyState = ({}: CanvasListEmptyStateProps) => (
  <Flex>
    <VStack mt={4}>
      <Heading size="md">
        {"You haven't created any canvas projects yet"}
      </Heading>

      <Link href={`/canvas/new`}>
        <Text color="blue.500">Click here to create your first project</Text>
      </Link>
    </VStack>
  </Flex>
);

export default CanvasListEmptyState;
