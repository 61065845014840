import {
  GridViewKey,
  gridViewKeys,
  gridViews,
} from "domains/file-manager/constants/GridView";
import Icon from "domains/ui/components/Icon";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import MenuTitle from "domains/ui/components/Menu/MenuTitle";
import { useHover } from "domains/ui/hooks/useHover";

import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";

export interface GridViewDropdownProps {
  value: Exclude<GridViewKey, "jobs">;
  onChange: (value: Exclude<GridViewKey, "jobs">) => void;
}

const GridViewDropdown = ({ value, onChange }: GridViewDropdownProps) => {
  const [hoverRef, isHover] = useHover<HTMLButtonElement>();
  const views = gridViewKeys.filter((key) => key !== "jobs");

  return (
    <Menu variant="blurred">
      <Tooltip
        isOpen={isHover}
        label="Change the display alignment of the images."
        placement="auto"
      >
        <MenuButton
          ref={hoverRef}
          as={IconButton}
          colorScheme="white"
          data-testid="asset-gallery-change-view-button"
          variant="ghost"
        >
          <Icon id={gridViews[value].icon.active} />
        </MenuButton>
      </Tooltip>

      <MenuList>
        <MenuTitle text="Images" />
        {views.map((key) => {
          const isSelected = value === key;
          const dynamicTestId =
            "asset-gallery-change-view-" +
            gridViews[key].label.toLowerCase().replace(/ /g, "-");
          return (
            <MenuItem
              key={key}
              w="150px"
              data-testid={dynamicTestId}
              iconId={
                isSelected
                  ? gridViews[key].icon.active
                  : gridViews[key].icon.default
              }
              onClick={() => onChange(key)}
              text={gridViews[key].label}
              isSelected={isSelected}
              check="icon"
            />
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default GridViewDropdown;
