import { memo, useMemo } from "react";
import MenuListMultipleChoice from "domains/ui/components/Menu/MenuListMultipleChoice";

const MODEL_BASES = [
  {
    id: "sd-1_5",
    label: "SD 1.5",
  },
  {
    id: "sd-xl",
    label: "SDXL",
  },
  {
    id: "flux",
    label: "Flux",
  },
];
export type ModelBase = (typeof MODEL_BASES)[number]["id"];

function getLabel(
  value: ModelBase,
  typesFacets?: {
    [key: string]: number;
  }
) {
  const label = MODEL_BASES.find((base) => base.id === value)!.label;

  let count = 0;
  Object.entries(typesFacets ?? {}).forEach(([type, typeCount]) => {
    if (type.includes(value)) {
      count += typeCount;
    }
  });

  if (!count) {
    return label;
  }
  return `${label} (${count})`;
}

export interface FilterModelBasesProps {
  value: ModelBase[];
  setValue: (value: ModelBase[]) => void;
  typesFacets?: {
    [key: string]: number;
  };
}

export default memo(function FilterModelBases({
  value,
  setValue,
  typesFacets,
}: FilterModelBasesProps) {
  const options = useMemo(() => {
    return MODEL_BASES.map((base) => ({
      value: base.id,
      label: getLabel(base.id, typesFacets),
    }));
  }, [typesFacets]);

  return (
    <MenuListMultipleChoice
      label="Base"
      options={options}
      setSelectedValues={setValue}
      selectedValues={value}
      withoutSearch
    />
  );
});
