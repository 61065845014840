import { assertNever } from "domains/commons/assertNever";
import { FileImageType } from "domains/file-manager/interfaces";
import {
  GetAssetsByAssetIdApiResponse,
  GetJobIdApiResponse,
} from "infra/api/generated/api";

export const mapAssetTypeToJobType = (
  assetType: GetAssetsByAssetIdApiResponse["asset"]["metadata"]["type"]
):
  | GetJobIdApiResponse["job"]["jobType"]
  | GetJobIdApiResponse["job"]["jobType"][] => {
  switch (assetType) {
    case "background-removal":
      return "remove-background";
    case "canvas-export":
      return "canvas-export";
    case "detection":
      return "detection";
    case "inference-controlnet":
    case "inference-controlnet-img2img":
    case "inference-controlnet-inpaint":
    case "inference-controlnet-inpaint-ip-adapter":
    case "inference-controlnet-ip-adapter":
    case "inference-controlnet-reference":
    case "inference-controlnet-texture":
    case "inference-txt2img":
    case "inference-txt2img-ip-adapter":
    case "inference-txt2img-texture":
    case "inference-img2img":
    case "inference-img2img-ip-adapter":
    case "inference-img2img-texture":
    case "inference-inpaint":
    case "inference-inpaint-ip-adapter":
    case "inference-reference":
    case "inference-reference-texture":
      return ["inference", "flux"];
    case "pixelization":
      return "pixelate";
    case "reframe":
      return "reframe";
    case "restyle":
      return "restyle";
    case "skybox-base-360":
      return "skybox-base-360";
    case "upscale-skybox":
      return "skybox-upscale-360";
    case "texture":
    case "texture-albedo":
    case "texture-ao":
    case "texture-edge":
    case "texture-height":
    case "texture-metallic":
    case "texture-normal":
    case "texture-smoothness":
      return "texture";
    case "uploaded":
      return "upload";
    case "upscale":
      return "upscale";
    case "upscale-texture":
      return "upscale-texture";
    case "vectorization":
      return "vectorize";
    case "patch":
    case "generative-fill":
    case "canvas":
    case "canvas-drawing":
    case "segment":
    case "segmentation-image":
    case "segmentation-mask":
      return "upload"; // this is simply a placeholder because i didn't find a suitable job type or didn't care enough about the asset to find one
    default:
      assertNever(assetType);
  }
};

export function mapAssetsToJobs(files: FileImageType[]) {
  return files.reduce(
    (acc, file) => {
      const parentJobId =
        (file.meta.metadata.parentJobId?.length ?? 0) > 0
          ? file.meta.metadata.parentJobId!
          : Math.random().toString();
      const jobIndex = acc.findIndex((job) => job.id === parentJobId);
      if (jobIndex === -1) {
        const jobType = mapAssetTypeToJobType(file.meta.metadata.type);
        acc.push({
          title: file.meta.metadata.prompt ?? "",
          id: parentJobId,
          job: {
            jobId: parentJobId,
            status: "success",
            createdAt: file.meta.createdAt,
            statusHistory: [],
            updatedAt: file.meta.updatedAt,
            metadata: {
              input: {
                ...file.meta.metadata,
                numSamples: 1,
                numOutputs: 1,
                type: file.meta.metadata.type
                  .replace("inference-", "")
                  .replace(/-/g, "_"),
              },
            },
            jobType: Array.isArray(jobType) ? jobType[0] : jobType,
            progress: 1,
          },
          files: [file],
        });
      } else {
        acc[jobIndex].files.push(file);
        const jobInput = acc[jobIndex].job.metadata?.input as any;
        const filesLength = acc[jobIndex].files.length;
        if (jobInput) {
          jobInput.numSamples = filesLength;
          jobInput.numOutputs = filesLength;
        }
      }
      return acc;
    },
    [] as {
      title: string;
      id: string;
      job: GetJobIdApiResponse["job"];
      files: FileImageType[];
    }[]
  );
}
