import { ComponentStyleConfig, defineStyleConfig } from "@chakra-ui/react";

export const Tabs: ComponentStyleConfig = defineStyleConfig({
  baseStyle: {
    root: {
      pos: "relative",
    },
    tab: {
      _selected: {
        color: "textPrimary",
      },
      color: "textSecondary",
      fontWeight: 500,
    },
    tablist: {
      borderBottomColor: "border.500",
      borderBottomWidth: "1px",
    },
    tabpanels: {
      pt: 10,
    },
    tabpanel: {
      p: 0,
    },
  },
  variants: {
    unstyled: {},
    light: {
      root: {
        "& .chakra-tabs__tab-indicator": {
          background: "white",
        },
      },
      tablist: {
        pos: "relative",
        px: 3,
        gap: 6,
      },
      tab: {
        paddingInlineStart: 0,
        paddingInlineEnd: 0,
        _hover: {
          color: "textPrimary",
        },
      },
    },
  },
  sizes: {
    lg: {
      tab: {
        h: "56px",
        fontSize: "14px",
        color: "textTertiary",
      },
      tabpanels: {
        pt: 2,
      },
    },
    sm: {
      tab: {
        py: 3,
        paddingInlineStart: 2,
        paddingInlineEnd: 2,
      },
      tablist: {
        gap: 3,
      },
      tabpanels: {
        pt: 2,
      },
    },
  },
  defaultProps: {
    size: "sm",
    variant: "unstyled",
  },
});
