import { getModelThumbnail } from "domains/models/utils";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  AsideCard,
  AsideList,
  AsideSection,
} from "domains/ui/components/Aside";
import {
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
  useGetModelsByModelIdQuery,
} from "infra/api/generated/api";

import { SkeletonText } from "@chakra-ui/react";

export interface AsideSectionConceptsProps {
  inference:
    | GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
    | undefined;
}

export default function AsideSectionConcepts({
  inference,
}: AsideSectionConceptsProps) {
  if (
    !(
      inference?.parameters.concepts &&
      inference?.parameters.concepts?.length > 0
    )
  ) {
    return null;
  }

  return (
    <AsideSection id="concepts" title="Model Components">
      <AsideList>
        {inference.parameters.concepts.map(
          ({ modelId, scale }: { modelId: string; scale: number }) => (
            <ConceptItem key={modelId} modelId={modelId} scale={scale} />
          )
        )}
      </AsideList>
    </AsideSection>
  );
}

// ------------------------------------

interface ConceptItemProps {
  modelId: string;
  scale: number;
}

export function ConceptItem({ modelId, scale }: ConceptItemProps) {
  const { selectedProject } = useTeamContext();
  const { data: modelData, isLoading: isModelDataLoading } =
    useGetModelsByModelIdQuery({
      projectId: selectedProject.id,
      modelId,
    });
  const model = modelData?.model;
  return (
    <AsideCard
      title={
        isModelDataLoading ? (
          <SkeletonText w="70%" noOfLines={1} skeletonHeight="20px" />
        ) : (
          model?.name
        )
      }
      subTitle={`${scale}`}
      avatarUrl={
        getModelThumbnail(model, {
          width: 64,
        }).url
      }
      internalLink={
        model && {
          pathname: "/models/[id]",
          query: {
            id: model.id,
          },
        }
      }
    />
  );
}
