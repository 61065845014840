import React from "react";
import { IconButton, IconButtonProps } from "domains/ui/components/Button";

import { ComponentWithAs, MenuButton, MenuButtonProps } from "@chakra-ui/react";

export type MenuButtonIconProps = MenuButtonProps;

const MenuButtonIcon = React.forwardRef<HTMLButtonElement, MenuButtonIconProps>(
  function MenuButtonIcon(props, ref) {
    return (
      <MenuButton
        as={IconButton}
        size="sm"
        variant="secondary"
        {...props}
        ref={ref}
      />
    );
  }
);

export default MenuButtonIcon as ComponentWithAs<"button", IconButtonProps>;
