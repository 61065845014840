import React from "react";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

interface BlendAddButtonProps {
  isAdded?: boolean;
  onAdd: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onUndoAdd: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function BlendAddButton({
  isAdded,
  onAdd,
  onUndoAdd,
}: BlendAddButtonProps) {
  return (
    <Button
      position="absolute"
      variant="ghost"
      top="0%"
      right="0%"
      onClick={isAdded ? onUndoAdd : onAdd}
      bg="grayAlpha.700"
      backdropFilter="blur(7px) brightness(0.65)"
      _hover={{
        bg: "blackAlpha.500",
      }}
      textColor="textPrimary"
      m={1}
      size="sm"
      px={2}
      h="32px"
      leftIcon={isAdded ? <Icon id="Ui/CheckSm" h="8px" /> : undefined}
      gap={1}
    >
      {isAdded ? "Added" : "Add"}
    </Button>
  );
}
