import { useCallback, useEffect, useRef, useState } from "react";

import {
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  Slider as ChakraSlider,
  SliderFilledTrack,
  SliderProps as ChakraSliderProps,
  SliderThumb,
  SliderTrack,
  StyleProps,
  Text,
  TextProps,
} from "@chakra-ui/react";

export interface SliderProps extends Omit<ChakraSliderProps, "size"> {
  hideValue?: boolean;
  valueText?: string | number;
  valueTextProps?: TextProps;
  valueNumberInputProps?: NumberInputProps;
  valueStyleProps?: StyleProps;
  withNumberInput?: boolean;
}

export default function Slider({
  hideValue,
  valueTextProps,
  valueNumberInputProps,
  valueStyleProps,
  valueText,
  withNumberInput: isEditable = false,
  ...props
}: SliderProps) {
  const thumbRef = useRef<HTMLDivElement>(null);
  const [valueAsString, setValueAsString] = useState<string>(
    props.value?.toString() ?? ""
  );

  const handleSubmit = useCallback(() => {
    let newValue = Number(valueAsString);
    const { min, max } = props;

    if (isNaN(newValue)) {
      newValue = min ?? 0; // or any default value you want to set when input is NaN
    }

    // need to ensure newValue doesn't have more decimal places than step
    const decimalCount = props.step?.toString().split(".")[1]?.length ?? 0;
    newValue = parseFloat(newValue.toFixed(decimalCount));

    if (min !== undefined && newValue < min) {
      newValue = min;
    } else if (max !== undefined && newValue > max) {
      newValue = max;
    }

    props.onChange?.(newValue);
    setValueAsString(newValue.toString());
  }, [props, valueAsString]);

  useEffect(() => {
    setValueAsString(props.value?.toString() ?? "");
  }, [props.value]);

  return (
    <HStack overflowY="visible" w="100%" spacing={2}>
      <ChakraSlider
        flex={3}
        m={2}
        colorScheme="whiteAlpha"
        focusThumbOnChange={false}
        onChangeStart={(value: number) => {
          thumbRef.current?.focus();
          props.onChangeStart?.(value);
        }}
        onClick={(...args) => {
          thumbRef.current?.focus();
          props.onClick?.(...args);
        }}
        {...props}
        {...(props.min !== undefined && props.min === props.max
          ? {
              min: props.min - 1,
            }
          : {})}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb ref={thumbRef} />
      </ChakraSlider>

      {!hideValue &&
        (isEditable ? (
          <NumberInput
            mr={-2.5}
            borderRadius="sm"
            isDisabled={props.isDisabled}
            max={props.max}
            min={props.min}
            onBlur={handleSubmit}
            onChange={(newValue) => {
              setValueAsString(newValue);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            size="xs"
            step={props.step}
            value={valueAsString}
            variant="ghost"
            {...valueNumberInputProps}
            {...valueStyleProps}
          >
            <NumberInputField />
          </NumberInput>
        ) : (
          <Text
            w="40px"
            color="inherit"
            textAlign="right"
            size="body.md"
            {...valueTextProps}
            {...valueStyleProps}
          >
            {valueText ?? props.value}
          </Text>
        ))}
    </HStack>
  );
}
