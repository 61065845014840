import React, { RefObject, useCallback, useMemo, useRef } from "react";
import useClickOutside from "domains/assets/hooks/useClickOutside";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import { AllOrNone } from "domains/commons/ts-utils";
import { useSelection } from "domains/file-manager/hooks/useSelectionV2";
import { FmFile, FmFileType, FmJob } from "domains/file-manager/interfacesV2";

import { Center, Flex, FlexProps, Spinner, VStack } from "@chakra-ui/react";

import FmGridContainer, { FmGridProps } from "./FmGrid";
import FmHeader from "./FmHeader";
import { FmListProps } from "./FmList";
import {
  FmSelectionActionsContainer,
  FmSelectionActionsProps,
} from "./FmSelectionActions";
import { FmVirtualFilesProps } from "./FmVirtualFiles";

type FmSettings = {
  gridView: "fill"; ////// TODO: Remove once FileManager (v1) is removed
  columnsCount: number;
};

export type FileManagerProps<
  T extends FmFileType = FmFileType,
  VF extends object = object,
  SA extends object = object,
  G extends object = object,
  L extends object = object,
  ES extends object = object
> = {
  type: T;

  jobs?: FmJob<T>[];
  files?: FmFile<T>[];

  isSkeleton?: boolean;

  isSelectable?: boolean;
  isSelectionForced?: boolean;
  selectionMax?: number;
  onSelectionChange?: (files: FmFile<T>[]) => void;

  onCardClick: (file: FmFile<T>) => void;
  withLink?: boolean;

  gridProps: G;
  GridComponent: React.FunctionComponent<FmGridProps<T, G>>;

  emptyStateProps?: ES;
  EmptyStateComponent?: (props: ES) => JSX.Element;

  settingsCacheKey: PersistedStateKey;
  columnsCount?: number;
  initialColumnsCount?: number;
  minColumnsCount?: number;

  isLoading?: boolean;
  hasMore?: boolean;
  onEndReached?: () => void;

  headerLeftText?: React.ReactNode;
  loadingText?: React.ReactNode;

  headerLeftContent?: React.ReactNode;
  headerRightContent?: React.ReactNode;
  topContent?: React.ReactNode;

  isWithoutHeader?: boolean;
  isWithoutSelectionBar?: boolean;

  scrollRef?: RefObject<HTMLDivElement>;
  styleProps?: {
    header?: FlexProps;
  };

  assetZoomPriorityLevel?: number;
} & AllOrNone<{
  listProps: L;
  ListComponent: React.FunctionComponent<FmListProps<T, L>>;
}> &
  AllOrNone<{
    virtualFilesProps: VF;
    VirtualFilesComponent: React.FunctionComponent<FmVirtualFilesProps<T, VF>>;
  }> &
  AllOrNone<{
    selectionActionsProps: SA;
    SelectionActionsComponent?: React.FunctionComponent<
      FmSelectionActionsProps<T, SA>
    >;
  }>;

export default function FileManager<
  T extends FmFileType = FmFileType,
  VF extends object = object,
  SA extends object = object,
  G extends object = object,
  L extends object = object,
  ES extends object = object
>({
  type,

  jobs,
  files,

  isSkeleton = false,

  isSelectable = false,
  isSelectionForced = false,
  selectionMax,
  onSelectionChange,

  onCardClick,
  withLink,

  emptyStateProps,
  EmptyStateComponent,

  gridProps,
  GridComponent,

  listProps,
  ListComponent,

  selectionActionsProps,
  SelectionActionsComponent,

  virtualFilesProps,
  VirtualFilesComponent,

  settingsCacheKey,
  columnsCount,
  initialColumnsCount,
  minColumnsCount = 1,

  isLoading = false,
  hasMore = false,
  onEndReached,

  topContent,
  headerLeftContent,
  headerRightContent,
  headerLeftText,
  loadingText,

  isWithoutHeader = false,
  isWithoutSelectionBar = false,

  scrollRef,
  styleProps,

  assetZoomPriorityLevel = 0,
}: FileManagerProps<T, VF, SA, G, L, ES>) {
  const gridContainerRef = useRef<HTMLDivElement>(null);

  const [settings, setSettings] = usePersistedState<FmSettings>(
    columnsCount === undefined ? settingsCacheKey : undefined,
    {
      defaultValue: {
        gridView: "fill", ////// TODO: Remove once FileManager (v1) is removed
        columnsCount: Math.max(minColumnsCount, initialColumnsCount ?? 6),
      },
    }
  );
  const settingColumnsCount = columnsCount ?? settings.columnsCount;

  const overallFiles = useMemo<FmFile<T>[]>(() => {
    const filesFromJobs =
      (jobs &&
        jobs.reduce<FmFile<T>[]>((memo, row) => {
          return memo.concat(row.files as FmFile<T>[]);
        }, [])) ||
      undefined;
    return filesFromJobs ?? files ?? [];
  }, [jobs, files]);

  const isEmpty = useMemo(
    () => !((jobs && jobs.length) || (files && files.length)),
    [jobs, files]
  );
  const isShowingGrid = files !== undefined;
  const isShowingList = jobs !== undefined;

  const { selection, handleSelectAll, handleSelect, handleClearSelection } =
    useSelection(overallFiles, { max: selectionMax, onSelectionChange });
  const hasSelection = !!selection.length;
  const isSelectionMaxReached =
    selectionMax !== undefined && selectionMax <= selection.length;

  const selectedFiles = useMemo(
    () => overallFiles.filter((file) => selection.includes(file.id)),
    [overallFiles, selection]
  );

  const step = useMemo(() => {
    if (isLoading && isEmpty && !isSkeleton) {
      return "loading";
    } else if (isEmpty && !isSkeleton) {
      return "empty";
    } else if (isShowingGrid) {
      return "grid";
    } else if (isShowingList) {
      return "list";
    }
  }, [isLoading, isEmpty, isShowingGrid, isShowingList, isSkeleton]);

  // ----------------------------------

  const handleColumnsCountChange = useCallback(
    (columnsCount: number) => {
      setSettings((settings) => ({ ...settings, columnsCount }));
    },
    [setSettings]
  );

  // ----------------------------------

  useClickOutside(handleClearSelection, !hasSelection || isSelectionForced);

  // ----------------------------------

  return (
    <>
      {!!VirtualFilesComponent && (
        <VirtualFilesComponent files={overallFiles} {...virtualFilesProps} />
      )}

      <Flex
        pos="relative"
        direction="column"
        h="full"
        data-asset-zoom-priority-level={assetZoomPriorityLevel}
      >
        {!isWithoutHeader && (
          <FmHeader
            title={headerLeftText}
            leftContent={headerLeftContent}
            rightContent={headerRightContent}
            styleProps={styleProps?.header}
            isLoading={!isSkeleton && isLoading}
            columnsCount={
              columnsCount === undefined ? settingColumnsCount : undefined
            }
            minColumnsCount={minColumnsCount}
            onColumnsCountChange={
              columnsCount === undefined ? handleColumnsCountChange : undefined
            }
          />
        )}

        <VStack align="stretch" flexGrow={1} pt={1} spacing={3}>
          {topContent}

          {step === "loading" && (
            <Center minH="100px">
              <Spinner />
            </Center>
          )}

          {step === "empty" && (
            <VStack h="full">
              {EmptyStateComponent ? (
                <EmptyStateComponent {...(emptyStateProps ?? ({} as ES))} />
              ) : undefined}
            </VStack>
          )}

          {(step === "grid" || step === "list") && (
            <FmGridContainer
              ref={gridContainerRef}
              hasSelection={hasSelection}
              hasMore={hasMore}
            >
              {step === "grid" && (
                <GridComponent
                  type={type}
                  files={files ?? []}
                  hasMore={hasMore}
                  isSkeleton={isSkeleton}
                  isSelectionForced={isSelectionForced}
                  onEndReached={onEndReached}
                  onSelect={handleSelect}
                  selection={selection}
                  isSelectable={isSelectable}
                  isSelectionMaxReached={isSelectionMaxReached}
                  columnsCount={settingColumnsCount}
                  containerRef={gridContainerRef}
                  scrollRef={scrollRef}
                  loadingText={loadingText}
                  onCardClick={onCardClick}
                  withLink={withLink}
                  {...gridProps}
                />
              )}

              {step === "list" && !!ListComponent && (
                <ListComponent
                  type={type}
                  jobs={jobs ?? []}
                  hasMore={hasMore}
                  isSelectionForced={isSelectionForced}
                  onEndReached={onEndReached}
                  onSelect={handleSelect}
                  selection={selection}
                  isSelectable={isSelectable}
                  isSelectionMaxReached={isSelectionMaxReached}
                  columnsCount={settingColumnsCount}
                  containerRef={gridContainerRef}
                  scrollRef={scrollRef}
                  loadingText={loadingText}
                  onCardClick={onCardClick}
                  withLink={withLink}
                  {...listProps}
                />
              )}
            </FmGridContainer>
          )}
        </VStack>

        {!isWithoutSelectionBar && (
          <FmSelectionActionsContainer
            totalCount={overallFiles.length}
            selectionCount={selectedFiles.length}
            selectionMax={selectionMax}
            onSelectAll={handleSelectAll}
            onDeselectAll={handleClearSelection}
          >
            {!!SelectionActionsComponent && (
              <SelectionActionsComponent
                selectedFiles={selectedFiles}
                isDisplayed={!!selectedFiles.length}
                {...selectionActionsProps}
              />
            )}
          </FmSelectionActionsContainer>
        )}
      </Flex>
    </>
  );
}
