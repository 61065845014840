import { INFERENCE_TYPE_LABELS } from "domains/inference/constants/inferenceTypes";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

export type AssetType =
  GetAssetsByAssetIdApiResponse["asset"]["metadata"]["type"];

export type AssetWithIpAdapterType =
  | Exclude<
      AssetType,
      | "inference-txt2img-ip-adapter"
      | "inference-img2img-ip-adapter"
      | "inference-controlnet-ip-adapter"
    >
  | "inference-txt2img-ip-adapter-character"
  | "inference-txt2img-ip-adapter-style"
  | "inference-img2img-ip-adapter-character"
  | "inference-img2img-ip-adapter-style"
  | "inference-controlnet-ip-adapter-character"
  | "inference-controlnet-ip-adapter-style";

export const ASSET_TYPE_LABELS: Record<
  AssetType | AssetWithIpAdapterType,
  string
> = {
  "background-removal": "Background Removal",
  canvas: "Canvas",
  detection: "Mode Map",
  "inference-controlnet": INFERENCE_TYPE_LABELS["controlnet"],
  "inference-controlnet-img2img": INFERENCE_TYPE_LABELS["controlnet_img2img"],
  "inference-controlnet-inpaint": INFERENCE_TYPE_LABELS["controlnet_inpaint"],
  "inference-controlnet-inpaint-ip-adapter":
    INFERENCE_TYPE_LABELS["controlnet_inpaint_ip_adapter"],
  "inference-controlnet-ip-adapter":
    INFERENCE_TYPE_LABELS["controlnet_ip_adapter"],
  "inference-controlnet-reference":
    INFERENCE_TYPE_LABELS["controlnet_reference"],
  "inference-img2img": INFERENCE_TYPE_LABELS["img2img"],
  "inference-img2img-ip-adapter": INFERENCE_TYPE_LABELS["img2img_ip_adapter"],
  "inference-img2img-texture": INFERENCE_TYPE_LABELS["img2img_texture"],
  "inference-inpaint": INFERENCE_TYPE_LABELS["inpaint"],
  "inference-inpaint-ip-adapter": INFERENCE_TYPE_LABELS["inpaint_ip_adapter"],
  "inference-reference": INFERENCE_TYPE_LABELS["reference"],
  "inference-reference-texture": INFERENCE_TYPE_LABELS["reference_texture"],
  "inference-txt2img": INFERENCE_TYPE_LABELS["txt2img"],
  "inference-txt2img-ip-adapter": INFERENCE_TYPE_LABELS["txt2img_ip_adapter"],
  "inference-txt2img-texture": INFERENCE_TYPE_LABELS["txt2img_texture"],
  "inference-controlnet-texture": INFERENCE_TYPE_LABELS["controlnet_texture"],
  pixelization: "Pixelization",
  "segmentation-image": "Segmentation Image",
  "segmentation-mask": "Segmentation Mask",
  uploaded: "Uploaded",
  upscale: "Enhance",
  vectorization: "Vectorization",
  "skybox-base-360": "Skybox",
  "upscale-skybox": "Skybox Enhance",
  restyle: "Restyle",
  "inference-txt2img-ip-adapter-character":
    INFERENCE_TYPE_LABELS["txt2img_ip_adapter_character"],
  "inference-txt2img-ip-adapter-style":
    INFERENCE_TYPE_LABELS["txt2img_ip_adapter_style"],
  "inference-img2img-ip-adapter-character":
    INFERENCE_TYPE_LABELS["img2img_ip_adapter_character"],
  "inference-img2img-ip-adapter-style":
    INFERENCE_TYPE_LABELS["img2img_ip_adapter_style"],
  "inference-controlnet-ip-adapter-character":
    INFERENCE_TYPE_LABELS["controlnet_ip_adapter_character"],
  "inference-controlnet-ip-adapter-style":
    INFERENCE_TYPE_LABELS["controlnet_ip_adapter_style"],
  texture: "Texture",
  "texture-albedo": "Albedo Map",
  "texture-ao": "AO Map",
  "texture-edge": "Edge Map",
  "texture-height": "Height Map",
  "texture-metallic": "Metallic Map",
  "texture-normal": "Normal Map",
  "texture-smoothness": "Smoothness Map",
  "upscale-texture": "Texture Enhance",
  patch: "Uploaded",
  segment: "Segment",
  "canvas-drawing": "Canvas Drawing",
  "canvas-export": "Canvas Export",
  "generative-fill": "Generative Fill",
  reframe: "Expand",
};
