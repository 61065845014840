import { assertNever } from "domains/commons/assertNever";
import { Job } from "domains/jobs/types";

import * as Sentry from "@sentry/nextjs";

export function getAssetNumberFromJob(job: Job): number {
  switch (job.jobType) {
    case "skybox-base-360":
      return (job.metadata.input as any)?.numOutputs ?? 0;
    case "skybox-upscale-360":
    case "upscale":
    case "upscale-texture":
    case "restyle":
    case "vectorize":
    case "pixelate":
    case "remove-background":
    case "reframe":
    case "repaint":
    case "detection":
    case "upload":
    case "canvas-export":
      return 1;
    case "inference":
    case "flux":
      return (
        (
          (job.metadata.input as any) ??
          ((job.metadata as any).parameters as any)
        ).numSamples ?? 1
      );
    case "texture":
      return 8;
    case "flux-model-training": {
      Sentry.captureException(
        "Thoses job types are not supposed to have an asset number https://github.com/scenario-labs/fawkes/pull/2861#discussion_r1819071941",
        {
          extra: {
            jobType: job.jobType,
            job,
          },
        }
      );
      return 0;
    }
    default:
      return assertNever(job.jobType as never);
  }
}

export function getSizeFromJob(job: Job) {
  switch (job.jobType) {
    case "skybox-base-360":
    case "skybox-upscale-360":
      return {
        width: job.frontData?.width ?? 1_024,
        height: job.frontData?.height ?? 512,
      };
    case "upscale":
    case "upscale-texture":
    case "vectorize":
    case "pixelate":
    case "repaint":
    case "reframe":
    case "restyle":
    case "detection":
    case "upload":
    case "canvas-export":
      return {
        width: job.frontData?.width ?? 512,
        height: job.frontData?.height ?? 512,
      };
    case "remove-background":
    case "inference":
    case "flux":
      return {
        width: (job.metadata.input as any).width ?? 512,
        height: (job.metadata.input as any).height ?? 512,
      };
    case "flux-model-training": {
      Sentry.captureException(
        "Thoses job types are not supposed to have a size https://github.com/scenario-labs/fawkes/pull/2861#discussion_r1819071941",
        {
          extra: {
            jobType: job.jobType,
            job,
          },
        }
      );
      return {
        width: 512,
        height: 512,
      };
    }
    case "texture":
      return {
        width: (job.metadata.input as any).textureWidth ?? 512,
        height: (job.metadata.input as any).textureHeight ?? 512,
      };
    default:
      return assertNever(job.jobType as never);
  }
}

export function getTypeLabelFromJob(job: Job) {
  switch (job.jobType) {
    case "inference":
    case "flux":
      if (((job.metadata.input as any)?.type ?? "").includes("texture")) {
        return "Texture";
      } else {
        return "Generate";
      }
    case "remove-background":
      return "Remove Background";
    case "pixelate":
      return "Pixelate";
    case "upscale":
      return "Enhance";
    case "vectorize":
      return "Vectorize";
    case "restyle":
      return "Restyle";
    case "reframe":
      return "Expand";

    case "upload":
      return "Upload";
    case "detection":
      return "Mode Map";
    case "canvas-export":
      return "Canvas Export";

    case "skybox-base-360":
      return "Preview";
    case "skybox-upscale-360":
      return "Enhanced Skybox";

    case "texture":
      return "Texture Set";
    case "upscale-texture":
      return "Enhanced Texture";

    default:
      return assertNever(job.jobType as never);
  }
}
