import Icon from "domains/ui/components/Icon";

import { BoxProps, HStack, Tag, Tooltip } from "@chakra-ui/react";

export interface TagPremiumProps {
  label?: string;
  tooltip?: string;
  containerProps?: BoxProps;
}

export function TagPremium({
  label,
  tooltip,
  containerProps,
}: TagPremiumProps) {
  return (
    <HStack pos="relative" zIndex="auto" justify="center" {...containerProps}>
      <Tooltip maxW="155px" hasArrow label={tooltip} placement="right">
        <Tag cursor="default" colorScheme="primary">
          <Icon mr={1} id="Ui/Stars" />
          {label}
        </Tag>
      </Tooltip>
    </HStack>
  );
}
