import { memo, useMemo } from "react";
import { FileModelType } from "domains/file-manager/interfaces";
import MenuListSingleChoice from "domains/ui/components/Menu/MenuListSingleChoice";

const MODEL_TYPES = [
  {
    id: "trained",
    label: "Trained",
  },
  {
    id: "composed",
    label: "Composed",
  },
  {
    id: "uploaded",
    label: "Uploaded",
  },
];
export type ModelType = (typeof MODEL_TYPES)[number]["id"];

function getLabel(value: ModelType, count?: number) {
  const label = MODEL_TYPES.find((type) => type.id === value)!.label;

  if (count) {
    return `${label} (${count})`;
  }
  return label;
}

export interface FilterModelTypeProps {
  value: ModelType | undefined;
  setValue: (value: ModelType | undefined) => void;
  models?: FileModelType[];
}

export default memo(function FilterModelType({
  value,
  setValue,
  models,
}: FilterModelTypeProps) {
  const facets = useMemo(() => {
    if (!models) {
      return undefined;
    }
    return models.reduce(
      (acc, model) => {
        if (model.meta.source !== "scenario") {
          acc.uploaded++;
        } else if (model.meta.type.includes("composition")) {
          acc.composed++;
        } else {
          acc.trained++;
        }
        return acc;
      },
      {
        trained: 0,
        composed: 0,
        uploaded: 0,
      } as Record<ModelType, number>
    );
  }, [models]);

  const options = useMemo(() => {
    return MODEL_TYPES.map((type) => ({
      value: type.id,
      label: getLabel(type.id, facets?.[type.id]),
    }));
  }, [facets]);

  return (
    <MenuListSingleChoice
      placeholder="Type"
      options={options}
      setSelectedValue={setValue}
      selectedValue={value}
      variant={"filter"}
    />
  );
});
