import {
  AssetType,
  AssetWithIpAdapterType,
} from "domains/assets/constants/assetTypes";
import { assertNever } from "domains/commons/assertNever";
import { PostModelsInferencesByModelIdApiArg } from "infra/api/generated/api";

export const assetTypeToAssetWithIpAdapterType = ({
  assetType,
  ipAdapterType,
}: {
  assetType: AssetType;
  ipAdapterType: PostModelsInferencesByModelIdApiArg["body"]["parameters"]["ipAdapterType"];
}): AssetType | AssetWithIpAdapterType => {
  switch (assetType) {
    case "inference-txt2img-ip-adapter":
    case "inference-img2img-ip-adapter":
    case "inference-controlnet-ip-adapter":
      // eslint-disable-next-line sonarjs/no-nested-switch
      switch (ipAdapterType) {
        case "character":
        case "style":
          return `${assetType}-${ipAdapterType}`;
        case undefined:
          return assetType;
        default:
          return assertNever(ipAdapterType);
      }
    default:
      return assetType;
  }
};
