import { useCallback, useRef, useState } from "react";
import ImageCropper from "domains/image/components/ImageCropper";
import { useAssetCrop } from "domains/image/hooks/useAssetCrop";
import Button from "domains/ui/components/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "domains/ui/components/Modal";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";
import { ReactCropperElement } from "react-cropper";

import { HStack, VStack } from "@chakra-ui/react";

export interface ModalCropProps {
  imageToCrop:
    | {
        assetId: string | undefined;
        src: string;
      }
    | undefined;
  onCancel: () => void;
  onSubmit: (asset: GetAssetsByAssetIdApiResponse["asset"]) => Promise<void>;
}

export default function ModalCrop({
  imageToCrop,
  onCancel,
  onSubmit,
}: ModalCropProps) {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { cropImage } = useAssetCrop();

  const handleSubmitClick = useCallback(async () => {
    if (!imageToCrop) return;

    try {
      setIsSubmitting(true);

      const imageElement: any = cropperRef?.current;
      const cropper: any = imageElement?.cropper;

      const croppedAsset = await cropImage({
        asset: imageToCrop,
        cropData: cropper.getData(),
      });
      if (!croppedAsset) {
        return;
      }

      await onSubmit(croppedAsset);
    } catch (_) {
    } finally {
      setIsSubmitting(false);
    }
  }, [imageToCrop, onSubmit, cropImage]);

  return (
    <Modal isOpen={!!imageToCrop} onClose={onCancel} variant="action">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Crop image</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <ImageCropper
              isFreeAspectRatioAllowed
              imageData={imageToCrop?.src ?? ""}
              cropperRef={cropperRef}
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button size="sm" variant="secondary" onClick={onCancel}>
              Cancel
            </Button>

            <Button
              size="sm"
              variant="primary"
              onClick={handleSubmitClick}
              isLoading={isSubmitting}
            >
              Accept
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
