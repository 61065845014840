import { useMemo } from "react";
import { MODEL_TYPE_TO_TITLE } from "domains/models/constants/text";
import { ModelBase } from "domains/search/components/SearchModal/FilterModelBases";
import { ModelType } from "domains/search/components/SearchModal/FilterModelType";
import { GetModelsByModelIdApiResponse } from "infra/api/generated/api";

interface UseFrontFiltersToBackProps {
  type: ModelType | undefined;
  bases: ModelBase[];
}

export default function useFrontFiltersToBack({
  type: originalType,
  bases: originalBases,
}: UseFrontFiltersToBackProps) {
  return useMemo(() => {
    const types: GetModelsByModelIdApiResponse["model"]["type"][] = [];
    const sources: GetModelsByModelIdApiResponse["model"]["source"][] = [];

    if (originalType === "uploaded") {
      sources.push("other", "huggingface", "civitai");
    }
    if (originalType === "composed" || originalType === "trained") {
      sources.push("scenario");
    }

    const modelTypes = Object.keys(
      MODEL_TYPE_TO_TITLE
    ) as GetModelsByModelIdApiResponse["model"]["type"][];
    types.push(
      ...modelTypes.filter((type) => {
        if (
          originalBases.length &&
          !originalBases.some((base) => type.includes(base))
        ) {
          return false;
        }
        if (
          originalType &&
          originalType === "trained" &&
          type.includes("composition")
        ) {
          return false;
        }
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (
          originalType &&
          originalType === "composed" &&
          !type.includes("composition")
        ) {
          return false;
        }
        return true;
      })
    );

    return { types, sources };
  }, [originalType, originalBases]);
}
