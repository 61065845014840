import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { useHorizontalScroll } from "domains/commons/hooks/useHorizontalScroll";
import { useScrollbarSize } from "domains/commons/hooks/useScrollbarSize";
import { useOptimizedAssetUrl } from "domains/file-manager/hooks/useOptimizedAssetUrl";
import { FileImageType } from "domains/file-manager/interfaces";
import { getShouldHaveNsfwBlur } from "domains/file-manager/utils/getShouldHaveNsfwBlur";
import useRouter from "domains/navigation/hooks/useRouter";
import { extraTheme } from "domains/theme";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { NsfwIndicator } from "domains/ui/components/NsfwIndicator";
import { NsfwType } from "domains/user/constants/Nsfw";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";
import _ from "lodash";
import { VariableSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import {
  Box,
  Flex,
  HStack,
  Image,
  Skeleton,
  Spinner,
  VStack,
} from "@chakra-ui/react";

export const IMAGE_HEIGHT = 80;
export const GAP = 6;

export interface AssetZoomImageListProps {
  currentAsset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
  assets: FileImageType[];
  hasMore: boolean;
  loadMore: () => void | undefined;
  isOpen: boolean;
  onSetIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AssetZoomImageList({
  currentAsset,
  assets,
  hasMore,
  loadMore,
  isOpen,
  onSetIsOpen,
}: AssetZoomImageListProps) {
  const { nsfwFilteredTypes, nsfwRevealedAssetIds, revealAsset } =
    useUserContext();
  const router = useRouter();
  const [scrolledTo, setScrolledTo] = useState<string | undefined>();
  const [hasRendered, setHasRendered] = useState(false);
  const [elementWidth, setElementWidth] = useState<number | undefined>();
  const widthHelperRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<VariableSizeList>();
  const virtualLoaderRef = useRef(null);
  const containerHeight = useImageListHeight();
  const horizontalScrollRef = useHorizontalScroll();

  const currentAssetId = currentAsset?.id;
  const currentAssetIndex = useMemo(
    () =>
      currentAssetId
        ? assets.findIndex(
            (item, index, arr) => arr[index]?.id === currentAssetId
          )
        : -1,
    [assets, currentAssetId]
  );
  const assetsLength = useMemo(
    () => assets.length + (hasMore ? 1 : 0),
    [assets.length, hasMore]
  );

  // ----------------------------------

  const getItemSize = useCallback(
    (index: number) => {
      const height = assets[index]?.height ?? 512;
      const width = assets[index]?.width ?? 512;
      const ratio = width / height;
      return (
        IMAGE_HEIGHT * ratio + GAP + (index === assetsLength - 1 ? GAP : 0)
      );
    },
    [assets, assetsLength]
  );

  // ----------------------------------

  const handleImageClick = useCallback(
    (id: string) => {
      if (currentAssetId === id) return;
      router.query.openAssetId = id;
      void router.push({
        query: router.query,
      });
    },
    [currentAssetId, router]
  );

  const handleOpenToggle = useCallback(() => {
    onSetIsOpen((isOpen) => !isOpen);
  }, [onSetIsOpen]);

  // ----------------------------------

  useEffect(() => {
    setHasRendered(true);
  }, [setHasRendered]);

  useEffect(() => {
    if (!widthHelperRef?.current) return;
    setElementWidth(widthHelperRef.current?.clientWidth);
    const resizeObserver = new ResizeObserver((entries) => {
      setElementWidth(entries[0].contentRect.width);
    });
    resizeObserver.observe(widthHelperRef.current);
    return () => resizeObserver.disconnect();
  }, [setElementWidth]);

  useEffect(() => {
    if (
      !hasRendered ||
      currentAssetIndex === -1 ||
      currentAssetId === scrolledTo
    ) {
      return;
    }

    listRef?.current?.scrollToItem(currentAssetIndex, "center");
    setScrolledTo(currentAssetId);
  }, [
    setScrolledTo,
    hasRendered,
    currentAssetIndex,
    currentAssetId,
    scrolledTo,
  ]);

  // Load assets until we found the current image
  const lastLoadRef = useRef<number>();
  useEffect(() => {
    if (
      !(
        hasRendered &&
        currentAssetIndex === -1 &&
        currentAsset?.metadata.type.includes("inference") &&
        hasMore
      )
    ) {
      return;
    }

    const minTimeout = lastLoadRef.current
      ? _.max([lastLoadRef.current + 200 - new Date().getTime(), 1])
      : 1;
    setTimeout(() => {
      lastLoadRef.current = new Date().getTime();
      loadMore();
    }, minTimeout);
  }, [
    loadMore,
    hasRendered,
    hasMore,
    currentAsset?.metadata.type,
    currentAssetIndex,
  ]);

  // ----------------------------------

  const virtualListData = useMemo(
    () => ({
      items: assets,
      currentAssetId,
      hasMore,
      nsfwRevealedAssetIds,
      nsfwFilteredTypes,
      onImageClick: handleImageClick,
      revealAsset,
    }),
    [
      assets,
      currentAssetId,
      hasMore,
      nsfwRevealedAssetIds,
      nsfwFilteredTypes,
      handleImageClick,
      revealAsset,
    ]
  );

  return (
    <Box pos="relative">
      {!isOpen && (
        <Box pos="absolute" right={4} bottom={4}>
          <Button
            tooltip="Gallery"
            tooltipProps={{
              placement: "left",
            }}
            variant="secondaryAlt"
            size="sm"
            p={0}
            onClick={handleOpenToggle}
          >
            <Icon id="Ui/Gallery" h="11px" />
          </Button>
        </Box>
      )}

      <HStack
        align="stretch"
        overflow="hidden"
        w="100%"
        h={!isOpen ? 0 : `${containerHeight}px`}
        borderTopWidth={!isOpen ? 0 : 1}
        visibility={!isOpen ? "hidden" : undefined}
        bgColor="secondary.900"
        spacing={0}
      >
        <Box pos="relative" flex={1}>
          <Box ref={widthHelperRef} w="100%" h={0} />

          <Box
            pos="absolute"
            top={0}
            left={0}
            overflow="hidden"
            w="100%"
            h="100%"
          >
            <InfiniteLoader
              ref={virtualLoaderRef}
              itemCount={hasMore ? assetsLength + 1 : assetsLength}
              loadMoreItems={loadMore}
              isItemLoaded={(index) => index < assetsLength}
            >
              {({ onItemsRendered, ref }) => {
                return (
                  <VariableSizeList
                    outerRef={horizontalScrollRef}
                    width={elementWidth || 9_999}
                    height={`${containerHeight - 1}px`}
                    itemData={virtualListData}
                    itemCount={assetsLength}
                    itemSize={getItemSize}
                    layout="horizontal"
                    onItemsRendered={onItemsRendered}
                    itemKey={(index, data) =>
                      data.items[index]?.id ?? `unknown-${index}`
                    }
                    ref={(innerRef) => {
                      ref(innerRef);
                      if (innerRef) listRef.current = innerRef;
                    }}
                    style={{
                      transition: extraTheme.transitions.fast,
                    }}
                  >
                    {ItemRenderer}
                  </VariableSizeList>
                );
              }}
            </InfiniteLoader>
          </Box>
        </Box>

        <VStack
          justify="center"
          w="36px"
          borderLeftWidth={1}
          borderLeftColor="border.500"
          _hover={{ opacity: 0.8 }}
          _active={{ opacity: 0.9 }}
          cursor="pointer"
          onClick={handleOpenToggle}
        >
          <Icon id="Ui/ChevronDoubleDown" h="14px" />
        </VStack>
      </HStack>
    </Box>
  );
}

// ------------------------------------

function ItemRenderer({
  index,
  style,
  data: {
    items,
    currentAssetId,
    hasMore,
    nsfwRevealedAssetIds,
    nsfwFilteredTypes,
    onImageClick,
    revealAsset,
  },
}: {
  index: number;
  style: any;
  data: {
    items: FileImageType[];
    currentAssetId: string | undefined;
    hasMore: boolean;
    nsfwRevealedAssetIds: string[];
    nsfwFilteredTypes: NsfwType[];
    onImageClick: (id: string) => void;
    revealAsset: (assetId: string) => void;
  };
}) {
  const asset: FileImageType | undefined =
    index > items.length - 1 ? undefined : items[index];
  const isSelected = asset && currentAssetId === asset.id;
  const isLast = index === items.length + (hasMore ? 1 : 0) - 1;
  const isLoader = index === items.length;

  const width = getAssetWidth(asset);
  const isRevealed = asset && nsfwRevealedAssetIds.includes(asset.id);
  const hasNsfwBlur =
    getShouldHaveNsfwBlur({
      nsfwFilteredTypes,
      nsfw: asset?.meta.nsfw,
    }) && !isRevealed;

  return (
    <AssetZoomImageListItem
      asset={asset}
      isBlurred={hasNsfwBlur}
      width={width}
      nsfwIndicatorDisplayed={hasNsfwBlur}
      selected={isSelected}
      last={isLast}
      loader={isLoader}
      style={style}
      onClick={onImageClick}
      onRevealClick={revealAsset}
    />
  );
}

// ------------------------------------

interface AssetZoomImageListItemProps {
  asset: FileImageType | undefined;
  isBlurred: boolean;
  width: number;
  nsfwIndicatorDisplayed: boolean;
  selected?: boolean;
  last?: boolean;
  loader?: boolean;
  style: any;
  onClick: (id: string) => void;
  onRevealClick: (id: string) => void;
}

const AssetZoomImageListItem = React.memo(function AssetZoomImageListItem({
  asset,
  isBlurred,
  width,
  nsfwIndicatorDisplayed: isNsfwIndicatorDisplayed,
  selected: isSelected,
  last: isLast,
  loader: isLoader,
  style,
  onClick,
  onRevealClick,
}: AssetZoomImageListItemProps) {
  const handleClick = useCallback(() => {
    if (asset) onClick(asset.id);
  }, [onClick, asset]);

  const handleRevealClick = useCallback(() => {
    if (asset) onRevealClick(asset.id);
  }, [onRevealClick, asset]);

  const { url: optimizedUrl } = useOptimizedAssetUrl({
    file: asset,
    cardWidth: width,
  });

  return (
    <Flex
      align="center"
      justify="center"
      pr={isLast ? GAP / 4 : 0}
      pl={GAP / 4}
      style={style}
    >
      {isLoader && <Spinner />}

      {!isLoader && asset && (
        <Box
          pos="relative"
          overflow="hidden"
          _after={
            isSelected
              ? {
                  content: '""',
                  display: "block",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderColor: "primary.500",
                  position: "absolute",
                }
              : {}
          }
        >
          <Image
            w={`${width}px`}
            h={`${IMAGE_HEIGHT}px`}
            cursor="pointer"
            objectFit="cover"
            alt="asset image"
            fallback={<Skeleton w={`${width}px`} h={`${IMAGE_HEIGHT}px`} />}
            filter={isBlurred ? "blur(10px)" : undefined}
            onClick={handleClick}
            src={optimizedUrl}
          />

          {isNsfwIndicatorDisplayed && (
            <NsfwIndicator onClick={handleRevealClick} />
          )}
        </Box>
      )}

      {!isLoader && !asset && (
        <Flex
          align="center"
          justify="center"
          w={`${width}px`}
          h={`${IMAGE_HEIGHT}px`}
          bg="black"
        >
          <Spinner />
        </Flex>
      )}
    </Flex>
  );
});

// ------------------------------------

function getAssetWidth(asset: FileImageType | undefined) {
  const height = asset?.height ?? 512;
  const width = asset?.width ?? 512;
  const ratio = width / height;
  return IMAGE_HEIGHT * ratio;
}

export function useImageListHeight() {
  const scrollbarSize = useScrollbarSize();
  return IMAGE_HEIGHT + GAP * 2 + scrollbarSize.height + 1;
}
