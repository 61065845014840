import { useCallback, useEffect, useState } from "react";
import Icon from "domains/ui/components/Icon";

import { Box, BoxProps, IconButton } from "@chakra-ui/react";

type HEX = `#${string}`;

export default function HorizontalScrollable({
  children,
  childrenRef,
  getShouldAvoidScroll,
  gradientColor,
}: {
  children: React.ReactNode;
  childrenRef: React.RefObject<HTMLDivElement>;
  getShouldAvoidScroll?: (e: WheelEvent) => boolean;
  gradientColor?: HEX;
}) {
  const [scrollRight, setScrollRight] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [childrenClientData, setChildrenClientData] = useState<{
    scrollLeft: number;
    clientWidth: number;
    offsetLeft: number;
  }>({
    scrollLeft: 0,
    clientWidth: 0,
    offsetLeft: 0,
  });

  const calculateCanScroll = useCallback(() => {
    if (!childrenRef.current) return;
    setScrollRight(
      Math.max(
        0,
        Math.round(
          childrenRef.current.scrollWidth -
            childrenRef.current.clientWidth -
            childrenRef.current.scrollLeft
        ) - 1
      )
    );
    setScrollLeft(childrenRef.current.scrollLeft);
    setChildrenClientData({
      scrollLeft: childrenRef.current?.scrollLeft ?? 0,
      clientWidth: childrenRef.current?.clientWidth ?? 0,
      offsetLeft: childrenRef.current?.offsetLeft ?? 0,
    });
  }, [childrenRef]);

  // ----------------------------------

  const handleScrollLeft = useCallback(() => {
    if (!childrenRef.current) return;
    const scrollTarget =
      childrenClientData.scrollLeft -
      Math.round(childrenRef.current?.clientWidth * 0.8) +
      50;
    childrenRef.current?.scrollTo({
      left: scrollTarget < 100 ? 0 : scrollTarget,
      behavior: "smooth",
    });
  }, [childrenRef, childrenClientData]);

  const handleScrollRight = useCallback(() => {
    if (!childrenRef.current) return;
    const scrollTarget =
      childrenClientData.scrollLeft +
      Math.round(childrenRef.current?.clientWidth * 0.8) -
      50;
    const maxScrollLeft =
      childrenRef.current.scrollWidth - childrenRef.current.clientWidth;
    childrenRef.current?.scrollTo({
      left: scrollTarget > maxScrollLeft - 100 ? maxScrollLeft : scrollTarget,
      behavior: "smooth",
    });
  }, [childrenRef, childrenClientData]);

  // ----------------------------------

  useEffect(() => {
    if (!childrenRef.current) return;
    const tabList = childrenRef.current;
    tabList.addEventListener("scroll", calculateCanScroll);
    return () => {
      tabList.removeEventListener("scroll", calculateCanScroll);
    };
  }, [childrenRef, calculateCanScroll]);

  useEffect(() => {
    if (!childrenRef.current) return;
    const listener = (e: WheelEvent) => {
      if (e.deltaX) {
        return;
      }
      if (!childrenRef.current) return;
      if (getShouldAvoidScroll && getShouldAvoidScroll(e)) return;
      e.preventDefault();
      e.stopPropagation();
      childrenRef.current.scrollTo({
        left: childrenRef.current.scrollLeft + e.deltaY,
        behavior: "smooth",
      });
    };
    const tabList = childrenRef.current;
    tabList.addEventListener("wheel", listener);
    return () => {
      tabList.removeEventListener("wheel", listener);
    };
  }, [childrenRef, getShouldAvoidScroll]);

  useEffect(() => {
    if (!childrenRef.current) return;
    childrenRef.current.style.paddingRight = "1px";
    const observer = new ResizeObserver(() => {
      calculateCanScroll();
    });
    const current = childrenRef.current;
    observer.observe(current);
    return () => current && observer.unobserve(current);
  }, [childrenRef, calculateCanScroll]);

  // ----------------------------------

  return (
    <>
      {children}

      {scrollLeft > 0 && (
        <>
          <BackgroundGradient
            left={childrenClientData.offsetLeft}
            color={gradientColor}
          />

          <IconButton
            pos="absolute"
            zIndex={1}
            top="50%"
            left={childrenClientData.offsetLeft}
            borderRadius="full"
            transform="translateY(-50%)"
            aria-label="Scroll Left"
            icon={<Icon id="Ui/ChevronLeft" />}
            onClick={handleScrollLeft}
            variant="blurred"
          />
        </>
      )}

      {scrollRight > 0 && (
        <>
          <BackgroundGradient
            left={
              childrenClientData.offsetLeft +
              childrenClientData.clientWidth -
              80
            }
            reverse
            color={gradientColor}
          />
          <IconButton
            pos="absolute"
            zIndex={1}
            top="50%"
            left={
              childrenClientData.offsetLeft +
              childrenClientData.clientWidth -
              36
            }
            borderRadius="full"
            transform="translateY(-50%)"
            aria-label="Scroll Right"
            icon={<Icon id="Ui/ChevronRight" />}
            onClick={handleScrollRight}
            variant="blurred"
          />
        </>
      )}
    </>
  );
}

function BackgroundGradient({
  left,
  reverse = false,
  color = "#040404",
}: {
  left?: BoxProps["left"];
  reverse?: boolean;
  color?: HEX;
}) {
  return (
    <Box
      pos="absolute"
      top={0}
      bottom={0}
      left={left}
      w="80px"
      h="100%"
      bg={`linear-gradient(${
        reverse ? "270deg" : "90deg"
      }, ${color} 30.46%, ${color}00 96.72%);`}
      pointerEvents="none"
    />
  );
}
