import { disabledOnWheel } from "domains/forms/disabledOnWheel";
import SidebarSection from "domains/inference/components/InferenceGenerator/Sidebar/Section";
import { ReframeForm } from "domains/reframe/interfaces/Reframe";
import ScenarioInput from "domains/ui/components/ScenarioInput";
import Slider from "domains/ui/components/Slider";
import WithLabelAndTooltip from "domains/ui/components/WithLabelAndTooltip";

interface SettingsSectionProps {
  form: ReframeForm;
  onFormChange: <T extends keyof ReframeForm>(
    key: T,
    value: ReframeForm[T]
  ) => void;
  isAssetZoom: boolean;
}

const SettingsSection = ({
  form,
  onFormChange,
  isAssetZoom,
}: SettingsSectionProps) => {
  if (isAssetZoom) return null;

  return (
    <SidebarSection
      compact={isAssetZoom}
      headerProps={{
        id: "settings",
        title: "Settings",
      }}
    >
      <WithLabelAndTooltip
        label={`Sampling steps`}
        tooltip="Sampling steps balance image detail and processing time in AI models."
      >
        <Slider
          withNumberInput
          min={5}
          max={50}
          step={1}
          value={form.numInferenceSteps}
          onChange={(value) => onFormChange("numInferenceSteps", value)}
        />
      </WithLabelAndTooltip>

      <WithLabelAndTooltip
        label="Seed"
        tooltip="Use a custom seed for targeted results (single-image inference)"
      >
        <ScenarioInput
          value={form.seed?.toString() ?? ""}
          setValue={(value) => onFormChange("seed", parseInt(value))}
          placeholder="Random"
          type="number"
          onWheel={disabledOnWheel}
          dataTestId="inference-builder-seed"
        />
      </WithLabelAndTooltip>
    </SidebarSection>
  );
};

export default SettingsSection;
