import { useCallback, useMemo, useState } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import Button from "domains/ui/components/Button";
import { ColorPalette } from "domains/ui/components/ColorPicker/defaultColorPalettes";
import Icon from "domains/ui/components/Icon";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { useHandleApiError } from "infra/api/error";

import { Box, Flex, HStack, Image, Text } from "@chakra-ui/react";

import ColorPaletteThumbnail from "./ElementColorPaletteThumbnail";
import ModalColorPaletteSelector from "./ModalColorPaletteSelector";

export interface SelectColorPaletteProps {
  imgSrc: string | undefined;
  selectedColorPalette: ColorPalette | undefined;
  setSelectedColorPalette: (value: ColorPalette | undefined) => void;
  shouldUseNewColorPalette: boolean;
  setShouldUseNewColorPalette: (value: boolean) => void;
  newColorPalette: ColorPalette;
  setNewColorPalette: (value: ColorPalette) => void;
}

export default function SelectColorPalette({
  imgSrc,
  selectedColorPalette,
  setSelectedColorPalette,
  shouldUseNewColorPalette,
  setShouldUseNewColorPalette,
  newColorPalette,
  setNewColorPalette,
}: SelectColorPaletteProps) {
  const { successToast } = useScenarioToast();
  const [isOpen, setIsOpen] = useState(false);
  const handleApiError = useHandleApiError();
  const { updateUserSettings, userSettings } = useUserContext();

  const savedColorPalettes = useMemo(() => {
    const savedColors: string[][] = userSettings?.["color-palettes"] || [];
    return savedColors.map((colors) => {
      return {
        id: Math.random().toString(),
        name: "Custom palette",
        colors,
      };
    });
  }, [userSettings]);

  const saveColorPalette = useCallback(async () => {
    try {
      const newColorPalettes: string[][] = [
        ...savedColorPalettes.map(
          (savedColorPalette) => savedColorPalette.colors
        ),
        newColorPalette.colors,
      ];
      await updateUserSettings({
        "color-palettes": newColorPalettes,
      });
      successToast({
        title: "Your color palette was saved",
      });
      setSelectedColorPalette(newColorPalette);
      setShouldUseNewColorPalette(false);
      setNewColorPalette({
        ...newColorPalette,
        colors: [],
      });
    } catch (error) {
      handleApiError(error, "There was an error saving your color palette");
    }
  }, [
    updateUserSettings,
    savedColorPalettes,
    newColorPalette,
    successToast,
    setSelectedColorPalette,
    setShouldUseNewColorPalette,
    setNewColorPalette,
    handleApiError,
  ]);

  return (
    <Box w={"100%"}>
      <Button
        variant={"secondary"}
        size={"lg"}
        onClick={() => setIsOpen(true)}
        px={2}
        w={"100%"}
      >
        <Flex align={"center"} justify={"start"} gap={3} w={"100%"}>
          {selectedColorPalette || shouldUseNewColorPalette ? (
            <HStack w={"30px"} h={"30px"} spacing={0}>
              <ColorPaletteThumbnail
                colorPalette={
                  shouldUseNewColorPalette
                    ? newColorPalette
                    : selectedColorPalette!
                }
              />
            </HStack>
          ) : (
            <Box w={"30px"} h={"30px"} bgColor="backgroundQuaternary.500">
              {imgSrc && (
                <Image
                  w={"100%"}
                  h={"100%"}
                  objectFit={"contain"}
                  alt={"image"}
                  src={imgSrc}
                />
              )}
            </Box>
          )}
          <Text
            flex={1}
            overflow={"hidden"}
            textAlign={"start"}
            textOverflow={"ellipsis"}
            size={"body.bold.md"}
          >
            {selectedColorPalette || shouldUseNewColorPalette
              ? (shouldUseNewColorPalette
                  ? newColorPalette
                  : selectedColorPalette!
                ).name
              : "Image palette"}
          </Text>
          {shouldUseNewColorPalette && newColorPalette.colors.length > 0 && (
            <Button
              variant={"ghost"}
              colorScheme={"secondary"}
              onClick={(e) => {
                e.stopPropagation();
                void saveColorPalette();
              }}
              px="5px"
              leftIcon={<Icon color="white" id="Ui/Save" />}
            />
          )}
        </Flex>
      </Button>
      <ModalColorPaletteSelector
        imgSrc={imgSrc}
        setSelectedColorPalette={setSelectedColorPalette}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setShouldUseNewColorPalette={setShouldUseNewColorPalette}
        savedColorPalettes={savedColorPalettes}
      />
    </Box>
  );
}
