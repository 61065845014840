import React, { PropsWithChildren, useCallback, useState } from "react";
import { NavBarItemProvider } from "domains/navigation/contexts/NavBarItemProvider";
import { useNavBarContext } from "domains/navigation/contexts/NavBarProvider";
import { extraTheme } from "domains/theme";
import Button, { ButtonProps, IconButton } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { useHover } from "domains/ui/hooks/useHover";
import _ from "lodash";

import {
  Box,
  ButtonGroup,
  Center,
  Fade,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";

interface NavBarItemProps extends PropsWithChildren {
  id: string;
  text: string;
  icon?: React.ReactNode;
  iconActive?: React.ReactNode;
  isActive?: boolean;
  isCompact?: boolean;
  link?: ButtonProps["internalLink"];
  onClick?: () => void;
  dataTestId?: string;
  isDisabled?: boolean;
}

export default function NavBarItem({
  id,
  text,
  icon,
  iconActive,
  isActive,
  isCompact,
  link,
  onClick,
  children,
  dataTestId,
  isDisabled,
}: NavBarItemProps) {
  const { collapsedItems, setCollapsedItems, isMobile, closeDrawer } =
    useNavBarContext();
  const isCollapsed = collapsedItems.includes(id);
  const isClickable = !!(onClick || link);
  const isHoverable = isClickable || (isCompact && !!children);
  const [itemHoverRef, isHoverItem] = useHover<HTMLDivElement>();
  const [menuHoverRef, isHoverMenu] = useHover<HTMLDivElement>();
  const [isSubItemClicked, setIsSubItemClicked] = useState<boolean>(false);

  const isMenuOpen =
    (isHoverItem || isHoverMenu) &&
    isCompact &&
    !!children &&
    !isSubItemClicked;
  const isCollapsable = !isMobile && !isCompact && !!children;

  const handleCollapseClick = useCallback(() => {
    if (!isCompact && !collapsedItems.includes(id)) {
      setCollapsedItems([...collapsedItems, id]);
    } else if (!isCompact) {
      setCollapsedItems(_.without(collapsedItems, id));
    }
  }, [setCollapsedItems, isCompact, collapsedItems, id]);

  const handleSubItemClick = useCallback(() => {
    if (isMobile) closeDrawer();
    setIsSubItemClicked(true);
    setTimeout(() => setIsSubItemClicked(false), 300);
  }, [setIsSubItemClicked, closeDrawer, isMobile]);

  return (
    <Menu gutter={0} isLazy isOpen={isMenuOpen} placement="right-start">
      <Box pos="relative">
        <Tooltip
          hasArrow
          isDisabled={!!children || !isCompact}
          label={text}
          placement="right"
        >
          <ButtonGroup
            ref={itemHoverRef}
            w="full"
            isAttached
            isDisabled={isDisabled}
            size="md"
          >
            <Button
              data-group
              flex={1}
              internalLink={isDisabled ? undefined : link}
              colorScheme="white"
              variant="ghost"
              cursor={!isClickable ? "default" : undefined}
              _hover={
                isHoverable
                  ? {
                      bg: "whiteAlpha.200",
                    }
                  : {}
              }
              aria-label={text}
              data-testid={dataTestId}
              textTransform="none"
              justifyContent="flex-start"
              transition={extraTheme.transitions.fast}
              fontWeight="normal"
              borderRadius="lg"
              px={2}
              gap={3}
              onClick={onClick}
              leftIcon={
                icon ? (
                  <Center color="textPrimary">
                    {isActive && iconActive ? iconActive : icon}
                  </Center>
                ) : undefined
              }
            >
              <Fade
                in={!isCompact || !icon}
                style={{ width: "100%", overflow: "hidden" }}
                unmountOnExit
              >
                <Text
                  align="left"
                  color={isActive ? "textPrimary" : "textSecondary"}
                  fontSize="sm"
                  _groupHover={isHoverable ? { color: "textPrimary" } : {}}
                  transition={extraTheme.transitions.fast}
                  isTruncated
                >
                  {text}
                </Text>
              </Fade>
            </Button>

            {isCollapsable && (
              <IconButton
                aria-label="Open sub navigation"
                colorScheme="white"
                onClick={handleCollapseClick}
                isDisabled={isDisabled}
                variant="ghost"
                _hover={{
                  bg: "whiteAlpha.200",
                }}
                px={2}
                minW={0}
                icon={
                  <Icon
                    color="textSecondary"
                    id={
                      collapsedItems.includes(id)
                        ? "Ui/ChevronRight"
                        : "Ui/ChevronDown"
                    }
                    w="18px"
                  />
                }
              />
            )}
          </ButtonGroup>
        </Tooltip>

        <MenuButton
          pos="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          pointerEvents="none"
        />
      </Box>

      <Portal>
        <Box
          ref={menuHoverRef}
          pointerEvents={isSubItemClicked ? "none" : undefined}
        >
          <MenuList
            minW="200px"
            maxW="400px"
            rootProps={{
              maxWidth: "100%",
              minWidth: "200px",
              px: 2,
              zIndex: 9_999,
            }}
          >
            <Text
              mr={2}
              mb={1}
              ml={2}
              color="textTertiary"
              textAlign="left"
              size="body.sm"
            >
              {text}
            </Text>

            <NavBarItemProvider isInMenu onSubItemClick={handleSubItemClick}>
              {children}
            </NavBarItemProvider>
          </MenuList>
        </Box>
      </Portal>

      {!!children && (
        <Fade
          in={!isCompact && !isCollapsed}
          style={{ width: "100%", overflow: "hidden" }}
          unmountOnExit
        >
          <VStack align="stretch" spacing={0.5}>
            <NavBarItemProvider onSubItemClick={handleSubItemClick}>
              {children}
            </NavBarItemProvider>
          </VStack>
        </Fade>
      )}
    </Menu>
  );
}
