import { InferenceBuilderErrors } from "domains/canvas/hooks/useInferenceBuilderErrors";
import AlertErrors from "domains/inference/components/InferenceGenerator/Sidebar/AlertErrors";
import SidebarSection from "domains/inference/components/InferenceGenerator/Sidebar/Section";
import { appShortcuts } from "domains/shortcuts/components/appShortcuts";
import { Shortcut } from "domains/shortcuts/components/Shorcut";
import ButtonWithCuIndicator from "domains/ui/components/ButtonWithCuIndicator";

interface GenerateButtonProps {
  isAssetZoom: boolean;
  onGenerate: () => void;
  isGenerating: boolean;
  errors: InferenceBuilderErrors;
  cuCost: number | undefined;
  isCuLoading: boolean;
  isDisabled: boolean;
}

export default function GenerateButton({
  isAssetZoom,
  onGenerate,
  isGenerating,
  errors,
  cuCost,
  isCuLoading,
  isDisabled,
}: GenerateButtonProps) {
  return (
    <SidebarSection compact={isAssetZoom}>
      <AlertErrors errors={errors} />
      <ButtonWithCuIndicator
        cuCost={cuCost}
        isCuLoading={isCuLoading}
        onClick={onGenerate}
        isLoading={isGenerating}
        isDisabled={isDisabled}
        w="100%"
        tooltip={
          <Shortcut {...appShortcuts.global.shortcuts.launchGeneration} />
        }
        tooltipProps={{
          placement: "right",
          hasArrow: true,
          isDisabled,
        }}
      >
        Expand
      </ButtonWithCuIndicator>
    </SidebarSection>
  );
}
