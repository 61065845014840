import { PropsWithChildren, useCallback } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import Icon from "domains/ui/components/Icon";

import { HStack, Text, Tooltip, VStack } from "@chakra-ui/react";

export interface AsideTitledDataProps extends PropsWithChildren {
  title: React.ReactNode;
  copyableValue?: string;
}

export default function AsideTitledData({
  title,
  copyableValue,
  children,
}: AsideTitledDataProps) {
  const { infoToast } = useScenarioToast();

  const handleCopyClick = useCallback(async () => {
    if (!copyableValue) return;
    await navigator.clipboard.writeText(String(copyableValue));
    infoToast({
      title: "Copied to clipboard",
    });
  }, [infoToast, copyableValue]);

  return (
    <Tooltip
      isDisabled={!copyableValue}
      label="Copy to clipboard"
      openDelay={500}
      placement="left-start"
    >
      <VStack
        align="stretch"
        spacing={2}
        {...(copyableValue
          ? {
              mx: -3,
              my: -2,
              px: 3,
              py: 2,
              borderRadius: "md",
              _hover: { bgColor: "background.500", cursor: "pointer" },
              onClick: handleCopyClick,
              "data-group": "true",
            }
          : {})}
      >
        <HStack>
          <Text size="body.bold.md">{title}</Text>
          {!!copyableValue && (
            <Icon
              h="12px"
              _groupHover={{ visibility: "visible" }}
              visibility="hidden"
              id="Ui/Copy"
            />
          )}
        </HStack>

        {children}
      </VStack>
    </Tooltip>
  );
}
