import React, { HTMLInputTypeAttribute, useCallback, useState } from "react";

import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Input,
  InputProps,
} from "@chakra-ui/react";

export interface ScenarioInputProps {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  leftComponent?: React.ReactNode;
  onEnter?: () => void;
  rightComponent?: React.ReactNode;
  isDisabled?: boolean;
  type?: HTMLInputTypeAttribute;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onWheel?: React.WheelEventHandler<HTMLDivElement>;
  bgColor?: BoxProps["bgColor"];
  borderColor?: BoxProps["borderColor"];
  dataTestId?: string;
  inputProps?: Partial<InputProps>;
  containerProps?: Partial<FlexProps>;
  isFocus?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
  cursor?: BoxProps["cursor"];
  maxLength?: number;
}

let timeout: NodeJS.Timeout;

const ScenarioInput = React.forwardRef<HTMLInputElement, ScenarioInputProps>(
  function ScenarioInput({ isFocus, onFocus, onBlur, ...props }, ref) {
    const [isFocused, setIsFocused] = useState(false);
    const [isInError, setIsInError] = useState(false);

    const isFocusing = isFocus ?? isFocused;

    const handleFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(
      (e) => {
        setIsFocused(true);
        onFocus?.(e);
      },
      [setIsFocused, onFocus]
    );

    const handleBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
      (e) => {
        setIsFocused(false);
        onBlur?.(e);
      },
      [setIsFocused, onBlur]
    );

    return (
      <Flex
        align="center"
        w="100%"
        h={9}
        px={3}
        opacity={props.isDisabled ? 0.4 : undefined}
        borderWidth={1}
        borderColor={
          isInError
            ? "danger.500"
            : isFocusing
            ? "primary.500"
            : props.borderColor ?? "border.500"
        }
        borderRadius={8}
        _hover={{
          borderColor:
            isFocusing || props.isDisabled ? undefined : "borderSecondary.500",
        }}
        cursor={props.isDisabled ? "default" : props.cursor}
        bgColor={props.bgColor ?? "whiteAlpha.50"}
        onClick={!props.isDisabled ? props.onClick : undefined}
        {...props.containerProps}
        onBlur={handleBlur}
      >
        {props.leftComponent && (
          <Box flex={0} pr={2.5}>
            {props.leftComponent}
          </Box>
        )}

        <Input
          ref={ref}
          flex={1}
          minW="50px"
          h="100%"
          p={0}
          color="textPrimary"
          fontSize={14}
          fontWeight={400}
          border="none"
          _focusVisible={{ outline: "none" }}
          _placeholder={{ color: "textSecondary" }}
          cursor={props.isDisabled ? "default !important" : props.cursor}
          bgColor="transparent"
          data-testid={props.dataTestId}
          disabled={props.isDisabled}
          onBlur={handleBlur}
          onChange={(event) => {
            const newValue = event.target.value;
            if (props.maxLength && newValue.length > props.maxLength) {
              setIsInError(true);
              if (timeout) clearTimeout(timeout);
              timeout = setTimeout(() => setIsInError(false), 200);
            }
            props.setValue(newValue.slice(0, props.maxLength));
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" && props.onEnter) {
              props.onEnter();
            }
          }}
          onWheel={props.onWheel}
          placeholder={props.placeholder}
          type={props.type || "text"}
          value={props.value}
          {...props.inputProps}
          autoFocus={props.autoFocus}
          onFocus={handleFocus}
        />
        {props.rightComponent && <Box pl={2.5}>{props.rightComponent}</Box>}
      </Flex>
    );
  }
);

export default ScenarioInput;
