import {
  Dispatch,
  MouseEvent,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useAssetJobFilterAuthor from "domains/assets/hooks/useAssetAuthor";
import useAssetFilterType, {
  UseAssetFilterTypeProps,
} from "domains/assets/hooks/useAssetFilterType";
import useAssetSort from "domains/assets/hooks/useAssetSort";
import { useFileCanvasHandler } from "domains/canvas/hooks/useFileCanvasHandler";
import Deffered from "domains/commons/components/Deffered";
import HorizontalScrollable from "domains/commons/components/HorizontalScrollable";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import FileManager from "domains/file-manager/components/FileManager";
import FileManagerImage, {
  FileManagerImageProps,
} from "domains/file-manager/components/FileManagerImage";
import HeaderFilterAssetType from "domains/file-manager/components/FileManagerImage/HeaderFilterAssetType";
import HeaderFilterAuthor from "domains/file-manager/components/FileManagerImage/HeaderFilterAuthor";
import HeaderFilterDate, {
  HeaderFilterDateProps,
} from "domains/file-manager/components/FileManagerImage/HeaderFilterDate";
import FmHeaderColumn from "domains/file-manager/components/FileManagerV2/FmHeaderColumn";
import FmHeaderSort from "domains/file-manager/components/FileManagerV2/FmHeaderSort";
import FmHeaderView from "domains/file-manager/components/FileManagerV2/FmHeaderView";
import { GridSortKey } from "domains/file-manager/constants/GridSort";
import {
  GridViewKey,
  gridViewKeys,
} from "domains/file-manager/constants/GridView";
import { useFileModelHandler } from "domains/file-manager/hooks/useFileModelHandler";
import { FileCanvasType, FileModelType } from "domains/file-manager/interfaces";
import { FmFile, FmFileImage } from "domains/file-manager/interfacesV2";
import useFrontFiltersToBack from "domains/models/hooks/useFrontFiltersToBack";
import SearchBar from "domains/search/components/SearchBar";
import FilterCollections from "domains/search/components/SearchModal/FilterCollections";
import FilterModelBases, {
  ModelBase,
} from "domains/search/components/SearchModal/FilterModelBases";
import FilterModels from "domains/search/components/SearchModal/FilterModels";
import FilterModelType, {
  ModelType,
} from "domains/search/components/SearchModal/FilterModelType";
import FilterNsfw from "domains/search/components/SearchModal/FilterNsfw";
import FilterRestricts, {
  ALLOWED_RESTRICT_VALUES,
  RestrictOption,
} from "domains/search/components/SearchModal/FilterRestricts";
import FilterTags from "domains/search/components/SearchModal/FilterTags";
import {
  useAssetsSearch,
  useCanvasesSearch,
  useModelsSearch,
} from "domains/search/hooks/useSearch";
import { useAutocompleteSearch } from "domains/search/hooks/useSearch";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button, { IconButton } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import ButtonSingleChoice from "domains/ui/components/Menu/ButtonSingleChoice";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "domains/ui/components/Modal";
import {
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "domains/ui/components/Tabs";
import _ from "lodash";
import { CardActions as ImageCardActions } from "pages/images";
import { CardActions as SkyboxCardActions } from "pages/skyboxes";
import { CardActions as TextureCardActions } from "pages/textures";

import {
  Box,
  Collapse,
  Divider,
  Flex,
  HStack,
  Image,
  Progress,
  Skeleton,
  SkeletonText,
  Spinner,
  Text,
  useBreakpoint,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

const SORT_OPTIONS: GridSortKey[] = [
  "relevance",
  "createdAtTSDesc",
  "createdAtTSAsc",
];
export type SortOption = (typeof SORT_OPTIONS)[number];

const SEARCH_MODAL_TABS = [
  "image",
  "model",
  "canvas",
  "texture",
  "skybox",
] as const;
export type SearchModalTab = (typeof SEARCH_MODAL_TABS)[number];

function getIsTabIdSearchModalTab(tabId: string): tabId is SearchModalTab {
  return SEARCH_MODAL_TABS.includes(tabId as SearchModalTab);
}

export interface SearchModalProps<T extends string[] = []> {
  isOpen: boolean;
  onClose: () => void;
  query: string;
  setQuery: (query: string) => void;
  assetId: string | undefined;
  setAssetId: (assetId: string | undefined) => void;
  aiBoost: boolean;
  setAiBoost: (aiBoost: boolean) => void;
  tabs?: (SearchModalTab | T[number])[];
  tabId: SearchModalTab | T[number];
  setTabId: (tabId: SearchModalTab | T[number]) => void;
  onSelect?: (asset: FmFileImage) => void;
  onSelectionChange?: (assets: FmFileImage[]) => void;
  selectionMax?: number;
  allowEmptySearch?: boolean;
  initialSort?: SortOption;
  forceModelIds?: string[];
  assetFilterTypeProps?: {
    image?: UseAssetFilterTypeProps;
    skybox?: UseAssetFilterTypeProps;
    texture?: UseAssetFilterTypeProps;
  };
  SelectionActionsComponent?: FileManagerImageProps["SelectionActionsComponent"];
  additionalTabs?: {
    id: T[number];
    title: string;
    children: (props: {
      scrollRef: RefObject<HTMLDivElement>;
      query: string;
    }) => React.ReactNode;
  }[];
}

export default function SearchModal<T extends string[] = []>({
  isOpen,
  onClose,
  query,
  setQuery,
  assetId,
  setAssetId,
  aiBoost,
  setAiBoost,
  tabs = ["image", "model", "skybox", "texture", "canvas"],
  tabId,
  setTabId,
  onSelect,
  onSelectionChange,
  selectionMax,
  allowEmptySearch = false,
  initialSort,
  forceModelIds,
  assetFilterTypeProps,
  SelectionActionsComponent,
  additionalTabs,
}: SearchModalProps<T>) {
  const isMac = navigator.userAgent.indexOf("Mac") !== -1;
  const { selectedProject } = useTeamContext();
  const breakpoint = useBreakpoint({ ssr: true });
  const {
    isOpen: isFilterOpen,
    onToggle: onToggleFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const lastIsOpenRef = useRef<boolean>(isOpen);
  const modalSearchBarRef = useRef<HTMLInputElement>(null);
  const tabListRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const filtersRef = useRef<HTMLDivElement>(null);
  const [recentOptions, setRecentOptions] = usePersistedState<
    {
      assetId: string;
      projectId: string; // we need the team id because if it doesn't match the selected team, we can't do the search by assetId
      query: string;
    }[]
  >(PersistedStateKey.RECENT_SEARCHES, { defaultValue: [] });
  const focusedRecentOptionRef = useRef<number | undefined>(undefined);
  const [privacy, setPrivacy] = useState<"public" | "private">("private");

  const initialColumnCount =
    {
      base: 3,
      xs: 3,
      sm: 4,
      md: 5,
      lg: 6,
    }[breakpoint] ?? 6;
  const [columnsCount, setColumnsCount] = useState<number>(initialColumnCount);
  const [view, setView] = useState<GridViewKey>("fill");

  const initialSkyboxColumnCount =
    {
      base: 1,
      xs: 1,
      sm: 2,
      md: 2,
      lg: 3,
    }[breakpoint] ?? 3;
  const [skyboxColumnsCount, setSkyboxColumnsCount] = useState<number>(
    initialSkyboxColumnCount
  );

  const [dateRange, setDateRange] = useState<
    HeaderFilterDateProps["value"] | undefined
  >();
  const searchDateArgs = useMemo(
    () => (dateRange?.start ? { dateRange } : {}),
    [dateRange]
  );
  const fmHeaderFilterDateProps = useMemo(
    () => ({ value: dateRange, onChange: setDateRange }),
    [dateRange, setDateRange]
  );
  const { searchSortArgs, fmHeaderSortProps, setSort } = useAssetSort(
    [...SORT_OPTIONS],
    initialSort
  );

  const {
    authorQueryArgs,
    fmHeaderFilterAuthorProps,
    reset: resetAuthor,
  } = useAssetJobFilterAuthor({ avoidPersistedState: true });

  const {
    allAssetsTypeArgs: imageTypesQueryArgs,
    fmHeaderFilterAssetProps: imageFmHeaderFilterAssetProps,
    reset: resetImageTypes,
  } = useAssetFilterType(
    assetFilterTypeProps?.image ?? {
      assetType: "image",
      showVectorization: true,
      avoidPersistedState: true,
    }
  );

  const {
    allAssetsTypeArgs: skyboxTypesQueryArgs,
    fmHeaderFilterAssetProps: skyboxFmHeaderFilterAssetProps,
    reset: resetSkyboxTypes,
  } = useAssetFilterType(
    assetFilterTypeProps?.skybox ?? {
      assetType: "skybox",
      avoidPersistedState: true,
    }
  );

  const {
    allAssetsTypeArgs: textureTypesQueryArgs,
    fmHeaderFilterAssetProps: textureFmHeaderFilterAssetProps,
    reset: resetTextureTypes,
  } = useAssetFilterType(
    assetFilterTypeProps?.texture ?? {
      assetType: "texture",
      avoidPersistedState: true,
    }
  );

  const [tags, setTags] = useState<string[]>([]);
  const [collections, setCollections] = useState<string[]>([]);
  const [modelIds, setModelIds] = useState<string[]>(forceModelIds ?? []);
  const [nsfw, setNsfw] = useState<string[]>([]);
  const [restrictBy, setRestrictBy] = useState<RestrictOption[]>([]);
  const [modelType, setModelType] = useState<ModelType | undefined>(undefined);
  const [modelBases, setModelBases] = useState<ModelBase[]>([]);
  const [conceptIds, setConceptIds] = useState<string[]>([]);

  const isInSearch = !!(query || assetId) || allowEmptySearch;
  const isRecentDisplayed = !isInSearch && !!recentOptions.length;

  const { propositions, isLoading: isLoadingAutocomplete } =
    useAutocompleteSearch({ query: isOpen ? query : "" });

  const debouncedQuery = useDebounce(query, 500);
  const isLoadingGlobal = debouncedQuery !== query && debouncedQuery === "";

  // ----------------------------------

  const {
    isImageSearchNeeded,
    isSkyboxSearchNeeded,
    isTextureSearchNeeded,
    isModelSearchNeeded,
    isCanvasSearchNeeded,
  } = useMemo(() => {
    return {
      isImageSearchNeeded: isOpen && tabs.includes("image"),
      isSkyboxSearchNeeded: isOpen && tabs.includes("skybox"),
      isTextureSearchNeeded: isOpen && tabs.includes("texture"),
      isModelSearchNeeded: isOpen && tabs.includes("model"),
      isCanvasSearchNeeded: isOpen && tabs.includes("canvas"),
    };
  }, [isOpen, tabs]);

  const {
    files: images,
    jobs: imageJobs,
    resultCount: imagesCount,
    isLoading: isLoadingImages,
    setDeletedIds: setDeletedImageIds,
    loadMore: loadMoreImages,
    hasMore: hasMoreImages,
    facets: imageFacets,
  } = useAssetsSearch({
    query: isImageSearchNeeded ? debouncedQuery : undefined,
    assetId: isImageSearchNeeded ? assetId : undefined,
    isHighResThumbnailRequired: tabId === "image",
    ...imageTypesQueryArgs,
    ...authorQueryArgs,
    ...searchDateArgs,
    ...searchSortArgs,
    tags,
    collectionIds: collections,
    nsfw,
    aiBoost,
    restrictBy,
    privacy,
    modelIds,
    isEmptyQueryAllowed: isImageSearchNeeded ? allowEmptySearch : false,
  });

  const {
    files: skyboxes,
    jobs: skyboxJobs,
    resultCount: skyboxesCount,
    isLoading: isLoadingSkyboxes,
    setDeletedIds: setDeletedSkyboxIds,
    loadMore: loadMoreSkyboxes,
    hasMore: hasMoreSkyboxes,
    facets: skyboxFacets,
  } = useAssetsSearch({
    query: isSkyboxSearchNeeded ? debouncedQuery : undefined,
    assetId: isSkyboxSearchNeeded ? assetId : undefined,
    isHighResThumbnailRequired: tabId === "skybox",
    ...skyboxTypesQueryArgs,
    ...authorQueryArgs,
    ...searchDateArgs,
    ...searchSortArgs,
    tags,
    collectionIds: collections,
    nsfw,
    aiBoost,
    restrictBy,
    privacy,
    isEmptyQueryAllowed: isSkyboxSearchNeeded ? allowEmptySearch : false,
  });

  const {
    files: textures,
    jobs: textureJobs,
    resultCount: texturesCount,
    isLoading: isLoadingTextures,
    setDeletedIds: setDeletedTextureIds,
    loadMore: loadMoreTextures,
    hasMore: hasMoreTextures,
    facets: textureFacets,
  } = useAssetsSearch({
    query: isTextureSearchNeeded ? debouncedQuery : undefined,
    assetId: isTextureSearchNeeded ? assetId : undefined,
    isHighResThumbnailRequired: tabId === "texture",
    ...textureTypesQueryArgs,
    ...authorQueryArgs,
    ...searchDateArgs,
    ...searchSortArgs,
    tags,
    collectionIds: collections,
    nsfw,
    aiBoost,
    restrictBy,
    privacy,
    modelIds,
    isEmptyQueryAllowed: isTextureSearchNeeded ? allowEmptySearch : false,
  });

  const { types: typesForSearch, sources: sourcesForSearch } =
    useFrontFiltersToBack({
      type: modelType,
      bases: modelBases,
    });
  const {
    files: models,
    resultCount: modelsCount,
    isLoading: isLoadingModels,
    setDeletedIds: setDeletedModelIds,
    loadMore: loadMoreModels,
    hasMore: hasMoreModels,
    facets: modelFacets,
  } = useModelsSearch({
    query: isModelSearchNeeded ? debouncedQuery : undefined,
    assetId: isModelSearchNeeded ? assetId : undefined,
    ...authorQueryArgs,
    ...searchDateArgs,
    ...searchSortArgs,
    tags,
    collectionIds: collections,
    aiBoost,
    restrictBy,
    types: typesForSearch,
    sources: sourcesForSearch,
    privacy,
    isEmptyQueryAllowed: isModelSearchNeeded ? allowEmptySearch : false,
  });

  const {
    files: canvas,
    resultCount: canvasCount,
    isLoading: isLoadingCanvas,
    setDeletedIds: setDeletedCanvasIds,
    loadMore: loadMoreCanvases,
    hasMore: hasMoreCanvases,
    facets: canvasFacets,
  } = useCanvasesSearch({
    query: isCanvasSearchNeeded ? debouncedQuery : undefined,
    assetId: isCanvasSearchNeeded ? assetId : undefined,
    ...authorQueryArgs,
    ...searchDateArgs,
    ...searchSortArgs,
    tags,
    collectionIds: collections,
    aiBoost,
    restrictBy,
    privacy,
    isEmptyQueryAllowed: isCanvasSearchNeeded ? allowEmptySearch : false,
  });

  const modelFileHandler = useFileModelHandler({
    emptyState: <EmptyState />,
    onOpen: onClose,
    onDelete: (files) => handleDelete(files, setDeletedModelIds),
    onUndoDelete: (files) => handleUndoDelete(files, setDeletedModelIds),
  });

  const canvasFileHandler = useFileCanvasHandler({
    emptyState: <EmptyState />,
    onOpen: onClose,
    onDelete: (files) => handleDelete(files, setDeletedCanvasIds),
    onUndoDelete: (files) => handleUndoDelete(files, setDeletedCanvasIds),
  });

  // ----------------------------------

  const setFocusedRecentOption = useCallback((index: number) => {
    focusedRecentOptionRef.current = index;
    (
      document.getElementById("recent-search-list")?.children[index] as
        | HTMLElement
        | undefined
    )?.focus();
  }, []);

  // ----------------------------------

  const handleRecentSearchClick = useCallback(
    (option: (typeof recentOptions)[number]) => {
      setQuery(option.query ?? "");
      setAssetId(option.assetId);
      modalSearchBarRef.current?.focus();
    },
    [setQuery, setAssetId]
  );

  const handleTabChange = useCallback(
    (idx: number) => {
      const tab = tabListRef.current?.children[idx];
      if (!tab) return;
      const newTabId = tab.getAttribute("data-id") ?? "";
      setTabId(newTabId as typeof tabId);
      tab.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
    [setTabId]
  );

  const handleRemoveRecentOptionClick = useCallback(
    (e: MouseEvent, option: (typeof recentOptions)[number]) => {
      e.preventDefault();
      e.stopPropagation();
      setRecentOptions((options) => _.without(options, option));
      focusedRecentOptionRef.current = undefined;
    },
    [setRecentOptions]
  );

  const handleActionClick = useCallback(
    (type: string) => {
      if (type === "searchSimilar" || type === "more") return;
      onClose();
    },
    [onClose]
  );

  const handleDelete = useCallback(
    (
      files: (FmFile<"image"> | FileModelType | FileCanvasType)[],
      setDeletedIds: Dispatch<SetStateAction<string[]>>
    ) => {
      setDeletedIds((prev) => [...prev, ...files.map((f) => f.id)]);
    },
    []
  );

  const handleUndoDelete = useCallback(
    (
      files: (FmFile<"image"> | FileModelType | FileCanvasType)[],
      setDeletedIds: Dispatch<SetStateAction<string[]>>
    ) => {
      setDeletedIds((prev) =>
        prev.filter((id) => !files.map((f) => f.id).includes(id))
      );
    },
    []
  );

  // ----------------------------------

  const resetFilters = useCallback(() => {
    setDateRange(undefined);
    resetImageTypes();
    resetSkyboxTypes();
    resetTextureTypes();
    resetAuthor();
    setTags([]);
    setCollections([]);
    setNsfw([]);
    setRestrictBy([]);
    setModelType(undefined);
    setModelBases([]);
    setConceptIds([]);
    setModelIds(forceModelIds ?? []);
  }, [
    resetAuthor,
    resetImageTypes,
    resetSkyboxTypes,
    resetTextureTypes,
    forceModelIds,
  ]);

  useEffect(() => {
    if (!isOpen && lastIsOpenRef.current) {
      onCloseFilter();
      setColumnsCount(initialColumnCount);
      setSkyboxColumnsCount(initialSkyboxColumnCount);
      setPrivacy("private");
      setSort(initialSort ?? "relevance");
      resetFilters();
    }
    lastIsOpenRef.current = isOpen;
  }, [
    initialColumnCount,
    initialSkyboxColumnCount,
    isOpen,
    onCloseFilter,
    resetFilters,
    setSort,
    initialSort,
  ]);

  useEffect(() => {
    if ((!query && !assetId) || !getIsTabIdSearchModalTab(tabId)) {
      return;
    }

    const newOption = {
      query: query ? query.trim() : "",
      ...(assetId
        ? { assetId, projectId: selectedProject.id }
        : {
            assetId: "",
            projectId: "",
          }),
    };
    const timeout = setTimeout(() => {
      setRecentOptions((options) => {
        return [
          newOption,
          ...options.filter((o) => !_.isEqual(o, newOption)),
        ].slice(0, 10);
      });
    }, 1_000);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query, assetId, setRecentOptions, selectedProject.id, tabId]);

  useEffect(() => {
    const resetFocusedRecentOption = (e: FocusEvent) => {
      if (
        !e.target ||
        !("closest" in e.target) ||
        !(e.target as HTMLElement).closest("#recent-search-list")
      ) {
        focusedRecentOptionRef.current = undefined;
      }
    };
    window.addEventListener("focus", resetFocusedRecentOption, true);
    return () => {
      window.removeEventListener("focus", resetFocusedRecentOption, true);
    };
  }, []);

  useHotkeys(
    "ArrowUp",
    () => {
      if (!recentOptions.length) return;
      if (focusedRecentOptionRef.current === undefined) {
        setFocusedRecentOption(recentOptions.length - 1);
      } else {
        setFocusedRecentOption(
          (((focusedRecentOptionRef.current - 1) % recentOptions.length) +
            recentOptions.length) %
            recentOptions.length
        );
      }
    },
    {
      enableOnContentEditable: true,
      enableOnFormTags: true,
      preventDefault: true,
      enabled: isOpen && isRecentDisplayed,
    },
    [recentOptions]
  );

  useHotkeys(
    "ArrowDown",
    () => {
      if (!recentOptions.length) return;
      if (focusedRecentOptionRef.current === undefined) {
        setFocusedRecentOption(0);
      } else {
        setFocusedRecentOption(
          (focusedRecentOptionRef.current + 1) % recentOptions.length
        );
      }
    },
    {
      enableOnContentEditable: true,
      enableOnFormTags: true,
      preventDefault: true,
      enabled: isOpen && isRecentDisplayed,
    },
    [recentOptions]
  );

  useHotkeys(
    "Enter",
    () => {
      if (focusedRecentOptionRef.current === undefined) return;
      handleRecentSearchClick(recentOptions[focusedRecentOptionRef.current]);
    },
    [recentOptions]
  );

  // ----------------------------------

  const cardActionsProps = useMemo(() => ({}), []);

  // ----------------------------------

  const facets = useMemo(() => {
    const facetsByTab = {
      image: imageFacets,
      model: modelFacets,
      skybox: skyboxFacets,
      texture: textureFacets,
      canvas: canvasFacets,
    };

    if (getIsTabIdSearchModalTab(tabId)) {
      return facetsByTab[tabId];
    }

    return undefined;
  }, [
    imageFacets,
    modelFacets,
    skyboxFacets,
    textureFacets,
    canvasFacets,
    tabId,
  ]);

  const filterCount = useMemo(() => {
    if (!getIsTabIdSearchModalTab(tabId)) {
      return 0;
    }

    let count = 0;
    if (tabId === "image" && !!imageFmHeaderFilterAssetProps?.values?.length) {
      count += 1;
    }
    if (
      tabId === "skybox" &&
      !!skyboxFmHeaderFilterAssetProps?.values?.length
    ) {
      count += 1;
    }
    if (
      tabId === "texture" &&
      !!textureFmHeaderFilterAssetProps?.values?.length
    ) {
      count += 1;
    }
    if (authorQueryArgs.authorId && authorQueryArgs.authorId !== "all") {
      count += 1;
    }
    if (dateRange?.start) {
      count += 1;
    }
    if (tags.length > 0) {
      count += 1;
    }
    if (collections.length > 0) {
      count += 1;
    }
    if (["image", "skybox", "texture"].includes(tabId) && nsfw.length > 0) {
      count += 1;
    }
    if (
      restrictBy.length > 0 &&
      restrictBy.some((r) => ALLOWED_RESTRICT_VALUES[tabId].includes(r))
    ) {
      count += 1;
    }
    if (tabId === "model") {
      if (modelType) {
        count += 1;
      }
      if (modelBases.length > 0) {
        count += 1;
      }
      if (conceptIds.length > 0) {
        count += 1;
      }
    }
    if ((tabId === "image" || tabId === "texture") && modelIds.length > 0) {
      count += 1;
    }
    return count;
  }, [
    authorQueryArgs,
    collections,
    conceptIds,
    dateRange,
    modelBases,
    modelType,
    nsfw,
    restrictBy,
    tabId,
    tags,
    imageFmHeaderFilterAssetProps,
    skyboxFmHeaderFilterAssetProps,
    textureFmHeaderFilterAssetProps,
    modelIds,
  ]);

  const isLoadingBarDisplayed = useDebounce(
    getIsTabIdSearchModalTab(tabId)
      ? {
          image: isLoadingImages,
          model: isLoadingModels,
          skybox: isLoadingSkyboxes,
          texture: isLoadingTextures,
          canvas: isLoadingCanvas,
        }[tabId] || isLoadingGlobal
      : false,
    1_000,
    {
      preventFor: (value) => !!value,
    }
  );

  // ----------------------------------

  const isCardWithoutActions = !!onSelect || !!onSelectionChange;
  const isMultiSelectable = !!onSelectionChange;

  // ----------------------------------

  const currentTabIndex = useMemo(() => {
    const orderedTabs = [
      "image",
      "model",
      "skybox",
      "texture",
      "canvas",
    ].filter((t) => tabs.includes(t));
    orderedTabs.push(...(additionalTabs?.map((t) => t.id) || []));
    return orderedTabs.indexOf(tabId);
  }, [tabId, tabs, additionalTabs]);

  return (
    <Modal
      closeOnEsc
      closeOnOverlayClick
      initialFocusRef={modalSearchBarRef}
      isOpen={isOpen}
      onClose={onClose}
      variant="search"
    >
      <ModalOverlay />
      <ModalContent
        pos="relative"
        overflow="hidden"
        w="min(80vw, 1500px)"
        h={isInSearch ? "90vh" : "auto"}
        maxH="90vh"
        mt="5vh"
        mb={0}
        p={2}
        pb={0}
        borderRadius="20px"
        containerProps={{
          onDragEnter: (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
        }}
      >
        {isLoadingBarDisplayed && (
          <Progress
            pos="absolute"
            zIndex="popover"
            top={0}
            left={0}
            w="100%"
            h={"4px"}
            isIndeterminate
          />
        )}
        <ModalBody flexDir="column" flex={1} display="flex" overflow="hidden">
          <VStack align="stretch" flex={1} h="100%" spacing={0}>
            <SearchBar
              searchBarRef={modalSearchBarRef}
              query={query}
              setQuery={setQuery}
              assetId={assetId}
              setAssetId={setAssetId}
              size="lg"
              color="textPrimary"
              borderWidth={0}
              searchOn={["enter"]}
              aiBoost={aiBoost}
              setAiBoost={setAiBoost}
              privacy={privacy}
              setPrivacy={setPrivacy}
            />

            {isRecentDisplayed && (
              <VStack align="stretch" pt={4} pb={2} spacing={2}>
                <Text pl={2} size="body.lg">
                  Recent Searches
                </Text>

                <VStack align="stretch" id="recent-search-list" spacing={0}>
                  {recentOptions.map((option, idx) => {
                    if (
                      option.assetId &&
                      option.projectId !== selectedProject.id
                    ) {
                      return null;
                    }
                    return (
                      <Button
                        key={`${option}-${idx}`}
                        _focusVisible={{
                          boxShadow: "var(--chakra-shadows-outline) inset",
                        }}
                        size="sm"
                        variant="menuItem"
                        leftIcon={<Icon id="Ui/Search" h="12px" />}
                        data-group
                        rightIcon={
                          <IconButton
                            w="24px"
                            minW={0}
                            h="24px"
                            mx={-1}
                            borderRadius="full"
                            _hover={{
                              bgColor: "whiteAlpha.300",
                            }}
                            _groupHover={{
                              visibility: "visible",
                            }}
                            visibility="hidden"
                            aria-label="remove"
                            colorScheme="white"
                            onClick={(e) =>
                              handleRemoveRecentOptionClick(e, option)
                            }
                            variant="ghost"
                          >
                            <Icon id="Ui/Cross" h="8px" />
                          </IconButton>
                        }
                        onClick={() => handleRecentSearchClick(option)}
                      >
                        {option.assetId && (
                          <Image
                            h="100%"
                            borderRadius="base"
                            objectFit="contain"
                            alt="image recent search"
                            aspectRatio="1"
                            fallback={<Skeleton h="100%" aspectRatio="1" />}
                            src={`${process.env.NEXT_PUBLIC_CDN_URL}/thumbnails/${option.assetId}`}
                          />
                        )}
                        <Box flex={1} textAlign="left">
                          {option.query}
                        </Box>
                      </Button>
                    );
                  })}
                </VStack>
              </VStack>
            )}

            {((!!propositions && propositions.propositions.length > 0) ||
              isLoadingAutocomplete) && (
              <>
                <HStack
                  pos="relative"
                  justify="start"
                  flexShrink={0}
                  overflow="hidden"
                  h="56px"
                  px={1}
                  py={2.5}
                  flexFlow="wrap"
                  spacing={0}
                >
                  {(!!propositions && propositions.propositions
                    ? propositions.propositions
                    : ["placeholder", "placeholder", "placeholder"]
                  ).map((option, idx) => (
                    <Button
                      key={`${option}-${idx}`}
                      size="sm"
                      width="auto"
                      variant="menuItem"
                      pointerEvents={isLoadingAutocomplete ? "none" : "auto"}
                      leftIcon={<Icon id="Ui/Search" h="12px" />}
                      onClick={() => {
                        if (!isLoadingAutocomplete) {
                          setQuery(option);
                        }
                      }}
                      fontSize="16px"
                    >
                      {propositions ? (
                        <HStack spacing={0}>
                          <Box as="span" color="textSecondary" whiteSpace="pre">
                            {propositions.query}
                          </Box>
                          <Box as="span" color="textPrimary" whiteSpace="pre">
                            {option.replace(propositions.query, "")}
                          </Box>
                        </HStack>
                      ) : (
                        <SkeletonText
                          w="60px"
                          noOfLines={1}
                          skeletonHeight="20px"
                        />
                      )}
                    </Button>
                  ))}
                  <Box pos="absolute" top="46px" left={0} w="100%" h="100px" />
                </HStack>

                <Divider color="border.500" opacity={1} />
              </>
            )}

            {isInSearch && (
              <Flex direction="column" flex={1} overflow="hidden">
                <Tabs
                  variant="light"
                  isLazy
                  flex={1}
                  overflow="hidden"
                  display="flex"
                  flexDirection="column"
                  borderRadius="xl"
                  onChange={handleTabChange}
                  index={currentTabIndex}
                >
                  <HStack
                    pos="relative"
                    justify="space-between"
                    w="100%"
                    borderBottomWidth={1}
                    borderBottomColor="border.500"
                  >
                    <HorizontalScrollable childrenRef={tabListRef}>
                      <TabList
                        ref={tabListRef}
                        py={1}
                        alignItems="center"
                        overflow="auto"
                        borderBottomWidth={0}
                        sx={{
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                          "-ms-overflow-style": "none",
                          "scrollbar-width": "none",
                        }}
                      >
                        {tabs.includes("image") && (
                          <ModalTab
                            id="image"
                            title="Images"
                            isLoading={isLoadingGlobal || isLoadingImages}
                            count={imagesCount}
                          />
                        )}

                        {tabs.includes("model") && (
                          <ModalTab
                            id="model"
                            title="Models"
                            isLoading={isLoadingGlobal || isLoadingModels}
                            count={modelsCount}
                          />
                        )}

                        {tabs.includes("skybox") && (
                          <ModalTab
                            id="skybox"
                            title="Skyboxes"
                            isLoading={isLoadingGlobal || isLoadingSkyboxes}
                            count={skyboxesCount}
                          />
                        )}

                        {tabs.includes("texture") && (
                          <ModalTab
                            id="texture"
                            title="Textures"
                            isLoading={isLoadingGlobal || isLoadingTextures}
                            count={texturesCount}
                          />
                        )}

                        {tabs.includes("canvas") && (
                          <ModalTab
                            id="canvas"
                            title="Canvas"
                            isLoading={isLoadingGlobal || isLoadingCanvas}
                            count={canvasCount}
                          />
                        )}

                        {additionalTabs?.map((tab) => (
                          <ModalTab
                            key={tab.id}
                            id={tab.id}
                            title={tab.title}
                            count={-1}
                          />
                        ))}
                      </TabList>
                    </HorizontalScrollable>

                    <ButtonSingleChoice
                      text="Filters"
                      hasValue={filterCount > 0}
                      onRemove={resetFilters}
                      leftIcon={<Icon id="Ui/Filter" h="16px" />}
                      onClick={onToggleFilter}
                      isChevronDisplayed={false}
                      isDisabled={!getIsTabIdSearchModalTab(tabId)}
                    />
                  </HStack>

                  <Box mb={4}>
                    <TabIndicator
                      key={[
                        isLoadingImages,
                        images.length,
                        isLoadingSkyboxes,
                        skyboxes.length,
                        isLoadingTextures,
                        textures.length,
                        isLoadingCanvas,
                        canvas.length,
                        isLoadingModels,
                        models.length,
                        isLoadingGlobal,
                      ].join("-")}
                    />
                  </Box>

                  {getIsTabIdSearchModalTab(tabId) && (
                    <Box pos="relative" zIndex="popover" mb={4}>
                      <Collapse animateOpacity={false} in={isFilterOpen}>
                        <HStack justify="space-between" w="100%">
                          <HorizontalScrollable
                            childrenRef={filtersRef}
                            getShouldAvoidScroll={(e) => {
                              return !!(
                                e.target instanceof HTMLElement &&
                                e.target.closest(".chakra-menu__menu-list")
                              );
                            }}
                          >
                            <HStack
                              ref={filtersRef}
                              sx={{
                                "&::-webkit-scrollbar": {
                                  display: "none",
                                },
                                "-ms-overflow-style": "none",
                                "scrollbar-width": "none",
                              }}
                              align="center"
                              overflow="auto"
                              spacing={2}
                            >
                              {tabId === "image" &&
                                !!imageFmHeaderFilterAssetProps && (
                                  <HeaderFilterAssetType
                                    {...imageFmHeaderFilterAssetProps}
                                    facets={imageFacets?.["metadata.type"]}
                                  />
                                )}

                              {tabId === "skybox" &&
                                !!skyboxFmHeaderFilterAssetProps && (
                                  <HeaderFilterAssetType
                                    {...skyboxFmHeaderFilterAssetProps}
                                    facets={skyboxFacets?.["metadata.type"]}
                                  />
                                )}

                              {tabId === "texture" &&
                                !!textureFmHeaderFilterAssetProps && (
                                  <HeaderFilterAssetType
                                    {...textureFmHeaderFilterAssetProps}
                                    facets={textureFacets?.["metadata.type"]}
                                  />
                                )}

                              {!!fmHeaderFilterAuthorProps && (
                                <HeaderFilterAuthor
                                  {...fmHeaderFilterAuthorProps}
                                  facets={facets?.["authorId"]}
                                />
                              )}

                              {!!fmHeaderFilterDateProps && (
                                <HeaderFilterDate
                                  {...fmHeaderFilterDateProps}
                                />
                              )}

                              <FilterTags
                                setValue={setTags}
                                value={tags}
                                facets={facets?.["tags"]}
                              />

                              <FilterCollections
                                value={collections}
                                setValue={setCollections}
                                facets={facets?.["collectionIds"]}
                              />

                              {(tabId === "image" || tabId === "texture") && (
                                <FilterModels
                                  label="Models"
                                  value={modelIds}
                                  setValue={setModelIds}
                                  facets={facets?.["metadata.modelId"]}
                                  isDisabled={!!forceModelIds}
                                />
                              )}

                              {tabId !== "model" && tabId !== "canvas" && (
                                <FilterNsfw
                                  value={nsfw}
                                  setValue={setNsfw}
                                  facets={facets?.["nsfw"]}
                                />
                              )}

                              {tabId === "model" && (
                                <>
                                  <FilterModelBases
                                    value={modelBases}
                                    setValue={setModelBases}
                                    typesFacets={facets?.["type"]}
                                  />
                                  <FilterModelType
                                    value={modelType}
                                    setValue={setModelType}
                                    models={models}
                                  />
                                  <FilterModels
                                    label="Components"
                                    value={conceptIds}
                                    setValue={setConceptIds}
                                    facets={facets?.["concepts.modelId"]}
                                  />
                                </>
                              )}

                              <FilterRestricts
                                type={tabId}
                                value={restrictBy}
                                setValue={setRestrictBy}
                              />
                            </HStack>
                          </HorizontalScrollable>

                          <HStack align="center" spacing={2}>
                            <FmHeaderColumn
                              value={
                                tabId === "skybox"
                                  ? skyboxColumnsCount
                                  : columnsCount
                              }
                              onChange={
                                tabId === "skybox"
                                  ? setSkyboxColumnsCount
                                  : setColumnsCount
                              }
                            />

                            <FmHeaderSort {...fmHeaderSortProps} />

                            {["image", "skybox", "texture"].includes(tabId) && (
                              <FmHeaderView
                                value={view}
                                options={gridViewKeys}
                                onChange={setView}
                              />
                            )}
                          </HStack>
                        </HStack>
                      </Collapse>
                    </Box>
                  )}

                  <TabPanels ref={scrollRef} flex={1} overflow="auto" pt={0}>
                    {tabs.includes("image") && (
                      <TabPanel h="100%">
                        <Deffered timeout={300}>
                          <FileManagerImage
                            scrollRef={scrollRef}
                            files={view !== "jobs" ? images : undefined}
                            jobs={view === "jobs" ? imageJobs : undefined}
                            isLoading={isLoadingGlobal || isLoadingImages}
                            view={view}
                            columnsCount={columnsCount}
                            isSelectable={isMultiSelectable || !onSelect}
                            isWithoutHeader
                            onActionClick={handleActionClick}
                            isSelectionForced={isMultiSelectable}
                            selectionMax={selectionMax}
                            onSelectionChange={onSelectionChange}
                            onCardClick={onSelect}
                            CardActionsComponent={ImageCardActions}
                            cardActionsProps={cardActionsProps}
                            EmptyStateComponent={EmptyState}
                            onDelete={(files) =>
                              handleDelete(files, setDeletedImageIds)
                            }
                            onUndoDelete={(files) =>
                              handleUndoDelete(files, setDeletedImageIds)
                            }
                            onEndReached={loadMoreImages}
                            hasMore={hasMoreImages}
                            assetZoomPriorityLevel={1}
                            onAssetZoomAction={onClose}
                            isCardWithoutActions={isCardWithoutActions}
                            SelectionActionsComponent={
                              SelectionActionsComponent
                            }
                          />
                        </Deffered>
                      </TabPanel>
                    )}

                    {tabs.includes("model") && (
                      <TabPanel h="100%">
                        <Deffered timeout={300}>
                          <FileManager
                            styleProps={{
                              topbar: {
                                display: "none",
                              },
                            }}
                            files={models}
                            isLoading={isLoadingGlobal || isLoadingModels}
                            options={{
                              canSelect: true,
                              canChangeView: false,
                              gridView: "fill",
                              canChangeNumberOfColumns: false,
                              numberOfColumns: columnsCount,
                              showFileNames: "always",
                            }}
                            fileHandlers={{
                              model: modelFileHandler,
                            }}
                            scrollRef={scrollRef}
                            loadMore={loadMoreModels}
                            hasMore={hasMoreModels}
                          />
                        </Deffered>
                      </TabPanel>
                    )}

                    {tabs.includes("skybox") && (
                      <TabPanel>
                        <Deffered timeout={300}>
                          <FileManagerImage
                            variant="skybox"
                            scrollRef={scrollRef}
                            files={view !== "jobs" ? skyboxes : undefined}
                            jobs={view === "jobs" ? skyboxJobs : undefined}
                            isLoading={isLoadingGlobal || isLoadingSkyboxes}
                            view="masonry"
                            columnsCount={skyboxColumnsCount}
                            isWithoutHeader
                            isSelectable={isMultiSelectable || !onSelect}
                            isSelectionForced={isMultiSelectable}
                            selectionMax={selectionMax}
                            onSelectionChange={onSelectionChange}
                            onCardClick={onSelect}
                            onActionClick={handleActionClick}
                            CardActionsComponent={SkyboxCardActions}
                            cardActionsProps={cardActionsProps}
                            EmptyStateComponent={EmptyState}
                            onDelete={(files) =>
                              handleDelete(files, setDeletedSkyboxIds)
                            }
                            onUndoDelete={(files) =>
                              handleUndoDelete(files, setDeletedSkyboxIds)
                            }
                            onEndReached={loadMoreSkyboxes}
                            hasMore={hasMoreSkyboxes}
                            assetZoomPriorityLevel={1}
                            onAssetZoomAction={onClose}
                            isCardWithoutActions={isCardWithoutActions}
                            SelectionActionsComponent={
                              SelectionActionsComponent
                            }
                          />
                        </Deffered>
                      </TabPanel>
                    )}

                    {tabs.includes("texture") && (
                      <TabPanel>
                        <Deffered timeout={300}>
                          <FileManagerImage
                            variant="texture"
                            scrollRef={scrollRef}
                            files={view !== "jobs" ? textures : undefined}
                            jobs={view === "jobs" ? textureJobs : undefined}
                            isLoading={isLoadingGlobal || isLoadingTextures}
                            view="fill"
                            columnsCount={columnsCount}
                            isWithoutHeader
                            isSelectable={isMultiSelectable || !onSelect}
                            isSelectionForced={isMultiSelectable}
                            selectionMax={selectionMax}
                            onSelectionChange={onSelectionChange}
                            onCardClick={onSelect}
                            onActionClick={handleActionClick}
                            CardActionsComponent={TextureCardActions}
                            cardActionsProps={cardActionsProps}
                            EmptyStateComponent={EmptyState}
                            onDelete={(files) =>
                              handleDelete(files, setDeletedTextureIds)
                            }
                            onUndoDelete={(files) =>
                              handleUndoDelete(files, setDeletedTextureIds)
                            }
                            onEndReached={loadMoreTextures}
                            hasMore={hasMoreTextures}
                            assetZoomPriorityLevel={1}
                            onAssetZoomAction={onClose}
                            isCardWithoutActions={isCardWithoutActions}
                            SelectionActionsComponent={
                              SelectionActionsComponent
                            }
                          />
                        </Deffered>
                      </TabPanel>
                    )}

                    {tabs.includes("canvas") && (
                      <TabPanel>
                        <Deffered timeout={300}>
                          <FileManager
                            styleProps={{
                              topbar: {
                                display: "none",
                              },
                            }}
                            files={canvas}
                            isLoading={isLoadingGlobal || isLoadingCanvas}
                            options={{
                              canSelect: true,
                              canChangeView: false,
                              gridView: "fill",
                              canChangeNumberOfColumns: false,
                              numberOfColumns: columnsCount,
                              showFileNames: "always",
                            }}
                            fileHandlers={{
                              canvas: canvasFileHandler,
                            }}
                            scrollRef={scrollRef}
                            loadMore={loadMoreCanvases}
                            hasMore={hasMoreCanvases}
                          />
                        </Deffered>
                      </TabPanel>
                    )}

                    {additionalTabs?.map((tab) => (
                      <TabPanel key={tab.id} w="100%" h="100%">
                        <Deffered timeout={300}>
                          {tab.children({
                            scrollRef,
                            query,
                          })}
                        </Deffered>
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
              </Flex>
            )}

            {!isInSearch && (
              <HStack
                align="center"
                justify="start"
                mt={isRecentDisplayed ? 0 : 2}
                mx={-2}
                px={4}
                py={2}
                borderTopWidth={1}
                spacing={8}
              >
                <HStack align="center" spacing={2}>
                  <Icon id="Ui/UpDownArrows" color="textPrimary" h="16px" />
                  <Text color="textSecondary" size="body.md">
                    Select
                  </Text>
                </HStack>
                <HStack align="center" spacing={2}>
                  <Text color="textSecondary" size="body.bold.md">
                    {isMac ? "⌘" : "Ctrl"} F
                  </Text>
                  <Text color="textSecondary" size="body.md">
                    Quick Search
                  </Text>
                </HStack>
              </HStack>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

// ------------------------------------

function EmptyState() {
  return (
    <VStack justify="center" py={20} spacing={2}>
      <Icon id="Ui/Search" h="16px" color="textPrimary" />
      <Text color="textPrimary" size="body.md">
        Sorry, no results found
      </Text>
      <Text color="textTertiary" size="body.sm">
        You can search models, images, prompts, tags...
      </Text>
    </VStack>
  );
}

// ------------------------------------

interface ModalTabProps {
  id: string;
  title: React.ReactNode;
  isLoading?: boolean;
  count?: number;
}

function ModalTab({ id, title, isLoading = false, count }: ModalTabProps) {
  return (
    <Tab data-id={id}>
      <HStack spacing={1}>
        <span>{title}</span>
        {isLoading && count === undefined ? (
          <Spinner size="sm" />
        ) : (
          count !== -1 && (
            <span>{`(${
              count !== undefined ? (count > 500 ? "500+" : count) : 0
            })`}</span>
          )
        )}
      </HStack>
    </Tab>
  );
}
