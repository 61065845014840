import { memo, useMemo } from "react";
import { tagIdToLabel } from "domains/tags/utils";
import MenuListMultipleChoice from "domains/ui/components/Menu/MenuListMultipleChoice";

export interface FilterTagsProps {
  value: string[];
  setValue: (value: string[]) => void;
  facets?: {
    [key: string]: number;
  };
}

export default memo(function FilterTags({
  value,
  setValue,
  facets,
}: FilterTagsProps) {
  const options = useMemo(() => {
    const newFacets = { ...facets };
    value.forEach((v) => {
      if (!(v in newFacets)) {
        newFacets[v] = 0;
      }
    });
    return Object.entries(newFacets)
      .map(([key, value]) => {
        return {
          value: key,
          label: value ? `${tagIdToLabel(key)} (${value})` : tagIdToLabel(key),
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [facets, value]);

  return (
    <MenuListMultipleChoice
      label="Tags"
      options={options}
      setSelectedValues={setValue}
      selectedValues={value}
    />
  );
});
