import {
  VECTORIZATION_COLOR_MODE_LABELS,
  VECTORIZATION_COLOR_MODES,
  VECTORIZATION_MODE_LABELS,
  VECTORIZATION_MODES,
  VECTORIZATION_PRESET_LABELS,
  VECTORIZATION_PRESETS,
  VectorizationColorMode,
  VectorizationMode,
  VectorizationPreset,
} from "domains/assets/constants/vectorize";
import { UseAssetVectorizeReturnValue } from "domains/assets/hooks/useAssetVectorize";
import ControlSelect from "domains/ui/components/ControlSelect";
import ControlSwitch from "domains/ui/components/ControlSwitch";
import SliderWithInputNumber from "domains/ui/components/SliderWithInputNumber";

import { VStack } from "@chakra-ui/react";
import { useAutoAnimate } from "@formkit/auto-animate/react";

interface VectorizeParamsProps {
  form: UseAssetVectorizeReturnValue["form"];
  setValue: UseAssetVectorizeReturnValue["setValue"];
}

export default function VectorizeParams({
  form,
  setValue,
}: VectorizeParamsProps) {
  const [animationRef] = useAutoAnimate();

  return (
    <VStack ref={animationRef} align="start" w="100%" spacing={2}>
      <ControlSelect
        title="Preset"
        value={form.preset}
        setValue={(newValue) =>
          setValue("preset", newValue as VectorizationPreset)
        }
        options={VECTORIZATION_PRESETS.map((presetValue) => ({
          label: VECTORIZATION_PRESET_LABELS[presetValue],
          value: presetValue,
        }))}
      />

      <ControlSwitch
        title="Remove Background"
        isChecked={form.isRemoveBackground}
        setIsChecked={(val) => setValue("isRemoveBackground", val)}
      />

      <ControlSelect
        title="Color Clustering"
        value={form.colorMode}
        setValue={(newValue) =>
          setValue("colorMode", newValue as VectorizationColorMode)
        }
        options={VECTORIZATION_COLOR_MODES.map((colorMode) => ({
          label: VECTORIZATION_COLOR_MODE_LABELS[colorMode],
          value: colorMode,
        }))}
      />

      <SliderWithInputNumber
        title="Filter Speckle"
        tooltip="Higher values filter out small, isolated dots for a cleaner vector output."
        value={form.filterSpeckle}
        setValue={(newValue) => {
          setValue("filterSpeckle", newValue ?? form.filterSpeckle);
        }}
        min={1}
        max={128}
      />

      {form.colorMode !== "bw" && (
        <>
          <SliderWithInputNumber
            title="Color Precision"
            tooltip="Higher values enhance the color matching accuracy to the original image."
            value={form.colorPrecision}
            setValue={(newValue) => {
              setValue("colorPrecision", newValue ?? form.colorPrecision);
            }}
            min={1}
            max={16}
          />

          <SliderWithInputNumber
            title="Layer Difference"
            tooltip="Higher values combine similar colors into fewer layers, simplifying the vector."
            value={form.layerDifference}
            setValue={(newValue) => {
              setValue("layerDifference", newValue ?? form.layerDifference);
            }}
            min={0}
            max={255}
          />
        </>
      )}

      <ControlSelect
        title="Curve Detection"
        value={form.mode}
        setValue={(newValue) => setValue("mode", newValue as VectorizationMode)}
        options={VECTORIZATION_MODES.map((mode) => ({
          label: VECTORIZATION_MODE_LABELS[mode],
          value: mode,
        }))}
      />

      {form.mode === "spline" && (
        <>
          <SliderWithInputNumber
            title="Corner Thresh"
            tooltip="Higher values smooth out corners for softer transitions in the vector shape."
            value={form.cornerThreshold}
            setValue={(newValue) => {
              setValue("cornerThreshold", newValue ?? form.cornerThreshold);
            }}
            min={0}
            max={180}
          />

          <SliderWithInputNumber
            title="Length Thresh"
            tooltip="Higher values ignore smaller lines, streamlining the vector."
            value={form.lengthThreshold}
            setValue={(newValue) => {
              setValue("lengthThreshold", newValue ?? form.lengthThreshold);
            }}
            step={0.1}
            min={3.5}
            max={10}
          />

          <SliderWithInputNumber
            title="Splice Thresh"
            tooltip="Higher value reduce accuracy"
            value={form.spliceThreshold}
            setValue={(newValue) => {
              setValue("spliceThreshold", newValue ?? form.spliceThreshold);
            }}
            min={1}
            max={180}
          />
        </>
      )}
    </VStack>
  );
}
