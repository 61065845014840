import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import { Box, BoxProps, HStack, Text, VStack } from "@chakra-ui/react";

import AsideCard, { AsideCardProps } from "./Card";
import { AsideProvider } from "./context";
import AsideDrawer, { AsideDrawerProps } from "./Drawer";
import {
  AsideList,
  AsideListItem,
  AsideListItemProps,
  AsideListProps,
} from "./List";
import AsideSection, { AsideSectionProps } from "./Section";
import AsideTitledData, { AsideTitledDataProps } from "./TitledData";
import AsideTitledText, { AsideTitledTextProps } from "./TitledText";

export {
  AsideCard,
  AsideDrawer,
  AsideList,
  AsideListItem,
  AsideSection,
  AsideTitledData,
  AsideTitledText,
};
export type {
  AsideCardProps,
  AsideDrawerProps,
  AsideListItemProps,
  AsideListProps,
  AsideSectionProps,
  AsideTitledDataProps,
  AsideTitledTextProps,
};

interface AsideProps extends BoxProps {
  id: string;
  title: string;
  icon: React.ReactNode;
  open: boolean;
  onOpenToggle: () => void;
  buttonTestId?: string;
  tooltip?: React.ReactNode;
}

export default function Aside({
  id,
  title,
  icon,
  children,
  open: isOpen,
  onOpenToggle,
  buttonTestId,
  tooltip,
  ...props
}: AsideProps) {
  return (
    <Box pos="relative" {...props}>
      {!isOpen && (
        <Box pos="absolute" top={4} right={4}>
          <Button
            variant="secondaryAlt"
            tooltip={tooltip}
            tooltipProps={{
              placement: "left",
            }}
            size="sm"
            p={0}
            onClick={onOpenToggle}
            data-testid={buttonTestId}
          >
            {icon}
          </Button>
        </Box>
      )}

      {isOpen && (
        <VStack
          align="stretch"
          w="375px"
          h="100%"
          borderLeftWidth={1}
          borderLeftColor="border.500"
          bgColor="backgroundTertiary.500"
          spacing={0}
        >
          <Box>
            <HStack
              h="56px"
              px={6}
              borderBottomWidth={1}
              borderBottomColor="border.500"
              spacing={3}
            >
              {icon}

              <Text flex={1} size="body.bold.md">
                {title}
              </Text>

              <Button
                variant="ghost"
                colorScheme="white"
                size="sm"
                p={0}
                mr={-3}
                onClick={onOpenToggle}
              >
                <Icon id="Ui/ChevronDoubleRight" h="10px" />
              </Button>
            </HStack>
          </Box>

          <Box flex={1} overflowY="auto" pb={5}>
            <AsideProvider prefix={id}>{children}</AsideProvider>
          </Box>
        </VStack>
      )}
    </Box>
  );
}
