function scale(vector: number[], scalar: number): number[] {
  return vector.map((value) => value * scalar);
}

export function avg(
  vectors: number[][],
  scales: number[]
): number[] | undefined {
  const localVectors = vectors.map((vector, i) =>
    scale(vector, scales[i] ?? 1)
  );
  const localScalesDenominator = scales.reduce((acc, val) => acc + val, 0);

  if (localScalesDenominator === 0) {
    return undefined;
  }

  const avgVector = localVectors.reduce((acc, val) => {
    return acc.map((v, i) => v + (val[i] ?? 0));
  }, Array(vectors[0].length).fill(0));
  return avgVector.map((v) => v / localScalesDenominator);
}
