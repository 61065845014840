import {
  getPixelateParamsBody,
  UseAssetPixelateReturnValue,
} from "domains/assets/hooks/useAssetPixelate";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useGetPixelateCostQuery } from "infra/store/apiSlice";
import _ from "lodash";

import { skipToken } from "@reduxjs/toolkit/dist/query";

export function useAssetPixelateCu(
  assetId: string | undefined,
  form: UseAssetPixelateReturnValue["form"]
): {
  isCuLoading: boolean;
  cuCost?: number;
} {
  const { selectedProject } = useTeamContext();
  const debouncedForm = useDebounce(form, 500);

  const body = assetId
    ? getPixelateParamsBody(assetId, debouncedForm)
    : undefined;

  const { data: cuCostData, isLoading: isCuLoading } = useGetPixelateCostQuery(
    body
      ? {
          projectId: selectedProject.id,
          body,
        }
      : skipToken
  );
  return {
    isCuLoading:
      isCuLoading || (!!cuCostData && !_.isEqual(form, debouncedForm)),
    cuCost: assetId ? cuCostData?.creativeUnitsCost : undefined,
  };
}
