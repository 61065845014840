import { RefObject } from "react";
import { FmFile, FmFileImage } from "domains/file-manager/interfacesV2";

import { VStack } from "@chakra-ui/react";

import { ActionType, FmImageCardActionsProps } from "./Actions";
import CardContent, { CardContentProps } from "./Content";
import CardPlaceholder from "./Placeholder";

type CardProps<C extends object = object> = {
  file: FmFileImage;
  variant: "image" | "skybox" | "texture";
  isVaryEnabled?: boolean;
  onVary?: () => void;
  cardWidth: number;
  containerRef: RefObject<HTMLDivElement>;
  isCancelable?: boolean;
  isDisabled?: boolean;
  isHovered?: boolean;
  isBlurred?: boolean;
  isPinned?: boolean;
  isWithoutActions?: boolean;
  onClick?: (file: FmFile<"image">) => void;
  onPinClick?: CardContentProps["onPinClick"];

  actionsProps: C;
  ActionsComponent?: React.FunctionComponent<FmImageCardActionsProps<C>>;
  onActionClick?: (type: ActionType) => void;
};

export default function Card<C extends object = object>({
  file,
  variant,
  isVaryEnabled = false,
  onVary,
  cardWidth,
  containerRef,
  isCancelable = false,
  isDisabled = false,
  isHovered = false,
  isBlurred = false,
  isPinned = false,
  isWithoutActions = false,
  onClick,
  onPinClick,
  actionsProps,
  ActionsComponent,
  onActionClick,
}: CardProps<C>) {
  const isSuccess = file.status === "success";
  const imgHeightRatio = Math.round((file.height / file.width) * 100);
  const cardMinEdge = Math.min(cardWidth, (cardWidth * imgHeightRatio) / 100);

  if (isDisabled) {
    return null;
  }

  if (!isSuccess) {
    return (
      <VStack
        pos="relative"
        align="center"
        justify="center"
        w="100%"
        h="100%"
        borderRadius="md"
        bgColor="backgroundSecondary.500"
        data-group
        spacing={3}
      >
        <CardPlaceholder
          file={file}
          cardMinEdge={cardMinEdge}
          isCancelable={isCancelable}
        />
      </VStack>
    );
  } else {
    return (
      <CardContent
        file={file}
        variant={variant}
        isVaryEnabled={isVaryEnabled}
        onVary={onVary}
        cardWidth={cardWidth}
        containerRef={containerRef}
        isHovered={isHovered}
        isBlurred={isBlurred}
        isPinned={isPinned}
        isWithoutActions={isWithoutActions}
        onClick={onClick}
        onPinClick={onPinClick}
        actionsProps={actionsProps}
        ActionsComponent={ActionsComponent}
        onActionClick={onActionClick}
      />
    );
  }
}
