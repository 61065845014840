import { useEffect } from "react";

let eventHasBeenAlreadyAdded = false;

function useClickOutside(onClickOutside: () => void, disabled = false): void {
  useEffect(() => {
    if (disabled) {
      return;
    }
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      const target = event.target as HTMLElement;
      const isExcluded =
        target.closest('[data-outside-click-excluded="true"]') ||
        target.tagName.toLowerCase() === "html";

      if (isExcluded) {
        return;
      }
      if (
        onClickOutside &&
        target &&
        !target.closest(".chakra-modal__overlay") &&
        !target.closest(".chakra-toast")
      ) {
        onClickOutside();
      }
    }

    if (!eventHasBeenAlreadyAdded) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
      eventHasBeenAlreadyAdded = true;
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
      eventHasBeenAlreadyAdded = false;
    };
  }, [disabled, onClickOutside]);
}

export default useClickOutside;
