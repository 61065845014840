import React, { useCallback, useMemo, useRef, useState } from "react";
import Link from "next/link";
import useAssetZoomCompareBefore from "domains/assets/hooks/useAssetZoomCompareBefore";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { useOptimizedAssetUrl } from "domains/file-manager/hooks/useOptimizedAssetUrl";
import { mapAssetsToImagesFiles } from "domains/file-manager/interfaces";
import { getShouldHaveNsfwBlur } from "domains/file-manager/utils/getShouldHaveNsfwBlur";
import { appShortcuts } from "domains/shortcuts/components/appShortcuts";
import { Shortcut } from "domains/shortcuts/components/Shorcut";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { NSFW_LABELS, NsfwType } from "domains/user/constants/Nsfw";
import { useUserContext } from "domains/user/contexts/UserProvider";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
} from "infra/api/generated/api";
import { ReactCompareSlider } from "react-compare-slider";
import {
  ReactZoomPanPinchProps,
  TransformComponent,
  TransformWrapper,
  useControls,
} from "react-zoom-pan-pinch";

import {
  Box,
  Center,
  Flex,
  HStack,
  Image as ChakraImage,
  Spinner,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";

type AssetZoomContentContainerProps = Omit<
  AssetZoomContentProps,
  "currentScale"
>;

export default function AssetZoomContentContainer(
  props: AssetZoomContentContainerProps
) {
  const [currentScale, setCurrentScale] = useState<number | undefined>();
  const isFullSize = currentScale === 100;

  const handleTransformed = useCallback<
    Exclude<ReactZoomPanPinchProps["onTransformed"], undefined>
  >(
    (_ref, { scale }) => {
      setCurrentScale(Math.round(scale * 100));
    },
    [setCurrentScale]
  );

  return (
    <TransformWrapper
      key={props.asset?.id}
      initialScale={1}
      panning={{
        disabled: isFullSize,
      }}
      centerOnInit
      centerZoomedOut
      onTransformed={handleTransformed}
    >
      <AssetZoomContent {...props} currentScale={currentScale} />
    </TransformWrapper>
  );
}

// ------------------------------------

interface AssetZoomContentProps {
  inference:
    | GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
    | undefined;
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
  currentScale: number | undefined;
  onSwitchImageClick: (direction: "next" | "previous") => void;
  isComparing: boolean;
  onToggleComparing: () => void;
}

function AssetZoomContent({
  inference,
  asset,
  currentScale,
  onSwitchImageClick,
  isComparing,
  onToggleComparing,
}: AssetZoomContentProps) {
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const { nsfwFilteredTypes, nsfwRevealedAssetIds, revealAsset } =
    useUserContext();
  const { zoomIn, zoomOut, resetTransform } = useControls();
  const [isHovering, setIsHovering] = useState(false);

  const assetNsfw = asset?.nsfw ?? [];
  const assetRatio =
    Math.round(
      ((asset?.metadata.height ?? 1) / (asset?.metadata.width ?? 1)) * 1_000
    ) / 10;
  const hasNsfwBlur =
    !!asset &&
    getShouldHaveNsfwBlur({
      nsfwFilteredTypes,
      nsfw: asset.nsfw,
    }) &&
    !nsfwRevealedAssetIds.includes(asset.id);

  const assetAsFile = useMemo(() => {
    return asset ? mapAssetsToImagesFiles([asset])[0] : undefined;
  }, [asset]);

  const imageFile =
    imageContainerRef.current?.clientWidth !== undefined
      ? assetAsFile
      : undefined;
  const imageCardWidth = imageContainerRef.current?.clientWidth ?? 512;

  const { url: minResUrl } = useOptimizedAssetUrl({
    imageFile,
    cardWidth: imageCardWidth,
  });
  const { url: maxResUrl } = useOptimizedAssetUrl({
    imageFile,
    cardWidth: imageCardWidth * ((currentScale ?? 100) / 100),
  });

  const beforeAsset = useAssetZoomCompareBefore({
    inference,
    asset,
  });
  const beforeAssetAsFile = useMemo(() => {
    return beforeAsset ? mapAssetsToImagesFiles([beforeAsset])[0] : undefined;
  }, [beforeAsset]);

  const beforeImageFile =
    imageContainerRef.current?.clientWidth !== undefined
      ? beforeAssetAsFile
      : undefined;
  const beforeImageCardWidth = imageContainerRef.current?.clientWidth ?? 512;

  const { url: beforeMinResUrl } = useOptimizedAssetUrl({
    imageFile: beforeImageFile,
    cardWidth: beforeImageCardWidth,
  });
  const { url: beforeMaxResUrl } = useOptimizedAssetUrl({
    imageFile: beforeImageFile,
    cardWidth: beforeImageCardWidth * ((currentScale ?? 100) / 100),
  });

  const canCompare = !!beforeAsset && !!beforeMinResUrl;

  // ----------------------------------

  useHotkeys(
    appShortcuts.assetPage.shortcuts.compare.shortcut,
    () => onToggleComparing(),
    [onToggleComparing]
  );

  useHotkeys(
    appShortcuts.assetPage.shortcuts.resetZoom.shortcut,
    () => resetTransform(),
    [resetTransform]
  );

  // ----------------------------------

  return (
    <Flex
      pos="relative"
      flex={1}
      w="100%"
      bgColor="black"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <TransformComponent
        wrapperStyle={{ width: "100%", height: "100%" }}
        contentStyle={{ width: "100%", height: "100%" }}
      >
        <Flex align="center" justify="center" w="100%" h="100%">
          {asset ? (
            <Box ref={imageContainerRef} pos="relative" w="80%" h="80%">
              {isComparing && canCompare && !hasNsfwBlur ? (
                <Flex
                  pos="absolute"
                  top="50%"
                  left="50%"
                  align="center"
                  justify="center"
                  maxW="100%"
                  h="100%"
                  transform="translate(-50%, -50%)"
                  aspectRatio={`100 / ${assetRatio}`}
                  data-group
                >
                  <Box pos="relative" w="100%" pt={`${assetRatio}%`}>
                    <Box pos="absolute" top={0} right={0} bottom={0} left={0}>
                      <ReactCompareSlider
                        style={{ width: "100%", height: "100%" }}
                        itemOne={
                          <Box pos="relative" w="100%" h="100%" bgColor="black">
                            <ChakraImage
                              pos="absolute"
                              top={0}
                              left={0}
                              w="100%"
                              h="100%"
                              objectFit="cover"
                              filter="blur(5px)"
                              src={minResUrl}
                            />

                            <ChakraImage
                              pos="absolute"
                              top={0}
                              left={0}
                              w="100%"
                              h="100%"
                              pointerEvents={"visible !important" as "visible"}
                              objectFit="cover"
                              alt="reference image"
                              fallbackSrc={beforeMinResUrl}
                              src={beforeMaxResUrl}
                            />

                            <Box
                              pos="absolute"
                              top={2}
                              left={2}
                              display="none"
                              _groupHover={{
                                display: "block",
                              }}
                            >
                              <Tag variant="secondary">Before</Tag>
                            </Box>
                          </Box>
                        }
                        itemTwo={
                          <Box pos="relative" w="100%" h="100%" bgColor="black">
                            <ChakraImage
                              pos="absolute"
                              top={0}
                              left={0}
                              w="100%"
                              h="100%"
                              pointerEvents={"visible !important" as "visible"}
                              objectFit="contain"
                              alt="asset image"
                              fallbackSrc={minResUrl}
                              src={maxResUrl}
                            />

                            <Box
                              pos="absolute"
                              top={2}
                              right={2}
                              display="none"
                              _groupHover={{
                                display: "block",
                              }}
                            >
                              <Tag variant="secondary">After</Tag>
                            </Box>
                          </Box>
                        }
                        handle={
                          <Box h="100%" px={5} cursor="col-resize">
                            <Box
                              pos="relative"
                              w="1px"
                              h="100%"
                              bgColor="white"
                            >
                              <Icon
                                id="Ui/ChevronRight"
                                position="absolute"
                                top="50%"
                                left="10px"
                                transform="translateY(-50%)"
                                color="white"
                                opacity={0.8}
                                height="10px"
                              />
                              <Icon
                                id="Ui/ChevronLeft"
                                position="absolute"
                                top="50%"
                                right="10px"
                                transform="translateY(-50%)"
                                color="white"
                                opacity={0.8}
                                height="10px"
                              />
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </Flex>
              ) : (
                <>
                  <Box
                    pos="relative"
                    w="100%"
                    h="100%"
                    filter={hasNsfwBlur ? "blur(50px)" : undefined}
                  >
                    <ChakraImage
                      pos="absolute"
                      top={0}
                      left={0}
                      w="100%"
                      h="100%"
                      pointerEvents={"visible !important" as "visible"}
                      objectFit="contain"
                      alt="asset image"
                      data-testid="asset-zoom-image"
                      fallback={
                        <Center w="100%" h="100%">
                          <Spinner size="xl" />
                        </Center>
                      }
                      fallbackSrc={minResUrl}
                      src={maxResUrl}
                    />
                  </Box>

                  {hasNsfwBlur && (
                    <>
                      <VStack
                        pos="absolute"
                        top="50%"
                        left="50%"
                        textAlign="center"
                        transform="translate(-50%, -50%)"
                        spacing={5}
                      >
                        <Icon id="Ui/EyeClosed" />
                        <VStack spacing={2}>
                          <Text size="title.sm">
                            Potentially Sensitive Content
                          </Text>
                          <Text maxW="30ch" size="body.lg">
                            The content of this image might not be suitable for
                            all audiences because it contains{" "}
                            {assetNsfw.map((item, index) => {
                              return (
                                <>
                                  {!!index &&
                                    index === assetNsfw.length - 1 &&
                                    " and "}
                                  {!!index &&
                                    index < assetNsfw.length - 1 &&
                                    ", "}
                                  <Text key={item} as="span" fontWeight={"600"}>
                                    {(
                                      NSFW_LABELS[item as NsfwType] ?? ""
                                    ).toLowerCase()}
                                  </Text>
                                </>
                              );
                            })}
                            .
                          </Text>
                        </VStack>
                        <Box
                          w="fit-content"
                          h="fit-content"
                          borderRadius="md"
                          bgColor="secondary.900"
                        >
                          <Button
                            variant="secondary"
                            onClick={() => revealAsset(asset.id)}
                          >
                            View image
                          </Button>
                        </Box>
                      </VStack>

                      <Link href="/profile">
                        <Button
                          pos="absolute"
                          top="90%"
                          left="50%"
                          transform="translateX(-50%)"
                          variant="underline"
                          colorScheme="white"
                          size="sm"
                          fontWeight={400}
                        >
                          Disable Safety Filter on Scenario
                        </Button>
                      </Link>
                    </>
                  )}
                </>
              )}
            </Box>
          ) : (
            <Spinner />
          )}
        </Flex>
      </TransformComponent>

      {isHovering && (
        <>
          <Button
            variant="secondaryAlt"
            position="absolute"
            top={0}
            bottom={0}
            margin="auto"
            left={5}
            onClick={() => onSwitchImageClick("previous")}
          >
            <Icon id="Ui/ChevronLeft" />
          </Button>

          <Button
            variant="secondaryAlt"
            position="absolute"
            top={0}
            bottom={0}
            margin="auto"
            right={5}
            onClick={() => onSwitchImageClick("next")}
          >
            <Icon id="Ui/ChevronRight" />
          </Button>

          {!hasNsfwBlur && (
            <AssetZoomBottomButtons
              zoom={{
                zoomIn,
                zoomOut,
                resetTransform,
                currentScale: currentScale || 0,
              }}
              compare={
                canCompare ? { isComparing, onToggleComparing } : undefined
              }
            />
          )}
        </>
      )}
    </Flex>
  );
}

export function AssetZoomBottomButtons({
  zoom,
  compare,
}: {
  zoom?: {
    zoomIn: () => void;
    zoomOut: () => void;
    resetTransform: () => void;
    currentScale: number;
  };
  compare?: {
    isComparing: boolean;
    onToggleComparing: () => void;
  };
}) {
  return (
    <HStack pos="absolute" bottom={5} justify="center" w="100%" spacing={2}>
      {!!zoom && (
        <>
          <Button
            variant="secondaryAlt"
            size="xs"
            onClick={() => zoom.resetTransform()}
            tooltip={
              <Shortcut {...appShortcuts.assetPage.shortcuts.resetZoom} />
            }
          >
            {`${zoom.currentScale}%`}
          </Button>
          <HStack spacing={1}>
            <Button
              variant="secondaryAlt"
              size="xs"
              onClick={() => zoom.zoomOut()}
              tooltip={
                <Shortcut {...appShortcuts.assetPage.shortcuts.zoomOut} />
              }
            >
              <Icon id="Ui/MinusSm" />
            </Button>
            <Button
              variant="secondaryAlt"
              size="xs"
              onClick={() => zoom.zoomIn()}
              tooltip={
                <Shortcut {...appShortcuts.assetPage.shortcuts.zoomIn} />
              }
            >
              <Icon id="Ui/PlusSm" />
            </Button>
          </HStack>
        </>
      )}

      {!!compare && (
        <Button
          variant="secondaryAlt"
          size="xs"
          isActive={compare.isComparing}
          onClick={compare.onToggleComparing}
          tooltip={<Shortcut {...appShortcuts.assetPage.shortcuts.compare} />}
        >
          <Icon id="Ui/Compare" h="10px" />
        </Button>
      )}
    </HStack>
  );
}
