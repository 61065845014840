import { useCallback, useMemo, useState } from "react";
import {
  UPSCALE_DEFAULT_VALUES,
  UPSCALE_PRESETS,
  UpscaleForm,
  UpscalePreset,
  UpscaleStyle,
  UpscaleStyleDefaultValues,
} from "domains/assets/constants/upscale";
import { getImageDimensions } from "domains/commons/utils/getImageDimensions";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import { removeCuFromType } from "infra/api/ExludeCU";
import {
  GetAssetsByAssetIdApiResponse,
  GetJobIdApiResponse,
  PostUpscaleInferencesApiArg,
  useLazyGetAssetsByAssetIdQuery,
  useLazyGetJobIdQuery,
  usePostUpscaleInferencesMutation,
} from "infra/api/generated/api";
import _ from "lodash";

export const UPSCALE_DEFAULT_FORM: UpscaleForm = {
  ...UPSCALE_DEFAULT_VALUES.standard.precise,
  prompt: "",
  negativePrompt: "",
  seed: undefined,
  scalingFactor: 2,
  creativityDecay: 80,
  tileStyle: false,
  styleImages: [],
  styleImagesFidelity: 25,
  overrideEmbeddings: false,
  targetWidth: undefined,
  reference: undefined,
};

// ------------------------------------

export interface UseAssetUpscaleReturnValue {
  form: UpscaleForm;
  setValue: <K extends keyof UpscaleForm>(
    key: K,
    value: UpscaleForm[K]
  ) => void;
  setForm: React.Dispatch<React.SetStateAction<UpscaleForm>>;
  setReference: (
    image: string,
    assetId?: string,
    assetMeta?: GetAssetsByAssetIdApiResponse["asset"]
  ) => void;
  handleUpscaleJob: (props: {
    assetId: string;
    trackingExtraParams: Record<string, unknown>;
  }) => Promise<GetJobIdApiResponse["job"] | undefined>;
  handleUpscaleAsset: (props: {
    assetId: string;
    trackingExtraParams: Record<string, unknown>;
  }) => Promise<GetAssetsByAssetIdApiResponse["asset"] | undefined>;
  isUpscaleLoading?: boolean;
}

export default function useAssetUpscale(
  type: "image" | "texture",
  { onValueChange }: { onValueChange?: (key: string) => void }
): UseAssetUpscaleReturnValue {
  const [isUpscaleLoading, setIsUpscaleLoading] = useState<boolean>(false);
  const [form, setForm] = useState<UpscaleForm>({ ...UPSCALE_DEFAULT_FORM });
  const { selectedProject } = useTeamContext();
  // const { toggleCollapsedSection } = useSectionsContext();
  const [getJobTrigger] = useLazyGetJobIdQuery();
  const [getAssetByAssetId] = useLazyGetAssetsByAssetIdQuery();
  const [triggerImageUpscale] = usePostUpscaleInferencesMutation();
  const { infoToast, successToast, errorToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { showLimitModal } = usePlanContext();

  // ----------------------------------

  const handleUpscale = useCallback(
    async ({
      assetId,
      trackingExtraParams,
    }: {
      assetId: string;
      trackingExtraParams: Record<string, unknown>;
    }) => {
      infoToast({
        title: "Enhancing image",
      });

      try {
        const body = getUpscaleParamsBody({
          assetId,
          form,
          prompt: form.prompt,
          negativePrompt: form.negativePrompt,
          type,
        });

        const upscaleResponse = await triggerImageUpscale({
          projectId: selectedProject.id,
          body,
        })
          .unwrap()
          .then(removeCuFromType);

        Track(AnalyticsEvents.ImageLibrary.UpscaledImage, {
          ...body,
          ...trackingExtraParams,
        });

        return upscaleResponse.job;
      } catch (error: any) {
        handleApiError(error, "Error enhancing image", {
          quota: () => {
            if (_.get(error, "data.details.remainingSeconds")) {
              showLimitModal("planCooldown", {
                timeout: error.data.details.remainingSeconds,
                type: "upscale",
              });
            } else if (
              _.get(error, "data.reason").includes(
                "You have reached your plan's limit."
              )
            ) {
              showLimitModal("notEnoughCreativeUnits");
            } else {
              errorToast({
                title: "Error generating images",
                description: _.get(error, "data.reason"),
              });
            }
          },
        });
      }
    },
    [
      type,
      form,
      selectedProject.id,
      infoToast,
      errorToast,
      handleApiError,
      showLimitModal,
      triggerImageUpscale,
    ]
  );

  const handleUpscaleJob = useCallback(
    async ({
      assetId,
      trackingExtraParams,
    }: {
      assetId: string;
      trackingExtraParams: Record<string, unknown>;
    }) => {
      setIsUpscaleLoading(true);
      const job = await handleUpscale({
        assetId,
        trackingExtraParams,
      });
      setIsUpscaleLoading(false);
      return job;
    },
    [handleUpscale]
  );

  const handleUpscaleAsset = useCallback(
    async ({
      assetId,
      trackingExtraParams,
    }: {
      assetId: string;
      trackingExtraParams: Record<string, unknown>;
    }) => {
      setIsUpscaleLoading(true);
      let job = await handleUpscale({
        assetId,
        trackingExtraParams,
      });

      while (
        job &&
        ["in-progress", "queued", "warming-up"].includes(job.status)
      ) {
        await new Promise((resolve) => setTimeout(resolve, 2_000));
        try {
          const response = await getJobTrigger({
            projectId: selectedProject.id,
            jobId: job.jobId,
          }).unwrap();
          job = response.job;
        } catch (_) {
          job = undefined;
        }
      }

      if (
        !job ||
        job.status === "failure" ||
        job.status === "canceled" ||
        !job.metadata.assetIds?.[0]
      ) {
        errorToast({
          title: "Error enhancing image",
        });
        return;
      }

      const asset = await getAssetByAssetId({
        projectId: selectedProject.id,
        originalAssets: "true",
        assetId: job.metadata.assetIds[0],
      }).unwrap();

      setIsUpscaleLoading(false);

      if (!asset || asset.asset.status === "error") {
        errorToast({
          title: "Error enhancing image",
        });
        return;
      }

      successToast({
        title: "The image has been enhanced",
      });

      return asset.asset;
    },
    [
      errorToast,
      getAssetByAssetId,
      getJobTrigger,
      handleUpscale,
      selectedProject.id,
      successToast,
    ]
  );

  // ----------------------------------

  const setValue = useCallback(
    <K extends keyof UpscaleForm>(key: K, value: UpscaleForm[K]) => {
      if (key === "style" && form.preset !== undefined) {
        setForm((form) => ({
          ...form,
          ...UPSCALE_DEFAULT_VALUES[value as UpscaleStyle][
            form.preset as UpscalePreset
          ],
        }));
      } else if (key === "preset") {
        setForm((form) => ({
          ...form,
          ...UPSCALE_DEFAULT_VALUES[form.style][value as UpscalePreset],
        }));
      } else {
        setForm((form) => {
          const newForm = { ...form, [key]: value };
          const interestingKeys = [
            "promptFidelity",
            "imageFidelity",
            "creativity",
            "fractality",
            "detailsLevel",
          ];
          newForm.preset = undefined;
          for (const preset of UPSCALE_PRESETS) {
            const isDifferent = interestingKeys.some((key) => {
              return (
                newForm[key as keyof UpscaleForm] !==
                UPSCALE_DEFAULT_VALUES[newForm.style][preset][
                  key as keyof UpscaleStyleDefaultValues["precise"]
                ]
              );
            });
            if (!isDifferent) {
              newForm.preset = preset;
              break;
            }
          }
          return newForm;
        });
      }
    },
    [form]
  );

  const setReference = useCallback(
    async (
      image: string,
      assetId?: string,
      assetMeta?: GetAssetsByAssetIdApiResponse["asset"]
    ) => {
      const { width, height } = await (async () => {
        if (assetMeta?.metadata.width && assetMeta?.metadata.height) {
          return {
            width: assetMeta.metadata.width,
            height: assetMeta.metadata.height,
          };
        } else if (image) {
          return await getImageDimensions(image);
        } else {
          return { width: undefined, height: undefined };
        }
      })();

      setForm((form) => ({
        ...form,
        reference: {
          src: image,
          assetId,
          dimensions: width ? { width, height } : undefined,
        },
      }));

      if (assetMeta) {
        setValue("prompt", assetMeta.metadata.prompt ?? "");
        setValue("negativePrompt", assetMeta.metadata.negativePrompt ?? "");
        onValueChange?.("prompt");
        onValueChange?.("negativePrompt");
      }
    },
    [setValue, onValueChange]
  );

  // ----------------------------------

  return useMemo(
    () => ({
      form,
      setForm,
      setValue,
      setReference,
      handleUpscaleJob,
      handleUpscaleAsset,
      isUpscaleLoading,
    }),
    [
      form,
      setForm,
      setValue,
      setReference,
      handleUpscaleJob,
      handleUpscaleAsset,
      isUpscaleLoading,
    ]
  );
}

// ------------------------------------

export function getUpscaleParamsBody({
  assetId,
  form,
  prompt,
  negativePrompt,
  type,
}: {
  assetId: string;
  form: UpscaleForm;
  prompt: string;
  negativePrompt: string;
  type: "image" | "texture";
}): PostUpscaleInferencesApiArg["body"] {
  const styleImagesIds = form.styleImages
    .filter((image) => !!image.assetId)
    .map((image) => image.assetId);

  return {
    image: assetId,
    style: form.style,
    preset: form.preset,
    prompt,
    negativePrompt,
    promptFidelity: form.promptFidelity,
    imageFidelity: form.imageFidelity,
    creativity: form.creativity,
    fractality: form.fractality,
    detailsLevel: form.detailsLevel,
    seed: form.seed ? parseInt(form.seed) : undefined,
    scalingFactor: form.scalingFactor,
    creativityDecay: form.creativityDecay,
    tileStyle: form.tileStyle,
    ...(styleImagesIds.length > 0 && {
      styleImages: styleImagesIds,
      styleImagesFidelity: form.styleImagesFidelity,
    }),
    overrideEmbeddings: form.overrideEmbeddings,
    targetWidth: form.targetWidth,
    imageType: type === "image" ? undefined : type,
  };
}
