import { useCallback, useState } from "react";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  GetAssetsByAssetIdApiResponse,
  useDeleteModelPresetByModelIdAndPresetIdMutation,
  useLazyGetModelPresetsByModelIdQuery,
  usePostModelPresetByModelIdMutation,
} from "infra/api/generated/api";

export default function useManagePresets() {
  const { selectedProject } = useTeamContext();
  const handleApiError = useHandleApiError();

  const [getModelPresets] = useLazyGetModelPresetsByModelIdQuery();
  const [postModelPreset] = usePostModelPresetByModelIdMutation();
  const [deleteModelPreset] =
    useDeleteModelPresetByModelIdAndPresetIdMutation();
  const [isCreatePresetLoading, setIsCreatePresetLoading] = useState(false);
  const [isRemoveAllPresetsLoading, setIsRemoveAllPresetsLoading] =
    useState(false);

  const removeAllPresets = useCallback(
    async (modelId: string, withToast = true) => {
      setIsRemoveAllPresetsLoading(true);
      try {
        const presets = await getModelPresets({
          modelId,
          projectId: selectedProject.id,
        }).unwrap();
        const presetIds = presets.presets
          .filter((preset) => preset.ownerId === selectedProject.id)
          .map((preset) => preset.id);
        await Promise.all(
          presetIds.map((presetId) =>
            deleteModelPreset({
              projectId: selectedProject.id,
              modelId,
              presetId,
            }).unwrap()
          )
        );
      } catch (error) {
        if (withToast) {
          handleApiError(error, "Error removing presets");
        } else {
          throw error;
        }
      } finally {
        setIsRemoveAllPresetsLoading(false);
      }
    },
    [deleteModelPreset, selectedProject.id, getModelPresets, handleApiError]
  );

  const createPreset = useCallback(
    async (asset: GetAssetsByAssetIdApiResponse["asset"]) => {
      const modelId = asset.metadata.modelId;
      const inferenceId = asset.metadata.inferenceId;

      if (!modelId || !inferenceId) {
        return;
      }

      setIsCreatePresetLoading(true);

      Track(AnalyticsEvents.AssetZoom.CreatePreset, {
        modelId,
        inferenceId,
      });

      try {
        await removeAllPresets(modelId, false);

        await postModelPreset({
          modelId,
          projectId: selectedProject.id,
          body: {
            isDefault: true,
            inferenceId,
          },
        }).unwrap();
      } catch (error) {
        handleApiError(error, "Error creating preset");
      } finally {
        setIsCreatePresetLoading(false);
      }
    },
    [handleApiError, postModelPreset, removeAllPresets, selectedProject.id]
  );

  return {
    createPreset,
    isCreatePresetLoading,
    removeAllPresets,
    isRemoveAllPresetsLoading,
  };
}
