import React, { useCallback, useEffect } from "react";
import { FileType } from "domains/file-manager/interfaces";
import { keyBy } from "lodash";

export function useSelection(
  files: FileType[],
  { max }: { max?: number } = {}
) {
  const [selection, setSelection] = React.useState<Set<string>>(new Set());
  const [lastSelected, setLastSelected] = React.useState<string | null>(null);

  // When files are updated, we want to remove from selection all files that might not exists anymore
  useEffect(() => {
    let hasChanged = false;
    const newSelected = new Set(selection);
    const filesById = keyBy(files, "id");
    newSelected.forEach((id) => {
      if (!filesById[id]) {
        hasChanged = true;
        newSelected.delete(id);
      }
    });
    if (hasChanged) {
      setSelection(newSelected);
    }
  }, [selection, files]);

  const handleSelectAll = () => {
    setSelection((selection) => {
      const newSelected = new Set(selection);
      for (const file of files) {
        if (max && newSelected.size >= max) {
          break;
        } else {
          newSelected.add(file.id);
        }
      }
      setLastSelected(null);
      return newSelected;
    });
  };

  const handleClearSelection = () => {
    setSelection(new Set());
    setLastSelected(null);
  };

  const handleSelectRange = useCallback(
    (from: string, to: string) => {
      setSelection((selection) => {
        const newSelected = new Set(selection);
        const fromIndex = files.findIndex((file) => file.id === from);
        const toIndex = files.findIndex((file) => file.id === to);

        // Shouldn't happen
        if (toIndex === -1 || fromIndex === -1) return selection;

        const [startIndex, endIndex] =
          fromIndex < toIndex ? [fromIndex, toIndex] : [toIndex, fromIndex];
        for (let i = startIndex; i <= endIndex; i++) {
          if (max && newSelected.size >= max) {
            break;
          } else {
            newSelected.add(files[i].id);
          }
        }

        return newSelected;
      });

      setLastSelected(to);
    },
    [files, max]
  );

  const handleSelect = useCallback(
    (id: string, { shiftPressed }: { shiftPressed: boolean }) => {
      if (!shiftPressed || !lastSelected) {
        setSelection((selection) => {
          const newSelected = new Set(selection);
          if (selection.has(id)) {
            newSelected.delete(id);
            setLastSelected(null);
          } else {
            newSelected.add(id);
            setLastSelected(id);
          }

          return newSelected;
        });
      } else {
        handleSelectRange(lastSelected, id);
      }
    },
    [handleSelectRange, lastSelected]
  );

  return {
    selection,
    setSelection,
    handleSelect,
    handleSelectAll,
    handleClearSelection,
    handleSelectRange,
    lastSelected,
  };
}
