import HeaderFilterAuthor, {
  HeaderFilterAuthorProps,
} from "domains/file-manager/components/FileManagerImage/HeaderFilterAuthor";
import HeaderFilterDate, {
  HeaderFilterDateProps,
} from "domains/file-manager/components/FileManagerImage/HeaderFilterDate";
import {
  FilterAssetCollectionOption,
  FilterAssetCollectionValue,
  FilterAssetType,
  FilterAssetTypeKey,
} from "domains/file-manager/constants/AssetFilter";
import { GridSortValue } from "domains/file-manager/constants/GridSort";
import { GridViewKey } from "domains/file-manager/constants/GridView";
import { TOP_BAR_HEIGHT } from "domains/navigation/constants/TopBar";

import { Box, BoxProps, HStack, Text } from "@chakra-ui/react";

import FilterAssetCollectionDropdown from "./FilterAssetCollectionDropdown";
import FilterAssetTypeDropdown from "./FilterAssetTypeDropdown";
import GridColumnNumberInput from "./GridColumnNumberInput";
import GridSortDropdown from "./GridSortDropdown";
import GridViewDropdown from "./GridViewDropdown";

export interface FileManagerTopBarFilterAsset {
  filterAssetTypes?: FilterAssetTypeKey[];
  filterAssetTypeOptions?: {
    [key in FilterAssetTypeKey]: FilterAssetType;
  };
  onFilterAssetTypeChange?: (value: FilterAssetTypeKey[] | undefined) => void;
}

export interface FileManagerTopBarProps extends FileManagerTopBarFilterAsset {
  styleProps?: BoxProps;

  numberOfColumns: number;
  onNumberOfColumnsChange: (numberOfColumns: number) => void;
  canChangeNumberOfColumns?: boolean;

  gridView: Exclude<GridViewKey, "jobs">;
  onGridViewChange: (gridView: Exclude<GridViewKey, "jobs">) => void;
  canChangeView?: boolean;

  sort?: GridSortValue;
  onSortChange?: (value: GridSortValue) => void;
  sortOptions?: GridSortValue[];

  filterAssetCollection?: FilterAssetCollectionValue;
  onFilterAssetCollectionChange?: (
    value: FilterAssetCollectionValue | undefined
  ) => void;
  filterAssetCollectionOptions?: FilterAssetCollectionOption[];
  isFilterAssetCollectionLoading?: boolean;

  headerFilterDateProps?: HeaderFilterDateProps;
  headerFilterAuthorProps?: HeaderFilterAuthorProps;

  title?: string;

  leftContent?: React.ReactNode;
}

function FileManagerTopBar({
  styleProps,

  numberOfColumns,
  onNumberOfColumnsChange,
  canChangeNumberOfColumns,

  gridView,
  onGridViewChange,
  canChangeView,

  sort,
  onSortChange,
  sortOptions,

  filterAssetTypes,
  filterAssetTypeOptions,
  onFilterAssetTypeChange,

  filterAssetCollection,
  onFilterAssetCollectionChange,
  filterAssetCollectionOptions,
  isFilterAssetCollectionLoading,

  headerFilterDateProps,
  headerFilterAuthorProps,

  title,

  leftContent,
}: FileManagerTopBarProps) {
  return (
    <HStack
      pos="sticky"
      zIndex="sticky"
      top={`${TOP_BAR_HEIGHT}px`}
      align="center"
      justify="flex-start"
      w="100%"
      py={2}
      bg="background.500"
      spacing={2}
      {...styleProps}
    >
      {title ? <Text size="body.bold.lg">{title}</Text> : leftContent}

      {filterAssetTypeOptions && onFilterAssetTypeChange && (
        <FilterAssetTypeDropdown
          values={filterAssetTypes ?? []}
          options={filterAssetTypeOptions}
          onChange={onFilterAssetTypeChange}
        />
      )}

      {!!headerFilterAuthorProps && (
        <HeaderFilterAuthor {...headerFilterAuthorProps} />
      )}

      {!!headerFilterDateProps && (
        <HeaderFilterDate {...headerFilterDateProps} />
      )}

      {filterAssetCollectionOptions && onFilterAssetCollectionChange && (
        <FilterAssetCollectionDropdown
          value={filterAssetCollection}
          options={filterAssetCollectionOptions}
          isLoading={isFilterAssetCollectionLoading}
          onChange={onFilterAssetCollectionChange}
        />
      )}

      <Box flex={1} />

      {canChangeNumberOfColumns && (
        <GridColumnNumberInput
          value={numberOfColumns}
          onChange={onNumberOfColumnsChange}
        />
      )}
      {sortOptions && sortOptions.length > 0 && onSortChange && (
        <GridSortDropdown
          value={sort ?? sortOptions[0]}
          options={sortOptions}
          onChange={onSortChange}
        />
      )}
      {canChangeView && (
        <GridViewDropdown value={gridView} onChange={onGridViewChange} />
      )}
    </HStack>
  );
}

export default FileManagerTopBar;
