import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import BackgroundTaskIcon from "domains/background-tasks/components/ElementBackgroundTaskIcon";
import { useBackgroundTaskContext } from "domains/background-tasks/contexts/BackgroundTaskProvider";
import { BackgroundTask } from "domains/background-tasks/interfaces/BackgroundTask";
import { blinkAnimation } from "domains/commons/style";
import NewsIndicator from "domains/navigation/components/TopBar/NewsIndicator";
import Button, { ButtonProps, IconButton } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import {
  Box,
  Center,
  CircularProgress,
  Divider,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";

export default function BackgroundTasksDropdown() {
  const { recentTasks, isLoading, clearTask } = useBackgroundTaskContext();
  const [lastOpenTimestamp, setLastOpenTimestamp] = useState<number>(
    Date.now()
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setLastOpenTimestamp(Date.now());
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setLastOpenTimestamp(Date.now());
    setIsOpen(false);
  }, []);

  const shouldShowNewsIndicator = useMemo(() => {
    if (isOpen || isLoading) {
      return false;
    }
    return recentTasks.some(
      (task) =>
        task.updatedAt &&
        new Date(task.updatedAt).getTime() > lastOpenTimestamp &&
        task.status !== "loading"
    );
  }, [lastOpenTimestamp, recentTasks, isOpen, isLoading]);

  const sections = useMemo(() => {
    const newSections: {
      id: string;
      title: string;
      tasks: BackgroundTask[];
    }[] = [
      { id: "model", title: "Model", tasks: [] },
      { id: "download", title: "Download", tasks: [] },
      { id: "generation", title: "Generations", tasks: [] },
    ];

    recentTasks.forEach((task) => {
      const sectionId =
        {
          modelTraining: "model",
          modelUpload: "model",
          modelDownload: "download",
          assetsDownload: "download",
        }[task.type as string] ?? "generation";
      const sectionIdx = newSections.findIndex(
        (section) => section.id === sectionId
      );
      newSections[sectionIdx].tasks.push(task);
    });

    return newSections.filter((section) => !!section.tasks.length);
  }, [recentTasks]);

  return (
    <Popover
      onClose={handleClose}
      onOpen={handleOpen}
      openDelay={0}
      placement="bottom-end"
      trigger="hover"
      variant="blurred"
    >
      <PopoverTrigger>
        <Button w="36px" h="36px" p={0} borderRadius="md" variant="alpha">
          {shouldShowNewsIndicator && <NewsIndicator />}
          <Icon
            id="Ui/BackgroundTasks"
            animation={
              isLoading ? `${blinkAnimation} 1s linear infinite` : undefined
            }
            h="12px"
            color={isLoading ? "textPrimary" : undefined}
          />
        </Button>
      </PopoverTrigger>

      <PopoverContent>
        <PopoverBody px={4} py={0}>
          <Box w="100%">
            <Box py={3}>
              <Text flex={1} color="textPrimary" size="body.bold.md">
                Recent Tasks
              </Text>
            </Box>

            <Divider borderColor="borderAlpha.500" />

            <VStack align="stretch" py={3} spacing={3}>
              {sections.map((section) => (
                <TaskSection key={section.id} title={section.title}>
                  {section.tasks.map((task) => (
                    <TaskItem
                      key={task.id}
                      taskId={task.id}
                      type={task.type}
                      label={task.label}
                      status={task.status}
                      internalLink={task.internalLink}
                      externalLink={task.externalLink}
                      progress={task.progress}
                      errorMessage={task.errorMessage}
                      onClear={clearTask}
                    />
                  ))}
                </TaskSection>
              ))}

              {!sections.length && (
                <Text py={2} color="textSecondary" size="body.sm">
                  No recent tasks
                </Text>
              )}
            </VStack>

            <Divider borderColor="borderAlpha.500" />

            <Box py={3}>
              <Button
                variant="link"
                colorScheme="textPrimary"
                size="sm"
                rightIcon={<Icon id="Ui/Link" h="12px" />}
                internalLink="/recent-tasks"
                target="_blank"
                onClick={() => close()}
              >
                View All
              </Button>
            </Box>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

// ------------------------------------

interface TaskSectionProps extends PropsWithChildren {
  title: React.ReactNode;
}

function TaskSection({ title, children }: TaskSectionProps) {
  return (
    <VStack align="stretch" spacing={1}>
      <Text color="textSecondary" size="body.md">
        {title}
      </Text>
      <VStack align="stretch" spacing={0}>
        {children}
      </VStack>
    </VStack>
  );
}

// ------------------------------------

interface TaskItemProps {
  taskId: string;
  type: BackgroundTask["type"];
  label: string;
  status: BackgroundTask["status"];
  internalLink?: ButtonProps["internalLink"];
  externalLink?: ButtonProps["externalLink"];
  progress?: number;
  errorMessage?: string;
  onClear?: (id: string) => void;
}

function TaskItem({
  taskId,
  type,
  label,
  status,
  internalLink,
  externalLink,
  progress,
  errorMessage,
  onClear,
}: TaskItemProps) {
  const isDownload = ["modelDownload", "assetsDownload"].includes(type);

  return (
    <Tooltip
      hasArrow
      isOpen={status === "failed" ? undefined : false}
      label={errorMessage}
      placement="left"
    >
      <HStack
        h="36px"
        _hover={status === "failed" ? { bgColor: "whiteAlpha.50" } : {}}
        data-group
        spacing={3}
      >
        <Center w="20px">
          <BackgroundTaskIcon type={type} />
        </Center>

        <Text flex={1} color="textSecondary" isTruncated size="body.md">
          {label}
        </Text>

        {!!(onClear || internalLink || externalLink) && (
          <HStack display="none" _groupHover={{ display: "flex" }}>
            {(internalLink || externalLink) && (
              <Tooltip
                hasArrow
                label={isDownload ? "Download" : "View task"}
                placement="top"
              >
                <IconButton
                  mx={-1}
                  aria-label={isDownload ? "Download" : "View task"}
                  colorScheme="white"
                  internalLink={internalLink}
                  externalLink={externalLink}
                  target="_blank"
                  size="xs"
                  variant="ghost"
                >
                  <Icon id="Ui/Link" h="12px" />
                </IconButton>
              </Tooltip>
            )}

            {!!onClear && (
              <Tooltip hasArrow label="Clear" placement="top">
                <IconButton
                  mx={-1}
                  aria-label="Clear"
                  colorScheme="white"
                  onClick={() => onClear(taskId)}
                  size="xs"
                  variant="ghost"
                >
                  <Icon id="Ui/Cross" h="10px" />
                </IconButton>
              </Tooltip>
            )}
          </HStack>
        )}

        <Box _groupHover={{ display: "none" }}>
          {(() => {
            if (status === "succeeded") {
              return <Icon id="Toast/Success" h="18px" color="success.600" />;
            } else if (status === "failed") {
              return <Icon id="Toast/Error" h="18px" color="danger.500" />;
            } else if (status === "loading") {
              return (
                <CircularProgress
                  color="textPrimary"
                  animation={
                    progress
                      ? `${blinkAnimation} 1s linear infinite`
                      : undefined
                  }
                  capIsRound
                  isIndeterminate={progress === undefined}
                  size="20px"
                  thickness="15px"
                  trackColor="whiteAlpha.200"
                  value={progress}
                />
              );
            }
          })()}
        </Box>
      </HStack>
    </Tooltip>
  );
}
