import { StringArrayToType } from "domains/commons/ts-utils";

export const NSFW_TYPES = [
  "alcohol",
  "drugs",
  "explicit_nudity",
  "explicit",
  "gambling",
  "hate_symbols",
  "rude_gestures",
  "suggestive",
  "non_explicit",
  "swimwear_underwear",
  "tobacco",
  "violence",
  "visually_disturbing",
] as const;

export type NsfwType = StringArrayToType<typeof NSFW_TYPES>;

export const DEPRECATED_NSFW_TYPES: NsfwType[] = [
  "explicit_nudity",
  "suggestive",
];

export const NSFW_TYPES_WITHOUT_DEPRECATED = NSFW_TYPES.filter(
  (item) => !DEPRECATED_NSFW_TYPES.includes(item)
);

export const NSFW_LABELS: { [key in NsfwType]: string } = {
  alcohol: "Alcohol",
  drugs: "Drugs",
  explicit_nudity: "Explicit Nudity",
  explicit: "Explicit Imagery",
  gambling: "Gambling",
  hate_symbols: "Hate Symbols",
  rude_gestures: "Offensive Content",
  suggestive: "Suggestive Imagery",
  non_explicit: "Non-Explicit Imagery",
  swimwear_underwear: "Swimwear/Underwear",
  tobacco: "Tobacco",
  violence: "Violence",
  visually_disturbing: "Disturbing Imagery",
};
