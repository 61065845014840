import { useAssetParents } from "domains/assets/hooks/useAssetParent";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
  useGetAssetsByAssetIdQuery,
} from "infra/api/generated/api";

import { skipToken } from "@reduxjs/toolkit/dist/query";

export interface UseAssetZoomCompareBeforeArgs {
  inference:
    | GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
    | undefined;
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}

export default function useAssetZoomCompareBefore({
  inference,
  asset,
}: UseAssetZoomCompareBeforeArgs) {
  const { selectedProject } = useTeamContext();

  const referenceParents = useAssetParents({
    type: "reference",
    asset,
  });
  const controlParents = useAssetParents({
    type: "control",
    parentAssetId: inference?.parameters.controlImageId,
  });
  const { currentData: ipAdapterAsset } = useGetAssetsByAssetIdQuery(
    inference?.parameters.ipAdapterImageId ??
      inference?.parameters.ipAdapterImageIds?.[0]
      ? {
          assetId:
            inference.parameters.ipAdapterImageId ??
            inference.parameters.ipAdapterImageIds![0],
          projectId: selectedProject.id,
        }
      : skipToken
  );

  const mapTypeToBeforeImage: {
    [key in GetAssetsByAssetIdApiResponse["asset"]["metadata"]["type"]]:
      | GetAssetsByAssetIdApiResponse["asset"]
      | undefined;
  } = {
    "background-removal": referenceParents?.image,
    canvas: undefined,
    detection: referenceParents?.image,
    "inference-controlnet": referenceParents?.image,
    "inference-controlnet-img2img": controlParents?.image,
    "inference-controlnet-inpaint": controlParents?.image,
    "inference-controlnet-inpaint-ip-adapter": controlParents?.image,
    "inference-controlnet-ip-adapter": referenceParents?.image,
    "inference-controlnet-reference": controlParents?.image,
    "inference-img2img": referenceParents?.image,
    "inference-img2img-ip-adapter": referenceParents?.image,
    "inference-img2img-texture": undefined,
    "inference-inpaint": referenceParents?.image,
    "inference-inpaint-ip-adapter": referenceParents?.image,
    "inference-reference": referenceParents?.image,
    "inference-reference-texture": undefined,
    "inference-txt2img": undefined,
    "inference-txt2img-ip-adapter": ipAdapterAsset?.asset,
    "inference-txt2img-texture": undefined,
    "inference-controlnet-texture": undefined,
    pixelization: referenceParents?.image,
    "segmentation-image": referenceParents?.image,
    "segmentation-mask": undefined,
    uploaded: undefined,
    upscale: referenceParents?.image,
    vectorization: referenceParents?.image,
    restyle: referenceParents?.image,

    "skybox-base-360": referenceParents?.image,
    "upscale-skybox": referenceParents?.image,

    texture: undefined,
    "texture-albedo": undefined,
    "texture-ao": undefined,
    "texture-edge": undefined,
    "texture-height": undefined,
    "texture-metallic": undefined,
    "texture-normal": undefined,
    "texture-smoothness": undefined,
    "upscale-texture": referenceParents?.image,

    patch: undefined,
    segment: undefined,
    "canvas-drawing": undefined,
    "canvas-export": undefined,
    "generative-fill": undefined,
    reframe: referenceParents?.image,
  };

  return asset ? mapTypeToBeforeImage[asset.metadata.type] : undefined;
}
