import { useMemo } from "react";
import { ASSET_TYPE_LABELS } from "domains/assets/constants/assetTypes";
import { assetTypeToAssetWithIpAdapterType } from "domains/assets/utils/assetTypeToAssetWithIpAdapterType";
import { formatFileSize } from "domains/commons/misc";
import { getModelThumbnail } from "domains/models/utils";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  AsideCard,
  AsideList,
  AsideListItem,
  AsideSection,
  AsideTitledData,
  AsideTitledText,
} from "domains/ui/components/Aside";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
} from "infra/api/generated/api";
import moment from "moment";

export interface AsideSectionTopProps {
  model: GetModelsByModelIdApiResponse["model"] | undefined;
  inference:
    | GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
    | undefined;
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}

export default function AsideSectionTop({
  model,
  inference,
  asset,
}: AsideSectionTopProps) {
  const isInference = asset?.metadata.type.includes("inference");
  const { selectedTeamDetails } = useTeamContext();

  const author = useMemo(() => {
    if (!asset?.authorId) {
      return undefined;
    }

    const teamMember = selectedTeamDetails?.users.find(
      (user) => user.id === asset.authorId
    );
    if (teamMember) {
      return teamMember.email;
    }

    const teamApiKey = selectedTeamDetails?.keys.find(
      (key) => key.id === asset.authorId
    );
    if (teamApiKey) {
      return `API Key - ${teamApiKey.name}`;
    }

    return asset.authorId;
  }, [asset?.authorId, selectedTeamDetails?.keys, selectedTeamDetails?.users]);

  return (
    <AsideSection id="top">
      {!!model && (
        <AsideTitledData title="Model">
          <AsideCard
            data-testid="asset-zoom-model-card"
            title={model.name}
            avatarUrl={
              getModelThumbnail(model, {
                width: 64,
              }).url
            }
            internalLink={{
              pathname: "/models/[id]",
              query: {
                id: model.id,
              },
            }}
            target="_blank"
          />
        </AsideTitledData>
      )}

      <AsideTitledText
        title="Prompt"
        value={asset?.metadata.prompt ?? "-"}
        copyable={!!asset?.metadata.prompt}
      />

      {asset?.metadata.negativePrompt && (
        <AsideTitledText
          title="Negative Prompt"
          value={asset.metadata.negativePrompt}
          copyable
        />
      )}

      <AsideTitledData title="Details">
        <AsideList>
          <AsideListItem
            label="Type"
            value={
              asset && (!isInference || inference)
                ? ASSET_TYPE_LABELS[
                    assetTypeToAssetWithIpAdapterType({
                      assetType: asset.metadata.type,
                      ipAdapterType: inference?.parameters.ipAdapterType,
                    })
                  ]
                : "-"
            }
          />
          {asset?.metadata.type !== "texture" && (
            <AsideListItem
              label="Dimensions"
              value={
                asset?.metadata.width && asset?.metadata.height
                  ? `${asset.metadata.width} x ${asset.metadata.height} px`
                  : "-"
              }
            />
          )}

          {asset?.metadata.type === "vectorization" && (
            <AsideListItem
              label="Size"
              value={
                asset?.metadata.size &&
                formatFileSize(asset.metadata.size, true, 1)
              }
            />
          )}

          <AsideListItem
            label="Created"
            value={moment(asset?.createdAt).format("MMM DD, YYYY, LT")}
          />

          {asset?.privacy === "private" && (
            <AsideListItem label="Author" value={author} copyable isTruncated />
          )}

          {asset?.metadata.seed && (
            <AsideListItem label="Seed" value={asset?.metadata.seed} copyable />
          )}

          <AsideListItem
            label="Image ID"
            value={asset?.id}
            copyable
            isTruncated
          />
        </AsideList>
      </AsideTitledData>
    </AsideSection>
  );
}
