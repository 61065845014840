import { FileImageType } from "../interfaces";
import { FmFileImage } from "../interfacesV2";

export function mapFmFileImagesToFileImages(
  files: FmFileImage[]
): FileImageType[] {
  return files.map((file) => ({
    ...file,
    type: "image",
    name: file.meta.metadata.prompt ?? "",
  }));
}
