import React, { forwardRef, useCallback } from "react";
import Button, { ButtonProps } from "domains/ui/components/Button";
import DecisionModal, {
  DecisionModalProps,
} from "domains/ui/components/DecisionModal";

import { useDisclosure } from "@chakra-ui/react";

export interface ButtonWithModalProps extends Omit<ButtonProps, "onClick"> {
  onConfirm: () => void | Promise<void>;
  onOpen?: () => void;
  onClose?: () => void;
  modalBody: React.ReactNode | string;
  modalHeader?: React.ReactNode | string;
  modalConfirmMessage?: React.ReactNode | string;
  modalShowCancelButton?: boolean;
  modalCancelMessage?: React.ReactNode | string;
  modalColorScheme?: DecisionModalProps["colorScheme"];
  modalContentProps?: DecisionModalProps["contentProps"];
  isModalConfirmButtonDisabled?: boolean;
  isModalConfirmButtonLoading?: boolean;
  isModalConfirmAsync?: boolean;
}

const ButtonWithModal = forwardRef(function ButtonWithModal(
  {
    onConfirm,
    onOpen,
    onClose,
    modalBody,
    modalHeader,
    modalConfirmMessage,
    modalShowCancelButton,
    modalCancelMessage,
    modalColorScheme,
    modalContentProps,
    isModalConfirmButtonDisabled,
    isModalConfirmButtonLoading,
    isModalConfirmAsync,
    ...props
  }: ButtonWithModalProps,
  ref
) {
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const handleOnDialogOpen = useCallback(() => {
    if (onOpen) onOpen();
    onModalOpen();
  }, [onModalOpen, onOpen]);

  const handleOnDialogClose = useCallback(() => {
    if (onClose) onClose();
    onModalClose();
  }, [onClose, onModalClose]);

  const handleConfirmClick = useCallback(async () => {
    if (isModalConfirmAsync) void onConfirm();
    else await onConfirm();
    onModalClose();
  }, [isModalConfirmAsync, onConfirm, onModalClose]);

  return (
    <>
      <Button ref={ref} {...props} onClick={handleOnDialogOpen} />

      <DecisionModal
        colorScheme={modalColorScheme}
        body={modalBody}
        onConfirm={handleConfirmClick}
        isOpen={isModalOpen}
        onClose={handleOnDialogClose}
        headerMessage={modalHeader}
        confirmMessage={modalConfirmMessage}
        showCancelButton={modalShowCancelButton}
        cancelMessage={modalCancelMessage}
        isConfirmButtonDisabled={isModalConfirmButtonDisabled}
        isConfirmButtonLoading={isModalConfirmButtonLoading}
        contentProps={modalContentProps}
      />
    </>
  );
});

export default ButtonWithModal;
