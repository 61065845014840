import React, { useCallback } from "react";
import CollectionMenuList from "domains/collections/components/CollectionMenuList";
import useAllCollections from "domains/collections/hooks/useAllCollections";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { chunkQuery } from "infra/api/chunkQuery";
import { useHandleApiError } from "infra/api/error";
import {
  GetAssetsByAssetIdApiResponse,
  useDeleteAssetsByCollectionIdMutation,
  usePutAssetsByCollectionIdMutation,
} from "infra/api/generated/api";
import _ from "lodash";

import { PlacementWithLogical } from "@chakra-ui/popper/dist/popper.placement";
export interface ButtonAddAssetToCollectionProps {
  assets: GetAssetsByAssetIdApiResponse["asset"][];
  menuPlacement: PlacementWithLogical;
  isDisabled?: boolean;
  assetType?: string;
}

export default function ButtonAddAssetToCollection({
  assets,
  menuPlacement,
  isDisabled,
  assetType,
}: ButtonAddAssetToCollectionProps) {
  const handleApiError = useHandleApiError();
  const { successToast } = useScenarioToast();
  const { selectedProject } = useTeamContext();
  const { collections, isLoading: isLoadingCollections } = useAllCollections();

  const isInCollection = useCallback(
    (collectionId: string) => {
      if (!assets.length) {
        return false;
      }
      return !assets.some(
        (asset) => !asset.collectionIds.includes(collectionId)
      );
    },
    [assets]
  );

  const [addAssetToCollectionTrigger] = usePutAssetsByCollectionIdMutation();
  const [removeAssetFromCollectionTrigger] =
    useDeleteAssetsByCollectionIdMutation();
  const toggleAssetsFromCollection = useCallback(
    async (collectionId: string) => {
      const nameOfAsset = assetType ?? "image";
      const add = !isInCollection(collectionId);
      const assetIds = assets
        .filter((asset) => add !== asset.collectionIds.includes(collectionId))
        .map((asset) => asset.id);
      const chunkedTrigger = add
        ? chunkQuery(addAssetToCollectionTrigger, "body.assetIds", 49)
        : chunkQuery(removeAssetFromCollectionTrigger, "body.assetIds", 49);
      try {
        await chunkedTrigger({
          projectId: selectedProject.id,
          collectionId,
          body: {
            assetIds,
          },
        }).unwrap();
        successToast({
          title: `${_.capitalize(nameOfAsset)}${
            assets.length > 1 ? (nameOfAsset === "skybox" ? "es" : "s") : ""
          } ${add ? "added to" : "removed from"} collection`,
        });
      } catch (error: any) {
        handleApiError(
          {
            ...error,
            data: {
              ...error.data,
              reason: error.data?.reason.replace("asset", "image"),
            },
          },
          `Error ${add ? "adding" : "removing"} ${nameOfAsset}${
            assets.length > 1 ? (nameOfAsset === "skybox" ? "es" : "s") : ""
          } ${add ? "to" : "from"} collection`
        );
      }
    },
    [
      assetType,
      isInCollection,
      assets,
      addAssetToCollectionTrigger,
      removeAssetFromCollectionTrigger,
      selectedProject.id,
      handleApiError,
      successToast,
    ]
  );

  return (
    <CollectionMenuList
      collections={collections}
      isLoadingCollections={isLoadingCollections}
      toggleFromCollection={toggleAssetsFromCollection}
      isInCollection={isInCollection}
      placement={menuPlacement}
      isDisabled={isDisabled}
    />
  );
}
