import React from "react";
import { GuideKeys } from "domains/guide/constants/guides";
import SectionPrompt from "domains/inference/components/InferenceGenerator/Sidebar/SectionPrompt";

export interface SidebarSectionNegativePromptProps {
  id: string;
  compact?: boolean;
  prompt: string;
  setPrompt: (prompt: string) => void;
  guide?: GuideKeys;
  loading?: boolean;
}

export default function SidebarSectionNegativePrompt({
  id,
  compact: isCompact,
  prompt,
  setPrompt,
  guide,
  loading: isLoading,
}: SidebarSectionNegativePromptProps) {
  return (
    <SectionPrompt
      title="Negative Prompt"
      key={isLoading ? "negativePromptLoading" : "negativePrompt"}
      id={id}
      prompt={prompt}
      setPrompt={setPrompt}
      guide={guide}
      placeholder="Exclude elements (objects, colors, characters...)"
      compact={isCompact}
      withRandom={false}
      withMagic={false}
      withAssetUpload={false}
      withShortcuts={false}
    />
  );
}
