import { useAssetReloadParametersLink } from "domains/assets/hooks/useAssetLink";
import { IconButton } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import {
  GetAssetsByAssetIdApiResponse,
  GetJobIdApiResponse,
} from "infra/api/generated/api";

interface ReuseButtonProps {
  job: GetJobIdApiResponse["job"] | undefined;
  firstAsset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}

export default function ReuseButton({ job, firstAsset }: ReuseButtonProps) {
  const linkReloadAllParameters = useAssetReloadParametersLink(firstAsset);
  const isDisplayed =
    ["success", "failure"].includes(job?.status ?? "") &&
    !!linkReloadAllParameters;

  if (!isDisplayed) return null;

  return (
    <IconButton
      aria-label="Reload Parameters"
      icon={<Icon id="Ui/RefreshAlt" h="12px" />}
      size="sm"
      variant="secondaryV2"
      internalLink={linkReloadAllParameters}
      tooltip="Reload Parameters"
    />
  );
}
