import { useMemo } from "react";
import Link from "next/link";
import useAllAssets from "domains/assets/hooks/useAllAssets";
import useAssetFilterType from "domains/assets/hooks/useAssetFilterType";
import { getShouldHaveNsfwBlur } from "domains/file-manager/utils/getShouldHaveNsfwBlur";
import useRouter from "domains/navigation/hooks/useRouter";
import { AsideSection } from "domains/ui/components/Aside";
import Button from "domains/ui/components/Button";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

import { Box, Image, SimpleGrid, VStack } from "@chakra-ui/react";

export interface AsideSectionDerivedProps {
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}

export default function AsideSectionDerived({
  asset,
}: AsideSectionDerivedProps) {
  const router = useRouter();
  const { nsfwFilteredTypes } = useUserContext();
  const { allAssetsTypeArgs } = useAssetFilterType({
    assetType: "all",
  });
  const { files: assets } = useAllAssets(
    asset
      ? {
          ...allAssetsTypeArgs,
          parent: asset,
        }
      : undefined
  );

  const derivedAssets = useMemo(
    () =>
      assets
        .slice(0, 9)
        .filter(
          (derivedAsset) =>
            asset?.id && derivedAsset.meta.metadata.parentId === asset?.id
        ),
    [assets, asset?.id]
  );

  if (!asset || derivedAssets.length === 0) return null;

  return (
    <AsideSection
      id="derivedImages"
      title={asset.metadata.type === "texture" ? "Texture Maps" : "Derived"}
    >
      <VStack align="stretch" spacing={2}>
        <SimpleGrid gap={2} columns={3}>
          {derivedAssets.map((derivedAsset) => (
            <Box key={derivedAsset.id}>
              <Link
                style={{
                  width: "100%",
                }}
                href={{
                  pathname: router.pathname,
                  query: {
                    ...router.query,
                    openAssetId: derivedAsset.id,
                  },
                }}
              >
                <Box
                  pos="relative"
                  overflow="hidden"
                  w="100%"
                  pt="100%"
                  borderRadius="md"
                >
                  <Image
                    pos="absolute"
                    top={0}
                    left={0}
                    w="100%"
                    h="100%"
                    objectFit="cover"
                    alt="derived image"
                    fallback={
                      <Box
                        pos="absolute"
                        top={0}
                        left={0}
                        w="100%"
                        h="100%"
                        borderRadius="md"
                        bgColor="background.500"
                      />
                    }
                    filter={
                      getShouldHaveNsfwBlur({
                        nsfwFilteredTypes,
                        nsfw: derivedAsset.meta.nsfw,
                      })
                        ? "blur(20px)"
                        : undefined
                    }
                    src={derivedAsset.thumbnail + "&height=128"}
                  />
                </Box>
              </Link>
            </Box>
          ))}
        </SimpleGrid>

        <Button
          variant="secondary"
          size="sm"
          internalLink={{
            pathname: "/images/[id]/children",
            query: {
              id: asset.id,
            },
          }}
        >
          View All
        </Button>
      </VStack>
    </AsideSection>
  );
}
