import { useEffect, useState } from "react";
import { IconButton } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import Cropper, { ReactCropperElement } from "react-cropper";

import { Box, Tooltip } from "@chakra-ui/react";

import "cropperjs/dist/cropper.css";

interface ImageCropperProps {
  imageData: string;
  cropMetadata?: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
  isFreeAspectRatioAllowed?: boolean;
  cropperRef: React.RefObject<ReactCropperElement>;
}

export default function ImageCropper({
  imageData,
  cropMetadata,
  isFreeAspectRatioAllowed,
  cropperRef,
}: ImageCropperProps) {
  const [isSquareRatio, setIsSquareRatio] = useState<boolean>(false);

  const width = "min(calc(80vw - 50px), 500px)"; // 50px for horizontal padding
  const height = "min(calc(80vh - 150px), 500px)"; // 150px for vertical padding and header and footer

  useEffect(() => {
    const cropper = cropperRef.current?.cropper;
    if (!cropper || !isFreeAspectRatioAllowed) return;
    if (isSquareRatio) {
      const cropBox = cropper.getCropBoxData();
      cropper.setAspectRatio(1);
      const newSize = Math.max(cropBox.width, cropBox.height);
      const newLeft = cropBox.left + (cropBox.width - newSize) / 2;
      const newTop = cropBox.top + (cropBox.height - newSize) / 2;
      cropper.setCropBoxData({
        left: newLeft,
        top: newTop,
        width: newSize,
        height: newSize,
      });
    } else {
      const cropBox = cropper.getCropBoxData();
      cropper.setAspectRatio(NaN);
      cropper.setCropBoxData(cropBox);
    }
  }, [isSquareRatio, isFreeAspectRatioAllowed, cropperRef]);

  return (
    <Box
      sx={{
        ".cropper-bg": {
          bg: "backgroundTertiary.500",
        },
      }}
      pos="relative"
      w={width}
      h={height}
    >
      <Cropper
        style={{
          width,
          height,
        }}
        ref={cropperRef}
        initialAspectRatio={1}
        aspectRatio={!isFreeAspectRatioAllowed || isSquareRatio ? 1 : undefined}
        src={imageData}
        data={cropMetadata}
        guides
        zoomable
        autoCropArea={1}
        viewMode={0}
        dragMode="move"
      />

      {isFreeAspectRatioAllowed && (
        <Tooltip label={isSquareRatio ? "Free Ratio" : "1:1 Ratio"}>
          <IconButton
            pos="absolute"
            bottom={4}
            left="50%"
            transform="translateX(-50%)"
            size="xs"
            variant={isSquareRatio ? "primary" : "secondaryAlt"}
            colorScheme={isSquareRatio ? "gray" : undefined}
            aria-label="restrict aspect ratio"
            onClick={() => setIsSquareRatio((isSquareRatio) => !isSquareRatio)}
          >
            <Icon id="Ui/Fullscreen" h="12px" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
