import { DowngradeSurvey } from "domains/teams/interfaces/Pricing";

export function checkIfDowngradeSurveyComplete(survey: DowngradeSurvey) {
  const isReasonDetailsMissing =
    survey.reason.includes("other") && survey.reasonDetails === "";
  return !!(
    survey.duration &&
    survey.frequency &&
    survey.reason.length > 0 &&
    !isReasonDetailsMissing &&
    survey.satisfaction.easeOfUse &&
    survey.satisfaction.featureSet &&
    survey.satisfaction.performance &&
    survey.satisfaction.reliability &&
    survey.satisfaction.valueForMoney &&
    survey.satisfaction.customerSupport &&
    survey.missingFeatures &&
    survey.serviceImprovements &&
    survey.returnReasons
  );
}

export function formatTeamName(s: string) {
  return s.replace(/[^\w\s\-_]/gi, "").trim();
}
