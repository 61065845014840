import React from "react";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { canvasShortcuts } from "domains/shortcuts/components/canvasShortcuts";
import { Shortcut } from "domains/shortcuts/components/Shorcut";
import Button from "domains/ui/components/Button";
import { ColorChangeHandler, ColorResult, CustomPicker } from "react-color";
import {
  Alpha,
  EditableInput,
  Hue,
  Saturation,
} from "react-color/lib/components/common";
import { FaEyeDropper } from "react-icons/fa";

import {
  Box,
  HStack,
  IconButton,
  IconButtonProps,
  Tooltip,
  VStack,
} from "@chakra-ui/react";

// Only import the polyfill in the browser
if (typeof window !== "undefined") {
  import("eyedropper-polyfill");
}

const scenarioInputStyleForColorPicker = {
  borderWidth: 1,
  borderRadius: "8px",
  borderColor: "#333333",
  backgroundColor: "#121212",
  color: "#ffffff",
  padding: "10px",
  fontSize: "14px",
  fontWeight: 400,
  outline: "none",
};

const CustomHorizontalPointer = () => {
  return (
    <Box
      pos={"absolute"}
      left={"-6px"}
      w={"12px"}
      h={"12px"}
      border={"2px solid #ffffff"}
      borderRadius={"full"}
    />
  );
};

function CustomSaturationPointer() {
  return (
    <Box
      pos={"absolute"}
      top={"-6px"}
      left={"-6px"}
      w={"12px"}
      h={"12px"}
      border={"2px solid #ffffff"}
      borderRadius={"full"}
    />
  );
}
/** */

/** EyeDropper api types */
interface ColorSelectionOptions {
  signal?: AbortSignal;
}

interface ColorSelectionResult {
  sRGBHex: string;
}

interface EyeDropperConstructor {
  new (): {
    open: (options?: ColorSelectionOptions) => Promise<ColorSelectionResult>;
  };
}

declare global {
  interface Window {
    EyeDropper: EyeDropperConstructor;
  }
}

interface InjectedColorPickerWithoutAlphaFixProps
  extends Omit<ColorPickerProps, "onChange"> {
  onChange: ColorChangeHandler;
}

const ColorPickerWithoutAlphaFix =
  CustomPicker<InjectedColorPickerWithoutAlphaFixProps>((props) => {
    return (
      <VStack
        sx={{
          // When using the color picker, prevent selecting the rest of the page
          // https://github.com/casesandberg/react-color/issues/667#issuecomment-1283383163
          "-webkit-touch-callout": "none" /* iOS Safari */,
          "-webkit-user-select": "none" /* Safari */,
          "-khtml-user-select": "none" /* Konqueror HTML */,
          "-moz-user-select": "none" /* Old versions of Firefox */,
          "-ms-user-select": "none" /* Internet Explorer/Edge */,
          "user-select": "none",
        }}
        p={2}
        borderWidth={1}
        borderRadius={"lg"}
        bgColor={"secondary.900"}
      >
        <Box
          pos={"relative"}
          overflow={"hidden"}
          w={"100%"}
          h={"200px"}
          borderRadius={"md"}
        >
          <Saturation {...props} pointer={CustomSaturationPointer} />
        </Box>
        <Box
          pos={"relative"}
          overflow={"hidden"}
          w={"100%"}
          h={"12px"}
          borderRadius={"md"}
        >
          <Hue
            {...props}
            pointer={CustomHorizontalPointer}
            direction={"horizontal"}
          />
        </Box>

        {props.withAlpha && (
          <Box
            className="checkerboard"
            pos={"relative"}
            overflow={"hidden"}
            w={"100%"}
            h={"12px"}
            bg={"white"}
            borderRadius={"md"}
          >
            <Alpha
              {...props}
              className="checkerboard"
              pointer={CustomHorizontalPointer}
            />
          </Box>
        )}
        <HStack>
          <EditableInput
            style={{
              input: {
                width: "100%",
                textAlign: "center",
                height: "36px",
                ...scenarioInputStyleForColorPicker,
              },
              wrap: {
                width: "100%",
              },
            }}
            value={props.color}
            onChange={(data, event) => {
              props.onChange?.(data);
              event.stopPropagation();
            }}
          />

          {props.withEyeDropper && (
            <EyeDropper
              onPickColor={(color) => {
                props.onChange?.(color);
              }}
              color={props.color}
            />
          )}
        </HStack>

        {props.onSubmit && (
          <Button
            onClick={() => {
              props.onSubmit?.(props.color);
            }}
            w={"100%"}
          >
            Select
          </Button>
        )}
      </VStack>
    );
  });

interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
  withAlpha?: boolean;
  withEyeDropper?: boolean;
  onSubmit?: (color: string) => void;
}

const ColorPicker = (props: ColorPickerProps) => {
  const handleChange: ColorChangeHandler = (color) => {
    const alphaHex =
      typeof color.rgb.a !== "undefined"
        ? Math.round(color.rgb.a * 255)
            .toString(16)
            .padStart(2, "0")
        : "ff";

    // React color set the hex value to "transparent" when the alpha is 0 and not something like #fafafa00
    if (color.hex === "transparent") {
      props.onChange?.(props.withAlpha ? "#000000" + alphaHex : color.hex);
      return;
    }

    props.onChange?.(props.withAlpha ? color.hex + alphaHex : color.hex);
  };

  return <ColorPickerWithoutAlphaFix {...props} onChange={handleChange} />;
};

export default ColorPicker;

interface EyeDropperProps extends Omit<IconButtonProps, "aria-label"> {
  onPickColor: (color: string) => void;
  color: string;
  withShortcut?: boolean;
}

export const EyeDropper = ({
  onPickColor,
  color, // receive the current color so we can keep the alpha value
  withShortcut = false,
  ...iconButtonProps
}: EyeDropperProps) => {
  const handleChange = (color: ColorResult) => {
    const alphaHex =
      typeof color.rgb.a !== "undefined"
        ? Math.round(color.rgb.a * 255)
            .toString(16)
            .padStart(2, "0")
        : "ff";

    // React color set the hex value to "transparent" when the alpha is 0 and not something like #fafafa00
    if (color.hex === "transparent") {
      onPickColor("#000000" + alphaHex);
      return;
    }

    onPickColor(color.hex + alphaHex);
  };

  const handleEyeDropper = async () => {
    if ("EyeDropper" in window) {
      const eyeDropper = new window.EyeDropper();

      await eyeDropper.open().then((result) => {
        if (result.sRGBHex) {
          // Extract the current alpha value, defaulting to 1 (fully opaque) if missing
          const currentAlpha =
            color.length === 9 // If the color has an alpha value
              ? parseInt(color.slice(-2), 16) / 255
              : 1;

          // Create the rgb object
          let rgb = {
            r: 0,
            g: 0,
            b: 0,
            a: currentAlpha, // Use the current alpha
          };
          if (result.sRGBHex.match("rgb")) {
            const rgbArray = result.sRGBHex.match(/\d+/g);
            if (!rgbArray) return null;
            rgb = {
              r: parseInt(rgbArray[0]),
              g: parseInt(rgbArray[1]),
              b: parseInt(rgbArray[2]),
              a: currentAlpha, // Use the current alpha
            };
          } else if (result.sRGBHex.match("#")) {
            const hex = result.sRGBHex.replace("#", "");
            rgb = {
              r: parseInt(hex.substring(0, 2), 16),
              g: parseInt(hex.substring(2, 4), 16),
              b: parseInt(hex.substring(4, 6), 16),
              a: currentAlpha, // Use the current alpha
            };
          }

          handleChange({
            // Convert the rgb object to hex
            hex: `#${(rgb.r || 0).toString(16).padStart(2, "0")}${(rgb.g || 0)
              .toString(16)
              .padStart(2, "0")}${(rgb.b || 0).toString(16).padStart(2, "0")}`,
            rgb,
            // We don't need the hsl object, just added here to match the type
            hsl: { h: 0, s: 0, l: 0, a: currentAlpha }, // Use the current alpha
          });
        }
      });
    }
  };

  const supportsEyeDropper = "EyeDropper" in window;

  useHotkeys(
    canvasShortcuts.colorPicker.shortcut,
    handleEyeDropper,
    {
      enabled: withShortcut && supportsEyeDropper,
    },
    [handleEyeDropper]
  );

  return supportsEyeDropper ? (
    <Tooltip
      hasArrow
      label={<Shortcut {...canvasShortcuts.colorPicker} />}
      placement="right"
    >
      <IconButton
        aria-label="Color picker"
        icon={<FaEyeDropper />}
        id="color-picker-button"
        onClick={handleEyeDropper}
        variant={"tertiary"}
        {...iconButtonProps}
      />
    </Tooltip>
  ) : null;
};
