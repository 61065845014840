import { useMemo } from "react";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import MenuListSingleChoice, {
  MenuListSingleChoiceProps,
} from "domains/ui/components/Menu/MenuListSingleChoice";
import { useUserContext } from "domains/user/contexts/UserProvider";
import _ from "lodash";

export type FilterAuthor = "me" | "all" | string;

export const FILTER_AUTHOR_OPTIONS: MenuListSingleChoiceProps<FilterAuthor>["options"] =
  [
    { value: "me" as const, label: "Me" },
    { value: "all" as const, label: "All" },
  ];

type FilterAuthorOption = (typeof FILTER_AUTHOR_OPTIONS)[number];

export interface HeaderFilterAuthorProps {
  projectIds?: string[];
  value: NonNullable<FilterAuthorOption["value"]>;
  isLoading?: boolean;
  onChange: (value: NonNullable<FilterAuthorOption["value"]>) => void;
  facets?: Record<string, number>;
}

function getLabel(label: string, facetValue?: number): string {
  if (facetValue === undefined || facetValue === 0) return label;
  return `${label} (${facetValue})`;
}

export default function HeaderFilterAuthor({
  projectIds,
  value,
  isLoading,
  onChange,
  facets,
}: HeaderFilterAuthorProps) {
  const { user } = useUserContext();
  const { selectedProject, projects } = useTeamContext();

  const authorsProjects = useMemo(() => {
    if (!projectIds?.length) return [selectedProject];
    return projects.filter((project) => projectIds.includes(project.id));
  }, [projects, projectIds, selectedProject]);

  const teamMembers = useMemo(() => {
    return _.uniqBy(
      authorsProjects.flatMap((project) => project.users),
      "id"
    ).filter((option) => option.id !== user?.id);
  }, [authorsProjects, user?.id]);

  const apiKeys = useMemo(() => {
    return _.uniqBy(
      authorsProjects.flatMap((project) => project.apiKeys),
      "id"
    );
  }, [authorsProjects]);

  const options = useMemo<
    MenuListSingleChoiceProps<FilterAuthor>["options"]
  >(() => {
    return [
      ...FILTER_AUTHOR_OPTIONS.map(
        (option) =>
          ({
            label:
              option.value === "me"
                ? getLabel("Me", user?.id ? facets?.[user.id] : undefined)
                : option.label,
            value: option.value,
          } as FilterAuthorOption)
      ),
      ...(teamMembers.length ? [{ isDivider: true as const }] : []),
      ...teamMembers
        .map((user) => ({
          label: getLabel(user.email, facets?.[user.id]),
          value: user.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
      ...(apiKeys.length ? [{ isDivider: true as const }] : []),
      ...apiKeys
        .map((apiKey) => ({
          label: getLabel(`API Key - ${apiKey.name}`, facets?.[apiKey.id]),
          value: apiKey.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];
  }, [teamMembers, facets, user, apiKeys]);

  return (
    <MenuListSingleChoice
      variant={"filter"}
      cannotBeUndefined
      placeholder="Author"
      options={options}
      setSelectedValue={onChange}
      selectedValue={value}
      isConsideredEmpty={value === "all"}
      isButtonLoading={isLoading || !projects.length}
    />
  );
}
