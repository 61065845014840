import { useCallback, useMemo } from "react";
import { useAssetTagsManager } from "domains/assets/hooks/useAssetTagsManager";
import { FmSelectionActionProps } from "domains/file-manager/components/FileManagerV2/FmSelectionActions";
import { mapFmFileImagesToFileImages } from "domains/file-manager/utils/mapFmFileToFile";
import SelectionBarTags from "domains/tags/components/SelectionBarTags";

export type SelectionActionEditTagsProps = FmSelectionActionProps<"image"> & {
  buttonRef: React.RefObject<HTMLButtonElement>;
};

export default function SelectionActionEditTags({
  selectedFiles,
  isDisplayed,
  buttonRef,
}: SelectionActionEditTagsProps) {
  const { updateAssetTags } = useAssetTagsManager();

  const tagFiles = useMemo(
    () => mapFmFileImagesToFileImages(selectedFiles),
    [selectedFiles]
  );

  const flatTags = useMemo(
    () => [...new Set(selectedFiles.flatMap((file) => file.meta.tags))],
    [selectedFiles]
  );

  const handleUpdateTags = useCallback(
    (fileId: string, newTags: string[]) => {
      const asset = selectedFiles.find((file) => file.id === fileId)!.meta!;
      void updateAssetTags(asset, newTags, false);
    },
    [selectedFiles, updateAssetTags]
  );

  return (
    <SelectionBarTags
      compact
      buttonRef={buttonRef}
      isDisabled={!isDisplayed}
      tags={flatTags}
      files={tagFiles}
      updateTags={handleUpdateTags}
      withoutTagSuggestion
    />
  );
}
