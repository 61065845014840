import { ComponentStyleConfig, defineStyleConfig } from "@chakra-ui/react";

export const Popover: ComponentStyleConfig = defineStyleConfig({
  variants: {
    blurred: {
      content: {
        border: "1px solid",
        borderColor: "#333",
        backdropFilter: "blur(13px)",
        bg: "rgba(44, 44, 44, 0.85)",
        boxShadow: "none",
      },
      body: {
        px: 4,
        py: 3,
      },
    },
  },
});
