import _ from "lodash";

export const arraysEqualIgnoreOrder = (a: any[], b: any[]) => {
  if (a.length !== b.length) {
    return false;
  }
  const aSorted = [...a].sort();
  const bSorted = [...b].sort();
  return _.isEqual(aSorted, bSorted);
};
