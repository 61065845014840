export type GridSortKey =
  | "relevance"
  | "createdAsc"
  | "createdDesc"
  | "nameAsc"
  | "nameDesc"
  | "createdAtTSDesc"
  | "createdAtTSAsc";
export interface GridSort {
  label: string;
  value: string;
}
export type GridSortMap = { [key in GridSortKey]: GridSort };
export type GridSortValue = GridSortKey;

export const gridSorts: GridSortMap = {
  relevance: { label: "Most Relevant", value: "relevance" },
  createdDesc: { label: "Newest", value: "createdAt:desc" },
  createdAsc: { label: "Oldest", value: "createdAt:asc" },
  nameAsc: { label: "Name (A-Z)", value: "name:asc" },
  nameDesc: { label: "Name (Z-A)", value: "name:desc" },
  createdAtTSDesc: { label: "Newest", value: "createdAtTS:desc" },
  createdAtTSAsc: { label: "Oldest", value: "createdAtTS:asc" },
};
export const gridSortKeys = Object.keys(gridSorts) as GridSortKey[];
