import { useState } from "react";
import { capitalizeAllWords } from "domains/commons/misc";
import Avatar from "domains/ui/components/Avatar";
import Button from "domains/ui/components/Button";
import DecisionModal from "domains/ui/components/DecisionModal";
import Icon from "domains/ui/components/Icon";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import {
  Divider,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useClerk } from "@clerk/nextjs";

export default function ProfileDropdown() {
  const { signOut } = useClerk();
  const { user } = useUserContext();
  const [isSignOutConfirmDialogOpen, setIsSignOutConfirmDialogOpen] =
    useState<boolean>(false);

  const userName =
    user?.fullName ??
    capitalizeAllWords(
      (user?.email ?? "").replace(/@.+$/, "").replace(/\._-/g, " ")
    );
  const userEmail = user?.email ?? "-";

  const handleSignOut = () => {
    Track(AnalyticsEvents.Navigation.ClickedLogout);
    setIsSignOutConfirmDialogOpen(false);
    void signOut({
      redirectUrl: "/login",
    });
  };

  return (
    <>
      <Popover
        openDelay={0}
        placement="bottom-end"
        trigger="hover"
        variant="blurred"
      >
        <PopoverTrigger>
          <Button
            w="auto"
            h="auto"
            p={0}
            _hover={{
              bgColor: "none",
              opacity: 0.7,
            }}
            _active={{
              bgColor: "none",
              opacity: 1,
            }}
            colorScheme="white"
            data-testid="topbar-user-setting-dropdown-button"
            variant="ghost"
          >
            <Avatar
              w="36px"
              h="36px"
              label={userName}
              borderRadius="md"
              size="md"
            />
          </Button>
        </PopoverTrigger>

        <PopoverContent w="220px">
          <PopoverBody>
            <VStack align="stretch" spacing={3}>
              <VStack align="start" spacing={1}>
                <Text w="100%" color="textSecondary" isTruncated size="body.md">
                  {userName}
                </Text>
                <Text w="100%" color="textSecondary" isTruncated size="body.sm">
                  {userEmail}
                </Text>
              </VStack>

              <Divider borderColor="borderAlpha.500" />

              <VStack align="stretch" mx={-2} my={-1} spacing={1}>
                <Button
                  variant="menuItem"
                  h={8}
                  leftIcon={<Icon id="Ui/Profile" h="12px" />}
                  colorScheme="white"
                  internalLink="/profile"
                  data-testid="user-menu-profile-button"
                >
                  Profile
                </Button>

                <Button
                  variant="menuItem"
                  h={8}
                  leftIcon={<Icon id="Layout/SignOut" h="12px" />}
                  colorScheme="white"
                  data-testid="user-menu-logout-button"
                  onClick={() => setIsSignOutConfirmDialogOpen(true)}
                >
                  Logout
                </Button>
              </VStack>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <DecisionModal
        isOpen={isSignOutConfirmDialogOpen}
        headerMessage="Confirm Logout"
        body="Are you sure you want to log out?"
        onConfirm={handleSignOut}
        onClose={() => setIsSignOutConfirmDialogOpen(false)}
        colorScheme="danger"
        confirmMessage="Logout"
      />
    </>
  );
}
