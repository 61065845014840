import { useMemo } from "react";
import { useAssetRemoveBackgroundCu } from "domains/assets/hooks/useAssetRemoveBackgroundCu";
import { FmSelectionActionProps } from "domains/file-manager/components/FileManagerV2/FmSelectionActions";
import { FmFileImage } from "domains/file-manager/interfacesV2";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { CuIndicator } from "domains/ui/components/ButtonWithCuIndicator";
import ButtonWithModal from "domains/ui/components/ButtonWithModal";
import Icon from "domains/ui/components/Icon";
import { PremiumButtonWrapper } from "domains/ui/components/PremiumBadge";

import { HStack } from "@chakra-ui/react";

export type SelectionActionRemoveBgProps = FmSelectionActionProps<"image"> & {
  isDisabled: boolean;
  isLoading: boolean;
  buttonRef: React.RefObject<HTMLButtonElement>;
  onClick: (files: FmFileImage[]) => void;
};

export default function SelectionActionRemoveBg({
  selectedFiles,
  isDisplayed,
  isDisabled,
  isLoading,
  buttonRef,
  onClick,
}: SelectionActionRemoveBgProps) {
  const { selectedTeam } = useTeamContext();

  const filesAssetsIdWidthAndHeight = useMemo(
    () =>
      isDisplayed
        ? selectedFiles.map((file) => ({
            assetId: file.id,
            width: file.width,
            height: file.height,
          }))
        : [],
    [isDisplayed, selectedFiles]
  );

  const hasNonCompatibleFiles = useMemo(
    () =>
      selectedFiles.some((file) => file.meta.metadata.type === "vectorization"),
    [selectedFiles]
  );

  const { cuCost, isCuLoading, hasEnoughCu, hasError } =
    useAssetRemoveBackgroundCu(
      hasNonCompatibleFiles ? { skip: true } : filesAssetsIdWidthAndHeight
    );

  return (
    <PremiumButtonWrapper shouldShowWrapper={selectedTeam.isFreePlan}>
      <ButtonWithModal
        ref={buttonRef}
        tooltip={
          (selectedTeam.isFreePlan &&
            "Upgrade to enable batch background removal") ||
          ((hasError || hasNonCompatibleFiles) &&
            "Background removal is unavailable for at least one selected asset") ||
          (!hasEnoughCu && "Insufficient balance") || (
            <HStack spacing={2}>
              <span>Remove Background</span>
              <CuIndicator p={0} cuCost={cuCost} isCuLoading={isCuLoading} />
            </HStack>
          )
        }
        variant="secondary"
        leftIcon={<Icon id="Domains/Tools/RemoveBackground" h="18px" />}
        modalConfirmMessage={
          <>
            {"Confirm"}
            <CuIndicator
              pos="absolute"
              top={0}
              right={0}
              bottom={0}
              cuCost={cuCost}
              isCuLoading={isCuLoading}
            />
          </>
        }
        onConfirm={() => onClick(selectedFiles)}
        modalHeader="Remove Background"
        modalBody="Are you sure you want to remove background for these images?"
        modalColorScheme="primary"
        isLoading={isLoading}
        isDisabled={
          isDisabled ||
          selectedTeam.isFreePlan ||
          !hasEnoughCu ||
          hasError ||
          hasNonCompatibleFiles
        }
        isModalConfirmAsync
        data-testid="file-manager-remove-bg-button"
        w="36px"
      />
    </PremiumButtonWrapper>
  );
}
