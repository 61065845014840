import React, { useEffect, useMemo } from "react";
import { UseSkyboxUpscaleReturnValue } from "domains/assets/hooks/useSkyboxUpscale";
import { disabledOnWheel } from "domains/forms/disabledOnWheel";
import ButtonSectionTopIcon from "domains/inference/components/InferenceGenerator/Sidebar/ButtonSectionTopIcon";
import FragmentReferenceImages from "domains/inference/components/InferenceGenerator/Sidebar/FragmentReferenceImages";
import SidebarSection from "domains/inference/components/InferenceGenerator/Sidebar/Section";
import SectionNegativePrompt from "domains/inference/components/InferenceGenerator/Sidebar/SectionNegativePrompt";
import SectionPrompt from "domains/inference/components/InferenceGenerator/Sidebar/SectionPrompt";
import { SKYBOX_MAGIC_PROMPT_MODEL } from "domains/skyboxes/constants/Skybox";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import ControlButtonSelect from "domains/ui/components/ControlButtonSelect";
import Divider from "domains/ui/components/Divider";
import Icon from "domains/ui/components/Icon";
import ScenarioInput from "domains/ui/components/ScenarioInput";
import Slider from "domains/ui/components/Slider";
import WithLabelAndTooltip from "domains/ui/components/WithLabelAndTooltip";
import { AnalyticsEvents } from "infra/analytics/constants/Events";

import { VStack } from "@chakra-ui/react";

const SCALING_FACTORS = [2, 4, 8];

interface SkyboxUpscaleParamsProps {
  form: UseSkyboxUpscaleReturnValue["form"];
  setValue: UseSkyboxUpscaleReturnValue["setValue"];
  isAssetZoom?: boolean;
}

export default function SkyboxUpscaleParams({
  form,
  setValue,
  isAssetZoom,
}: SkyboxUpscaleParamsProps) {
  const { maxUpscalingFactor: maxPlanUpscalingFactor } = usePlanContext();

  const width = form.reference?.dimensions?.width ?? 0;
  const maxScalingFactor = useMemo(() => {
    return [1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, ...SCALING_FACTORS]
      .reverse()
      .find((value) => width * value <= 16_000);
  }, [width]);

  useEffect(() => {
    if (maxScalingFactor === undefined) {
      return;
    }
    if (
      form.scalingFactor > maxScalingFactor ||
      form.scalingFactor > maxPlanUpscalingFactor
    ) {
      const newScalingFactor = Math.min(
        maxScalingFactor,
        maxPlanUpscalingFactor
      );
      setValue("scalingFactor", newScalingFactor);
    }
  }, [form.scalingFactor, maxPlanUpscalingFactor, maxScalingFactor, setValue]);

  return (
    <>
      <VStack
        align="stretch"
        w="100%"
        spacing={2}
        {...(!isAssetZoom
          ? {
              px: 6,
              py: 4,
            }
          : {})}
      >
        <ControlButtonSelect
          title="Scaling Factor"
          options={SCALING_FACTORS.map((_value) => {
            const value =
              _value === 2 &&
              maxScalingFactor !== undefined &&
              maxScalingFactor < 2
                ? maxScalingFactor
                : _value;
            const isAvailable =
              maxScalingFactor !== undefined && value <= maxScalingFactor;
            const isAllowedInPlan = value <= maxPlanUpscalingFactor;
            let tooltip = undefined;
            if (!isAvailable) {
              tooltip =
                "Enhance output cannot exceed 128,000,000 pixels (16,000 x 8,000 px)";
            } else if (!isAllowedInPlan) {
              tooltip = `Upgrade your plan to upscale ${value}x`;
            }
            return {
              label: `${value}x`,
              value,
              isDisabled: !isAvailable || !isAllowedInPlan,
              tooltip,
              premiumWrapper: !isAllowedInPlan && isAvailable,
            };
          })}
          selectedOption={form.scalingFactor}
          onOptionSelected={(newValue) => {
            setValue("scalingFactor", newValue);
          }}
        />
      </VStack>

      {!isAssetZoom && (
        <>
          <Divider />
          <SectionPrompt
            id="prompt"
            prompt={form.prompt}
            setPrompt={(newValue) => setValue("prompt", newValue)}
            withRandom={false}
            referenceAssetId={form.reference?.assetId}
            placeholder="Describe your scene, and let prompt tools generate, complete, or translate it."
            getModel={async () => SKYBOX_MAGIC_PROMPT_MODEL}
          />

          <Divider />
          <SectionNegativePrompt
            id="negativePrompt"
            prompt={form.negativePrompt}
            setPrompt={(newValue) => setValue("negativePrompt", newValue)}
          />

          <Divider />

          <SidebarSection
            headerProps={{
              title: "Style Images",
              id: "styleImages",
              tooltip:
                "Upload up to 10 images for the AI to learn from and shape the style of your final output.",
              topRight:
                form.styleImages.length > 0 ? (
                  <ButtonSectionTopIcon
                    tooltip="Remove All"
                    tooltipProps={{ placement: "right" }}
                    onClick={() => setValue("styleImages", [])}
                  >
                    <Icon id="Ui/TrashMd" h="14px" />
                  </ButtonSectionTopIcon>
                ) : undefined,
            }}
          >
            <VStack align="stretch" w="100%" spacing={2}>
              <FragmentReferenceImages
                images={form.styleImages}
                setImages={(newValue) => setValue("styleImages", newValue)}
                maxImages={10}
                trackingEvent={AnalyticsEvents.SkyboxUpscale.ImportedStyleImage}
                trackingProperties={{}}
              />

              {form.styleImages.length > 0 && (
                <WithLabelAndTooltip
                  label="Style Fidelity"
                  tooltip="Higher fidelity keeps the skybox style aligned with the style images."
                >
                  <Slider
                    withNumberInput
                    max={100}
                    min={0}
                    onChange={(value) => setValue("styleFidelity", value)}
                    step={1}
                    value={form.styleFidelity}
                  />
                </WithLabelAndTooltip>
              )}
            </VStack>
          </SidebarSection>

          <Divider />
          <SidebarSection
            headerProps={{
              title: "Settings",
              id: "settings",
            }}
          >
            <VStack align="stretch" w="100%" spacing={2}>
              <WithLabelAndTooltip
                label="Details Level"
                tooltip="Enhances or reduces the intensity of details"
              >
                <Slider
                  withNumberInput
                  max={50}
                  min={-50}
                  onChange={(value) => setValue("detailsLevel", value)}
                  step={1}
                  value={form.detailsLevel}
                />
              </WithLabelAndTooltip>

              <WithLabelAndTooltip
                label="Seed"
                tooltip="Using a fixed seed can help maintain consistency while adjusting settings"
              >
                <ScenarioInput
                  value={form.seed ?? ""}
                  setValue={(newValue) => {
                    setValue("seed", newValue.length ? newValue : undefined);
                  }}
                  placeholder="Random"
                  type="number"
                  onWheel={disabledOnWheel}
                />
              </WithLabelAndTooltip>
            </VStack>
          </SidebarSection>
        </>
      )}
    </>
  );
}
