import { FileType } from "domains/file-manager/interfaces";
import { FmFileImage } from "domains/file-manager/interfacesV2";
import _ from "lodash";

const BREAKPOINTS = [128, 256, 512, 1_024, 2_048];

type UseAssetUrlArgs = {
  file?: FileType;
  imageFile?: FmFileImage;
  cardWidth: number;
};

export function useOptimizedAssetUrl({
  file,
  imageFile,
  cardWidth,
}: UseAssetUrlArgs):
  | { url: string; width: number }
  | { url: undefined; width: undefined } {
  const imageFileType =
    (file?.type === "image" && file?.meta.metadata.type) ||
    imageFile?.meta.metadata.type;
  const isTexture = imageFileType === "texture";

  const originWidth = file?.width ?? imageFile?.width ?? 512;
  const targetWidth =
    _.find(
      BREAKPOINTS,
      (breakpoint) => breakpoint >= cardWidth * (isTexture ? 7 : 1)
    ) ??
    _.last(BREAKPOINTS) ??
    512;

  const originThumbnail = file?.thumbnail ?? imageFile?.thumbnail;
  const isOriginReturned =
    !originThumbnail?.includes("/assets-transform/") ||
    originWidth <= targetWidth;
  const targetThumbnail = isOriginReturned
    ? undefined
    : `${originThumbnail}&width=${targetWidth}`;

  return (
    (targetThumbnail && { url: targetThumbnail, width: targetWidth }) ||
    (originThumbnail && { url: originThumbnail, width: originWidth }) || {
      url: undefined,
      width: undefined,
    }
  );
}
