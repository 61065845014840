import { PostModelsInferencesByModelIdApiArg } from "infra/api/generated/api";

export type SCHEDULER_TYPE = Exclude<
  PostModelsInferencesByModelIdApiArg["body"]["parameters"]["scheduler"],
  undefined
>;

export const SCHEDULERS: {
  [key in SCHEDULER_TYPE]: string;
} = {
  LCMScheduler: "LCM (fastest)",
  DDIMScheduler: "DDIM",
  EulerAncestralDiscreteScheduler: "Euler Ancestral",
  EulerDiscreteScheduler: "Euler",
  HeunDiscreteScheduler: "Heun",
  UniPCMultistepScheduler: "UniPC",
  DDPMScheduler: "DDPM",
  DEISMultistepScheduler: "DEIS",
  DPMSolverMultistepScheduler: "DPM Multi steps",
  DPMSolverSinglestepScheduler: "DPM Single step",
  KDPM2AncestralDiscreteScheduler: "KDPM Ancestral",
  KDPM2DiscreteScheduler: "KDPM",
  LMSDiscreteScheduler: "LMS",
  PNDMScheduler: "PNDM",
  TCDScheduler: "TCD",
};
