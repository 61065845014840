import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import {
  MODEL_MAIN_TAGS,
  MODEL_SECONDARY_TAGS,
} from "domains/models/constants/tags";
import {
  expandTagItems,
  formatTagsArray,
  tagIdToLabel,
} from "domains/tags/utils";
import MenuListCategoryMultipleChoice from "domains/ui/components/Menu/MenuListCategoryMultipleChoice";

export default function TagSuggestion({
  tags,
  closeOnSelect = false,
  onUpdateTags,
}: {
  tags: string[];
  closeOnSelect?: boolean;
  onUpdateTags: (newTags: string[]) => void;
}) {
  const [updatedTags, setUpdatedTags] = useState<string[] | undefined>();
  const debouncedUpdatedTagsRef = useRef<string[] | undefined>();
  const debouncedUpdatedTags = useDebounce<string[] | undefined>(
    updatedTags,
    500
  );

  const formattedTags = useMemo(
    () => formatTagsArray(MODEL_SECONDARY_TAGS),
    []
  );
  const options = useMemo(() => {
    return MODEL_MAIN_TAGS.map((tag) => ({
      category: tag,
      items: formattedTags[tag] ?? [],
    }));
  }, [formattedTags]);

  const handleUpdateTags = useCallback(
    async (newTags: string[]) => {
      const tagThatHasBeenAdded = newTags.filter((tag) => !tags.includes(tag));
      const newPotentialTags = [];
      const removedPotentialTags = tags.filter((tag) => !newTags.includes(tag));
      for (const tag of tagThatHasBeenAdded) {
        newPotentialTags.push(...expandTagItems(tag));
      }

      const updatedTags = [
        ...new Set(
          [...tags, ...newPotentialTags].filter(
            (tag) => !removedPotentialTags.includes(tag)
          )
        ),
      ];

      setUpdatedTags(updatedTags);
    },
    [tags, setUpdatedTags]
  );

  useEffect(() => {
    if (
      debouncedUpdatedTags === undefined ||
      debouncedUpdatedTagsRef.current === debouncedUpdatedTags
    ) {
      return;
    }

    debouncedUpdatedTagsRef.current = debouncedUpdatedTags;
    onUpdateTags(debouncedUpdatedTags);
  }, [debouncedUpdatedTags, onUpdateTags]);

  return (
    <MenuListCategoryMultipleChoice
      hasValueEnabled={false}
      menuButtonText="Tags"
      options={options}
      selectedValues={updatedTags ?? tags}
      setSelectedValues={handleUpdateTags}
      selectedCategories={updatedTags ?? tags}
      setSelectedCategories={handleUpdateTags}
      renderLabel={tagIdToLabel}
      closeOnSelect={closeOnSelect}
    />
  );
}
