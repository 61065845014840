import { useMemo, useRef, useState } from "react";
import Icon from "domains/ui/components/Icon";
import MenuButtonMultipleChoice from "domains/ui/components/Menu/MenuButtonMultipleChoice";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import MenuTitle from "domains/ui/components/Menu/MenuTitle";
import ScenarioInput from "domains/ui/components/ScenarioInput";

import { Menu, MenuList } from "@chakra-ui/react";

interface OptionsProps<T> {
  value: T;
  label: string;
  isDivider?: boolean;
  dataTestId?: string;
  isDisabled?: boolean;
}

export interface MenuListMultipleChoiceProps<T extends string = string> {
  label: string;
  options: OptionsProps<T>[];
  setSelectedValues: (values: T[]) => void;
  selectedValues: T[];
  withoutSearch?: boolean;
  dataTestId?: string;
  topText?: string;
  isDisabled?: boolean;
  maxItems?: number;
}

export default function MenuListMultipleChoice<T extends string>({
  label,
  options,
  setSelectedValues,
  selectedValues,
  withoutSearch,
  dataTestId,
  topText,
  isDisabled,
  maxItems = 100,
}: MenuListMultipleChoiceProps<T>) {
  const [search, setSearch] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const menuButtonText = useMemo(() => {
    if (selectedValues.length === 0) {
      return label;
    }
    return `${label} (${selectedValues.length})`;
  }, [label, selectedValues]);

  const displayedOptions = useMemo(() => {
    const filteredOptions = options.filter((option) => {
      if (option.isDivider) {
        return true;
      }
      return option.label.toLowerCase().includes(search.toLowerCase());
    });

    if (maxItems === -1) {
      return filteredOptions;
    }

    return filteredOptions.filter((option, index) => {
      if (selectedValues.includes(option.value)) {
        return true;
      }
      return index < maxItems;
    });
  }, [options, maxItems, selectedValues, search]);

  return (
    <Menu closeOnSelect={false} variant="blurred">
      <MenuButtonMultipleChoice
        text={menuButtonText}
        hasValue={selectedValues.length > 0}
        onRemove={() => {
          setSelectedValues([]);
        }}
        dataTestId={dataTestId}
        isDisabled={isDisabled}
      />

      <MenuList
        zIndex="popover"
        onFocus={() => {
          inputRef.current?.focus({
            preventScroll: true,
          });
        }}
        rootProps={{ maxWidth: "100%", minWidth: "unset" }}
      >
        {topText && (
          <MenuTitle variant="blurred" text={topText.toUpperCase()} mx={2} />
        )}
        {!withoutSearch && (
          <ScenarioInput
            ref={inputRef}
            value={search}
            setValue={setSearch}
            placeholder={"Search"}
            leftComponent={
              <Icon mb={0.5} id={"Ui/Search"} color={"textTertiary"} />
            }
            containerProps={{
              mb: options.length > 0 ? 1.5 : 0,
              flexShrink: 0,
            }}
          />
        )}
        {displayedOptions.map(
          ({ value, label, isDivider, dataTestId, isDisabled }) => {
            if (isDivider) {
              return <MenuTitle key={`title-${label}-${value}`} text={label} />;
            } else {
              return (
                <MenuItem
                  key={value}
                  check="checkbox"
                  text={label}
                  isSelected={selectedValues.some((x) => x === value)}
                  onClick={() => {
                    setSelectedValues(
                      selectedValues.some((x) => x === value)
                        ? selectedValues.filter((x) => x !== value)
                        : [...selectedValues, value]
                    );
                  }}
                  dataTestId={dataTestId}
                  isDisabled={isDisabled}
                />
              );
            }
          }
        )}
      </MenuList>
    </Menu>
  );
}
