import React, { useMemo } from "react";
import { NextSeo } from "next-seo";
import useAllAssets from "domains/assets/hooks/useAllAssets";
import useAssetJobFilterAuthor from "domains/assets/hooks/useAssetAuthor";
import useAssetFilterType from "domains/assets/hooks/useAssetFilterType";
import useAssetSort from "domains/assets/hooks/useAssetSort";
import useAssetView from "domains/assets/hooks/useAssetView";
import FileManagerImage, {
  FileManagerImageProps,
} from "domains/file-manager/components/FileManagerImage";
import { FmImageCardActionsProps } from "domains/file-manager/components/FileManagerImage/Card/Actions";
import { EmptyMessageSkybox } from "domains/file-manager/components/FileManagerImage/EmptyMessage";
import { TOP_BAR_HEIGHT } from "domains/navigation/constants/TopBar";
import Button from "domains/ui/components/Button";
import Header from "domains/ui/components/Header";
import Icon from "domains/ui/components/Icon";
import MenuItem from "domains/ui/components/Menu/MenuItem";

import { Box } from "@chakra-ui/react";

export default function SkyboxesPage() {
  return (
    <>
      <NextSeo title="360 Skyboxes" />

      <Header title="360 Skyboxes">
        <Button
          leftIcon={<Icon id="Ui/Plus" />}
          data-testid="new-skybox-button"
          size="sm"
          internalLink="/skyboxes/new"
        >
          New Skybox
        </Button>
      </Header>

      <Box mx={9}>
        <ListSkyboxes />
      </Box>
    </>
  );
}

// ------------------------------------

export type ListSkyboxesProps = {
  collectionId?: string;
  EmptyStateComponent?: FileManagerImageProps["EmptyStateComponent"];
};

export function ListSkyboxes({
  collectionId,
  EmptyStateComponent,
}: ListSkyboxesProps) {
  const fmViewProps = useAssetView({ variant: "skybox" });
  const { allAssetsTypeArgs, fmHeaderFilterAssetProps } = useAssetFilterType({
    assetType: "skybox",
  });
  const { authorQueryArgs, fmHeaderFilterAuthorProps } =
    useAssetJobFilterAuthor();
  const { sortQueryArgs, fmHeaderSortProps } = useAssetSort();
  const result = useAllAssets({
    collectionId,
    ...sortQueryArgs,
    ...allAssetsTypeArgs,
    ...(collectionId ? undefined : authorQueryArgs),
  });

  // ----------------------------------

  const cardActionsProps = useMemo(() => ({}), []);

  // ----------------------------------

  return (
    <FileManagerImage
      variant="skybox"
      files={fmViewProps.view === "jobs" ? undefined : result.files}
      jobs={fmViewProps.view === "jobs" ? result.jobs : undefined}
      isLoading={result.isLoading}
      hasMore={result.hasMore}
      onEndReached={result.loadMore}
      isSelectable
      CardActionsComponent={CardActions}
      cardActionsProps={cardActionsProps}
      {...fmViewProps}
      headerFilterAssetProps={fmHeaderFilterAssetProps}
      headerFilterAuthorProps={
        collectionId ? undefined : fmHeaderFilterAuthorProps
      }
      headerSortProps={fmHeaderSortProps}
      EmptyStateComponent={EmptyStateComponent ?? EmptyMessageSkybox}
      styleProps={{
        header: {
          top: `${TOP_BAR_HEIGHT}px`,
        },
      }}
    />
  );
}

// ------------------------------------

export type CardActionsCustomProps = {
  _never?: never;
};

export type CardActionsProps = FmImageCardActionsProps<CardActionsCustomProps>;

export function CardActions({ asset, top, bottom }: CardActionsProps) {
  const assetId = asset?.id;
  const hasPrompt = !!asset?.metadata.prompt;

  if (!hasPrompt) {
    return null;
  }

  return (
    <>
      {top}

      <MenuItem
        w="150px"
        iconId="Ui/Prompt"
        iconH="12px"
        internalLink={{
          pathname: "/skyboxes/new",
          query: {
            importPromptFromAssetId: assetId,
          },
        }}
        text="Prompt"
      />

      {bottom}
    </>
  );
}
