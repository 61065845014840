import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { Endpoints } from "infra/store/interface";

export const usersEndpoints: Endpoints = {
  putMe: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const undo: (() => void)[] = [];

      for (const {
        endpointName,
        originalArgs,
      } of apiSlice.util.selectInvalidatedBy(getState(), [
        API_TAGS.user,
      ]) as SelectedInvalidatedByTag[]) {
        if (endpointName === "getMe") {
          const update = dispatch(
            apiSlice.util.updateQueryData(
              endpointName,
              originalArgs,
              (draft) => {
                if (!draft) {
                  return;
                }

                draft.user = {
                  ...draft.user,
                  ...arg.body,
                  settings: {
                    ...draft.user.settings,
                    ...arg.body.settings,
                  },
                };
              }
            )
          );
          undo.push(update.undo);
        }
      }

      queryFulfilled.catch(() => undo.forEach((undo) => undo()));
    },
  },
  getMe: {
    providesTags: [API_TAGS.user],
  },
  putUserNotificationsByToken: {
    invalidatesTags: [API_TAGS.user],
  },
};
