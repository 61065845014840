import { useMemo } from "react";
import Image from "next/image";
import Link from "next/link";
import { LinkProps } from "next/link";
import useRouter from "domains/navigation/hooks/useRouter";
import Button from "domains/ui/components/Button";
import WithGradientEffect from "domains/ui/components/WithGradientEffect";

import { Flex, Text, VStack } from "@chakra-ui/react";

export interface EmptyMessageProps {
  target?: "image" | "skybox" | "texture";
  message?: React.ReactNode;
  modelId?: string;
  withoutGenerateButton?: boolean;
}

export default function EmptyMessage({
  target = "image",
  message = "No Images Available",
  modelId,
  withoutGenerateButton = false,
}: EmptyMessageProps) {
  const router = useRouter();
  const isOnLoraCatalog = router.pathname.includes("/blend");
  const ctaHref = useMemo<LinkProps["href"]>(() => {
    if (target === "image") {
      return {
        pathname: "/images/new",
        query: modelId ? { modelId } : {},
      };
    } else if (target === "skybox") {
      return {
        pathname: "/skyboxes/new",
        query: {},
      };
    } else if (target === "texture") {
      return {
        pathname: "/textures/new",
        query: {},
      };
    } else {
      return undefined as never;
    }
  }, [modelId, target]);

  return (
    <Flex data-testid="asset-gallery-empty-state">
      <VStack mt={4}>
        <WithGradientEffect>
          <Image
            src="/ui/model-empty-state.svg"
            alt="Empty state"
            width={200}
            height={200}
          />
        </WithGradientEffect>
        <VStack pt={3} pb={5}>
          <Text size="title.md">{message}</Text>
        </VStack>

        {!withoutGenerateButton && (
          <Link href={ctaHref} target={isOnLoraCatalog ? "_blank" : undefined}>
            <Button variant="secondary">Start Generating Now</Button>
          </Link>
        )}
      </VStack>
    </Flex>
  );
}

// ------------------------------------

type EmptyMessageSkyboxProps = Omit<EmptyMessageProps, "target" | "message">;

export function EmptyMessageSkybox(props: EmptyMessageSkyboxProps) {
  return (
    <EmptyMessage {...props} message="No Skyboxes Available" target="skybox" />
  );
}

// ------------------------------------

type EmptyMessageTextureProps = Omit<EmptyMessageProps, "target" | "message">;

export function EmptyMessageTexture(props: EmptyMessageTextureProps) {
  return (
    <EmptyMessage {...props} message="No Textures Available" target="texture" />
  );
}
