import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { AppRootState } from "infra/store/store";

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

export const updateCollectionsQueryByDeletingCollection = (
  collectionId: string,
  {
    dispatch,
    getState,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    getState: () => AppRootState;
  }
) => {
  for (const {
    endpointName,
    originalArgs,
  } of apiSlice.util.selectInvalidatedBy(getState(), [
    API_TAGS.collection,
  ]) as SelectedInvalidatedByTag[]) {
    if (endpointName === "getCollections") {
      dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (draft) {
            draft.collections = draft.collections.filter(
              (item) => item.id !== collectionId
            );
          }
        })
      );
    }
  }
};
