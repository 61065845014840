export const srcToBase64 = async (src: string): Promise<string | undefined> => {
  if (src.startsWith("data:")) {
    return src;
  }

  const response = await fetch(src);

  const data = await response.blob();
  if (data.size === 0) {
    throw new Error("Empty file");
  }

  const reader = new FileReader();
  const promise = new Promise<string>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error("Error reading file"));
    };
    reader.onload = () => {
      resolve(reader.result as string);
    };
  });
  reader.readAsDataURL(data);
  return promise;
};
