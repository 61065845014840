import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import _ from "lodash";

interface AsideContextValue {
  collapsedSections: string[];
  toggleCollapsedSection: (section: string, isCollapsed?: boolean) => void;
}

export const AsideContext = createContext<AsideContextValue>({
  collapsedSections: [],
  toggleCollapsedSection: () => {},
});

interface AsideProviderProps extends PropsWithChildren {
  prefix: string;
  defaultCollapsedSections?: string[];
}

export function AsideProvider({
  prefix,
  defaultCollapsedSections = [],
  children = <></>,
}: AsideProviderProps) {
  const defaultCollapsedPrefixedSections = useMemo(() => {
    return defaultCollapsedSections.map((val) => `${prefix}.${val}`);
  }, [defaultCollapsedSections, prefix]);
  const [collapsedPrefixedSections, setCollapsedPrefixedSections] =
    usePersistedState<string[]>(PersistedStateKey.COLLAPSED_ASIDE_SECTIONS, {
      defaultValue: defaultCollapsedPrefixedSections,
    });

  const collapsedSections = useMemo(() => {
    return collapsedPrefixedSections.reduce<string[]>((memo, val) => {
      if (val.indexOf(`${prefix}.`) === 0) {
        memo.push(val.replace(`${prefix}.`, ""));
      }
      return memo;
    }, []);
  }, [collapsedPrefixedSections, prefix]);

  const toggleCollapsedSection = useCallback(
    (section: string, isCollapsed?: boolean) => {
      const sectionWithPrefix = `${prefix}.${section}`;
      setCollapsedPrefixedSections((collapsedPrefixedSections) => {
        if (
          isCollapsed === true ||
          (isCollapsed === undefined &&
            !collapsedPrefixedSections.includes(sectionWithPrefix))
        ) {
          return _.uniq([...collapsedPrefixedSections, sectionWithPrefix]);
        } else {
          return _.without(collapsedPrefixedSections, sectionWithPrefix);
        }
      });
    },
    [setCollapsedPrefixedSections, prefix]
  );

  const sectionsContextValue = useMemo(
    () => ({
      collapsedSections,
      toggleCollapsedSection,
    }),
    [collapsedSections, toggleCollapsedSection]
  );

  return (
    <AsideContext.Provider value={sectionsContextValue}>
      {children}
    </AsideContext.Provider>
  );
}

export function useAsideContext() {
  return useContext<AsideContextValue>(AsideContext);
}
