import { useMemo } from "react";
import { sizes } from "domains/theme/components/text";
import {
  BsArrowDown,
  BsArrowLeft,
  BsArrowRight,
  BsArrowUp,
  BsBackspace,
  BsCommand,
  BsShift,
} from "react-icons/bs";
import { TbArrowBack, TbSpace } from "react-icons/tb";

import { HStack, Kbd, StackProps, Text } from "@chakra-ui/react";

export const ShortcutKBD = ({ shortcut }: { shortcut?: string | string[] }) => {
  const values = useMemo(() => {
    const isMac = navigator.userAgent.indexOf("Mac") !== -1;

    const item = Array.isArray(shortcut) ? shortcut[0] : shortcut;
    if (!item) {
      return undefined;
    }

    const items = item === "+" ? ["+"] : item.toLowerCase().split("+");
    return {
      keysAsStrings: items,
      elements: items.map((key) => {
        if (key === "mod") {
          if (isMac) {
            return <BsCommand key={key} />;
          } else {
            return "ctrl";
          }
        } else if (key === "shift") {
          return <BsShift key={key} />;
        } else if (key === "click") {
          return "click";
        } else if (key === "enter") {
          return <TbArrowBack key={key} style={{ width: "24px" }} />;
        } else if (key === "esc" || key === "escape") {
          return "esc";
        } else if (key === "arrowup") {
          return <BsArrowUp key={key} />;
        } else if (key === "arrowdown") {
          return <BsArrowDown key={key} />;
        } else if (key === "arrowleft" || key === "left") {
          return <BsArrowLeft key={key} />;
        } else if (key === "arrowright" || key === "right") {
          return <BsArrowRight key={key} />;
        } else if (
          key === "mouse wheel up" ||
          key === "mouse up" ||
          key === "mouse scroll up"
        ) {
          return "scroll up";
        } else if (
          key === "mouse wheel down" ||
          key === "mouse down" ||
          key === "mouse scroll down"
        ) {
          return "scroll down";
        } else if (key === "mouse scroll") {
          return "scroll";
        } else if (key === "delete" || key === "backspace") {
          return <BsBackspace key={key} />;
        } else if (key === "space") {
          return <TbSpace key={key} />;
        } else {
          return key.toUpperCase();
        }
      }),
    };
  }, [shortcut]);

  if (!values) {
    return null;
  }

  return (
    <HStack spacing={1}>
      {values.elements.map((key, index) => (
        <Kbd
          key={values.keysAsStrings[index]}
          alignItems="center"
          display="flex"
          h="22px"
          fontSize={sizes["body.md"].fontSize}
        >
          {key}
        </Kbd>
      ))}
    </HStack>
  );
};

export const Shortcut = ({
  shortcut,
  description,
  ...props
}: {
  shortcut?: string | string[];
  description?: string;
} & StackProps) => {
  return (
    <HStack {...props}>
      {description && (
        <Text color={"inherit"} size="body.md">
          {description}
        </Text>
      )}
      <ShortcutKBD shortcut={shortcut} />
    </HStack>
  );
};
