import { useCallback } from "react";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  GetAssetsByAssetIdApiResponse,
  useLazyGetAssetsByAssetIdQuery,
  useLazyGetUploadsQuery,
} from "infra/api/generated/api";

export default function useGetUploadedAsset() {
  const [getUploadFile] = useLazyGetUploadsQuery();
  const [getAssetById] = useLazyGetAssetsByAssetIdQuery();
  const { selectedProject } = useTeamContext();

  const getUploadedAsset = useCallback(
    async (
      uploadId: string,
      { retryCount }: { retryCount: number } = { retryCount: 0 }
    ) => {
      const { data } = await getUploadFile({
        projectId: selectedProject.id,
        uploadId: uploadId,
      });

      const status = data?.upload.status || "failed";

      if (status === "imported" && data?.upload.entityId) {
        const assetId = data?.upload.entityId;
        return await getAssetById({
          projectId: selectedProject.id,
          assetId,
        }).unwrap();
      }

      if (
        ["complete", "pending", "validating", "validated"].includes(status) &&
        retryCount < 30
      ) {
        return new Promise<GetAssetsByAssetIdApiResponse>((resolve, reject) => {
          setTimeout(async () => {
            try {
              const asset = await getUploadedAsset(uploadId, {
                retryCount: retryCount + 1,
              });
              resolve(asset);
            } catch (err) {
              reject(err);
            }
          }, 2_000);
        });
      }

      throw new Error("Failed to get asset");
    },
    [getUploadFile, getAssetById, selectedProject.id]
  );

  return getUploadedAsset;
}
