import useDisclosureDebounce from "domains/commons/hooks/useDisclosureDebounce";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import ButtonSingleChoice from "domains/ui/components/Menu/ButtonSingleChoice";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import MenuTitle from "domains/ui/components/Menu/MenuTitle";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import { Box, Menu, MenuButton, MenuDivider, MenuList } from "@chakra-ui/react";

export default function ProjectDropdown() {
  const {
    selectedTeam,
    selectedProject,
    projects,
    redirectToProject,
    showCreateProjectModal,
  } = useTeamContext();
  const { isOpen, onOpen, onClose } = useDisclosureDebounce(100);

  if (!selectedProject.id) return null;

  return (
    <Menu isOpen={isOpen} offset={[-12, 8]} variant="blurred">
      <MenuButton
        as={ButtonSingleChoice}
        px={2}
        _focusVisible={{
          boxShadow: "none !important",
        }}
        data-testid="topbar-user-proj-menu-button"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        size="sm"
        text={selectedProject.name}
        variant="alpha"
      />

      <MenuList
        sx={{
          "& *:focus-visible": {
            boxShadow: "none !important",
          },
        }}
        overflowY="auto"
        w="220px"
        maxH="80vh"
        px={2}
        data-testid="topbar-user-org-menu-content"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        <MenuTitle variant="blurred" text="PROJECTS" />

        {projects.map((project) => {
          const isSelected = selectedProject.id === project.id;
          return (
            <MenuItem
              key={project.id}
              isSelected={isSelected}
              check="icon"
              onClick={() => redirectToProject(project)}
            >
              <Box as="span" lineHeight="normal" isTruncated>
                {project.name}
              </Box>
            </MenuItem>
          );
        })}

        <MenuDivider />

        <MenuItem
          iconId="Ui/Gear"
          iconH="14px"
          iconContainerProps={{
            w: "24px",
            mr: 0.5,
          }}
          colorScheme="white"
          internalLink={
            selectedProject.id
              ? { pathname: "/team", query: { tab: "project" } }
              : undefined
          }
          data-testid="user-menu-project-settings-button"
          onClick={() => {
            Track(AnalyticsEvents.Navigation.ClickedTeamSettings);
          }}
          isDisabled={!selectedProject.id}
          text="Manage Project"
        />

        <MenuItem
          iconId="Ui/Plus"
          iconH="10px"
          iconContainerProps={{
            w: "24px",
            mr: 0.5,
          }}
          colorScheme="white"
          data-testid="user-menu-new-project-button"
          onClick={showCreateProjectModal}
          isDisabled={selectedTeam.userRole !== "admin"}
          text="Create Project"
          tooltip={
            selectedTeam.userRole !== "admin"
              ? "Only admins can create projects."
              : undefined
          }
          tooltipProps={{ placement: "right" }}
        />
      </MenuList>
    </Menu>
  );
}
