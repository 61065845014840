import React, { useCallback, useEffect, useState } from "react";
import {
  DEFAULT_REFRAME_FORM,
  useAssetReframe,
} from "domains/assets/hooks/useAssetReframe";
import useRouter from "domains/navigation/hooks/useRouter";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import ReframeParams from "domains/reframe/components/ReframeParams";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  GetAssetsByAssetIdApiResponse,
  GetJobIdApiResponse,
  useGetJobIdQuery,
} from "infra/api/generated/api";

import { VStack } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export interface DrawerReframeProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  onClose: (options: { avoidRedirect?: boolean }) => void;
}

export default function DrawerReframe({ asset, onClose }: DrawerReframeProps) {
  const router = useRouter();
  const { selectedProject } = useTeamContext();
  const { errorToast } = useScenarioToast();
  const {
    form,
    setForm,
    setValue,
    cuCost,
    isCuLoading,
    errors,
    isDisabled,
    handleReframeJob,
    isReframeLoading,
  } = useAssetReframe();
  const [reframingJobToFetch, setReframingJobToFetch] = useState<
    GetJobIdApiResponse["job"] | undefined
  >();
  const { currentData: reframingJob } = useGetJobIdQuery(
    reframingJobToFetch
      ? {
          projectId: selectedProject.id,
          jobId: reframingJobToFetch.jobId,
        }
      : skipToken,
    {
      pollingInterval: 5_000,
    }
  );

  const handleSubmit = useCallback(async () => {
    const newJob = await handleReframeJob({
      asset,
      trackingExtraParams: {
        origin: "image edit",
      },
    });
    if (newJob) {
      setReframingJobToFetch(newJob);
    }
  }, [asset, handleReframeJob]);

  useEffect(() => {
    if (
      reframingJob?.job &&
      reframingJob.job.status === "success" &&
      reframingJob.job.metadata.assetIds?.[0]
    ) {
      delete router.query.edit;
      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          openAssetId: reframingJob.job.metadata.assetIds[0],
        },
      });
      onClose({
        avoidRedirect: true,
      });
      setReframingJobToFetch(undefined);
    } else if (
      reframingJob?.job &&
      (reframingJob.job.status === "failure" ||
        reframingJob.job.status === "canceled")
    ) {
      errorToast({
        title: "Expand failed",
        description:
          "There was an error expanding the image. Please try again.",
      });
      setReframingJobToFetch(undefined);
    }
  }, [errorToast, onClose, router, reframingJob]);

  useEffect(() => {
    setReframingJobToFetch(undefined);
  }, [asset]);

  // Initialize form with asset metadata
  useEffect(() => {
    if (asset && asset.id !== form.asset?.id) {
      setForm(DEFAULT_REFRAME_FORM);
      setValue("asset", asset);
      if (asset.metadata.prompt) {
        setValue("prompt", asset.metadata.prompt);
      }
    }
  }, [asset, form.asset?.id, setForm, setValue]);

  return (
    <VStack align="start" w="100%" spacing={0}>
      <ReframeParams
        form={form}
        onFormChange={setValue}
        isDisabled={isDisabled}
        cuCost={cuCost}
        isCuLoading={isCuLoading}
        isGenerating={isReframeLoading || !!reframingJobToFetch}
        onGenerate={handleSubmit}
        errors={errors}
        isAssetZoom
      />
    </VStack>
  );
}
