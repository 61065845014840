import { ComponentStyleConfig, defineStyleConfig } from "@chakra-ui/react";

export const Table: ComponentStyleConfig = defineStyleConfig({
  baseStyle: {
    table: {
      maxW: "600px",
      width: "100%",
      borderWidth: 0,
      borderRadius: "xl",
      boxShadow: "inset 0 0 0 1px #333333",
      "tr:last-of-type": {
        borderBottomWidth: 0,
      },
    },
    tr: {
      borderColor: "border.500",
      borderBottomWidth: 1,
    },
    td: {
      whiteSpace: "nowrap",
    },
    thead: {
      "& > tr > th": {
        bgColor: "whiteAlpha.50",
      },
      "& > tr > th:first-child": {
        borderTopLeftRadius: "xl",
      },
      "& > tr > th:last-child": {
        borderTopRightRadius: "xl",
      },
    },
    th: {
      color: "textSecondary",
      fontSize: 14,
      fontWeight: 600,
      textTransform: "none",
    },
  },
  variants: {
    simple: {
      td: {
        borderBottom: "none",
        paddingLeft: 3,
        paddingRight: 0,
      },
      th: {
        paddingLeft: 3,
        paddingRight: 0,
      },
      tr: {
        "td:last-of-type": {
          paddingRight: 3,
        },
        "th:last-of-type": {
          paddingRight: 3,
        },
      },
    },
  },
  defaultProps: {
    variant: "simple",
  },
});
