import React, { useCallback, useState } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "domains/ui/components/Modal";
import ScenarioInput from "domains/ui/components/ScenarioInput";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import { usePostProjectMutation } from "infra/api/generated/api";

import { Box, Flex, Image, Text } from "@chakra-ui/react";

export default function ModalCreateProject() {
  const { successToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { selectedTeam, isCreateProjectModalOpen, hideCreateProjectModal } =
    useTeamContext();
  const [postProjectTrigger] = usePostProjectMutation();
  const [projectName, setProjectName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // ----------------------------------

  const createProject = useCallback(async () => {
    if (!projectName) {
      return;
    }

    setIsSubmitting(true);

    try {
      const createProjectResponse = await postProjectTrigger({
        teamId: selectedTeam.id,
        body: {
          name: projectName,
          autoJoin: true,
        },
      }).unwrap();

      Track(AnalyticsEvents.Project.CreatedProject, {
        teamId: createProjectResponse.project.teamId,
        projectId: createProjectResponse.project.id,
        membersCount: 1,
      });

      successToast({
        title: "Your project was successfully created",
      });

      await new Promise((resolve) => setTimeout(resolve, 1_000));

      setProjectName("");
      hideCreateProjectModal();

      window.location.href = `/team?teamId=${createProjectResponse.project.teamId}&projectId=${createProjectResponse.project.id}&tab=project_members`;
    } catch (error: unknown) {
      handleApiError(error, "There was an error creating your project");
    } finally {
      setIsSubmitting(false);
    }
  }, [
    projectName,
    postProjectTrigger,
    hideCreateProjectModal,
    successToast,
    handleApiError,
    selectedTeam.id,
  ]);

  // ----------------------------------

  return (
    <Modal
      isOpen={isCreateProjectModalOpen}
      onClose={hideCreateProjectModal}
      variant="modern"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column">
            <Image
              h="56px"
              mb={5}
              alt="led"
              filter="drop-shadow(0px 0px 25px #0088CC)"
              src="/ui/led.svg"
            />
            <Text
              mb={3}
              letterSpacing={-1}
              data-testid="project-creation-modal-title"
              size="title.lg"
            >
              Create a new Project
            </Text>
            <Text
              mb={12}
              color="textSecondary"
              data-testid="project-creation-modal-description"
              size="body.lg"
            >
              {`Projects let you create dedicated spaces for specific teams or initiatives within your organization. Each project can have its own member(s), selected from the organization users.`}
            </Text>
            <Flex justify="start" gap={5} w="100%" pb={8}>
              <Box
                w="64px"
                h="64px"
                p={1}
                borderWidth={2}
                borderStyle="dashed"
                borderColor="border.500"
                borderRadius="2xl"
              >
                <Flex
                  align="center"
                  justify="center"
                  w="100%"
                  h="100%"
                  borderRadius="xl"
                  bgColor="whiteAlpha.50"
                >
                  <Icon id="Domains/Teams/Building" />
                </Flex>
              </Box>
              <Flex
                align="start"
                justify="space-between"
                direction="column"
                flexGrow={1}
              >
                <Text color="textSecondary" size="body.bold.md">
                  Project Name
                </Text>
                <ScenarioInput
                  placeholder="Enter a project name"
                  value={projectName}
                  setValue={setProjectName}
                  dataTestId="project-name-input"
                />
              </Flex>
            </Flex>

            <Button
              data-testid="project-creation-modal-create-button"
              size="md"
              variant="primary"
              onClick={createProject}
              isLoading={isSubmitting}
              isDisabled={!projectName.length}
            >
              Create Project
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
