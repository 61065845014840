export function getPreviousMatchingElementSibling<T extends HTMLElement>(
  element: HTMLElement,
  selector: string
) {
  let prev = element.previousElementSibling;
  while (prev) {
    if (selector && prev.matches(selector)) return prev as T;
    prev = prev.previousElementSibling;
  }
}

export function getNextMatchingElementSibling<T extends HTMLElement>(
  element: HTMLElement,
  selector: string
) {
  let next = element.nextElementSibling;
  while (next) {
    if (selector && next.matches(selector)) return next as T;
    next = next.nextElementSibling;
  }
}

export function findSrcFromHtml(html: string | undefined) {
  if (!html) return;
  const element = document.createElement("div");
  element.innerHTML = html;
  const imgSrc =
    element.getElementsByTagName("img")[0]?.getAttribute("src") || undefined;
  element.remove();
  return imgSrc;
}
