import { NsfwType } from "domains/user/constants/Nsfw";
import _ from "lodash";

export const getShouldHaveNsfwBlur = ({
  nsfwFilteredTypes,
  nsfw,
}: {
  nsfwFilteredTypes: NsfwType[];
  nsfw: string[] | undefined;
}): boolean => {
  return !!(
    nsfw &&
    nsfwFilteredTypes &&
    _.intersection(nsfwFilteredTypes, nsfw).length > 0
  );
};
