import React from "react";
import {
  gridSorts,
  GridSortValue,
} from "domains/file-manager/constants/GridSort";
import { MenuListSingleChoiceNeverUndefined } from "domains/ui/components/Menu/MenuListSingleChoice";

export interface FmHeaderSortProps<T extends GridSortValue[]> {
  options: T;
  value: T[number];
  onChange: (value: T[number]) => void;
}

export default React.memo(function FmHeaderSort<T extends GridSortValue[]>({
  options,
  value,
  onChange,
}: FmHeaderSortProps<T>) {
  return (
    <MenuListSingleChoiceNeverUndefined
      placeholder="Sort by"
      options={options.map((key) => ({
        value: key,
        label: gridSorts[key].label,
        dataTestId: `file-manager-change-order-${key}`,
      }))}
      setSelectedValue={onChange}
      selectedValue={value}
      topText="Sort by"
      dataTestId="file-manager-change-order-dropdown"
      variant="filterGhost"
    />
  );
});
