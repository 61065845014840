import Link from "next/link";
import useDisclosureDebounce from "domains/commons/hooks/useDisclosureDebounce";
import { formatCreativeUnits } from "domains/commons/misc";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import { formatTeamName } from "domains/teams/utils";
import Avatar from "domains/ui/components/Avatar";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import MenuTitle from "domains/ui/components/Menu/MenuTitle";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import {
  Box,
  Center,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Spinner,
  Text,
} from "@chakra-ui/react";

export default function OrganizationDropdown() {
  const { selectedTeam, teams, redirectToTeam, showCreateTeamModal } =
    useTeamContext();
  const { totalRemainingCreativeUnits } = usePlanContext();
  const { isOpen, onOpen, onClose } = useDisclosureDebounce(100);

  const isTeamLoading = teams.length === 0;

  return (
    <Menu isOpen={isOpen} offset={[-12, 8]} variant="blurred">
      <MenuButton
        as={Button}
        px={1.5}
        _focusVisible={{
          boxShadow: "none !important",
        }}
        data-testid="topbar-user-org-menu-button"
        leftIcon={
          <Avatar
            w="24px"
            h="24px"
            borderRadius="md"
            label={formatTeamName(selectedTeam.name)}
            size="md"
            singleLetter
          />
        }
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        size="sm"
        variant="alpha"
      >
        <Link
          href={{
            pathname: "/team",
            query: {
              tab: "plans",
            },
          }}
          style={{ pointerEvents: "all" }}
        >
          <Center h="24px" px={1.5} bg="#000D13" borderRadius="md">
            <HStack
              color="primary.500"
              filter="drop-shadow(0px 0px 4px #0088CC)"
              spacing={1}
            >
              <Icon id="Ui/CreativeUnit" h="14px" mt="-1px" />
              <Text color="inherit" size="body.bold.md">
                {totalRemainingCreativeUnits !== undefined
                  ? formatCreativeUnits(totalRemainingCreativeUnits)
                  : "-"}
              </Text>
            </HStack>
          </Center>
        </Link>
      </MenuButton>

      <MenuList
        sx={{
          "& *:focus-visible": {
            boxShadow: "none !important",
          },
        }}
        overflowY="auto"
        w="220px"
        maxH="80vh"
        px={2}
        data-testid="topbar-user-org-menu-content"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        <MenuTitle variant="blurred" text="ORGANIZATIONS" />
        {isTeamLoading && <Spinner ml={2} size="xs" />}

        {teams.map((team) => {
          const isSelected = selectedTeam.id === team.id;
          return (
            <MenuItem
              key={team.id}
              isSelected={isSelected}
              check="icon"
              onClick={() => redirectToTeam(team)}
            >
              <HStack spacing={2}>
                <Avatar
                  w="24px"
                  h="24px"
                  borderRadius="md"
                  label={formatTeamName(team.name)}
                  size="md"
                  singleLetter
                />
                <Box as="span" lineHeight="normal" isTruncated>
                  {team.name}
                </Box>
              </HStack>
            </MenuItem>
          );
        })}

        <MenuDivider />

        <MenuItem
          iconId="Ui/Gear"
          iconH="14px"
          iconContainerProps={{
            w: "24px",
            mr: 0.5,
          }}
          colorScheme="white"
          internalLink="/team"
          data-testid="user-menu-organization-settings-button"
          onClick={() => {
            Track(AnalyticsEvents.Navigation.ClickedTeamSettings);
          }}
          text="Manage Organization"
        />

        <MenuItem
          iconId="Ui/Plus"
          iconH="10px"
          iconContainerProps={{
            w: "24px",
            mr: 0.5,
          }}
          colorScheme="white"
          data-testid="user-menu-new-organization-button"
          onClick={showCreateTeamModal}
          text="Create Organization"
        />
      </MenuList>
    </Menu>
  );
}
