import { useEffect, useMemo, useState } from "react";
import { mapModelsToFiles } from "domains/file-manager/interfaces";
import { ModelTag } from "domains/models/constants/tags";
import { tagIdToLabel } from "domains/tags/utils";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  GetModelsApiArg,
  GetModelsApiResponse,
  GetModelsByModelIdApiResponse,
  useGetModelsQuery,
} from "infra/api/generated/api";
import _ from "lodash";

import { skipToken } from "@reduxjs/toolkit/dist/query";

export type PrivacyFilter = "private" | "public";

export type UseAllModelsArgs = {
  statusesFilter?: GetModelsByModelIdApiResponse["model"]["status"][];
  typesFilter?: GetModelsByModelIdApiResponse["model"]["type"][];
  tagsFilter?: ModelTag[];
  privacyFilter?: PrivacyFilter;
  searchTerm?: string;
  customFilter?: (model: GetModelsByModelIdApiResponse["model"]) => boolean;
  isFeaturedPrioritized?: boolean;
  conceptsFilter?: string[];
  sourcesFilter?: GetModelsByModelIdApiResponse["model"]["source"][];
  authorsFilter?: string[];
  dateRange?: { start: string; end: string | undefined };
  sortDirection?: "asc" | "desc";
} & (
  | {
      collectionId?: string;
      collectionsFilter?: never;
    }
  | {
      collectionId?: never;
      collectionsFilter?: string[];
    }
);

export default function useAllModels({
  statusesFilter,
  typesFilter,
  tagsFilter,
  privacyFilter,
  searchTerm,
  customFilter,
  collectionId,
  collectionsFilter,
  isFeaturedPrioritized,
  conceptsFilter,
  sourcesFilter,
  authorsFilter,
  dateRange,
  sortDirection = "desc",
}: UseAllModelsArgs) {
  const { selectedProject } = useTeamContext();
  const [paginationTokenPublic, setPaginationTokenPublic] = useState<
    string | undefined
  >();
  const [paginationTokenPrivate, setPaginationTokenPrivate] = useState<
    string | undefined
  >();

  const paramsCommon: GetModelsApiArg = useMemo<GetModelsApiArg>(
    () => ({
      pageSize: "500",
      projectId: selectedProject.id,
      collectionId,
    }),
    [selectedProject.id, collectionId]
  );

  const paramsPublic = useMemo<GetModelsApiArg | typeof skipToken>(() => {
    if (privacyFilter === "private") {
      return skipToken;
    }
    return {
      ...paramsCommon,
      privacy: "public",
      paginationToken: paginationTokenPublic,
    };
  }, [paramsCommon, paginationTokenPublic, privacyFilter]);

  const paramsPrivate = useMemo<GetModelsApiArg | typeof skipToken>(() => {
    if (privacyFilter === "public") {
      return skipToken;
    }
    return {
      ...paramsCommon,
      privacy: "private",
      paginationToken: paginationTokenPrivate,
    };
  }, [paramsCommon, paginationTokenPrivate, privacyFilter]);

  const { data: dataPublic, isLoading: isLoadingPublic } =
    useGetModelsQuery(paramsPublic);
  const { data: dataPrivate, isLoading: isLoadingPrivate } =
    useGetModelsQuery(paramsPrivate);

  const models = useMemo<GetModelsApiResponse["models"]>(
    () =>
      _.uniqBy(
        [
          ...(paramsPrivate === skipToken || !dataPrivate?.models
            ? []
            : dataPrivate.models),
          ...(paramsPublic === skipToken || !dataPublic?.models
            ? []
            : dataPublic.models.filter(
                (model) => !model.tags.includes("sc:hidden")
              )),
        ],
        "id"
      ),
    [paramsPublic, paramsPrivate, dataPublic?.models, dataPrivate?.models]
  );
  const isLoading = !!(isLoadingPublic || isLoadingPrivate);
  const hasMore = !!(
    dataPublic?.nextPaginationToken || dataPrivate?.nextPaginationToken
  );

  // ----------------------------------

  useEffect(() => {
    if (dataPublic?.nextPaginationToken) {
      setPaginationTokenPublic(dataPublic?.nextPaginationToken);
    }
  }, [dataPublic?.nextPaginationToken]);

  useEffect(() => {
    if (dataPrivate?.nextPaginationToken) {
      setPaginationTokenPrivate(dataPrivate?.nextPaginationToken);
    }
  }, [dataPrivate?.nextPaginationToken]);

  useEffect(() => {
    setPaginationTokenPublic(undefined);
    setPaginationTokenPrivate(undefined);
  }, [collectionId]);

  const files = useMemo(() => {
    const filteredModels = models.filter((model) => {
      const normalizedFileTags = model.tags.map((item) =>
        tagIdToLabel(item).toLowerCase()
      );
      const searchTermLoweredAndTrimmed =
        searchTerm?.trim().toLowerCase() ?? "";

      const searchMatch =
        !searchTermLoweredAndTrimmed ||
        model.name?.toLowerCase().includes(searchTermLoweredAndTrimmed) ||
        normalizedFileTags.some((tag) =>
          tag.includes(searchTermLoweredAndTrimmed)
        );
      if (!searchMatch) {
        return false;
      }

      const statusMatch =
        !statusesFilter?.length || statusesFilter.includes(model.status);
      if (!statusMatch) {
        return false;
      }

      const typeMatch = (() => {
        if (typesFilter) {
          return (
            !typesFilter?.length ||
            typesFilter?.some((type) => model.type.endsWith(type))
          );
        } else {
          return true;
        }
      })();
      if (!typeMatch) {
        return false;
      }

      const fileTags = model.tags.map((tag) => tag.toLowerCase());
      const tagMatch =
        !tagsFilter?.length ||
        tagsFilter.some((tag) => fileTags.includes(tag.toLowerCase()));
      if (!tagMatch) {
        return false;
      }

      const collectionMatch =
        !collectionsFilter?.length ||
        model.collectionIds.some((id) => collectionsFilter.includes(id));
      if (!collectionMatch) {
        return false;
      }

      const conceptMatch =
        !conceptsFilter?.length ||
        (model.concepts?.some((concept) =>
          conceptsFilter.includes(concept.modelId)
        ) ??
          false);
      if (!conceptMatch) {
        return false;
      }

      const sourceMatch =
        !sourcesFilter?.length || sourcesFilter.includes(model.source);
      if (!sourceMatch) {
        return false;
      }

      const authorMatch =
        !authorsFilter?.length || authorsFilter.includes(model.authorId ?? "");
      if (!authorMatch) {
        return false;
      }

      const dateMatch =
        !dateRange?.start ||
        !dateRange?.end ||
        (model.createdAt >= dateRange.start &&
          model.createdAt <= dateRange.end);
      if (!dateMatch) {
        return false;
      }

      const filterMatch = !customFilter || customFilter(model);
      // eslint-disable-next-line sonarjs/prefer-single-boolean-return
      if (!filterMatch) {
        return false;
      }

      return true;
    });
    return mapModelsToFiles(
      filteredModels.sort((a, b) =>
        sortFunction(a, b, sortDirection, isFeaturedPrioritized)
      )
    );
  }, [
    sortDirection,
    models,
    customFilter,
    searchTerm,
    statusesFilter,
    typesFilter,
    tagsFilter,
    collectionsFilter,
    conceptsFilter,
    sourcesFilter,
    authorsFilter,
    dateRange,
    isFeaturedPrioritized,
  ]);

  return {
    files: files,
    isLoading: isLoading || (hasMore && !files.length),
    hasMore,
    loadMore: () => {},
  };
}

function sortFunction(
  a: GetModelsByModelIdApiResponse["model"],
  b: GetModelsByModelIdApiResponse["model"],
  sortDirection: UseAllModelsArgs["sortDirection"],
  isFeaturedPrioritized: UseAllModelsArgs["isFeaturedPrioritized"]
) {
  if (isFeaturedPrioritized) {
    const aFeatured = a.tags.includes("sc:featured");
    const bFeatured = b.tags.includes("sc:featured");
    if (aFeatured !== bFeatured) {
      return aFeatured ? -1 : 1;
    } else if (aFeatured && bFeatured) {
      const aDate = new Date(a.createdAt);
      const bDate = new Date(b.createdAt);
      return bDate.getTime() - aDate.getTime();
    }
  }

  const aDate = new Date(a.createdAt);
  const bDate = new Date(b.createdAt);
  if (sortDirection === "asc") {
    return aDate.getTime() - bDate.getTime();
  } else {
    return bDate.getTime() - aDate.getTime();
  }
}
