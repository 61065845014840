import React from "react";
import ButtonAddAssetToCollection from "domains/collections/components/ButtonAddAssetToCollection";
import AssetDataDeletion from "domains/pricing/components/AssetDataDeletion";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
} from "infra/api/generated/api";

import { HStack, Skeleton } from "@chakra-ui/react";

import ButtonDownload from "./ButtonDownload";
import ButtonMarkAsThumbnail from "./ButtonMarkAsThumbnail";
import ButtonMenu from "./ButtonMenu";
import ButtonPin from "./ButtonPin";
import ButtonPublish from "./ButtonPublish";
import ButtonRefine from "./ButtonRefine";
import ButtonReloadParameters from "./ButtonReloadParameters";
import ButtonRemove from "./ButtonRemove";
import ButtonUpscale from "./ButtonUpscale";

export const HEADER_HEIGHT = 56;

export type AssetZoomHeaderProps = {
  onCloseRequest: () => void;
} & (
  | {
      isSkeleton: true;
      model?: never;
      inference?: never;
      asset?: never;
      onRemove?: never;
      onAction?: never;
    }
  | {
      isSkeleton?: never;
      model?: GetModelsByModelIdApiResponse["model"];
      inference?: GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"];
      asset?: GetAssetsByAssetIdApiResponse["asset"];
      onRemove: () => void;
      onAction?: () => void;
    }
);

export default function AssetZoomHeader({
  model,
  inference,
  asset,
  onCloseRequest,
  onRemove,
  onAction,
  isSkeleton,
}: AssetZoomHeaderProps) {
  return (
    <HStack
      pos="relative"
      zIndex="calc(var(--chakra-zIndices-sticky) + 3)"
      align="center"
      justify="space-between"
      h={`${HEADER_HEIGHT}px`}
      px={6}
      borderBottomWidth={1}
      bgColor="secondary.900"
      spacing={4}
    >
      <HStack spacing={2}>
        <Button
          onClick={onCloseRequest}
          variant="ghost"
          size="sm"
          colorScheme="secondary"
          p={1.5}
          ml={-2}
        >
          <Icon id="Ui/Cross" color="textPrimary" height="12px" />
        </Button>

        {isSkeleton ? (
          <>
            <Skeleton w="150px" h="36px" borderRadius="md" />
            <Skeleton w="150px" h="36px" borderRadius="md" />
          </>
        ) : (
          <>
            <ButtonRefine asset={asset} model={model} onAction={onAction} />
            <ButtonReloadParameters
              asset={asset}
              inference={inference}
              model={model}
              onAction={onAction}
            />
            <ButtonUpscale asset={asset} onAction={onAction} />
            <ButtonMenu asset={asset} onAction={onAction} />
          </>
        )}
      </HStack>

      <HStack spacing={2}>
        {isSkeleton ? (
          <>
            <Skeleton w="36px" h="36px" borderRadius="md" />
            <Skeleton w="36px" h="36px" borderRadius="md" />
            <Skeleton w="150px" h="36px" borderRadius="md" />
          </>
        ) : (
          <>
            <ButtonPublish asset={asset} />
            <AssetDataDeletion asset={asset} />
            <ButtonRemove asset={asset} onRemove={onRemove} />
            <ButtonDownload model={model} inference={inference} asset={asset} />
            <ButtonPin model={model} asset={asset} />
            <ButtonMarkAsThumbnail model={model} assetUrl={asset?.url} />
            <ButtonAddAssetToCollection
              assets={asset ? [asset] : []}
              isDisabled={!asset}
              menuPlacement="bottom-end"
            />
          </>
        )}
      </HStack>
    </HStack>
  );
}
