import { useCallback } from "react";
import { useAssetReloadPromptLink } from "domains/assets/hooks/useAssetLink";
import { IconButton } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import {
  GetAssetsByAssetIdApiResponse,
  GetJobIdApiResponse,
} from "infra/api/generated/api";

interface CopyPromptButtonProps {
  job: GetJobIdApiResponse["job"] | undefined;
  firstAsset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
  onClick?: (arg: { prompt: string; negativePrompt?: string }) => void;
}

export default function CopyPromptButton({
  job,
  firstAsset,
  onClick,
}: CopyPromptButtonProps) {
  const linkReloadPrompt = useAssetReloadPromptLink(firstAsset);
  const isDisplayed =
    ["success", "failure"].includes(job?.status ?? "") && !!linkReloadPrompt;

  const handleClick = useCallback(() => {
    if (!onClick) return;
    onClick({
      prompt: firstAsset?.metadata.prompt ?? "",
      negativePrompt: firstAsset?.metadata.negativePrompt ?? "",
    });
  }, [
    firstAsset?.metadata.negativePrompt,
    firstAsset?.metadata.prompt,
    onClick,
  ]);

  if (!isDisplayed) return null;

  return (
    <IconButton
      aria-label="Re-use Prompt"
      size="sm"
      variant="secondaryV2"
      icon={<Icon id="Ui/Prompt" h="12px" />}
      tooltip="Re-use Prompt"
      internalLink={!onClick ? linkReloadPrompt : undefined}
      onClick={handleClick}
    />
  );
}
