export type BaseModelId =
  | "stable-diffusion-xl-base-1.0"
  | "rd-juggernaut-xl-v9"
  | "rd-juggernaut-xi-v11";

export const SDXL_BASE_MODELS: { id: BaseModelId; name: string }[] = [
  {
    id: "stable-diffusion-xl-base-1.0",
    name: "Default (SDXL)",
  },
  {
    id: "rd-juggernaut-xl-v9",
    name: "Juggernaut XL V9",
  },
  {
    id: "rd-juggernaut-xi-v11",
    name: "Juggernaut XI V11",
  },
];

export const SDXL_DEFAULT_BASE_MODEL = SDXL_BASE_MODELS[0];
