import React from "react";
import Button, { ButtonProps } from "domains/ui/components/Button";
import { CuIndicator } from "domains/ui/components/ButtonWithCuIndicator";
import Icon, { IconId, IconProps } from "domains/ui/components/Icon";

import {
  Box,
  BoxProps,
  Center,
  Checkbox,
  HStack,
  //eslint-disable-next-line no-restricted-imports
  MenuItem as ChakraMenuItem,
  Text,
} from "@chakra-ui/react";
import { ComponentWithAs } from "@chakra-ui/system";

export interface MenuItemProps extends ButtonProps {
  check?: "checkbox" | "icon";
  text?: React.ReactNode;
  iconId?: IconId;
  iconH?: IconProps["h"];
  iconContainerProps?: BoxProps;
  selectedIconId?: IconId;
  isSelected?: boolean;
  isFocused?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  dataTestId?: string;
  isHidden?: boolean;
  cuCost?: {
    cost: number | undefined;
    isLoading: boolean;
  };
}

function MenuItem({
  check,
  text,
  iconId,
  iconH,
  iconContainerProps,
  selectedIconId,
  isSelected,
  isFocused,
  onClick,
  dataTestId,
  isDisabled,
  isHidden,
  cuCost,
  children,
  ...menuItemProps
}: MenuItemProps) {
  const color: ButtonProps["color"] =
    ((isSelected || isFocused) && "textPrimary") ||
    (isDisabled && "textTertiary") ||
    "textSecondary";

  return (
    <ChakraMenuItem
      as={Button}
      w="100%"
      px={2}
      _hover={{
        bgColor: isFocused
          ? "whiteAlpha.300"
          : check === "checkbox"
          ? "backgroundTertiary.500"
          : undefined,
      }}
      pointerEvents={isDisabled ? "none" : "auto"}
      bgColor={
        (isFocused && "whiteAlpha.300") ||
        (isSelected &&
          isFocused === undefined &&
          check !== "checkbox" &&
          "whiteAlpha.100") ||
        "transparent"
      }
      data-testid={dataTestId}
      isDisabled={isDisabled}
      onClick={!(isDisabled || isHidden) ? onClick : undefined}
      variant="menuItem"
      {...(isHidden && { display: "none", tabIndex: -1 })}
      {...menuItemProps}
      data-group
    >
      <HStack w="full" spacing={1.5}>
        {iconId && (
          <Center w="20px" h="20px" {...iconContainerProps}>
            <Icon
              id={iconId}
              h={iconH}
              color={color}
              _groupHover={{
                color: "textPrimary",
              }}
            />
          </Center>
        )}

        <Box flex={1} overflowX="hidden">
          {!!text && (
            <Text
              color={color}
              lineHeight="normal"
              _groupHover={{
                color: "textPrimary",
              }}
              isTruncated
              size="body.bold.md"
            >
              {text}
            </Text>
          )}
          {children}
        </Box>

        {cuCost && (
          <CuIndicator
            p={0}
            cuCost={cuCost.cost}
            isCuLoading={cuCost.isLoading}
          />
        )}

        {check === "checkbox" && (
          <Checkbox
            ml={2}
            pointerEvents="none"
            colorScheme="primary"
            isChecked={isSelected}
          />
        )}

        {check === "icon" && isSelected && (
          <Box pl={3}>
            <Icon
              id={selectedIconId ?? "Ui/Checkmark"}
              h={!selectedIconId ? "10px" : undefined}
              color="textPrimary"
              data-testid="check-icon"
            />
          </Box>
        )}
      </HStack>
    </ChakraMenuItem>
  );
}

export default MenuItem as ComponentWithAs<"button", MenuItemProps>;
