import { updateCollectionsQueryByDeletingCollection } from "domains/collections/api/updateCollectionsQueryByDeletingCollection";
import { updateCollectionsQueryByUpdatingCollection } from "domains/collections/api/updateCollectionsQueryByUpdatingCollection";
import { API_TAGS } from "infra/store/constants";
import { Endpoints } from "infra/store/interface";
import _ from "lodash";

export const collectionsEndpoints: Endpoints = {
  postCollection: {
    invalidatesTags: [API_TAGS.collection, API_TAGS.limits],
  },
  getCollections: {
    merge(existing, incoming) {
      existing.collections = _.chain(existing.collections)
        .unionBy(incoming.collections, "id")
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
        .value();
      if (!existing?.collections || existing.nextPaginationToken) {
        existing.nextPaginationToken = incoming.nextPaginationToken;
      }
    },
    serializeQueryArgs: ({ endpointName, queryArgs }) => {
      return endpointName + queryArgs.projectId;
    },
    forceRefetch({ currentArg, previousArg }) {
      return !_.isEqual(currentArg, previousArg);
    },
    providesTags: [API_TAGS.collection],
  },
  deleteCollectionsByCollectionId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const { data } = await queryFulfilled;
      if (data) {
        updateCollectionsQueryByDeletingCollection(arg.collectionId, {
          dispatch,
          getState,
        });
      }
    },
    invalidatesTags: [API_TAGS.limits],
  },
  putCollectionsByCollectionId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const { data } = await queryFulfilled;
      if (data) {
        updateCollectionsQueryByUpdatingCollection(data.collection, {
          dispatch,
          getState,
        });
      }
    },
    invalidatesTags: (_result, _error, arg) => {
      return [
        {
          type: API_TAGS.collection,
          id: `collectionId:${arg.collectionId}`,
        },
      ];
    },
  },
  getCollectionsByCollectionId: {
    providesTags: (_result, _error, arg) => {
      return [
        {
          type: API_TAGS.collection,
          id: `collectionId:${arg.collectionId}`,
        },
      ];
    },
  },
};
