import {
  createContext,
  DependencyList,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import _ from "lodash";
import {
  HotkeyCallback,
  Keys,
  //eslint-disable-next-line no-restricted-imports
  useHotkeys as useReactHotKeys,
} from "react-hotkeys-hook";
import {
  Options,
  OptionsOrDependencyArray,
} from "react-hotkeys-hook/dist/types";

import useDeepMemo from "../hooks/useDeepMemo";

interface HotkeysContextValue {
  hotkeys: { [key: string]: string[] };
  addHotkey: (keys: Keys) => string;
  removeHotkey: (id: string) => void;
}

export const HotkeysContext = createContext<HotkeysContextValue>({
  hotkeys: {},
  addHotkey: () => "",
  removeHotkey: () => {},
});

export function HotkeysProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const [hotkeys, setHotkeys] = useState<{ [key: string]: string[] }>({});

  // ----------------------------------

  const addHotkey = useCallback((keys: Keys) => {
    const flattedKeys = (typeof keys === "string" ? [keys] : keys).join(",");
    const id = Math.random().toString();

    setHotkeys((hotkeys) => {
      return {
        ...hotkeys,
        [flattedKeys]: [...(hotkeys[flattedKeys] ?? []), id],
      };
    });

    return id;
  }, []);

  const removeHotkey = useCallback((id: string) => {
    setHotkeys((hotkeys) => {
      const newHotkeys = { ...hotkeys };
      Object.keys(newHotkeys).forEach((key) => {
        newHotkeys[key] = _.without(newHotkeys[key], id);
      });
      return newHotkeys;
    });
  }, []);

  // ----------------------------------

  const contextValue = useMemo(
    () => ({
      hotkeys,
      addHotkey,
      removeHotkey,
    }),
    [hotkeys, addHotkey, removeHotkey]
  );

  return (
    <HotkeysContext.Provider value={contextValue}>
      {children}
    </HotkeysContext.Provider>
  );
}

// ------------------------------------

function useGetIsHotkeyEnabled(id: string | undefined) {
  const { hotkeys } = useContext(HotkeysContext);
  return useMemo(() => {
    if (!id) return false;
    for (const key in hotkeys) {
      if (!hotkeys[key].includes(id)) continue;
      const lastId = _.last(hotkeys[key]);
      return lastId === id;
    }
  }, [hotkeys, id]);
}

// ------------------------------------

export function useHotkeys(
  keys: Keys,
  callback: HotkeyCallback,
  optsOrDeps?: OptionsOrDependencyArray,
  deps?: OptionsOrDependencyArray
) {
  const { addHotkey, removeHotkey } = useContext(HotkeysContext);
  const [hotkeyId, setHotkeyId] = useState<string | undefined>();

  const memoizedKeys = useDeepMemo(keys);
  const options = useMemo(
    () =>
      (optsOrDeps && !Array.isArray(optsOrDeps) ? optsOrDeps : {}) as Options,
    [optsOrDeps]
  );
  const dependencies = (
    Array.isArray(optsOrDeps) ? optsOrDeps : deps
  ) as DependencyList;

  const isInitiallyDisabled = options.enabled === false;
  const isEnabled = useGetIsHotkeyEnabled(hotkeyId);
  const optionsWithEnabled = useMemo(
    () => ({ ...options, enabled: isEnabled }),
    [options, isEnabled]
  );

  useEffect(() => {
    if (isInitiallyDisabled) return;
    const id = addHotkey(memoizedKeys);
    setHotkeyId(id);
    return () => {
      removeHotkey(id);
    };
  }, [addHotkey, removeHotkey, memoizedKeys, isInitiallyDisabled]);

  return useReactHotKeys(
    memoizedKeys,
    (event, ...args) => {
      if (
        (event.target as HTMLInputElement | undefined)?.id.includes("inkeep")
      ) {
        return;
      }
      callback(event, ...args);
    },
    optionsWithEnabled,
    dependencies
  );
}
