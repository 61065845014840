import { ExcludeCu } from "infra/api/ExludeCU";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";
import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { AppRootState } from "infra/store/store";

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

export const updateAssetIdQueryDataByUpdatingAsset = (
  asset: ExcludeCu<GetAssetsByAssetIdApiResponse>["asset"],
  {
    dispatch,
    getState,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    getState: () => AppRootState;
  }
) => {
  for (const {
    endpointName,
    originalArgs,
  } of apiSlice.util.selectInvalidatedBy(getState(), [
    API_TAGS.asset,
  ]) as SelectedInvalidatedByTag[]) {
    if (
      endpointName === "getAssetsByAssetId" &&
      originalArgs.assetId === asset.id
    ) {
      dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (draft) {
            draft.asset = asset;
          }
        })
      );
    }
  }
};
