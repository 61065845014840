import { memo, useMemo } from "react";
import { useModelsByIds } from "domains/models/hooks/useModelsByIds";
import MenuListMultipleChoice from "domains/ui/components/Menu/MenuListMultipleChoice";
import _ from "lodash";

export interface FilterModelsProps {
  label: string;
  value: string[];
  setValue: (value: string[]) => void;
  facets?: {
    [key: string]: number;
  };
  isDisabled?: boolean;
}

export default memo(function FilterModels({
  label,
  value,
  setValue,
  facets,
  isDisabled,
}: FilterModelsProps) {
  const ids = useMemo(() => {
    if (isDisabled) {
      return [];
    }
    return _.uniq([...Object.keys(facets ?? {}), ...value]);
  }, [facets, value, isDisabled]);

  const { models } = useModelsByIds({ modelIds: ids });

  const options = useMemo(() => {
    return ids
      .map((id) => ({
        value: id,
        label: facets?.[id]
          ? `${models[id]?.name ?? id} (${facets[id]})`
          : `${models[id]?.name ?? id}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [ids, facets, models]);

  return (
    <MenuListMultipleChoice
      label={label}
      options={options}
      setSelectedValues={setValue}
      selectedValues={value}
      isDisabled={isDisabled}
    />
  );
});
