import { useCallback, useState } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { expandTagItems } from "domains/tags/utils";
import { isTagValid } from "domains/tags/utils/validateTag";
import ScenarioInput from "domains/ui/components/ScenarioInput";

import { Text } from "@chakra-ui/react";

export default function TagInput({
  currentTags,
  onAddTags,
  inputRef,
}: {
  currentTags: string[];
  onAddTags: (tags: string[]) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}) {
  const { errorToast } = useScenarioToast();
  const [newTag, setNewTag] = useState("");

  const handleAddTag = useCallback(() => {
    const newPotentialTag = isTagValid(newTag);

    if (newPotentialTag.isValid) {
      if (currentTags.includes(newTag)) {
        errorToast({
          title: "Tag already exists",
        });
        return;
      }
      onAddTags(expandTagItems(newTag));
      setNewTag("");
    } else {
      errorToast({
        title: newPotentialTag.message,
      });
    }
  }, [currentTags, errorToast, newTag, onAddTags]);

  return (
    <ScenarioInput
      ref={inputRef}
      value={newTag}
      setValue={setNewTag}
      placeholder={"Add a tag"}
      onEnter={handleAddTag}
      containerProps={{ h: 8 }}
      leftComponent={
        <Text mt={"2px"} size="body.lg">
          #
        </Text>
      }
    />
  );
}
