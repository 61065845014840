import { PropsWithChildren } from "react";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import { Box, Fade, HStack, Text, VStack } from "@chakra-ui/react";

export interface AsideDrawerProps extends PropsWithChildren {
  title?: string;
  open: boolean;
  onClose: () => void;
}

export default function AsideDrawer({
  title,
  open: isOpen,
  onClose,
  children,
}: AsideDrawerProps) {
  return (
    <Fade in={isOpen} unmountOnExit>
      <VStack
        pos="absolute"
        top={0}
        left={0}
        align="stretch"
        w="100%"
        h="100%"
        borderLeftWidth={1}
        borderLeftColor="border.500"
        bgColor="backgroundTertiary.500"
        spacing={0}
      >
        <Box>
          <HStack
            h="56px"
            px={6}
            borderBottomWidth={1}
            borderBottomColor="border.500"
            spacing={2}
          >
            <Button
              variant="ghost"
              colorScheme="white"
              size="sm"
              p={0}
              ml={-3}
              onClick={onClose}
            >
              <Icon id="Ui/ChevronLeft" h="10px" />
            </Button>

            <Text flex={1} size="body.bold.md">
              {title}
            </Text>
          </HStack>
        </Box>

        <Box flex={1} overflowY="auto" px={6} py={4}>
          {children}
        </Box>
      </VStack>
    </Fade>
  );
}
