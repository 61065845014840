import { useCallback, useMemo, useState } from "react";
import CanvasListEmptyState from "domains/canvas/components/CanvasListEmptyState";
import {
  LOCKED_ASSET_ID_TO_FORCE_UNLOCK_KEY,
  LOCKED_ASSET_MODEL_ID,
  useCanvasLock,
} from "domains/canvas/hooks/useCanvasLock";
import ButtonAddAssetToCollection from "domains/collections/components/ButtonAddAssetToCollection";
import DefaultFilePreview, {
  FilePreviewProps,
} from "domains/file-manager/components/FilePreview";
import {
  Action,
  FileCanvasType,
  FileHandler,
} from "domains/file-manager/interfaces";
import useRouter from "domains/navigation/hooks/useRouter";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import Button from "domains/ui/components/Button";
import ButtonWithModal from "domains/ui/components/ButtonWithModal";
import Icon from "domains/ui/components/Icon";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { chunkQuery } from "infra/api/chunkQuery";
import { useHandleApiError } from "infra/api/error";
import {
  useCopyAssetByAssetIdMutation,
  useDeleteAssetMutation,
  usePutAssetByAssetIdMutation,
} from "infra/api/generated/api";

import { Box, Flex, Tooltip } from "@chakra-ui/react";

interface FileCanvasHandlerArgs {
  emptyState?: JSX.Element;
  onOpen?: () => void;
  onDelete?: (files: FileCanvasType[]) => void;
  onUndoDelete?: (files: FileCanvasType[]) => void;
  withLink?: boolean;
}

export function useFileCanvasHandler({
  emptyState,
  onOpen,
  onDelete,
  onUndoDelete,
  withLink,
}: FileCanvasHandlerArgs): FileHandler<FileCanvasType> {
  const router = useRouter();
  const [triggerDeleteAsset, { isLoading: isLoadingDeleteAssetById }] =
    useDeleteAssetMutation();
  const [triggerCopyAsset] = useCopyAssetByAssetIdMutation();
  const { selectedProject } = useTeamContext();
  const handleApiError = useHandleApiError();
  const { successToast } = useScenarioToast();
  const { showLimitModal } = usePlanContext();
  const [isLoadingCopyAssetById, setIsLoadingCopyAssetById] = useState(false);

  // ----------------------------------

  const handleDelete = useCallback(
    async (files: FileCanvasType[]) => {
      try {
        onDelete?.(files);
        const assetIds = files.map((file) => file.id);

        const chunkedTriggerDeleteImage = chunkQuery(
          triggerDeleteAsset,
          "body.assetIds",
          100
        );

        await chunkedTriggerDeleteImage({
          body: { assetIds },
          projectId: selectedProject.id,
        });

        Track(AnalyticsEvents.Canvas.Delete, {
          assetIds,
        });

        successToast({
          title: "Projects deleted",
        });
      } catch (error) {
        onUndoDelete?.(files);
        handleApiError(
          error,
          "There was an error deleting the canvas projects"
        );
      }
    },
    [
      handleApiError,
      triggerDeleteAsset,
      successToast,
      selectedProject.id,
      onDelete,
      onUndoDelete,
    ]
  );

  const [putAssetTrigger] = usePutAssetByAssetIdMutation();
  const handleDuplicate = useCallback(
    async (files: FileCanvasType[]) => {
      setIsLoadingCopyAssetById(true);
      for (const file of files) {
        try {
          const newAsset = await triggerCopyAsset({
            projectId: selectedProject.id,
            assetId: file.meta.id,
          }).unwrap();

          await putAssetTrigger({
            projectId: selectedProject.id,
            assetId: newAsset.asset.id,
            body: {
              name: `Copy of ${file.name}`,
            },
          });

          Track(AnalyticsEvents.Canvas.Duplicate, {
            assetId: file.meta.id,
          });
        } catch (error) {
          handleApiError(error, "There was an error duplicating the canvas", {
            quota: async () => {
              showLimitModal("planCanvasProjects");
            },
          });
        }
      }
      setIsLoadingCopyAssetById(false);
    },
    [
      triggerCopyAsset,
      selectedProject.id,
      putAssetTrigger,
      handleApiError,
      showLimitModal,
    ]
  );

  // ----------------------------------

  const duplicateAction = useMemo<Action<FileCanvasType>>(
    () => ({
      kind: ["selectionBar"],
      label: "Duplicate",
      onAction: handleDuplicate,
      Component: ({ files, onAction }) => (
        <Button
          colorScheme={"danger"}
          isLoading={isLoadingCopyAssetById}
          leftIcon={<Icon id="Ui/Copy" />}
          onClick={() => onAction(files)}
          variant="secondary"
        >
          Duplicate
        </Button>
      ),
    }),
    [handleDuplicate, isLoadingCopyAssetById]
  );

  const deleteAction = useMemo<Action<FileCanvasType>>(
    () => ({
      kind: ["selectionBar"],
      label: "Delete",
      onAction: handleDelete,
      Component: ({ files, onAction }) => (
        <ButtonWithModal
          variant="secondary"
          leftIcon={<Icon id="Ui/Trash" />}
          onConfirm={() => onAction(files)}
          modalHeader={`Delete Project${files.length > 1 ? "s" : ""}`}
          modalBody={`Are you sure you want to delete ${
            files.length > 1 ? "these" : "this"
          } project${files.length > 1 ? "s" : ""}?`}
          modalColorScheme="danger"
          isLoading={isLoadingDeleteAssetById}
          isModalConfirmButtonLoading={isLoadingDeleteAssetById}
          isDisabled={isLoadingDeleteAssetById}
        >
          Delete
        </ButtonWithModal>
      ),
      // TODO: Implement the shortcut handler
      // shortcut: (e: KeyboardEvent) =>
      //   e.key === "Delete" || e.key === "Backspace",
    }),
    [handleDelete, isLoadingDeleteAssetById]
  );

  const actions = useMemo<Action<FileCanvasType>[]>(
    () => [
      duplicateAction,
      deleteAction,
      {
        kind: ["selectionBar"],
        label: "Collections",
        onAction: (_) => {},
        Component: ({ files }) => (
          <ButtonAddAssetToCollection
            assets={files.map((item) => item.meta)}
            menuPlacement="top"
            assetType="project"
          />
        ),
      },
    ],
    [duplicateAction, deleteAction]
  );

  return {
    // TODO: enable when we are sure model list and asset gallery are integrated into file manager fully
    EmptyState: emptyState ?? <CanvasListEmptyState />,
    FilePreview: FileCanvasPreview,
    actions,
    onOpen: (file: FileCanvasType) => {
      onOpen?.();
      void router.push({
        pathname: "/canvas/[id]",
        query: {
          id: file.id,
        },
      });
    },
    withLink,
  };
}

// ------------------------------------

function FileCanvasPreview(props: FilePreviewProps<FileCanvasType>) {
  const { isAssetLocked } = useCanvasLock();

  const shouldLockAsset = isAssetLocked(
    props.file.id,
    props.file.meta?.metadata?.lockExpiresAt
  );
  return (
    <Box>
      <ExpiredLockPreview file={props.file} isLocked={shouldLockAsset}>
        <DefaultFilePreview
          {...(props as any)}
          file={{
            ...props.file,
            thumbnail:
              props.file.thumbnail + (shouldLockAsset ? "&blur=20" : ""),
          }}
        />
      </ExpiredLockPreview>
    </Box>
  );
}

// ------------------------------------

interface ExpiredLockPreviewProps {
  file: FileCanvasType;
  isLocked: boolean;
  children: React.ReactNode;
}

function ExpiredLockPreview({
  file,
  isLocked,
  children,
}: ExpiredLockPreviewProps) {
  const { setDisplayLockModal } = useCanvasLock();

  const getModelId = async (url: string) => {
    const response = await fetch(url);
    const json = await response.json();
    sessionStorage.setItem(LOCKED_ASSET_MODEL_ID, json.modelId);
  };

  const handleOpenLockCanvas = async (e: React.MouseEvent) => {
    e.preventDefault();
    await getModelId(file.meta.url);
    sessionStorage.setItem(LOCKED_ASSET_ID_TO_FORCE_UNLOCK_KEY, file.id);
    setDisplayLockModal(true);
  };

  if (isLocked) {
    return (
      <Box onClick={handleOpenLockCanvas}>
        {children}
        <Flex
          pos="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
          align="center"
          justify="center"
          data-outside-click-excluded
        >
          <Tooltip
            hasArrow
            label={"This project is temporarily locked"}
            placement="auto"
          >
            <Icon
              transform={"translate(0, -20%)"}
              w="50%"
              h="50%"
              id="Ui/Lock"
              filter="drop-shadow(0px 0px 2px rgb(0 0 0))"
            />
          </Tooltip>
        </Flex>
      </Box>
    );
  } else {
    return <>{children}</>;
  }
}
