import React from "react";
import Icon from "domains/ui/components/Icon";

import {
  //eslint-disable-next-line no-restricted-imports
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from "@chakra-ui/react";

export default React.forwardRef<HTMLSelectElement, ChakraSelectProps>(
  function Select(props, ref) {
    return (
      <ChakraSelect
        ref={ref}
        icon={<Icon id="Domains/Theme/ComponentSelectArrows" />}
        iconSize={"16px"}
        {...props}
      />
    );
  }
);
