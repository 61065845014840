import { useCallback } from "react";
// eslint-disable-next-line no-restricted-imports
import { Router, useRouter as useNextRouter } from "next/router";
import type { Route } from "nextjs-routes";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";

type TransitionOptions = Omit<
  NonNullable<Parameters<Router["push"]>[2]>,
  "locale"
>;

type Path = {
  pathname?: Route["pathname"];
  query?: Route["query"];
};

export default function useRouter() {
  const { selectedTeam, selectedProject } = useTeamContext();
  const router = useNextRouter();

  const push = useCallback(
    async (path: Path, as?: string, options?: TransitionOptions) => {
      await router.push(
        getPathWithTeamIdAndProjectId(path, {
          teamId: selectedTeam.id,
          projectId: selectedProject.id,
        }),
        as,
        options
      );
    },
    [router, selectedTeam, selectedProject]
  );

  const replace = useCallback(
    async (path: Path, as?: string, options?: TransitionOptions) => {
      await router.replace(
        getPathWithTeamIdAndProjectId(path, {
          teamId: selectedTeam.id,
          projectId: selectedProject.id,
        }),
        as,
        options
      );
    },
    [router, selectedTeam, selectedProject]
  );

  return {
    ...router,
    push,
    replace,
    nextRouterPush: router.push,
    nextRouterReplace: router.replace,
  };
}

function getPathWithTeamIdAndProjectId(
  path: Path,
  defaultQuery: {
    teamId: string;
    projectId: string;
  }
) {
  const { pathname, query } = path;

  if (pathname === "/login" || pathname === "/see-you-soon") {
    return path;
  }

  const queryWithTeamIdAndProjectId = {
    ...query,
    teamId: query?.teamId ?? defaultQuery.teamId,
    projectId: query?.projectId ?? defaultQuery.projectId,
  };

  return pathname
    ? {
        pathname,
        query: queryWithTeamIdAndProjectId,
      }
    : { query: queryWithTeamIdAndProjectId };
}
