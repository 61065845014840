import { Box } from "@chakra-ui/react";

export default function NewsIndicator() {
  return (
    <Box
      pos="absolute"
      top="-4px"
      right="-4px"
      w="16px"
      h="16px"
      bg="softRed"
      borderWidth={3}
      borderColor="black.500"
      borderRadius="full"
    />
  );
}
