import { useMemo } from "react";
import { InferenceBuilderErrors } from "domains/canvas/hooks/useInferenceBuilderErrors";
import Alert from "domains/ui/components/Alert";

interface SidebarAlertErrorsProps {
  errors: InferenceBuilderErrors;
}

export default function SidebarAlertErrors({
  errors,
}: SidebarAlertErrorsProps) {
  const sortedAndFilteredErrors = useMemo(() => {
    return Object.values(errors)
      .filter((error) => !!error)
      .sort((a, b) => {
        if (a?.type === "error" && b?.type === "warning") {
          return 1;
        }
        if (a?.type === "warning" && b?.type === "error") {
          return -1;
        }
        return 0;
      })
      .filter((error) => error.type === "warning" || error?.type === "error");
  }, [errors]);

  if (!sortedAndFilteredErrors) {
    return null;
  }

  return (
    <>
      {Object.values(errors).map(
        (error) =>
          error && (
            <Alert
              variant="discrete"
              key={error.message}
              type={"info"}
              title={error.message}
              message={error.autoFix}
            />
          )
      )}
    </>
  );
}
