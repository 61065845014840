import React, { PropsWithChildren, useCallback } from "react";
import { GuideKeys } from "domains/guide/constants/guides";
import { useSectionsContext } from "domains/inference/contexts/SectionsProvider";
import { extraTheme } from "domains/theme";
import Icon from "domains/ui/components/Icon";
import TourGuideIcon from "domains/ui/components/TourGuideIcon";

import {
  Box,
  BoxProps,
  Collapse,
  HStack,
  StackProps,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";

export interface SidebarSectionProps extends PropsWithChildren {
  compact?: boolean;
  headerProps?: {
    id: string;
    title: string;
    tooltip?: string;
    guide?: GuideKeys;
    noCollapse?: boolean;
    topRight?: React.ReactElement;
    style?: StackProps;
  };
  contentBoxStyle?: BoxProps;
}

const SidebarSection = React.forwardRef<HTMLDivElement, SidebarSectionProps>(
  function SidebarSection(
    { headerProps, compact: isCompact, contentBoxStyle, children },
    ref
  ) {
    const {
      id,
      title,
      tooltip,
      guide,
      topRight,
      noCollapse,
      style: headerStyle,
    } = headerProps ?? {};
    const { collapsedSections, toggleCollapsedSection } = useSectionsContext();
    const isCollapsed =
      (id ? collapsedSections.includes(id) : false) && !noCollapse;

    const handleToggleRequest = useCallback(() => {
      if (!id) return;
      toggleCollapsedSection(id);
    }, [toggleCollapsedSection, id]);

    return (
      <VStack ref={ref} align="stretch" w="full" spacing={0}>
        {!!title && (
          <HStack
            h="52px"
            px={isCompact ? 2 : 6}
            userSelect="none"
            spacing={2}
            {...headerStyle}
          >
            <HStack
              flex={1}
              pointerEvents={noCollapse ? "none" : undefined}
              cursor="pointer"
              data-group
              onClick={handleToggleRequest}
              spacing={2}
            >
              {!noCollapse && (
                <VStack
                  w="16px"
                  color="textSecondary"
                  _groupHover={{ color: "textPrimary" }}
                >
                  {isCollapsed ? <IconCollapsed /> : <IconUncollapsed />}
                </VStack>
              )}
              <HStack flex={1} spacing={0}>
                <Text
                  color="textSecondary"
                  _groupHover={{ color: "textPrimary" }}
                  size="body.bold.md"
                >
                  {title}
                </Text>

                {tooltip && (
                  <Tooltip color={"inherit"} label={tooltip}>
                    <Icon
                      id="Ui/Help"
                      ml={2}
                      opacity={0}
                      _groupHover={{ opacity: 1 }}
                      transition={extraTheme.transitions.fast}
                      color={"textTertiary"}
                    />
                  </Tooltip>
                )}

                {guide && (
                  <TourGuideIcon
                    guide={guide}
                    opacity={0}
                    _groupHover={{ opacity: 1 }}
                    ml={2}
                  />
                )}
              </HStack>
            </HStack>

            {!!topRight && <Box>{topRight}</Box>}
          </HStack>
        )}

        <Collapse animateOpacity in={!isCollapsed}>
          <Box
            pt={title ? 0 : 4}
            pb={4}
            px={isCompact ? 2 : 6}
            {...contentBoxStyle}
          >
            {children}
          </Box>
        </Collapse>
      </VStack>
    );
  }
);

export default SidebarSection;

// ------------------------------------

function IconCollapsed() {
  return <Icon id="Ui/ChevronRight" h="10px" />;
}

function IconUncollapsed() {
  return <Icon id="Ui/ChevronDown" h="6px" />;
}
