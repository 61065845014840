import { useCallback, useState } from "react";
import { findSrcFromHtml } from "domains/commons/dom";

export interface UseImageUploadDragDropProps {
  onImageDrop?: (result: {
    dragUrl?: string;
    assetId?: string;
  }) => Promise<void>;
  allowEventFromApp?: boolean;
  stopPropagation?: boolean;
}

export default function useImageUploadDragDrop({
  onImageDrop,
  allowEventFromApp = true,
}: UseImageUploadDragDropProps) {
  const [isDraggingHover, setIsDraggingHover] = useState(0);

  const onDragEnter = useCallback(
    (event: React.DragEvent<HTMLElement>) => {
      if (!allowEventFromApp && !event.dataTransfer.types.includes("Files")) {
        return;
      }

      setIsDraggingHover((prevState) => prevState + 1);
    },
    [allowEventFromApp]
  );

  const onDragLeave = useCallback(
    (event: React.DragEvent<HTMLElement>) => {
      if (!allowEventFromApp && !event.dataTransfer.types.includes("Files")) {
        return;
      }

      setIsDraggingHover((prevState) => {
        if (prevState > 0) {
          return prevState - 1;
        }
        return prevState;
      });
    },
    [allowEventFromApp]
  );

  const onDragOver = useCallback((event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const onDrop = useCallback(
    (
      event: React.DragEvent<HTMLElement>,
      callback?: (event: React.DragEvent<HTMLElement>) => void
    ) => {
      setIsDraggingHover(0);

      const dragUrl = event?.dataTransfer?.getData("URL");
      const assetId =
        findAssetIdInScenarioImgUrl(dragUrl) ??
        findAssetIdInScenarioImgUrl(
          findSrcFromHtml(event?.dataTransfer?.getData("text/html"))
        );

      if (dragUrl) {
        void onImageDrop?.({ dragUrl, assetId });
      }

      if (callback) {
        callback(event);
      }
    },
    [onImageDrop]
  );

  return {
    isDraggingHover: isDraggingHover > 0,
    dragFunctions: {
      onDragEnter,
      onDragLeave,
      onDragOver,
    },
    onDrop,
  };
}

function findAssetIdInScenarioImgUrl(imgUrl: string | undefined) {
  if (!imgUrl) return;
  if (
    imgUrl.includes(".scenario.com/assets") ||
    imgUrl.includes(".scenario-labs.io/assets")
  ) {
    return imgUrl.split("/")?.pop()?.split("?")[0];
  } else if (
    imgUrl.startsWith(`${process.env.NEXT_PUBLIC_CDN_URL}/thumbnails/`)
  ) {
    return imgUrl.replace(`${process.env.NEXT_PUBLIC_CDN_URL}/thumbnails/`, "");
  }
}
