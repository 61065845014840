import { useCallback, useMemo } from "react";
import useDeepMemo from "domains/commons/hooks/useDeepMemo";
import { PersistedStateKey } from "domains/commons/hooks/usePersistedState";
import {
  FilterAssetCollectionOption,
  FilterAssetCollectionValue,
} from "domains/file-manager/constants/AssetFilter";
import { GridViewKey } from "domains/file-manager/constants/GridView";
import { FmFileImage } from "domains/file-manager/interfacesV2";
import useRouter from "domains/navigation/hooks/useRouter";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { chunkQuery } from "infra/api/chunkQuery";
import { useHandleApiError } from "infra/api/error";
import { useDeleteAssetMutation } from "infra/api/generated/api";

import FileManager, { FileManagerProps } from "../FileManagerV2";
import FmHeaderView, { FmHeaderViewProps } from "../FileManagerV2/FmHeaderView";
import { FmSelectionActionsProps } from "../FileManagerV2/FmSelectionActions";

import { ActionType, FmImageCardActionsProps } from "./Card/Actions";
import { CardContentProps } from "./Card/Content";
import { FmImageListActionsProps } from "./List/Actions";
import Grid, { GridCustomProps } from "./Grid";
import HeaderClearSession, {
  HeaderClearSessionProps,
} from "./HeaderClearSession";
import HeaderFilterAssetType, {
  HeaderFilterAssetTypeProps,
} from "./HeaderFilterAssetType";
import HeaderFilterAuthor, {
  HeaderFilterAuthorProps,
} from "./HeaderFilterAuthor";
import HeaderFilterCollection from "./HeaderFilterCollection";
import HeaderFilterDate, { HeaderFilterDateProps } from "./HeaderFilterDate";
import HeaderFilterJobType, {
  HeaderFilterJobTypeProps,
} from "./HeaderFilterJobType";
import HeaderSort, { HeaderSortProps } from "./HeaderSort";
import List, { ListCustomProps } from "./List";
import SelectionActions, {
  SelectionActionsCustomProps,
  SelectionActionsProps,
} from "./SelectionActions";
import VirtualFiles, { VirtualFilesCustomProps } from "./VirtualFiles";

export type FileManagerImageProps<
  L extends object = object,
  C extends object = object,
  ES extends object = object
> = Omit<
  FileManagerProps<"image", object, object, object, object, ES>,
  | "type"
  | "settingsCacheKey"
  | "gridProps"
  | "GridComponent"
  | "listProps"
  | "ListComponent"
  | "virtualFilesProps"
  | "VirtualFilesComponent"
  | "selectionActionsProps"
  | "SelectionActionsComponent"
  | "onCardClick"
  | "withLink"
> &
  Partial<
    Pick<
      FileManagerProps<"image">,
      "settingsCacheKey" | "onCardClick" | "withLink"
    >
  > & {
    variant?: "image" | "skybox" | "texture";

    isWithoutAssetZoom?: boolean;
    isOriginalAssetsInAssetZoom?: boolean;
    assetZoomAdditionalFiles?: FmFileImage[];
    assetZoomPriorityLevel?: number;
    onAssetZoomAction?: () => void;

    ignoreActions?: SelectionActionsProps["ignoreActions"];
    isVaryEnabled?: boolean;
    onVary?: () => void;

    view: GridViewKey;
    viewOptions?: GridViewKey[];
    onViewChange?: (value: GridViewKey) => void;

    headerFilterAssetProps?: HeaderFilterAssetTypeProps;
    headerFilterJobProps?: HeaderFilterJobTypeProps;
    headerFilterAuthorProps?: HeaderFilterAuthorProps;
    headerFilterDateProps?: HeaderFilterDateProps;
    headerSortProps?: HeaderSortProps;
    headerOnClearSession?: HeaderClearSessionProps;

    filterCollection?: FilterAssetCollectionValue;
    filterCollectionOptions?: FilterAssetCollectionOption[];
    isFilterCollectionLoading?: boolean;
    onFilterCollectionChange?: (
      value: FilterAssetCollectionValue | undefined
    ) => void;

    onDelete?: (files: FmFileImage[]) => void;
    onUndoDelete?: (files: FmFileImage[]) => void;

    listActionsProps?: L;
    ListActionsComponent?: React.FunctionComponent<FmImageListActionsProps<L>>;

    pinnedFileIds?: string[];
    onCardPinClick?: CardContentProps["onPinClick"];

    isCardWithoutActions?: boolean;
    cardActionsProps?: C;
    CardActionsComponent?: React.FunctionComponent<FmImageCardActionsProps<C>>;
    onActionClick?: (type: ActionType) => void;

    SelectionActionsComponent?: React.FunctionComponent<
      FmSelectionActionsProps<"image", SelectionActionsCustomProps>
    >;
  };

export default function FileManagerImage<
  L extends object = object,
  C extends object = object,
  ES extends object = object
>({
  settingsCacheKey,
  onCardClick,
  withLink,

  variant = "image",

  isWithoutAssetZoom = false,
  isOriginalAssetsInAssetZoom = false,
  assetZoomAdditionalFiles,
  assetZoomPriorityLevel = 0,
  onAssetZoomAction,

  ignoreActions,
  isVaryEnabled = false,
  onVary,

  view,
  viewOptions,
  onViewChange,

  headerFilterAssetProps,
  headerFilterJobProps,
  headerFilterAuthorProps,
  headerFilterDateProps,
  headerSortProps,
  headerOnClearSession,
  filterCollection,
  filterCollectionOptions,
  isFilterCollectionLoading,
  onFilterCollectionChange,
  headerLeftContent,
  headerRightContent,

  onDelete,
  onUndoDelete,

  listActionsProps,
  ListActionsComponent,

  pinnedFileIds,
  onCardPinClick,

  isCardWithoutActions = false,
  cardActionsProps,
  CardActionsComponent,
  onActionClick,

  SelectionActionsComponent,

  ...props
}: FileManagerImageProps<L, C, ES>) {
  const router = useRouter();
  const { successToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { selectedProject } = useTeamContext();
  const [deleteImageTrigger, { isLoading: isDeleting }] =
    useDeleteAssetMutation();

  const memoizedIgnoreActions = useDeepMemo(ignoreActions);
  const validIgnoreActions = useMemo(
    () =>
      variant === "skybox"
        ? [...new Set([...(memoizedIgnoreActions ?? []), "removeBg" as const])]
        : memoizedIgnoreActions,
    [variant, memoizedIgnoreActions]
  );

  const cacheKey =
    settingsCacheKey ??
    {
      image: PersistedStateKey.FILE_MANAGER_IMAGE_V2,
      skybox: PersistedStateKey.FILE_MANAGER_SKYBOX_V2,
      texture: PersistedStateKey.FILE_MANAGER_TEXTURE_V2,
    }[variant];
  const initialColumnsCount = {
    image: 4,
    skybox: 3,
    texture: 4,
  }[variant];

  // ----------------------------------

  const handleCardClick = useCallback(
    (file: FmFileImage) => {
      router.query.openAssetId = file.id;
      void router.push({ query: router.query }, undefined, { scroll: false });
    },
    [router]
  );

  const handleDelete = useCallback(
    async (files: FmFileImage[]) => {
      const fileType = {
        image: "Images",
        skybox: "Skyboxes",
        texture: "Textures",
      }[variant];

      try {
        onDelete?.(files);

        const chunkedTriggerDeleteImage = chunkQuery(
          deleteImageTrigger,
          "body.assetIds",
          100
        );

        chunkedTriggerDeleteImage({
          body: { assetIds: files.map((file) => file.id) },
          projectId: selectedProject.id,
        }).unwrap();

        successToast({
          title: `${fileType} deleted`,
        });
      } catch (error) {
        onUndoDelete?.(files);
        handleApiError(
          error,
          `There was an error deleting the ${fileType.toLowerCase()}`
        );
      }
    },
    [
      variant,
      handleApiError,
      deleteImageTrigger,
      successToast,
      selectedProject.id,
      onDelete,
      onUndoDelete,
    ]
  );

  // ----------------------------------

  const selectionActionsProps = useMemo<
    Required<
      Pick<
        FileManagerProps<"image", object, SelectionActionsCustomProps>,
        "SelectionActionsComponent" | "selectionActionsProps"
      >
    >
  >(
    () => ({
      SelectionActionsComponent: SelectionActionsComponent ?? SelectionActions,
      selectionActionsProps: {
        isDeleting,
        onDeleteClick: handleDelete,
        ignoreActions: validIgnoreActions,
      },
    }),
    [SelectionActionsComponent, isDeleting, handleDelete, validIgnoreActions]
  );

  const virtualFilesProps = useMemo<
    Required<
      Pick<
        FileManagerProps<"image", VirtualFilesCustomProps, object>,
        "VirtualFilesComponent" | "virtualFilesProps"
      >
    >
  >(
    () => ({
      VirtualFilesComponent: VirtualFiles,
      virtualFilesProps: {
        assetZoomAdditionalFiles,
        isWithoutAssetZoom,
        isOriginalAssetsInAssetZoom,
        assetZoomPriorityLevel,
        onAssetZoomAction,
        hasMore: props.hasMore,
        onEndReached: props.onEndReached,
        onDeleteClick: (files) => onDelete?.(files),
      },
    }),
    [
      assetZoomAdditionalFiles,
      onDelete,
      isOriginalAssetsInAssetZoom,
      isWithoutAssetZoom,
      assetZoomPriorityLevel,
      onAssetZoomAction,
      props.hasMore,
      props.onEndReached,
    ]
  );

  const gridProps = useMemo<
    Required<
      Pick<
        FileManagerProps<"image", object, object, GridCustomProps<C>>,
        "GridComponent" | "gridProps"
      >
    >
  >(
    () => ({
      GridComponent: Grid,
      gridProps: {
        variant,
        view: (() => {
          if (view === "jobs" && variant === "skybox") {
            return "masonry";
          } else if (view === "jobs" && variant === "texture") {
            return "masonry";
          } else if (view === "jobs") {
            return "fit";
          } else {
            return view;
          }
        })(),
        pinnedFileIds,
        onCardPinClick,
        isCardWithoutActions,
        isVaryEnabled,
        onVary,
        CardActionsComponent,
        cardActionsProps: cardActionsProps ?? ({} as C),
        onActionClick,
      },
    }),
    [
      variant,
      pinnedFileIds,
      onCardPinClick,
      isCardWithoutActions,
      isVaryEnabled,
      onVary,
      CardActionsComponent,
      cardActionsProps,
      onActionClick,
      view,
    ]
  );

  const listProps = useMemo<
    Required<
      Pick<
        FileManagerProps<
          "image",
          object,
          object,
          object,
          ListCustomProps<L, C>
        >,
        "ListComponent" | "listProps"
      >
    >
  >(
    () => ({
      ListComponent: List,
      listProps: {
        variant,
        isVaryEnabled,
        onVary,
        ListActionsComponent,
        listActionsProps: listActionsProps ?? ({} as L),
        pinnedFileIds,
        onCardPinClick,
        isCardWithoutActions,
        CardActionsComponent,
        cardActionsProps: cardActionsProps ?? ({} as C),
        onActionClick,
      },
    }),
    [
      variant,
      ListActionsComponent,
      listActionsProps,
      pinnedFileIds,
      onCardPinClick,
      isCardWithoutActions,
      CardActionsComponent,
      cardActionsProps,
      onActionClick,
      isVaryEnabled,
      onVary,
    ]
  );

  const headerViewProps = useMemo<FmHeaderViewProps | undefined>(
    () =>
      onViewChange
        ? {
            onChange: onViewChange,
            value: view,
            options:
              ["skybox", "texture"].includes(variant) &&
              viewOptions === undefined
                ? ["masonry", "jobs"]
                : viewOptions,
          }
        : undefined,
    [variant, onViewChange, view, viewOptions]
  );

  return (
    <FileManager
      type="image"
      {...selectionActionsProps}
      {...virtualFilesProps}
      {...gridProps}
      {...listProps}
      settingsCacheKey={cacheKey}
      initialColumnsCount={initialColumnsCount}
      onCardClick={onCardClick ?? handleCardClick}
      withLink={withLink}
      headerLeftContent={
        <>
          {!!headerFilterAssetProps && (
            <HeaderFilterAssetType {...headerFilterAssetProps} />
          )}

          {!!headerFilterJobProps && (
            <HeaderFilterJobType {...headerFilterJobProps} />
          )}

          {!!headerFilterAuthorProps && (
            <HeaderFilterAuthor {...headerFilterAuthorProps} />
          )}

          {!!headerFilterDateProps && (
            <HeaderFilterDate {...headerFilterDateProps} />
          )}

          {!!headerOnClearSession && (
            <HeaderClearSession {...headerOnClearSession} />
          )}

          {filterCollectionOptions && onFilterCollectionChange && (
            <HeaderFilterCollection
              value={filterCollection}
              options={filterCollectionOptions}
              isLoading={isFilterCollectionLoading}
              onChange={onFilterCollectionChange}
            />
          )}

          {headerLeftContent}
        </>
      }
      headerRightContent={
        <>
          {!!headerSortProps && <HeaderSort {...headerSortProps} />}
          {!!headerViewProps && <FmHeaderView {...headerViewProps} />}
          {headerRightContent}
        </>
      }
      assetZoomPriorityLevel={assetZoomPriorityLevel}
      {...props}
    />
  );
}
