import React, { useRef } from "react";
import Link from "next/link";
import { useAssetUpscaleLink } from "domains/assets/hooks/useAssetLink";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { appShortcuts } from "domains/shortcuts/components/appShortcuts";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

export interface ButtonUpscaleProps {
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
  onAction?: () => void;
}

export default function ButtonUpscale({ asset, onAction }: ButtonUpscaleProps) {
  const upscaleButtonRef = useRef<HTMLButtonElement>(null);

  const linkUpscale = useAssetUpscaleLink(asset);

  useHotkeys(appShortcuts.assetPage.shortcuts.enhance.shortcut, () => {
    upscaleButtonRef.current?.click();
  });

  if (!linkUpscale) {
    return null;
  }

  return (
    <Link href={linkUpscale} onClick={onAction}>
      <Button
        leftIcon={<Icon id="Domains/Tools/Upscale" h="14px" />}
        size="sm"
        tooltip="Send the image to the Scenario Creative Enhancer to increase its resolution or level of details"
        variant="secondary"
        ref={upscaleButtonRef}
      >
        Enhance
      </Button>
    </Link>
  );
}
