import { GetModelsByModelIdApiResponse } from "infra/api/generated/api";

export const PLATFORM_DESCRIPTION = "Curated & easy to prompt models.";
export const COMMUNITY_DESCRIPTION =
  "Model examples with easy to replicate training information.";
export const FOUNDATION_DESCRIPTION =
  "The general models used to support user customization.";

export const MODEL_TYPE_TO_TITLE: {
  [key in GetModelsByModelIdApiResponse["model"]["type"]]: string;
} = {
  "sd-1_5": "SD 1.5",
  "sd-xl": "SDXL",
  "sd-xl-composition": "SDXL Composition",
  "sd-xl-lora": "SDXL LoRA",
  "sd-1_5-composition": "SD 1.5 Composition",
  "sd-1_5-lora": "SD 1.5 LoRA",
  "flux.1": "Flux",
  "flux.1-lora": "Flux LoRA",
  "flux.1-composition": "Flux Composition",
  "flux.1-pro": "Flux Pro",
  "flux1.1-pro": "Flux Pro 1.1",
  "flux.1.1-pro-ultra": "Flux Pro 1.1 Ultra",
};
