import React, { useCallback } from "react";
import useRouter from "domains/navigation/hooks/useRouter";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import { useHandleApiError } from "infra/api/error";
import {
  GetModelsByModelIdApiResponse,
  usePutModelsByModelIdMutation,
} from "infra/api/generated/api";

export interface ButtonMarkAsThumbnailProps {
  model: GetModelsByModelIdApiResponse["model"] | undefined;
  assetUrl: string | undefined;
}

export default function ButtonMarkAsThumbnail({
  model,
  assetUrl,
}: ButtonMarkAsThumbnailProps) {
  const router = useRouter();

  const isOnModelPage = router.pathname.includes("/models");
  const { selectedProject } = useTeamContext();
  const { successToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const [updateModelTrigger, { isLoading: isUpdateModelLoading }] =
    usePutModelsByModelIdMutation();

  const handleSetAsThumbnail = useCallback(async () => {
    if (!(assetUrl && model?.id)) return;
    try {
      await updateModelTrigger({
        modelId: model.id,
        body: {
          thumbnail: assetUrl.split("?")[0].split("/").pop(),
        },
        projectId: selectedProject.id,
      }).unwrap();
      successToast({
        title: "Thumbnail updated",
      });
    } catch (error) {
      handleApiError(
        error,
        "There was an error updating the model's thumbnail"
      );
    }
  }, [
    updateModelTrigger,
    handleApiError,
    successToast,
    assetUrl,
    model?.id,
    selectedProject.id,
  ]);

  if (!(isOnModelPage && model?.ownerId === selectedProject.id)) return null;

  return (
    <Button
      isLoading={isUpdateModelLoading}
      onClick={handleSetAsThumbnail}
      variant="secondary"
      size="sm"
    >
      Set as thumbnail
    </Button>
  );
}
