import { useCallback, useEffect, useMemo, useState } from "react";
import {
  VECTORIZATION_DEFAULT_VALUES,
  VECTORIZATION_PRESET_DEFAULT_VALUES,
  VectorizationColorMode,
  VectorizationMode,
  VectorizationPreset,
} from "domains/assets/constants/vectorize";
import useAssetRemoveBackground from "domains/assets/hooks/useAssetRemoveBackground";
import { getImageDimensions } from "domains/commons/utils/getImageDimensions";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import { ExcludeCu, removeCuFromType } from "infra/api/ExludeCU";
import {
  GetAssetsByAssetIdApiResponse,
  PostVectorizeInferencesApiArg,
  PostVectorizeInferencesApiResponse,
  useLazyGetAssetsByAssetIdQuery,
  usePostVectorizeInferencesMutation,
} from "infra/api/generated/api";
import _ from "lodash";

export type VectorizationForm = {
  preset: VectorizationPreset;
  isRemoveBackground: boolean;
  mode: VectorizationMode;
  colorMode: VectorizationColorMode;
  filterSpeckle: number;
  colorPrecision: number;
  layerDifference: number;
  cornerThreshold: number;
  lengthThreshold: number;
  spliceThreshold: number;
  reference:
    | {
        src: string;
        assetId?: string;
        dimensions?: { width: number; height: number };
      }
    | undefined;
};

export interface UseAssetVectorizeReturnValue {
  form: VectorizationForm;
  setValue: <K extends keyof VectorizationForm>(
    key: K,
    value: VectorizationForm[K]
  ) => void;
  setReference: (
    image: string,
    assetId?: string,
    assetMeta?: GetAssetsByAssetIdApiResponse["asset"]
  ) => void;
  resetForm: () => void;
  handleVectorizeJob: (props: {
    assetId?: string;
    trackingExtraParams: Record<string, unknown>;
  }) => Promise<
    ExcludeCu<PostVectorizeInferencesApiResponse>["job"] | undefined
  >;
  handleVectorizeAsset: (props: {
    assetId?: string;
    trackingExtraParams: Record<string, unknown>;
  }) => Promise<GetAssetsByAssetIdApiResponse["asset"] | undefined>;
  isVectorizeLoading: boolean;
}

export default function useAssetVectorize(): UseAssetVectorizeReturnValue {
  const { selectedProject } = useTeamContext();
  const [getAssetByAssetId] = useLazyGetAssetsByAssetIdQuery();
  const [triggerImageVectorization] = usePostVectorizeInferencesMutation();
  const { infoToast, successToast, errorToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { showLimitModal } = usePlanContext();
  const { handleRemoveBackgroundAsset } = useAssetRemoveBackground();
  const [form, setForm] = useState<VectorizationForm>({
    ...VECTORIZATION_DEFAULT_VALUES,
  });
  const [isVectorizeLoading, setIsVectorizeLoading] = useState<boolean>(false);

  // ----------------------------------

  const handleVectorize = useCallback(
    async ({
      assetId: initialAssetId,
      trackingExtraParams,
    }: {
      assetId?: string;
      trackingExtraParams: Record<string, unknown>;
    }) => {
      try {
        infoToast({
          title: "Vectorizing image",
        });

        let assetId = initialAssetId ?? form.reference?.assetId;
        if (!assetId) {
          throw new Error("No asset");
        }

        if (form.isRemoveBackground) {
          const assetWithoutBackground = await handleRemoveBackgroundAsset({
            assetId,
            trackingExtraParams,
            withToast: false,
          });
          if (!assetWithoutBackground) {
            const error = new Error("Background remove error") as any;
            error.data = {
              reason: "There was an error removing the background.",
            };
            throw error;
          }
          assetId = assetWithoutBackground.id;
        }

        const body = getVectorizeParamsBody(assetId, form);
        const vectorizeReponse = await triggerImageVectorization({
          projectId: selectedProject.id,
          originalAssets: "true",
          body,
        })
          .unwrap()
          .then(removeCuFromType);

        Track(AnalyticsEvents.ImageLibrary.VectorizedImage, {
          assetId,
          ...form,
          ...trackingExtraParams,
        });

        return vectorizeReponse;
      } catch (error: any) {
        handleApiError(error, "Error vectorizing image", {
          quota: () => {
            if (_.get(error, "data.details.remainingSeconds")) {
              showLimitModal("planCooldown", {
                timeout: error.data.details.remainingSeconds,
                type: "vectorize",
              });
            } else if (
              _.get(error, "data.reason").includes(
                "You have reached your plan's limit."
              )
            ) {
              showLimitModal("notEnoughCreativeUnits");
            } else {
              errorToast({
                title: "Error generating images",
                description: _.get(error, "data.reason"),
              });
            }
          },
        });
      }
    },
    [
      infoToast,
      selectedProject.id,
      handleApiError,
      showLimitModal,
      errorToast,
      triggerImageVectorization,
      form,
      handleRemoveBackgroundAsset,
    ]
  );

  const handleVectorizeJob = useCallback(
    async ({
      assetId: initialAssetId,
      trackingExtraParams,
    }: {
      assetId?: string;
      trackingExtraParams: Record<string, unknown>;
    }) => {
      setIsVectorizeLoading(true);
      const vectorizeResponse = await handleVectorize({
        assetId: initialAssetId,
        trackingExtraParams,
      });
      setIsVectorizeLoading(false);
      return vectorizeResponse?.job;
    },
    [handleVectorize]
  );

  const handleVectorizeAsset = useCallback(
    async ({
      assetId: initialAssetId,
      trackingExtraParams,
    }: {
      assetId?: string;
      trackingExtraParams: Record<string, unknown>;
    }) => {
      setIsVectorizeLoading(true);

      const vectorizeResponse = await handleVectorize({
        assetId: initialAssetId,
        trackingExtraParams,
      });

      if (!vectorizeResponse?.asset) {
        return;
      }

      let vectorizedAsset: GetAssetsByAssetIdApiResponse | undefined =
        await getAssetByAssetId({
          projectId: selectedProject.id,
          originalAssets: "true",
          assetId: vectorizeResponse.asset.id,
        }).unwrap();

      while (
        vectorizedAsset?.asset &&
        vectorizedAsset.asset.status === "pending"
      ) {
        await new Promise((resolve) => setTimeout(resolve, 2_000));
        try {
          vectorizedAsset = await getAssetByAssetId({
            projectId: selectedProject.id,
            originalAssets: "true",
            assetId: vectorizedAsset.asset.id,
          }).unwrap();
        } catch (_) {
          vectorizedAsset = undefined;
        }
      }

      if (
        !vectorizedAsset?.asset ||
        vectorizedAsset.asset.status !== "success"
      ) {
        errorToast({
          title: "Error vectorizing image",
        });
      }

      successToast({
        title: "The image has been vectorized",
      });
      setIsVectorizeLoading(false);

      return vectorizedAsset?.asset;
    },
    [
      errorToast,
      getAssetByAssetId,
      handleVectorize,
      selectedProject.id,
      successToast,
    ]
  );

  const setValue = useCallback(
    <K extends keyof VectorizationForm>(
      key: K,
      value: VectorizationForm[K]
    ) => {
      if (key === "preset" && value !== "custom") {
        const preset = value as Exclude<VectorizationForm["preset"], "custom">;
        setForm((form) => ({
          ...form,
          preset,
          ...VECTORIZATION_PRESET_DEFAULT_VALUES[preset],
        }));
      } else {
        setForm((form) => ({
          ...form,
          [key]: value,
        }));
      }
    },
    [setForm]
  );

  const setReference = useCallback(
    async (
      image: string,
      assetId?: string,
      assetMeta?: GetAssetsByAssetIdApiResponse["asset"]
    ) => {
      const { width, height } = await (async () => {
        if (assetMeta?.metadata.width && assetMeta?.metadata.height) {
          return {
            width: assetMeta.metadata.width,
            height: assetMeta.metadata.height,
          };
        } else if (image) {
          return await getImageDimensions(image);
        } else {
          return { width: undefined, height: undefined };
        }
      })();

      setForm((form) => ({
        ...form,
        reference: {
          src: image,
          assetId,
          dimensions: width ? { width, height } : undefined,
        },
      }));
    },
    [setForm]
  );

  const resetForm = useCallback(() => {
    setForm({ ...VECTORIZATION_DEFAULT_VALUES });
  }, []);

  // ----------------------------------

  useEffect(() => {
    if (form.preset !== "custom") {
      const presetFormValues = VECTORIZATION_PRESET_DEFAULT_VALUES[form.preset];
      const isDifferent = Object.entries(presetFormValues).some(
        ([key, value]) => {
          return value !== form[key as keyof VectorizationForm];
        }
      );
      if (isDifferent) {
        setForm((form) => ({ ...form, preset: "custom" }));
      }
    }
  }, [form]);

  // ----------------------------------

  return useMemo(
    () => ({
      form,
      setValue,
      setReference,
      resetForm,
      handleVectorizeJob,
      handleVectorizeAsset,
      isVectorizeLoading,
    }),
    [
      form,
      setValue,
      setReference,
      resetForm,
      handleVectorizeJob,
      handleVectorizeAsset,
      isVectorizeLoading,
    ]
  );
}

// ----------------------------------

export function getVectorizeParamsBody(
  assetId: string,
  form: VectorizationForm
): PostVectorizeInferencesApiArg["body"] {
  return {
    image: assetId,
    mode: form.mode,
    colorMode: form.colorMode,
    filterSpeckle: form.filterSpeckle,
    colorPrecision: form.colorPrecision,
    layerDifference: form.layerDifference,
    cornerThreshold: form.cornerThreshold,
    lengthThreshold: form.lengthThreshold,
    spliceThreshold: form.spliceThreshold,
  };
}
