import React, { useState } from "react";
import { GuideKeys } from "domains/guide/constants/guides";
import SidebarSection from "domains/inference/components/InferenceGenerator/Sidebar/Section";
import { extraTheme } from "domains/theme";
import Icon from "domains/ui/components/Icon";
import PreviewModalVideo from "domains/ui/components/PreviewModalVideo";
import TourGuideIcon from "domains/ui/components/TourGuideIcon";

import { HStack, Text, Tooltip } from "@chakra-ui/react";

export interface SidebarTitleProps {
  title: React.ReactNode;
  guide?: GuideKeys;
  videoUrl?: string;
  videoTooltip?: string;
}

export default function SidebarTitle({
  title,
  guide,
  videoUrl,
  videoTooltip,
}: SidebarTitleProps) {
  return (
    <SidebarSection>
      <HStack align="center" justify="space-between" data-group>
        <HStack spacing={2}>
          <Text size="title.sm">{title}</Text>
          {guide && (
            <TourGuideIcon
              guide={guide}
              _groupHover={{
                opacity: 1,
              }}
              mt="1px"
            />
          )}
        </HStack>
        {videoUrl && <VideoHelp url={videoUrl} tooltip={videoTooltip} />}
      </HStack>
    </SidebarSection>
  );
}

export function VideoHelp({ url, tooltip }: { url: string; tooltip?: string }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <PreviewModalVideo
        isOpen={isOpen}
        youtubeLink={url}
        onClose={() => setIsOpen(false)}
      />
      <Tooltip isDisabled={!tooltip} label={tooltip}>
        <Icon
          id="Ui/PlayVideo"
          h="20px"
          cursor="pointer"
          onClick={() => setIsOpen(true)}
          transition={extraTheme.transitions.fast}
          color="textSecondary"
          _hover={{
            opacity: "0.6",
          }}
        />
      </Tooltip>
    </>
  );
}
