import { useCallback, useState } from "react";
import { UseImageUploadDragDropProps } from "domains/image/hooks/useImageUploadDragDrop";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  GetAssetsByAssetIdApiResponse,
  useLazyGetAssetsByAssetIdQuery,
} from "infra/api/generated/api";

interface UseScenarioAssetDropProps {
  onAssetFound?: (
    asset: GetAssetsByAssetIdApiResponse["asset"]
  ) => Promise<void>;
}

export const useScenarioAssetDrop = ({
  onAssetFound,
}: UseScenarioAssetDropProps) => {
  const [getAssetById] = useLazyGetAssetsByAssetIdQuery();
  const { selectedProject } = useTeamContext();
  const { successToast, errorToast } = useScenarioToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleAssetUrl = useCallback<
    NonNullable<UseImageUploadDragDropProps["onImageDrop"]>
  >(
    async ({ assetId }) => {
      if (!assetId) return;

      setIsLoading(true);
      try {
        const getAssetData = await getAssetById({
          projectId: selectedProject.id,
          assetId,
        });

        if (getAssetData.data?.asset) {
          await onAssetFound?.(getAssetData.data.asset);
          successToast({
            title: "Image imported successfully",
          });
        }
      } catch (error) {
        errorToast({
          title: "Error importing image",
          description:
            "There was an error importing the image. Please try again.",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [getAssetById, selectedProject.id, onAssetFound, successToast, errorToast]
  );

  return {
    handleAssetUrl,
    isLoading,
  };
};
