import React, {
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useMemo,
} from "react";
import Link from "next/link";
import { FmFile, FmFileType } from "domains/file-manager/interfacesV2";
import useRouter from "domains/navigation/hooks/useRouter";
import _ from "lodash";
import { isSafari } from "react-device-detect";

import {
  Box,
  BoxProps,
  Checkbox,
  Flex,
  Grid,
  Image as ChakraImage,
  ImageProps,
  Skeleton,
} from "@chakra-ui/react";

export interface FmCardContentProps<T extends FmFileType = FmFileType> {
  type: T;
  file: FmFile<T>;
}

export interface FmCardProps<T extends FmFileType = FmFileType>
  extends PropsWithChildren {
  type: T;
  file: FmFile<T>;
  thumbnail: string;
  lowResThumbnail?: string;
  isSelectable?: boolean;
  isShiftSelectable?: boolean;
  isSelected?: boolean;
  onSelect?: (id: string, options?: { shiftPressed: boolean }) => void;
  isDisabled?: boolean;
  isSkeleton?: boolean;
  isBlurred?: boolean;
  isHovered?: boolean;
  containerProps?: BoxProps;
  imgProps?: ImageProps;
  onClick?: (file: FmFile<T>) => void;
  withLink?: boolean;
}

const FmCardWithoutMemo = <T extends FmFileType = FmFileType>({
  type,
  file,
  thumbnail,
  lowResThumbnail,
  isSelectable = false,
  isShiftSelectable = false,
  isSelected = false,
  onSelect,
  isHovered = false,
  isDisabled = false,
  isSkeleton = false,
  isBlurred = false,
  containerProps = {},
  imgProps = {},
  onClick,
  withLink,
  children,
}: FmCardProps<T>) => {
  const isClickable = !isDisabled && !!onClick;
  const isGreyed =
    (isShiftSelectable && !isSelected && !isHovered) ||
    (!isShiftSelectable && isHovered);
  const imageFilters = useMemo(
    () =>
      _.compact([
        isBlurred && "blur(20px)",
        isHovered && isSelected && "brightness(1.1)",
      ]).join(" "),
    [isBlurred, isHovered, isSelected]
  );

  const handleCardClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (isShiftSelectable) {
        onSelect?.(file.id, { shiftPressed: event.shiftKey });
        event.preventDefault();
      } else if (event.shiftKey) {
        onSelect?.(file.id, { shiftPressed: false });
        event.preventDefault();
      } else if (!event.ctrlKey && !event.metaKey) {
        onClick?.(file);
        event.preventDefault();
      }
      event.stopPropagation();
    },
    [file, isShiftSelectable, onClick, onSelect]
  );

  const handleCheckboxChange: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      onSelect?.(file.id, {
        shiftPressed: event.shiftKey,
      });
      event.preventDefault();
      event.stopPropagation();
    },
    [onSelect, file.id]
  );

  return (
    <LinkWrapper withLink={withLink} file={file} type={type}>
      <Box
        pos="relative"
        zIndex={0}
        overflow="hidden"
        w="100%"
        h={0}
        pt="100%"
        borderRadius="md"
        userSelect="none"
        data-outside-click-excluded
        data-testid="asset-gallery-grid-cell"
        onClick={!isDisabled ? handleCardClick : undefined}
        {...containerProps}
      >
        <Grid
          pos="absolute"
          top={0}
          overflow="hidden"
          w="100%"
          h="100%"
          cursor={isClickable ? "pointer" : "default"}
          bgColor="backgroundSecondary.500"
          data-testid="asset-gallery-preview-image"
        >
          <CardImage
            thumbnail={thumbnail}
            lowResThumbnail={lowResThumbnail}
            imageFilters={imageFilters}
            isSkeleton={isSkeleton}
            imgProps={imgProps}
          />

          {isGreyed && (
            <Box
              pos="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              pointerEvents="none"
              bgColor="rgba(0,0,0,0.3)"
            />
          )}

          {children}

          {isSelectable && (isHovered || isShiftSelectable) && (
            <CardCheckbox
              isShiftSelectable={isShiftSelectable}
              isHovered={isHovered}
              isSelected={isSelected}
              handleCheckboxChange={handleCheckboxChange}
            />
          )}
        </Grid>
      </Box>

      {isDisabled && (
        <Box
          pos="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          pointerEvents="none"
          bgColor="rgba(100,100,100,0.6)"
        />
      )}
    </LinkWrapper>
  );
};

// We have to do this so the generic type is not lost with React.memo
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087
const FmCard = React.memo(FmCardWithoutMemo) as typeof FmCardWithoutMemo;

// ----------------------------------

type CardImageProps = {
  thumbnail: string;
  lowResThumbnail?: string;
  imageFilters: string;
  isSkeleton: boolean;
  imgProps: ImageProps;
};
const CardImage = React.memo(function CardImage({
  thumbnail,
  lowResThumbnail,
  imageFilters,
  isSkeleton,
  imgProps,
}: CardImageProps) {
  return (
    <ChakraImage
      pos="absolute"
      top={0}
      w="100%"
      h="100%"
      objectFit="cover"
      alt=""
      data-id="asset-image"
      data-testid="asset-gallery-preview-image"
      fallback={
        <Skeleton
          pos="absolute"
          zIndex={1}
          top={0}
          left={0}
          w="100%"
          h="100%"
        />
      }
      fallbackSrc={lowResThumbnail}
      filter={imageFilters}
      loading={isSafari ? "eager" : "lazy"}
      src={thumbnail}
      {...imgProps}
      {...(isSkeleton || !(thumbnail ?? lowResThumbnail)
        ? {
            display: "none",
            position: "absolute",
            pointerEvents: "none",
          }
        : {})}
    />
  );
});

type CardCheckboxProps = {
  isShiftSelectable: boolean;
  isHovered: boolean;
  isSelected: boolean;
  handleCheckboxChange: MouseEventHandler<HTMLDivElement>;
};

const CardCheckbox = React.memo(function CardCheckbox({
  isShiftSelectable,
  isHovered,
  isSelected,
  handleCheckboxChange,
}: CardCheckboxProps) {
  return (
    <Flex
      pos="absolute"
      zIndex={4}
      top={0}
      direction={"column"}
      display={["none", "none", "flex"]}
      p={2}
      data-testid="asset-gallery-preview-image-checkbox"
      onClick={handleCheckboxChange}
    >
      <Checkbox
        opacity={
          isShiftSelectable && !isHovered && !isSelected ? 0.5 : undefined
        }
        pointerEvents="none"
        colorScheme="primary"
        isChecked={isSelected}
        size="lg"
        variant="lightCircular"
      />
    </Flex>
  );
});

// ----------------------------------

function LinkWrapper<T extends FmFileType = FmFileType>({
  withLink = true,
  file,
  type,
  children,
}: {
  withLink?: boolean;
  file: FmFile<T>;
  type: T;
  children: React.ReactNode;
}) {
  const router = useRouter();

  if (
    withLink &&
    type === "image" &&
    "status" in file &&
    file.status === "success"
  ) {
    return (
      <Link
        href={{
          pathname: router.pathname,
          query: {
            ...router.query,
            openAssetId: file.id,
          },
        }}
      >
        {children}
      </Link>
    );
  }
  return <>{children}</>;
}

export default FmCard;
