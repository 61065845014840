import React from "react";

/* eslint-disable no-restricted-imports */
import {
  Modal as ChakraModal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalCloseButtonProps,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalOverlayProps,
  ModalProps as ChakraModalProps,
} from "@chakra-ui/react";

/* eslint-enable */
import ModalTopIcon from "./TopIcon";
import ModalTopTitle from "./TopTitle";

type ModalProps = ChakraModalProps;

export default function Modal(props: ModalProps) {
  return <ChakraModal returnFocusOnClose={false} {...props} />;
}

export type {
  ModalBodyProps,
  ModalCloseButtonProps,
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalOverlayProps,
  ModalProps,
};

export {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTopIcon,
  ModalTopTitle,
};
