import React, { Suspense, useMemo, useState } from "react";
import { Loader } from "domains/assets/components/AssetZoom/TextureViewer";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import useRouter from "domains/navigation/hooks/useRouter";
import { appShortcuts } from "domains/shortcuts/components/appShortcuts";
import { Shortcut } from "domains/shortcuts/components/Shorcut";
import Button, { ButtonProps } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import * as THREE from "three";

import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { OrbitControls, useTexture } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";

interface AssetZoomSkyboxViewerProps {
  imageUrl: string;
  assetId: string;
  isDraft?: boolean;
  onFullscreenToggle: () => void;
}

export default function AssetZoomSkyboxViewer({
  imageUrl,
  assetId,
  isDraft = false,
  onFullscreenToggle,
}: AssetZoomSkyboxViewerProps) {
  const router = useRouter();
  const [isHovering, setIsHovering] = useState(false);

  const buttonProps = useMemo<ButtonProps>(() => {
    if (router.pathname === "/skyboxes/new") {
      return {
        internalLink: {
          pathname: "/skyboxes/new",
          query: { enhanceAssetId: assetId },
        },
      };
    } else {
      return {
        internalLink: {
          pathname: "/skyboxes/enhance",
          query: {
            importAssetId: assetId,
          },
        },
      };
    }
  }, [assetId, router.pathname]);

  useHotkeys(
    appShortcuts.assetPage.shortcuts.fullscreen.shortcut,
    () => onFullscreenToggle(),
    [onFullscreenToggle]
  );

  return (
    <Flex
      pos="relative"
      flex={1}
      w="100%"
      cursor="move"
      bgColor="black"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Box pos="absolute" top={0} right={0} bottom={0} left={0}>
        <Canvas
          linear
          flat
          key={imageUrl}
          frameloop="demand"
          camera={{ position: [0, 0, 100], far: 10_000 }}
          resize={{ debounce: 300 }}
        >
          <Suspense fallback={<Loader />}>
            <OrbitControls
              enableZoom
              enablePan
              enableDamping
              minDistance={10}
              maxDistance={150}
              zoomSpeed={2}
              dampingFactor={0.1}
              autoRotate={false}
              rotateSpeed={-0.5}
            />
            <ThreeViewer skyboxUrl={imageUrl} />
          </Suspense>
        </Canvas>

        {(isHovering || isDraft) && (
          <VStack
            pos="absolute"
            bottom={5}
            justify="center"
            w="100%"
            spacing={2}
          >
            {isHovering && (
              <Button
                variant="secondaryAlt"
                size="xs"
                onClick={onFullscreenToggle}
                tooltip={
                  <Shortcut {...appShortcuts.assetPage.shortcuts.fullscreen} />
                }
                tooltipProps={{
                  placement: "top",
                }}
              >
                <Icon id="Ui/Fullscreen" h="12px" />
              </Button>
            )}

            {isDraft && (
              <HStack
                p={2}
                borderRadius="lg"
                bgColor="backgroundSecondary.500"
                spacing={2}
              >
                <Text
                  px={2}
                  color="textSecondary"
                  textAlign="center"
                  size="body.md"
                >
                  This is a low-resolution preview. Enhance it to upscale and
                  finalize your Skybox.
                </Text>
                <Button
                  leftIcon={<Icon id="Domains/Tools/Upscale" h="12px" />}
                  variant="primary"
                  size="xs"
                  {...buttonProps}
                >
                  Enhance
                </Button>
              </HStack>
            )}
          </VStack>
        )}
      </Box>
    </Flex>
  );
}

// ------------------------------------

interface ThreeViewerProps {
  skyboxUrl: string;
}

function ThreeViewer({ skyboxUrl }: ThreeViewerProps) {
  const texture = useTexture(skyboxUrl, (texture) => {
    texture.mapping = THREE.EquirectangularReflectionMapping;
    texture.wrapS = THREE.RepeatWrapping;
    texture.repeat.x = -1;
  });

  return (
    <mesh position={[0, 0, 0]}>
      <sphereGeometry args={[500, 32, 16]} />
      <meshBasicMaterial map={texture} side={THREE.BackSide} />
    </mesh>
  );
}
