import { useRef, useState } from "react";
import Button from "domains/ui/components/Button";
import Divider from "domains/ui/components/Divider";
import { ModalTopIcon, ModalTopTitle } from "domains/ui/components/Modal";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "domains/ui/components/Modal";
import { getDefaultCountryCode } from "domains/ui/components/PhoneInput";
import { PolicyText } from "domains/user/components/PolicyAcceptance";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { OnboardingForm as Form } from "domains/user/interfaces/OnboardingForm";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import { VStack } from "@chakra-ui/react";

import SectionContact from "./SectionContact";
import SectionPersonal from "./SectionPersonal";
import SectionRole from "./SectionRole";
import SectionSource from "./SectionSource";
import SectionUsage from "./SectionUsage";

const INITIAL_FORM: Form = {
  fullName: "",
  companyName: "",
  countryCode: "us",
  phoneNumber: "",
  role: undefined,
  source: undefined,
  usage: [],
  contact: [],
};

export default function _OnboardingForm() {
  const { isOnboardingFormMissing, submitOnboardingForm } = useUserContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form, setForm] = useState<Form>({
    ...INITIAL_FORM,
    countryCode: getDefaultCountryCode(),
  });
  const fullNameInputRef = useRef(null);

  const canSubmit = !!(
    form.fullName &&
    form.role &&
    form.source &&
    form.usage.length > 0 &&
    form.contact.length > 0 &&
    (form.contact.includes("phone")
      ? form.countryCode && form.phoneNumber
      : true)
  );

  const handleSubmitClick = async () => {
    setIsLoading(true);
    const finalForm = !form.contact.includes("phone")
      ? _.omit(form, ["countryCode", "phoneNumber"])
      : form;
    await submitOnboardingForm(finalForm);
    setIsLoading(false);
  };

  if (!isOnboardingFormMissing) {
    return null;
  }

  return (
    <Modal
      initialFocusRef={fullNameInputRef}
      isOpen
      onClose={() => {}}
      size={["full", "md"]}
      variant="modern"
    >
      <ModalOverlay />
      <ModalContent
        alignItems="stretch"
        overflowX="hidden"
        overflowY="auto"
        w={["100vw", "80vw"]}
      >
        <ModalBody textAlign="left">
          <VStack align="flex-start" pt={6} spacing={6}>
            <ModalTopIcon src="ui/onboarding-user.svg" />
            <ModalTopTitle
              title={
                <FormattedMessage
                  id="modal.onboardingUser.title"
                  defaultMessage="Complete Your Profile"
                />
              }
              subTitle={
                <FormattedMessage
                  id="modal.onboardingUser.subTitle"
                  defaultMessage="Help us know you better! Please take a moment to share a bit more about yourself."
                />
              }
            />

            <VStack align="stretch" w="100%" spacing={4}>
              <SectionPersonal setForm={setForm} form={form} />
              <Divider my={2} />
              <SectionRole setForm={setForm} form={form} />
              <Divider my={2} />
              <SectionUsage setForm={setForm} form={form} />
              <Divider my={2} />
              <SectionSource setForm={setForm} form={form} />
              <Divider my={2} />
              <SectionContact setForm={setForm} form={form} />
            </VStack>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <VStack
            align="center"
            w="100%"
            p={4}
            borderRadius="md"
            bgColor="background.500"
            spacing={4}
          >
            <PolicyText size="body.md" maxW="none" />
            <Button
              alignSelf="stretch"
              size="lg"
              data-testid="submit-button"
              isLoading={isLoading}
              isDisabled={!canSubmit}
              onClick={handleSubmitClick}
            >
              <FormattedMessage
                id="modal.onboardingUser.submit"
                defaultMessage="Confirm"
              />
            </Button>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
