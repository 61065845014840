import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const baseStyle = definePartsStyle({});

const variants = {
  rounded: definePartsStyle({}),
  squared: definePartsStyle((props) => {
    return {
      container: {
        borderRadius: props.borderRadius || "md",
        color: "black.500",
      },
    };
  }),
};

const sizes = {};

export const Avatar = defineMultiStyleConfig({
  variants,
  baseStyle,
  sizes,
  defaultProps: { variant: "rounded" },
});
