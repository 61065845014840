import React from "react";
import Select from "domains/ui/components/Select";
import WithLabelAndTooltip from "domains/ui/components/WithLabelAndTooltip";

interface ControlSelectProps {
  title: string;
  tooltip?: string;
  value: string;
  setValue: (value: string) => void;
  options: {
    label: string;
    value: string;
  }[];
}

export default function ControlSelect({
  title,
  tooltip,
  value,
  setValue,
  options,
}: ControlSelectProps) {
  return (
    <WithLabelAndTooltip label={title} tooltip={tooltip}>
      <Select
        w="fit-content"
        mr={-1}
        ml="auto"
        onChange={(e) => setValue(e.target.value)}
        value={value}
      >
        {options.map((option) => (
          <option
            key={option.label}
            value={option.value}
            label={option.label}
          />
        ))}
      </Select>
    </WithLabelAndTooltip>
  );
}
