import { PropsWithChildren, useCallback } from "react";
import Icon from "domains/ui/components/Icon";

import {
  Collapse,
  CollapseProps,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { useSidebarContext } from "./context";

export interface SidebarSectionProps extends PropsWithChildren {
  id: string;
  title?: string;
  collapseProps?: CollapseProps;
}

export default function SidebarSection({
  id,
  title,
  collapseProps,
  children,
}: SidebarSectionProps) {
  const { collapsedSections, toggleCollapsedSection } = useSidebarContext();
  const isCollapsed = collapsedSections.includes(id);

  const handleCollapseClick = useCallback(() => {
    toggleCollapsedSection(id);
  }, [toggleCollapsedSection, id]);

  return (
    <VStack
      align="stretch"
      flex={0}
      borderBottomWidth={1}
      borderBottomColor="border.500"
      spacing={0}
    >
      {!!title && (
        <HStack
          h="52px"
          px={6}
          cursor="pointer"
          onClick={handleCollapseClick}
          spacing={3}
        >
          <HStack justify="center" w="10px">
            <Icon
              id={isCollapsed ? "Ui/ChevronRight" : "Ui/ChevronDown"}
              h={isCollapsed ? "10px" : "6px"}
              color="textSecondary"
            />
          </HStack>
          <Text flex={1} color="textSecondary" size="body.bold.md">
            {title}
          </Text>
        </HStack>
      )}
      <Collapse in={!isCollapsed} {...collapseProps}>
        <VStack align="stretch" pt={title ? 0 : 5} pb={5} px={6} spacing={6}>
          {children}
        </VStack>
      </Collapse>
    </VStack>
  );
}
