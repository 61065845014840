import React, { useCallback, useMemo, useRef } from "react";
import { isAssetSkybox, isAssetTexture } from "domains/assets/utils/isType";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { capitalizeFirstWord } from "domains/commons/misc";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { appShortcuts } from "domains/shortcuts/components/appShortcuts";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import ButtonWithModal from "domains/ui/components/ButtonWithModal";
import Icon from "domains/ui/components/Icon";
import { useHandleApiError } from "infra/api/error";
import {
  GetAssetsByAssetIdApiResponse,
  useDeleteAssetMutation,
} from "infra/api/generated/api";

export interface ButtonRemoveProps {
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
  onRemove: () => void | undefined;
}

export default function ButtonRemove({ asset, onRemove }: ButtonRemoveProps) {
  const { successToast } = useScenarioToast();
  const { selectedProject } = useTeamContext();
  const handleApiError = useHandleApiError();
  const deleteButtonRef = useRef<HTMLButtonElement>(null);
  const [deleteImageTrigger, { isLoading: deleteImageIsLoading }] =
    useDeleteAssetMutation();

  const assetId = useMemo(() => asset?.id, [asset]);
  const assetType =
    (isAssetTexture(asset) && "texture") ||
    (isAssetSkybox(asset) && "skybox") ||
    "image";

  const handleDelete = useCallback(async () => {
    if (!assetId) return;
    try {
      await deleteImageTrigger({
        projectId: selectedProject.id,
        body: {
          assetIds: [assetId],
        },
      });
      successToast({
        title: `${capitalizeFirstWord(assetType)} deleted`,
      });
      if (onRemove) onRemove();
    } catch (error) {
      handleApiError(error, `Error deleting ${assetType}`);
    }
  }, [
    assetId,
    assetType,
    deleteImageTrigger,
    selectedProject.id,
    successToast,
    onRemove,
    handleApiError,
  ]);

  useHotkeys(appShortcuts.assetPage.shortcuts.delete.shortcut, () => {
    deleteButtonRef.current?.click();
  });

  return (
    <ButtonWithModal
      tooltip="Delete"
      tooltipProps={{
        placement: "bottom",
      }}
      variant="secondary"
      size="sm"
      p={0}
      isDisabled={!assetId}
      isLoading={deleteImageIsLoading}
      modalBody={`Are you sure you want to delete this ${assetType}?`}
      modalColorScheme="danger"
      onConfirm={handleDelete}
      isModalConfirmButtonLoading={deleteImageIsLoading}
      ref={deleteButtonRef}
    >
      <Icon id="Ui/Trash" h="18px" />
    </ButtonWithModal>
  );
}
