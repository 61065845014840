import { ExcludeCu } from "infra/api/ExludeCU";
import { GetModelsByModelIdApiResponse } from "infra/api/generated/api";
import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { AppRootState } from "infra/store/store";

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

export const updateModelsQueryDataByUpdatingModel = (
  model: ExcludeCu<GetModelsByModelIdApiResponse>["model"],
  {
    dispatch,
    getState,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    getState: () => AppRootState;
  }
) => {
  for (const {
    endpointName,
    originalArgs,
  } of apiSlice.util.selectInvalidatedBy(getState(), [
    API_TAGS.model,
  ]) as SelectedInvalidatedByTag[]) {
    if (endpointName === "getModels") {
      dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (draft) {
            const modelIndex = draft.models.findIndex(
              (item) => item.id === model.id
            );
            if (modelIndex !== -1) {
              draft.models[modelIndex] = model;
            }
          }
        })
      );
    }
  }
};
