import { getIsModelFlux, getIsModelSdxl } from "domains/models/utils";
import { GetModelsByModelIdApiResponse } from "infra/api/generated/api";

export enum ControlNetNonComposablePreset {
  CHARACTER = "character",
  LANDSCAPE = "landscape",
  CITY = "city",
  INTERIOR = "interior",
  TILE = "tile",
  BLUR = "blur",
  GRAY = "gray",
  LOW_QUALITY = "low-quality",
}

export enum ControlNetComposablePreset {
  CANNY = "canny",
  DEPTH = "depth",
  POSE = "pose",
  LINES = "lines",
  SEG = "seg",
  NORMAL_MAP = "normal-map",
  SCRIBBLE = "scribble",
  LINEART = "lineart",
  ILLUSION = "illusion",
  SKETCH = "sketch",
}

export const ControlNetPreset = {
  ...ControlNetNonComposablePreset,
  ...ControlNetComposablePreset,
};
export type ControlNetPreset =
  | ControlNetNonComposablePreset
  | ControlNetComposablePreset;

export const controlNetNonComposablePresets = [
  {
    label: "Character",
    value: ControlNetPreset.CHARACTER,
  },
  {
    label: "Landscape",
    value: ControlNetPreset.LANDSCAPE,
  },
  {
    label: "City",
    value: ControlNetPreset.CITY,
  },
  {
    label: "Interior",
    value: ControlNetPreset.INTERIOR,
  },
  {
    label: "Tile",
    value: ControlNetPreset.TILE,
  },
  {
    label: "Blur",
    value: ControlNetPreset.BLUR,
  },
  {
    label: "Gray",
    value: ControlNetPreset.GRAY,
  },
  {
    label: "Low Quality",
    value: ControlNetPreset.LOW_QUALITY,
  },
];

export const controlNetComposablePresets = [
  {
    label: "Depth",
    value: ControlNetPreset.DEPTH,
  },
  {
    label: "Structure",
    value: ControlNetPreset.CANNY,
  },
  {
    label: "Pose",
    value: ControlNetPreset.POSE,
  },
  {
    label: "Edges",
    value: ControlNetPreset.LINES,
  },
  {
    label: "Segmentation",
    value: ControlNetPreset.SEG,
  },
  {
    label: "Scribble",
    value: ControlNetPreset.SCRIBBLE,
  },
  {
    label: "Normal Map",
    value: ControlNetPreset.NORMAL_MAP,
  },
  {
    label: "Line Art",
    value: ControlNetPreset.LINEART,
  },
  {
    label: "Illusion",
    value: ControlNetPreset.ILLUSION,
  },
  {
    label: "Sketch",
    value: ControlNetPreset.SKETCH,
  },
];

const controlNetAllPresets = [
  ...controlNetNonComposablePresets,
  ...controlNetComposablePresets,
];

export const allowedPresets: {
  [key in "sdxl" | "sd1_5" | "flux"]: {
    nonComposable: ControlNetPreset[];
    composable: ControlNetPreset[];
  };
} = {
  sdxl: {
    nonComposable: [
      ControlNetNonComposablePreset.CHARACTER,
      ControlNetNonComposablePreset.LANDSCAPE,
    ],
    composable: [
      ControlNetComposablePreset.CANNY,
      ControlNetComposablePreset.DEPTH,
      ControlNetComposablePreset.POSE,
      ControlNetComposablePreset.SEG,
      ControlNetComposablePreset.ILLUSION,
      ControlNetComposablePreset.SCRIBBLE,
    ],
  },
  sd1_5: {
    nonComposable: [
      ControlNetNonComposablePreset.CHARACTER,
      ControlNetNonComposablePreset.LANDSCAPE,
      ControlNetNonComposablePreset.CITY,
      ControlNetNonComposablePreset.INTERIOR,
    ],
    composable: [
      ControlNetComposablePreset.CANNY,
      ControlNetComposablePreset.DEPTH,
      ControlNetComposablePreset.POSE,
      ControlNetComposablePreset.LINES,
      ControlNetComposablePreset.SEG,
      ControlNetComposablePreset.NORMAL_MAP,
      ControlNetComposablePreset.SCRIBBLE,
      ControlNetComposablePreset.LINEART,
      ControlNetComposablePreset.ILLUSION,
    ],
  },
  flux: {
    nonComposable: [
      ControlNetNonComposablePreset.TILE,
      ControlNetNonComposablePreset.BLUR,
      ControlNetNonComposablePreset.GRAY,
      ControlNetNonComposablePreset.LOW_QUALITY,
    ],
    composable: [
      ControlNetComposablePreset.CANNY,
      ControlNetComposablePreset.DEPTH,
      ControlNetComposablePreset.POSE,
      ControlNetComposablePreset.SKETCH,
    ],
  },
};

export const getAllowedControlNetPreset = (
  model: GetModelsByModelIdApiResponse["model"] | undefined
) => {
  const modelType = (() => {
    if (getIsModelSdxl(model)) {
      return "sdxl";
    } else if (getIsModelFlux(model)) {
      return "flux";
    }
    return "sd1_5";
  })();
  return allowedPresets[modelType];
};

export const getControlNetPresetFromString = (value: string) => {
  return controlNetAllPresets.find(
    (option) => option.value === value.split(":")[0]
  );
};

export const getControlNetInfluenceFromString = (value: string) => {
  const influence = parseFloat(value.split(":")[1]);
  return influence && !isNaN(influence) ? influence : 1;
};
