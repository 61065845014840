import React, { RefObject, useMemo, useRef, useState } from "react";
import {
  FileManagerProps,
  HandleSelect,
} from "domains/file-manager/components/FileManager";
import DefaultFilePreview from "domains/file-manager/components/FilePreview";
import { GridViewKey } from "domains/file-manager/constants/GridView";
import { useAvoidFlicker } from "domains/file-manager/hooks/useAvoidFlicker";
import {
  FileCanvasType,
  FileHandler,
  FileImageType,
  FileModelType,
  FileType,
} from "domains/file-manager/interfaces";
import { useWindowSize } from "domains/ui/hooks/useWindowSize";
import { useUserContext } from "domains/user/contexts/UserProvider";
import {
  useContainerPosition,
  useInfiniteLoader,
  useMasonry,
  usePositioner,
  useResizeObserver,
} from "masonic";
import { useScroller } from "mini-virtual-list";

import { Box, HStack, Spinner, Text } from "@chakra-ui/react";
import useSize from "@react-hook/size";

interface FileViewProps {
  files: FileType[];
  gridView: GridViewKey;
  numberOfColumns: number;
  selection: Set<string>;
  onSelect: HandleSelect;
  canSelect?: boolean;
  isSelectOverflow?: boolean;
  forceSelectMode?: boolean;
  fileHandlers: FileManagerProps["fileHandlers"];
  hasMore?: boolean;
  onLoadMore: () => void;
  showFileNames?: "always" | "hover" | "never";
  scrollRef?: RefObject<HTMLDivElement>;
  loadingText?: string;
}

export default React.memo(function FileView({
  files,
  gridView,
  numberOfColumns,
  selection,
  fileHandlers,
  hasMore,
  onLoadMore,
  onSelect,
  canSelect,
  isSelectOverflow,
  forceSelectMode,
  showFileNames = "hover",
  scrollRef,
  loadingText = "Loading",
}: FileViewProps) {
  const { nsfwRevealedAssetIds, revealAsset } = useUserContext();
  const maybeLoadMore = useInfiniteLoader(async () => onLoadMore(), {
    isItemLoaded: (index, items) => !!items[index],
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [containerWidth] = useSize(containerRef);
  const { offset, width: itemWidth } = useContainerPosition(containerRef, [
    windowWidth,
    windowHeight,
    containerWidth,
  ]);
  const { scrollTop } = useScroller(scrollRef ?? window, {
    offset,
  });

  // check if files[0] has changed
  const fileZero = files[0];
  const [firstFile, setFirstFile] = useState(fileZero);
  const willChangeOnlyIfFirstFileChangedDimensions = useMemo(() => {
    if (
      fileZero.width !== firstFile.width ||
      fileZero.height !== firstFile.height
    ) {
      return {};
    }
    setFirstFile(fileZero);
    // NOTE: ignoring firstFile to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // firstFile
    fileZero,
  ]);

  const [fileLength, setFileLength] = useState(files.length);
  /** Used to reset positions of already existing files, when we delete files, but not when new files are loaded */
  const willChangeOnlyIfLessFilesThanBefore = useMemo(() => {
    if (files.length < fileLength) {
      return {};
    }
    setFileLength(files.length);
    // NOTE: ignoring fileLength to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // fileLength
    files.length,
  ]);

  const positioner = usePositioner(
    {
      width: itemWidth,
      columnGutter: 8,
      columnCount: numberOfColumns,
      rowGutter: 8,
    },
    [
      willChangeOnlyIfLessFilesThanBefore,
      numberOfColumns,
      gridView,
      willChangeOnlyIfFirstFileChangedDimensions,
      itemWidth,
    ]
  );

  const resizeObserver = useResizeObserver(positioner);

  // Set the selected and revealed property on the files to be rendered
  const masonryItems = useMemo(
    () =>
      files.map(
        (file): MasonryCardItem => ({
          file: file as FileImageType | FileCanvasType | FileModelType,
          selected: selection.has(file.id),
          revealed: nsfwRevealedAssetIds.includes(file.id),
          disabled: !!isSelectOverflow && !selection.has(file.id),
          cardWidth: positioner.columnWidth,
          gridView,
          onSelect,
          canSelect,
          fileHandler: fileHandlers[file.type],
          selectModeEnabled: forceSelectMode || !!selection.size,
          showFileNames,
          onReveal: revealAsset,
        })
      ),
    [
      files,
      selection,
      nsfwRevealedAssetIds,
      isSelectOverflow,
      positioner.columnWidth,
      gridView,
      onSelect,
      canSelect,
      fileHandlers,
      forceSelectMode,
      showFileNames,
      revealAsset,
    ]
  );

  const rendering = useAvoidFlicker(
    useMasonry({
      scrollTop,
      positioner,
      resizeObserver,
      items: masonryItems,
      onRender: maybeLoadMore,
      className: "masonic",
      containerRef,
      itemKey: (data) => data.file.id,
      overscanBy: 2,
      height: windowHeight || 0,
      render: MasonryCard,
    })
  );

  return (
    <Box
      ref={containerRef}
      sx={{ ".masonic": { outline: "none" } }}
      w="full"
      h="full"
      pb={selection.size > 0 ? "90px" : "0px"}
      data-outside-click-excluded="true"
      data-testid="asset-gallery-grid"
      // Remove the ugly outline when the user clicks on the masonic container
    >
      {rendering}

      {hasMore && (
        <HStack
          key="loader"
          alignSelf={"center"}
          w="100%"
          maxW="max-content"
          my={6}
          marginX="auto"
        >
          <Text fontSize={"1.5em"}>{loadingText}</Text>
          <Spinner />
        </HStack>
      )}
    </Box>
  );
});

type MasonryCardItem = {
  file: FileImageType | FileCanvasType | FileModelType;
  selected: boolean;
  revealed: boolean;
  disabled: boolean;
  cardWidth: number;
  gridView: GridViewKey;
  onSelect: FileViewProps["onSelect"];
  canSelect?: boolean;
  fileHandler?:
    | FileHandler<FileImageType>
    | FileHandler<FileCanvasType>
    | FileHandler<FileModelType>;
  selectModeEnabled: boolean;
  showFileNames: FileViewProps["showFileNames"];
  onReveal: (assetId: string) => void;
};

const MasonryCard = React.memo(function MasonryCard({
  data: {
    file,
    selected,
    revealed,
    disabled,
    cardWidth,
    gridView,
    onSelect,
    canSelect,
    fileHandler,
    selectModeEnabled,
    showFileNames,
    onReveal,
  },
  index,
}: {
  index: number;
  data: MasonryCardItem;
  width: number;
}) {
  /** The MasonryCard component need to make space for the file name if it is shown under the file */
  const offsetForFileName = showFileNames === "always" ? 40 : 0;
  const FilePreview = useMemo(
    () =>
      (fileHandler?.FilePreview ||
        DefaultFilePreview) as typeof DefaultFilePreview,
    [fileHandler?.FilePreview]
  );
  const cardHeight =
    (file.height || cardWidth) * (cardWidth / (file.width || cardWidth));

  return (
    <Box
      key={file.id}
      pos="relative"
      h={
        gridView === "masonry"
          ? `${cardHeight + offsetForFileName}px`
          : `${cardWidth + offsetForFileName}px`
      }
      cursor={disabled ? "default" : "pointer"}
      data-id={file.id}
      data-key={index}
      data-testid="asset-gallery-grid-cell"
    >
      <FilePreview
        file={file}
        gridView={gridView}
        isDisabled={disabled}
        onSelect={disabled ? () => {} : onSelect}
        canSelect={canSelect && !disabled}
        isSelected={selected}
        // FIXME: fix type
        fileHandler={fileHandler as FileHandler<FileType>}
        selectModeEnabled={selectModeEnabled}
        showFileNames={showFileNames}
        isRevealed={revealed}
        onReveal={onReveal}
        cardWidth={cardWidth}
      />
    </Box>
  );
});
