import { Fragment, RefObject, useEffect, useMemo } from "react";
import AssetZoom from "domains/assets/components/AssetZoom";
import useClickOutside from "domains/assets/hooks/useClickOutside";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import { HeaderFilterAuthorProps } from "domains/file-manager/components/FileManagerImage/HeaderFilterAuthor";
import { HeaderFilterDateProps } from "domains/file-manager/components/FileManagerImage/HeaderFilterDate";
import FileManagerTopBar, {
  FileManagerTopBarFilterAsset,
} from "domains/file-manager/components/FileManagerTopBar";
import FileView from "domains/file-manager/components/FileView";
import SelectionBar from "domains/file-manager/components/SelectionBar";
import {
  FilterAssetCollectionOption,
  FilterAssetCollectionValue,
} from "domains/file-manager/constants/AssetFilter";
import { GridSortValue } from "domains/file-manager/constants/GridSort";
import { GridViewKey } from "domains/file-manager/constants/GridView";
import { useSelection } from "domains/file-manager/hooks/useSelection";
import {
  FileCanvasType,
  FileHandler,
  FileImageType,
  FileModelType,
  FileType,
} from "domains/file-manager/interfaces";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import { Box, Center, FlexProps, Spinner, VStack } from "@chakra-ui/react";

export interface FileManagerProps {
  files: FileType[];
  assetZoomAdditionalFiles?: FileImageType[];

  fileHandlers: {
    image?: FileHandler<FileImageType>;
    canvas?: FileHandler<FileCanvasType>;
    model?: FileHandler<FileModelType>;
  };

  options?: {
    cacheKey?: PersistedStateKey;

    gridView?: Exclude<GridViewKey, "jobs">;
    canChangeView?: boolean;

    numberOfColumns?: number;
    canChangeNumberOfColumns?: boolean;

    sort?: GridSortValue;
    sortOptions?: GridSortValue[];
    onSortChange?: (value: GridSortValue) => void;

    filterAssetCollection?: FilterAssetCollectionValue;
    filterAssetCollectionOptions?: FilterAssetCollectionOption[];
    isFilterAssetCollectionLoading?: boolean;
    onFilterAssetCollectionChange?: (
      value: FilterAssetCollectionValue | undefined
    ) => void;

    headerFilterDateProps?: HeaderFilterDateProps;
    headerFilterAuthorProps?: HeaderFilterAuthorProps;

    canSelect?: boolean;
    selectionMax?: number;

    showFileNames?: "always" | "hover" | "never";

    withoutTopBar?: boolean;
    withoutSelectionBar?: boolean;
  } & FileManagerTopBarFilterAsset;

  hasMore?: boolean;
  loadMore?: () => void;
  isLoading?: boolean;

  styleProps?: {
    topbar?: FlexProps;
  };

  title?: string;

  scrollRef?: RefObject<HTMLDivElement>;

  loadingText?: string;

  topBarLeftContent?: React.ReactNode;
  topContent?: React.ReactNode;

  assetZoomUseOriginalAssets?: boolean;

  forceSelectMode?: boolean;
  onSelectionChange?: (files: FileType[]) => void;

  withoutAssetZoom?: boolean;
}

export type HandleSelect = (
  id: string,
  options: { shiftPressed: boolean }
) => void;

type FileManagerPersistedState = {
  gridView: Exclude<GridViewKey, "jobs">;
  numberOfColumns: number;
};

const useFileManagerOptions = (
  _files: FileType[],
  options: FileManagerProps["options"]
) => {
  const [state, setState] = usePersistedState<FileManagerPersistedState>(
    options?.cacheKey || undefined,
    {
      defaultValue: {
        gridView: options?.gridView ?? "fill",
        numberOfColumns: options?.numberOfColumns ?? 6,
      },
    }
  );

  const handleGridViewChange = (gridView: Exclude<GridViewKey, "jobs">) => {
    Track(AnalyticsEvents.Gallery.ChangeGridView, {
      gridView,
    });
    setState((state) => ({ ...state, gridView }));
  };

  const handleNumberOfColumnsChange = (numberOfColumns: number) => {
    Track(AnalyticsEvents.Gallery.ChangeColumnsNumber, {
      numberOfColumns,
    });
    setState((state) => ({ ...state, numberOfColumns }));
  };

  // Used for selection area
  // const selectableFilesIds = files.map((file) => file.name);

  return {
    ...state,

    // Used for selection area
    // selectableFilesIds,
    handleGridViewChange,
    handleNumberOfColumnsChange,

    ...(_files.length && _files[0].type === "model"
      ? {
          gridView: "fill" as const,
        }
      : {}),
  };
};

export default function FileManager({
  files = [],
  assetZoomAdditionalFiles,

  hasMore = false,
  loadMore = () => {},
  fileHandlers,
  isLoading = false,

  options = {
    canChangeView: true,
    canChangeNumberOfColumns: true,
    withoutTopBar: false,
    withoutSelectionBar: false,
  },

  styleProps,

  title,

  scrollRef,

  loadingText,

  topBarLeftContent,
  topContent,

  assetZoomUseOriginalAssets,

  forceSelectMode,
  onSelectionChange,

  withoutAssetZoom,
}: FileManagerProps) {
  const {
    gridView,
    numberOfColumns,
    handleGridViewChange,
    handleNumberOfColumnsChange,
  } = useFileManagerOptions(files, options);
  const { selection, handleSelectAll, handleSelect, handleClearSelection } =
    useSelection(files, { max: options.selectionMax });

  const selectedFiles = useMemo(
    () => files.filter((file) => selection.has(file.id)),
    [files, selection]
  );

  const isSelectOverflow =
    options.selectionMax !== undefined &&
    options.selectionMax <= selectedFiles.length;

  // ----------------------------------

  useClickOutside(() => {
    if (forceSelectMode) return;
    handleClearSelection();
  });

  const assets = useMemo(() => {
    return files
      .map((file) => file.type === "image" && file.status === "success" && file)
      .filter(Boolean) as FileImageType[];
  }, [files]);

  // ----------------------------------

  useEffect(() => {
    if (!onSelectionChange) return;
    onSelectionChange(selectedFiles);
  }, [onSelectionChange, selectedFiles]);

  // ----------------------------------

  const canHaveAssetZoom = useMemo(
    () => Object.keys(fileHandlers).includes("image"),
    [fileHandlers]
  );

  const assetZoomFiles = useMemo(() => {
    return [
      ...(assetZoomAdditionalFiles?.filter(
        (file) => !assets.find((item) => item.id === file.id)
      ) ?? []),
      ...assets,
    ];
  }, [assetZoomAdditionalFiles, assets]);

  return (
    <>
      {canHaveAssetZoom && !withoutAssetZoom && (
        <AssetZoom
          assets={assetZoomFiles ?? assets}
          hasMore={hasMore}
          loadMore={loadMore}
          originalAssets={assetZoomUseOriginalAssets}
          onDelete={fileHandlers.image?.onDelete}
        />
      )}

      <Box pos="relative" w="full" h="full">
        {!options.withoutTopBar && (
          <FileManagerTopBar
            styleProps={styleProps?.topbar}
            gridView={gridView}
            canChangeView={options.canChangeView}
            onGridViewChange={handleGridViewChange}
            numberOfColumns={numberOfColumns}
            canChangeNumberOfColumns={options.canChangeNumberOfColumns}
            onNumberOfColumnsChange={handleNumberOfColumnsChange}
            sort={options.sort}
            sortOptions={options.sortOptions}
            onSortChange={options.onSortChange}
            // ---
            filterAssetTypes={options.filterAssetTypes}
            filterAssetTypeOptions={options.filterAssetTypeOptions}
            onFilterAssetTypeChange={options.onFilterAssetTypeChange}
            // ---
            filterAssetCollection={options.filterAssetCollection}
            filterAssetCollectionOptions={options.filterAssetCollectionOptions}
            isFilterAssetCollectionLoading={
              options.isFilterAssetCollectionLoading
            }
            onFilterAssetCollectionChange={
              options.onFilterAssetCollectionChange
            }
            // ---
            headerFilterDateProps={options.headerFilterDateProps}
            headerFilterAuthorProps={options.headerFilterAuthorProps}
            // ---
            leftContent={topBarLeftContent}
            title={title}
          />
        )}

        <VStack align="stretch" pt={1} spacing={3}>
          {topContent}

          {isLoading ? (
            // Loading state
            <Center minH="100px">
              <Spinner />
            </Center>
          ) : !files.length ? (
            // Empty state
            <VStack>
              {Object.keys(fileHandlers).map((key) => (
                <Fragment key={key}>
                  {fileHandlers[key as keyof typeof fileHandlers]?.EmptyState}
                </Fragment>
              ))}
            </VStack>
          ) : (
            <FileView
              hasMore={hasMore}
              forceSelectMode={forceSelectMode}
              onLoadMore={loadMore}
              fileHandlers={fileHandlers}
              onSelect={handleSelect}
              selection={selection}
              canSelect={options.canSelect}
              isSelectOverflow={isSelectOverflow}
              files={files}
              gridView={gridView}
              numberOfColumns={numberOfColumns}
              showFileNames={options.showFileNames}
              scrollRef={scrollRef}
              loadingText={loadingText}
            />
          )}
        </VStack>

        {!options.withoutSelectionBar && (
          <SelectionBar
            fileHandlers={fileHandlers}
            onDeselectAll={handleClearSelection}
            onSelectAll={handleSelectAll}
            files={files}
            selectedFiles={selectedFiles}
            selectionMax={options.selectionMax}
          />
        )}
      </Box>
    </>
  );
}
