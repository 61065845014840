import { PixelateForm } from "domains/assets/hooks/useAssetPixelate";

export const PIXELATE_DEFAULT_VALUES: PixelateForm = {
  shouldRemoveNoise: true,
  shouldRemoveBackground: false,
  pixelGridSize: 64,
  shouldUseNewColorPalette: false,
  newColorPalette: {
    id: Math.random().toString(),
    name: "Custom palette",
    colors: [],
  },
  selectedColorPalette: undefined,
  colorPaletteSize: undefined,
  reference: undefined,
};
