import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";
import { MiniCanvasSettings } from "domains/mini-canvas/interfaces/miniCanvas";

interface MiniCanvasProviderContextValue {
  miniCanvasSettings: MiniCanvasSettings;
  setMiniCanvasSettings: (miniCanvasSettings: MiniCanvasSettings) => void;
}

export const MiniCanvasContext = createContext<MiniCanvasProviderContextValue>({
  miniCanvasSettings: { color: "#000000", width: 30 },
  setMiniCanvasSettings: () => {},
});

export function MiniCanvasProvider({ children = <></> }: PropsWithChildren) {
  const [miniCanvasSettings, setMiniCanvasSettings] =
    useState<MiniCanvasSettings>({ color: "#000000", width: 30 });

  const miniCanvasContextValue = useMemo(
    () => ({
      miniCanvasSettings,
      setMiniCanvasSettings,
    }),
    [miniCanvasSettings, setMiniCanvasSettings]
  );

  return (
    <MiniCanvasContext.Provider value={miniCanvasContextValue}>
      {children}
    </MiniCanvasContext.Provider>
  );
}

export function useMiniCanvasContext() {
  return useContext<MiniCanvasProviderContextValue>(MiniCanvasContext);
}
