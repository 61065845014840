import React, { useCallback, useMemo } from "react";
import useAssetPixelate from "domains/assets/hooks/useAssetPixelate";
import { useAssetPixelateCu } from "domains/assets/hooks/useAssetPixelateCu";
import { getImageThumbnail } from "domains/file-manager/interfaces";
import useRouter from "domains/navigation/hooks/useRouter";
import ButtonWithCuIndicator from "domains/ui/components/ButtonWithCuIndicator";
import PixelateParams from "domains/ui/components/PixelateParams";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

import { VStack } from "@chakra-ui/react";

export interface DrawerPixelateProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  onClose: (options: { avoidRedirect?: boolean }) => void;
}

export default function DrawerPixelate({
  asset,
  onClose,
}: DrawerPixelateProps) {
  const router = useRouter();
  const { form, setValue, handlePixelateAsset, isPixelateLoading } =
    useAssetPixelate();

  const assetThumbnail = useMemo(
    () =>
      getImageThumbnail({
        url: asset.url,
        type: asset.metadata.type,
        width: 50,
      }),
    [asset.url, asset.metadata.type]
  );

  const handleSubmit = useCallback(async () => {
    const newAsset = await handlePixelateAsset({
      assetId: asset.id,
      trackingExtraParams: {
        origin: "image edit",
      },
    });
    if (newAsset) {
      delete router.query.edit;
      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          openAssetId: newAsset.id,
        },
      });
      onClose({
        avoidRedirect: true,
      });
    }
  }, [asset, handlePixelateAsset, router, onClose]);

  const { cuCost, isCuLoading } = useAssetPixelateCu(asset.id, form);

  return (
    <VStack align="start" w="100%" spacing={5}>
      <PixelateParams
        imageSrc={assetThumbnail}
        form={form}
        setValue={setValue}
      />
      <ButtonWithCuIndicator
        cuCost={cuCost}
        isCuLoading={isCuLoading}
        size="sm"
        onClick={handleSubmit}
        isLoading={isPixelateLoading}
        w="100%"
      >
        Pixelate
      </ButtonWithCuIndicator>
    </VStack>
  );
}
