import React, { PropsWithChildren } from "react";
import isBreakpointMobile from "domains/commons/isBreakpointMobile";
import Divider from "domains/ui/components/Divider";

import { useBreakpoint, VStack } from "@chakra-ui/react";

import { SidebarProvider } from "./context";
import SidebarSection, { SidebarSectionProps } from "./Section";

export { SidebarSection };
export type { SidebarSectionProps };

export interface SidebarProps extends PropsWithChildren {
  id: string;
  isMobile?: boolean;
  title?: React.ReactNode;
}

export default function Sidebar({
  id,
  isMobile: overrideIsMobile,
  title,
  children,
}: SidebarProps) {
  const breakpoint = useBreakpoint({ ssr: true });
  const isMobile =
    overrideIsMobile !== undefined
      ? overrideIsMobile
      : isBreakpointMobile(breakpoint);

  return (
    <VStack
      className="custom-scrollbar"
      zIndex="calc(var(--chakra-zIndices-sticky) + 1)"
      align="stretch"
      overflow="auto"
      w={isMobile ? "100%" : "375px"}
      h={isMobile ? "50%" : "100%"}
      pb={6}
      borderRightWidth={isMobile ? 0 : 1}
      borderBottomWidth={isMobile ? 1 : 0}
      bgColor="secondary.900"
      spacing={0}
    >
      {!!title && (
        <>
          {title}
          <Divider />
        </>
      )}

      <SidebarProvider prefix={id}>{children}</SidebarProvider>
    </VStack>
  );
}
