import { useCallback, useMemo } from "react";
import useDeepMemo from "domains/commons/hooks/useDeepMemo";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import { GridViewKey } from "domains/file-manager/constants/GridView";
import _ from "lodash";

interface UseAssetViewArg {
  variant?: "image" | "skybox" | "texture";
  options?: GridViewKey[];
}

export default function useAssetView({
  variant = "image",
  options: unmemoizedOptions,
}: UseAssetViewArg = {}) {
  const options = useDeepMemo(unmemoizedOptions);
  const [viewType, setViewType] = usePersistedState<"jobs" | "slabs">(
    PersistedStateKey.FILE_MANAGER_VIEW,
    {
      defaultValue: "jobs",
    }
  );
  const [slabType, setSlabType] = usePersistedState<
    Exclude<GridViewKey, "jobs">
  >(PersistedStateKey.FILE_MANAGER_SLAB, {
    defaultValue: "masonry",
  });

  const viewOptions = useMemo<GridViewKey[] | undefined>(() => {
    const validOptions = ["skybox", "texture"].includes(variant)
      ? (["masonry", "jobs"] as GridViewKey[])
      : undefined;
    if (options && validOptions) return _.intersection(options, validOptions);
    return options ?? validOptions;
  }, [variant, options]);

  const view = useMemo<GridViewKey>(() => {
    if (
      viewType === "slabs" &&
      (!viewOptions || viewOptions.includes(slabType))
    ) {
      return slabType;
    } else if (
      viewType === "jobs" &&
      (!viewOptions || viewOptions.includes("jobs"))
    ) {
      return "jobs";
    } else {
      return (viewOptions ?? [])[0];
    }
  }, [slabType, viewOptions, viewType]);

  const handleViewChange = useCallback(
    (view: GridViewKey) => {
      setViewType(view !== "jobs" ? "slabs" : view);
      if (view !== "jobs") {
        setSlabType(view);
      }
    },
    [setSlabType, setViewType]
  );

  return {
    view,
    viewOptions,
    onViewChange: handleViewChange,
  };
}
