import {
  getIsBaseModel,
  getIsModelFlux,
  getIsModelFluxDev,
} from "domains/models/utils";
import { GetModelsByModelIdApiResponse } from "infra/api/generated/api";

export function getApiControlEndValue(controlEnd: number) {
  return controlEnd / 100;
}

export function getFrontendControlEndValue(controlEnd: number) {
  return Math.round(controlEnd * 100);
}

export function getIsControlEndAvailable(
  model: GetModelsByModelIdApiResponse["model"] | undefined
) {
  return (
    getIsModelFlux(model) &&
    (!getIsBaseModel(model) || getIsModelFluxDev(model))
  );
}
