import { useRef } from "react";
import { useAssetTagsManager } from "domains/assets/hooks/useAssetTagsManager";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { appShortcuts } from "domains/shortcuts/components/appShortcuts";
import TagDisplayWithRemove from "domains/tags/components/TagDisplayWithRemove";
import TagInput from "domains/tags/components/TagInput";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { AsideSection } from "domains/ui/components/Aside";
import { useAsideContext } from "domains/ui/components/Aside/context";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

export interface AsideSectionTagsProps {
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}

export default function AsideSectionTags({ asset }: AsideSectionTagsProps) {
  const { selectedProject } = useTeamContext();
  const { updateAssetTags } = useAssetTagsManager();
  const isAssetOwner = asset?.ownerId === selectedProject.id;
  const tagInputRef = useRef<HTMLInputElement>(null);
  const { collapsedSections, toggleCollapsedSection } = useAsideContext();

  useHotkeys(appShortcuts.assetPage.shortcuts.enterTags.shortcut, () => {
    if (!isAssetOwner) return;
    const isCollapsed = collapsedSections.includes("tags");
    if (isCollapsed) {
      toggleCollapsedSection("tags", false);
    }
    setTimeout(
      () => {
        tagInputRef.current?.focus();
      },
      isCollapsed ? 100 : 1
    );
  });

  const isSectionHidden = !asset || (!isAssetOwner && !asset.tags.length);
  if (isSectionHidden) {
    return null;
  }

  return (
    <AsideSection id="tags" title="Tags">
      {isAssetOwner && (
        <TagInput
          currentTags={asset.tags}
          onAddTags={(tags) =>
            updateAssetTags(asset, [...asset.tags, ...tags], true)
          }
          inputRef={tagInputRef}
        />
      )}
      <TagDisplayWithRemove
        tags={asset.tags}
        onRemove={
          isAssetOwner
            ? (tag) =>
                updateAssetTags(
                  asset,
                  asset.tags.filter((t) => t !== tag),
                  true
                )
            : undefined
        }
      />
    </AsideSection>
  );
}
