export const AsideEditActions = [
  "removeBg",
  "upscale",
  "skyboxUpscale",
  "pixelate",
  "vectorize",
  "expand",
] as const;
export type AsideEditAction = (typeof AsideEditActions)[number];

export const ASIDE_EDIT_ACTIONS: {
  [key in AsideEditAction]: { title: string };
} = {
  removeBg: { title: "Remove Background" },
  upscale: { title: "Enhance" },
  skyboxUpscale: { title: "Enhance" },
  pixelate: { title: "Pixelate" },
  vectorize: { title: "Vectorize" },
  expand: { title: "Expand" },
};
