import { useCallback } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { createTagUpdateMessage } from "domains/tags/utils";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useHandleApiError } from "infra/api/error";
import {
  GetAssetsByAssetIdApiResponse,
  usePutAssetsTagsByAssetIdMutation,
} from "infra/api/generated/api";

export const useAssetTagsManager = () => {
  const { selectedProject } = useTeamContext();
  const { successToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const [putAssetTags] = usePutAssetsTagsByAssetIdMutation();

  const updateAssetTags = useCallback(
    async (
      asset: GetAssetsByAssetIdApiResponse["asset"],
      newTags: string[],
      withToast: boolean
    ) => {
      const tagsToAdd = newTags.filter((tag) => !asset.tags.includes(tag));
      const tagsToRemove = asset.tags.filter((tag) => !newTags.includes(tag));

      try {
        await putAssetTags({
          assetId: asset.id,
          projectId: selectedProject.id,
          body: {
            add: tagsToAdd,
            delete: tagsToRemove,
          },
        }).unwrap();

        if (withToast) {
          successToast({
            title: createTagUpdateMessage(tagsToAdd, tagsToRemove),
          });
        }
      } catch (error) {
        handleApiError(
          error,
          "There was an error updating the tags",
          {},
          withToast
        );
        if (!withToast) {
          throw error;
        }
      }
    },
    [putAssetTags, selectedProject.id, handleApiError, successToast]
  );

  return {
    updateAssetTags,
  };
};
