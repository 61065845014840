import { MouseEvent } from "react";
import Icon from "domains/ui/components/Icon";

import { Tooltip } from "@chakra-ui/react";

export interface NsfwIndicatorProps {
  onClick: (event: MouseEvent<SVGElement>) => void;
}

export function NsfwIndicator({ onClick }: NsfwIndicatorProps) {
  return (
    <Tooltip hasArrow label={"Click to see sensitive content"} placement="auto">
      <Icon
        pos="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        id="Ui/EyeClosed"
        maxW="20%"
        maxH="20%"
        onClick={onClick}
        cursor="pointer"
        data-outside-click-excluded
      />
    </Tooltip>
  );
}
