import { useCallback, useEffect, useState } from "react";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { useAssetUpload } from "domains/image/hooks/useAssetUpload";
import useImageUploadDragDrop from "domains/image/hooks/useImageUploadDragDrop";
import useRouter from "domains/navigation/hooks/useRouter";
import SearchModal, {
  SearchModalTab,
} from "domains/search/components/SearchModal";
import { useSearchContext } from "domains/search/contexts/SearchProvider";
import { appShortcuts } from "domains/shortcuts/components/appShortcuts";
import Button from "domains/ui/components/Button";
import CompatibleImageUploader, {
  ImageListType,
} from "domains/ui/components/CompatibleImageUploader";
import Icon from "domains/ui/components/Icon";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import _ from "lodash";

import { Spinner, Text, useDisclosure } from "@chakra-ui/react";

export default function SearchButton() {
  const router = useRouter();
  const { uploadImage } = useAssetUpload();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [assetId, setAssetId] = useState<string | undefined>();
  const [aiBoost, setAiBoost] = useState(false);
  const [tabId, setTabId] = useState<SearchModalTab>("image");
  const { clearCache } = useSearchContext();

  const {
    isDraggingHover: isDraggingHoverDropZone,
    dragFunctions,
    onDrop,
  } = useImageUploadDragDrop({
    onImageDrop: async ({ assetId }) => {
      if (!assetId) return;
      setIsUploadLoading(true);
      setAssetId(assetId);
      onOpen();
      setIsUploadLoading(false);
    },
  });

  // ----------------------------------

  const handleImageDrop = useCallback(
    async (imageList: ImageListType) => {
      if (imageList.length === 0) {
        return;
      }

      const image = imageList[0].dataURL;
      if (!image) {
        return;
      }

      setIsUploadLoading(true);
      const uploadedAsset = await uploadImage({
        imageData: image,
        name: "Search Image",
        trackingEvent: AnalyticsEvents.Search.ImportedImage,
      });
      if (uploadedAsset) {
        setAssetId(uploadedAsset.id);
        onOpen();
      }

      setIsUploadLoading(false);
    },
    [uploadImage, onOpen, setAssetId]
  );

  const handleClose = useCallback(() => {
    onClose();
    clearCache();
    setQuery("");
    setAssetId(undefined);
    setAiBoost(false);
    setTabId("image");
  }, [onClose, clearCache, setQuery, setAssetId, setAiBoost]);

  // ----------------------------------

  useEffect(() => {
    if (router.query.searchQuery) {
      setQuery((router.query.searchQuery as string) ?? "");
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (router.query.searchAssetId) {
      setAssetId((router.query.searchAssetId as string) ?? undefined);
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.searchAssetId]);

  useEffect(() => {
    const updateQueryParams = () => {
      void router.push(
        {
          query: _.pickBy(
            {
              ...router.query,
              searchQuery: query,
              searchAssetId: assetId,
            },
            Boolean
          ),
        },
        undefined,
        { shallow: true }
      );
    };
    if (query || assetId) {
      const timeout = setTimeout(updateQueryParams, 500);
      return () => {
        if (timeout) clearTimeout(timeout);
      };
    } else {
      updateQueryParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, assetId]);

  // ----------------------------------

  useHotkeys(
    appShortcuts.global.shortcuts.search.shortcut,
    (e) => {
      e.preventDefault();
      onOpen();
    },
    {
      enabled: !router.query.openAssetId,
    }
  );

  // ----------------------------------

  return (
    <CompatibleImageUploader
      value={[]}
      onChange={handleImageDrop}
      allowNonImageType={false}
      multiple={false}
    >
      {({ dragProps: { onDrop: dragPropsOnDrop } }) => {
        return (
          <>
            <Button
              onDrop={(event) => onDrop(event, dragPropsOnDrop)}
              {...dragFunctions}
              {...(isDraggingHoverDropZone && {
                color: "textPrimary !important",
                bg: "whiteAlpha.400 !important",
              })}
              size="sm"
              variant="alpha"
              leftIcon={<Icon id="Ui/Search" h="14px" />}
              onClick={onOpen}
            >
              <Text color="inherit" size="body.md">
                Search
              </Text>
              {isUploadLoading && <Spinner size="sm" />}
            </Button>

            <SearchModal
              isOpen={isOpen}
              onClose={handleClose}
              query={query}
              setQuery={setQuery}
              assetId={assetId}
              setAssetId={setAssetId}
              aiBoost={aiBoost}
              setAiBoost={setAiBoost}
              tabId={tabId}
              setTabId={setTabId}
            />
          </>
        );
      }}
    </CompatibleImageUploader>
  );
}
