import { useCallback } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";

import * as Sentry from "@sentry/react";

const Handlers = {
  400: "bad_request",
  429: "quota",
  413: "file_size",
} as const;
type HandlersKeys = keyof typeof Handlers;
type HandlersValues = (typeof Handlers)[HandlersKeys];

export function useHandleApiError() {
  const { errorToast } = useScenarioToast();
  const { refetchTeamDetails } = useTeamContext();
  const { showLimitModal } = usePlanContext();

  return useCallback(
    (
      error: any,
      errorTitle: string,
      handlers?: Partial<{
        [key in HandlersValues]: () => void | Promise<void>;
      }>,
      withToast = true
    ) => {
      if (
        error.status !== 429 &&
        error.status !== 503 &&
        error.status !== 403 &&
        error.error !== "TypeError: Failed to fetch" &&
        error.error !==
          "TypeError: NetworkError when attempting to fetch resource."
      ) {
        let errorName = "";
        if (error.data?.reason) {
          errorName = error.data.reason;
        } else if (error.message) {
          errorName = error.message;
        } else {
          errorName = errorTitle;
        }
        Sentry.captureMessage(errorName, {
          level: "error",
          extra: { error },
        });
      }

      if (error.status === 503) {
        return;
      }

      if (error.status === 429) {
        if (error.data?.reason.includes("update your payment method")) {
          errorToast({
            title:
              "Latest payment attempt was unsuccessful. Please update the organization payment method.",
          });
          refetchTeamDetails();
          return;
        } else if (
          error.data?.reason.includes(
            "You have reached the maximum number of free teams"
          )
        ) {
          showLimitModal("freeTeams");
          return;
        }
      }

      if (error.status && handlers) {
        for (const [handlerType, handler] of Object.entries(handlers)) {
          if (Handlers[error.status as HandlersKeys] === handlerType) {
            void handler();
            return;
          }
        }
      }

      if (withToast) {
        if (error.data?.reason) {
          errorToast({
            title: errorTitle,
            description: error.data.reason,
          });
          return;
        }

        errorToast({
          title: "Something went wrong, please try again later.",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
