import { useEffect, useMemo, useState } from "react";
import useDeepMemo from "domains/commons/hooks/useDeepMemo";
import {
  gridSorts,
  GridSortValue,
} from "domains/file-manager/constants/GridSort";

export default function useAssetSort(
  options: GridSortValue[] = ["createdDesc", "createdAsc"],
  defaultSort?: GridSortValue
) {
  const memoizedOptions = useDeepMemo(options);
  const [sort, setSort] = useState<GridSortValue>(
    defaultSort ?? memoizedOptions[0]
  );

  const fmHeaderSortProps = useMemo(
    () => ({
      value: sort,
      options: memoizedOptions,
      onChange: setSort,
    }),
    [sort, setSort, memoizedOptions]
  );

  const sortByAndOrder = gridSorts[sort].value.split(":");

  useEffect(() => {
    if (!defaultSort || !memoizedOptions.includes(defaultSort)) {
      setSort(memoizedOptions[0]);
    }
  }, [memoizedOptions, defaultSort]);

  return {
    setSort,
    searchSortArgs: {
      sort: gridSorts[sort].value,
    },
    allAssetsSortArgs: {
      sortBy: sortByAndOrder[0],
      sortDirection: sortByAndOrder[1],
    },
    assetFileManagerSortOptions: {
      sort,
      sortOptions: memoizedOptions,
      onSortChange: setSort,
    },
    sortQueryArgs: {
      sortBy: sortByAndOrder[0],
      sortDirection: sortByAndOrder[1],
    },
    fmHeaderSortProps,
  };
}
