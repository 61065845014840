import { useCallback, useMemo, useState } from "react";
import { MAX_BASE64_UPLOAD_SIZE } from "domains/assets/constants/upload";
import { getBase64Size } from "domains/assets/utils";
import { compressImageForUpload } from "domains/assets/utils/compressImage";
import { useClipboardContext } from "domains/commons/contexts/ClipboardProvider";
import useImageUploadDragDrop from "domains/image/hooks/useImageUploadDragDrop";
import loadBase64ImageFromUrl from "domains/image/methods/loadBase64ImageFromUrl";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import SearchBarFileUpload from "domains/search/components/SearchBar/fileUpload";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import CompatibleImageUploader from "domains/ui/components/CompatibleImageUploader";
import Icon from "domains/ui/components/Icon";
import ScenarioInput, {
  ScenarioInputProps,
} from "domains/ui/components/ScenarioInput";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import { usePostAssetMutation } from "infra/api/generated/api";

import {
  Box,
  BoxProps,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Skeleton,
  Switch,
  Text,
  Tooltip,
} from "@chakra-ui/react";

export interface SearchQueryData {
  query: string;
  assetId: string | undefined;
}

type SearchBarProps = BoxProps & {
  query: string;
  setQuery: (query: string) => void;
  onSearch?: (searchQueryData: SearchQueryData) => void;
  searchOn?: ("enter" | "clear" | "imageChange")[];
  searchBarRef?: React.RefObject<HTMLInputElement>;
  size?: "md" | "lg";
  inputProps?: ScenarioInputProps["inputProps"];
  aiBoost?: boolean;
  setAiBoost?: (aiBoost: boolean) => void;
  privacy?: "public" | "private";
  setPrivacy?: (privacy: "public" | "private") => void;
} & (
    | {
        assetId: string | undefined;
        setAssetId: (assetId: string | undefined) => void;
      }
    | {
        assetId?: undefined;
        setAssetId?: undefined;
      }
  );

export default function SearchBar({
  query,
  setQuery,
  onSearch,
  searchOn = ["enter", "clear", "imageChange"],
  searchBarRef,
  size = "md",
  assetId,
  setAssetId,
  inputProps,
  aiBoost,
  setAiBoost,
  privacy,
  setPrivacy,
  ...props
}: SearchBarProps) {
  const { errorToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { selectedProject } = useTeamContext();
  const [postAssetTrigger] = usePostAssetMutation();
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const { addImagePasteListener, removeImagePasteListener } =
    useClipboardContext();
  const { successToast } = useScenarioToast();

  const { isDraggingHover, dragFunctions, onDrop } = useImageUploadDragDrop({
    onImageDrop: async ({ dragUrl, assetId }) => {
      if (assetId) {
        setAssetId?.(assetId);
        if (searchOn.includes("imageChange")) {
          callOnSearch({
            query,
            assetId: assetId,
          });
        }
      } else if (dragUrl) {
        const base64 = await loadBase64ImageFromUrl(dragUrl);
        if (base64) {
          void handleImageUpload(base64);
        } else {
          errorToast({
            title: "Error importing image",
          });
        }
      }
    },
  });

  // ----------------------------------

  const callOnSearch = useCallback(
    (searchQueryData: SearchQueryData) => {
      if (onSearch) {
        if (searchQueryData.query || searchQueryData.assetId) {
          Track(AnalyticsEvents.Search.Searched, searchQueryData);
        }
        onSearch(searchQueryData);
      }
    },
    [onSearch]
  );

  const clearAll = useCallback(() => {
    setQuery("");
    setAssetId?.(undefined);
    if (onSearch && searchOn.includes("clear")) {
      onSearch({
        query: "",
        assetId: undefined,
      });
    }
  }, [setAssetId, setQuery, onSearch, searchOn]);

  // ----------------------------------

  const handleImageUpload = useCallback(
    async (imageData: string) => {
      if (!setAssetId) return;

      setIsUploadLoading(true);
      let imageBase64Size: number | undefined;

      try {
        imageBase64Size = getBase64Size(imageData);
      } catch (_) {
        errorToast({
          title: "Error importing image",
          description: "The image file size is too big.",
        });
        setIsUploadLoading(false);
        return;
      }
      Track(AnalyticsEvents.Search.ImportedImage, {
        size: imageBase64Size,
        resize: imageBase64Size > MAX_BASE64_UPLOAD_SIZE,
      });

      const resizedImage = await compressImageForUpload(imageData);
      if (!resizedImage) {
        errorToast({
          title: "Error importing image",
          description:
            "There was an error importing the image. Please try again.",
        });
        setIsUploadLoading(false);
        return;
      }

      try {
        const response = await postAssetTrigger({
          projectId: selectedProject.id,
          body: {
            image: resizedImage.data,
            name: "search-image",
          },
        }).unwrap();
        setAssetId(response.asset.id);
        if (searchOn.includes("imageChange")) {
          callOnSearch({
            query,
            assetId: response.asset.id,
          });
        }
      } catch (error) {
        handleApiError(error, "Error importing image", {
          file_size: () => {
            errorToast({
              title: "Error importing image",
              description: "The image file size is too big.",
            });
          },
        });
      } finally {
        setIsUploadLoading(false);
      }
    },
    [
      postAssetTrigger,
      selectedProject.id,
      callOnSearch,
      errorToast,
      handleApiError,
      setAssetId,
      query,
      searchOn,
    ]
  );

  const handleImagePaste = useCallback(
    (images: string[]) => {
      if (images.length !== 1 || !setAssetId) return;
      void handleImageUpload(images[0]);

      successToast({
        title: "Image imported from clipboard",
      });
    },
    [handleImageUpload, successToast, setAssetId]
  );

  // ----------------------------------

  const isPublicSearch = useMemo(() => {
    return privacy === "public";
  }, [privacy]);

  // ----------------------------------

  return (
    <CompatibleImageUploader
      value={[]}
      onChange={async (imageList) => {
        if (imageList.length === 0) {
          return;
        }

        const dataUrl = imageList[0].dataURL;
        if (dataUrl === undefined) {
          return;
        }

        void handleImageUpload(dataUrl);
      }}
      allowNonImageType={false}
      maxNumber={1}
      multiple={false}
    >
      {({
        onImageUpload: onClickImageUpload,
        dragProps: { onDrop: dragPropsOnDrop },
      }) => {
        return (
          <Box
            {...(setAssetId ? dragFunctions : {})}
            pos="relative"
            w="100%"
            onDrop={setAssetId ? (e) => onDrop(e, dragPropsOnDrop) : undefined}
          >
            <ScenarioInput
              ref={searchBarRef}
              inputProps={{
                fontSize: size === "lg" ? "16px" : undefined,
                ...inputProps,
                onFocus: () => {
                  addImagePasteListener(handleImagePaste);
                },
                onBlur: () => {
                  removeImagePasteListener(handleImagePaste);
                },
              }}
              onEnter={() => {
                if (searchOn.includes("enter")) {
                  callOnSearch({
                    query,
                    assetId,
                  });
                }
              }}
              value={query}
              setValue={setQuery}
              placeholder="Search"
              leftComponent={
                <Flex align="center" gap={2}>
                  <Icon mb={0.5} id="Ui/Search" color="inherit" />

                  {assetId && (
                    <Center
                      sx={{
                        button: {
                          display: "none",
                        },
                        "&:hover button": {
                          display: "inline-flex",
                        },
                      }}
                      pos="relative"
                      w="38px"
                      h="38px"
                    >
                      <Image
                        maxW="100%"
                        maxH="100%"
                        borderWidth={1}
                        borderRadius="base"
                        alt="image search"
                        fallback={<Skeleton w="100%" h="100%" />}
                        src={`${process.env.NEXT_PUBLIC_CDN_URL}/thumbnails/${assetId}`}
                      />

                      <Button
                        position="absolute"
                        top={0}
                        right={0}
                        transform="translate(25%, -25%)"
                        variant="blurred"
                        borderWidth={0}
                        h="16px"
                        w="16px"
                        borderRadius="full"
                        minW="16px"
                        px={0}
                        onClick={() => {
                          setAssetId(undefined);
                          if (searchOn.includes("imageChange")) {
                            callOnSearch({
                              query,
                              assetId: undefined,
                            });
                          }
                        }}
                      >
                        <Icon id="Ui/Cross" color="white" h="8px" />
                      </Button>
                    </Center>
                  )}
                </Flex>
              }
              rightComponent={
                <Flex align="center" gap={2.5}>
                  {assetId || query ? (
                    <>
                      <Button
                        px={2}
                        h="28px"
                        variant="ghost"
                        onClick={clearAll}
                        colorScheme="white"
                        size="xs"
                      >
                        <Text color="textSecondary">Clear</Text>
                      </Button>

                      <Divider h={5} orientation="vertical" />
                    </>
                  ) : null}

                  {setAssetId && (
                    <SearchBarFileUpload
                      onClickImageUpload={onClickImageUpload}
                      isUploadLoading={isUploadLoading}
                    />
                  )}

                  {setPrivacy && (
                    <>
                      <Divider h={5} orientation="vertical" />
                      <Button
                        variant="ghost"
                        size="xs"
                        onClick={() =>
                          setPrivacy(isPublicSearch ? "private" : "public")
                        }
                        colorScheme={isPublicSearch ? "white" : "gray"}
                      >
                        public: {isPublicSearch ? "on" : "off"}
                      </Button>
                    </>
                  )}

                  {setAiBoost && (
                    <>
                      <Divider h={5} orientation="vertical" />
                      <HStack align="center" gap={2.5}>
                        <HStack align="center" gap={1}>
                          <Icon
                            id="Ui/StarsAlt"
                            h="12px"
                            color="textSecondary"
                          />
                          <Text color="textSecondary" size="body.sm">
                            Boost
                          </Text>
                        </HStack>
                        <Tooltip
                          label={
                            "Find more results with AI-powered embeddings, and search in any language"
                          }
                          placement="top"
                        >
                          <Box>
                            <Switch
                              isChecked={aiBoost}
                              onChange={(e) => setAiBoost(e.target.checked)}
                            />
                          </Box>
                        </Tooltip>
                      </HStack>
                    </>
                  )}
                </Flex>
              }
              bgColor="backgroundTertiary.500"
              containerProps={{
                opacity: isDraggingHover ? 0 : 1,
                borderRadius: size === "lg" ? "16px" : undefined,
                h: size === "lg" ? "56px" : undefined,
                pl: size === "lg" ? 4 : 2,
                pr: size === "lg" ? 3 : 1,
                color: "textTertiary",
                ...props,
              }}
            />
            {isDraggingHover && (
              <Center
                pos="absolute"
                top={0}
                left={0}
                w="100%"
                h="100%"
                borderWidth={1.5}
                borderStyle="dashed"
                borderColor="primary.500"
                borderRadius="lg"
                bgColor="rgba(0, 136, 204, 0.15)"
              >
                <Text color="textPrimary" size="body.lessbold.lg">
                  Drop an image here
                </Text>
              </Center>
            )}
          </Box>
        );
      }}
    </CompatibleImageUploader>
  );
}
