import React, { useCallback, useMemo, useState } from "react";
import { NextSeo } from "next-seo";
import DropAssetModal from "domains/assets/components/DropAssetModal";
import useAllAssets from "domains/assets/hooks/useAllAssets";
import useAssetJobFilterAuthor from "domains/assets/hooks/useAssetAuthor";
import useAssetFilterType from "domains/assets/hooks/useAssetFilterType";
import useAssetSort from "domains/assets/hooks/useAssetSort";
import useAssetView from "domains/assets/hooks/useAssetView";
import FileManagerImage, {
  FileManagerImageProps,
} from "domains/file-manager/components/FileManagerImage";
import { FmImageCardActionsProps } from "domains/file-manager/components/FileManagerImage/Card/Actions";
import EmptyMessage from "domains/file-manager/components/FileManagerImage/EmptyMessage";
import { FmImageListActionsProps } from "domains/file-manager/components/FileManagerImage/List/Actions";
import CopyPromptButton from "domains/file-manager/components/FileManagerImage/List/CopyPromptButton";
import ReuseButton from "domains/file-manager/components/FileManagerImage/List/ReuseButton";
import { TOP_BAR_HEIGHT } from "domains/navigation/constants/TopBar";
import Button from "domains/ui/components/Button";
import Header from "domains/ui/components/Header";
import Icon from "domains/ui/components/Icon";
import MenuItem from "domains/ui/components/Menu/MenuItem";

import { Box, Divider } from "@chakra-ui/react";

export default function AssetsPage() {
  return (
    <>
      <NextSeo title="Images" />
      <Assets />
    </>
  );
}

export type ListAssetsProps = {
  pageTitle?: React.ReactNode;
  isPrimaryUploadButton?: boolean;
  isWithoutGenerateButton?: boolean;
  collectionId?: string;
  onBeforeUploading?: () => void;
  secondaryActions?: React.ReactNode;
  listContainerProps?: any;
  ListContainerComponent?: React.FunctionComponent<any>;
  EmptyStateComponent?: FileManagerImageProps["EmptyStateComponent"];
};

export function Assets({
  pageTitle,
  isPrimaryUploadButton,
  isWithoutGenerateButton = false,
  collectionId,
  onBeforeUploading,
  secondaryActions,
  listContainerProps,
  ListContainerComponent,
  EmptyStateComponent,
}: ListAssetsProps) {
  const [showUploadModal, setShowUploadModal] = useState(false);

  const fmViewProps = useAssetView();

  const { allAssetsTypeArgs, fmHeaderFilterAssetProps } = useAssetFilterType({
    assetType: "image",
    showVectorization: true,
  });
  const { authorQueryArgs, fmHeaderFilterAuthorProps } =
    useAssetJobFilterAuthor();
  const { sortQueryArgs, fmHeaderSortProps } = useAssetSort();
  const result = useAllAssets({
    collectionId,
    ...sortQueryArgs,
    ...allAssetsTypeArgs,
    ...(collectionId ? undefined : authorQueryArgs),
  });

  const beforeUploading = useCallback(async () => {
    if (onBeforeUploading) {
      onBeforeUploading();
    }

    if (
      fmHeaderFilterAssetProps &&
      fmHeaderFilterAssetProps.values !== undefined &&
      !fmHeaderFilterAssetProps.values.includes("image:uploaded") &&
      fmHeaderFilterAssetProps.onChange
    ) {
      fmHeaderFilterAssetProps.onChange([
        ...fmHeaderFilterAssetProps.values,
        "image:uploaded",
      ]);
    }
  }, [onBeforeUploading, fmHeaderFilterAssetProps]);

  // ----------------------------------

  const ListContainer = ListContainerComponent ?? Box;

  const listActionsProps = useMemo(() => ({}), []);
  const cardActionsProps = useMemo(() => ({}), []);

  // ----------------------------------

  return (
    <>
      <Header title={pageTitle ?? "Images"}>
        <Button
          leftIcon={<Icon id="Ui/Upload" h="12px" />}
          variant={isPrimaryUploadButton ? "primary" : "secondary"}
          size="sm"
          onClick={() => setShowUploadModal(true)}
        >
          Upload
        </Button>

        {!isWithoutGenerateButton && (
          <Button
            leftIcon={<Icon id="Ui/Plus" />}
            size="sm"
            internalLink="/images/new"
          >
            Generate Images
          </Button>
        )}

        {!!secondaryActions && (
          <>
            <Divider h={6} orientation="vertical" />
            {secondaryActions}
          </>
        )}
      </Header>

      <ListContainer mx={9} {...listContainerProps}>
        <DropAssetModal
          showModal={showUploadModal}
          setShowModal={setShowUploadModal}
          beforeUploading={beforeUploading}
        >
          <FileManagerImage
            files={fmViewProps.view === "jobs" ? undefined : result.files}
            jobs={fmViewProps.view === "jobs" ? result.jobs : undefined}
            isLoading={result.isLoading}
            hasMore={result.hasMore}
            onEndReached={result.loadMore}
            isSelectable
            ListActionsComponent={ListActions}
            listActionsProps={listActionsProps}
            CardActionsComponent={CardActions}
            cardActionsProps={cardActionsProps}
            {...fmViewProps}
            headerSortProps={fmHeaderSortProps}
            headerFilterAssetProps={fmHeaderFilterAssetProps}
            headerFilterAuthorProps={
              collectionId ? undefined : fmHeaderFilterAuthorProps
            }
            EmptyStateComponent={EmptyStateComponent ?? EmptyMessage}
            styleProps={{
              header: {
                top: `${TOP_BAR_HEIGHT}px`,
              },
            }}
          />
        </DropAssetModal>
      </ListContainer>
    </>
  );
}

// ------------------------------------

export type CardActionsCustomProps = {
  _never?: never;
};

export type CardActionsProps = FmImageCardActionsProps<CardActionsCustomProps>;

export function CardActions({
  asset,
  top,
  bottom,
  onActionClick,
}: CardActionsProps) {
  const assetId = asset?.id;
  const modelId = asset?.metadata.modelId;
  const hasPrompt = !!asset?.metadata.prompt;
  const canReuseImage = !["vectorization"].includes(asset?.metadata.type ?? "");

  if (!modelId && !canReuseImage && !hasPrompt) {
    return null;
  }

  return (
    <>
      {top}

      {modelId && (
        <MenuItem
          w="150px"
          iconId="Nav/Model/Outline"
          iconH="12px"
          internalLink={{
            pathname: "/images/new",
            query: {
              modelId,
            },
          }}
          onClick={() => onActionClick?.("useModel")}
          text="Model"
        />
      )}

      {canReuseImage && (
        <MenuItem
          w="150px"
          iconId="Nav/Image/Outline"
          iconH="12px"
          internalLink={{
            pathname: "/images/new",
            query: {
              importAssetId: assetId,
            },
          }}
          onClick={() => onActionClick?.("useImage")}
          text="Image"
        />
      )}

      {hasPrompt && (
        <MenuItem
          w="150px"
          iconId="Ui/Prompt"
          iconH="12px"
          internalLink={{
            pathname: "/images/new",
            query: {
              importPromptFromAssetId: assetId,
            },
          }}
          onClick={() => onActionClick?.("usePrompt")}
          text="Prompt"
        />
      )}

      {bottom}
    </>
  );
}

// ------------------------------------

export type ListActionsCustomProps = {
  _never?: never;
};

export type ListActionsProps = FmImageListActionsProps<ListActionsCustomProps>;

export function ListActions({ job, firstAsset }: ListActionsProps) {
  return (
    <>
      <ReuseButton job={job} firstAsset={firstAsset} />
      <CopyPromptButton job={job} firstAsset={firstAsset} />
    </>
  );
}
