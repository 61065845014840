import React from "react";
import {
  PIXEL_GRID_SIZES,
  UseAssetPixelateReturnValue,
} from "domains/assets/hooks/useAssetPixelate";
import ControlButtonSelect from "domains/ui/components/ControlButtonSelect";
import ControlSwitch from "domains/ui/components/ControlSwitch";
import SliderStepsNicolas from "domains/ui/components/Slider/SliderStepsNicolas";

import { VStack } from "@chakra-ui/react";

import ControlColors from "./ControlColors";
import ColorPaletteSelect from "./SelectColorPalette";

interface PixelateParamsProps {
  form: UseAssetPixelateReturnValue["form"];
  setValue: UseAssetPixelateReturnValue["setValue"];
  imageSrc?: string;
}

export default function PixelateParams({
  form,
  setValue,
  imageSrc,
}: PixelateParamsProps) {
  return (
    <VStack align="start" w="100%" spacing={2}>
      <ColorPaletteSelect
        shouldUseNewColorPalette={form.shouldUseNewColorPalette}
        setShouldUseNewColorPalette={(value) =>
          setValue("shouldUseNewColorPalette", value)
        }
        newColorPalette={form.newColorPalette}
        setNewColorPalette={(value) => setValue("newColorPalette", value)}
        selectedColorPalette={form.selectedColorPalette}
        setSelectedColorPalette={(value) =>
          setValue("selectedColorPalette", value)
        }
        imgSrc={imageSrc ?? form.reference?.src}
      />

      <ControlSwitch
        title="Custom color palette"
        description="Create a new palette for your images"
        isChecked={form.shouldUseNewColorPalette}
        setIsChecked={(value) => setValue("shouldUseNewColorPalette", value)}
      />

      {form.shouldUseNewColorPalette && (
        <ControlColors
          newColorPalette={form.newColorPalette}
          setNewColorPalette={(value) => setValue("newColorPalette", value)}
        />
      )}

      {!form.shouldUseNewColorPalette && !form.selectedColorPalette && (
        <SliderStepsNicolas
          title="Color palette size"
          value={form.colorPaletteSize}
          setValue={(newValue) => {
            setValue("colorPaletteSize", newValue === 0 ? undefined : newValue);
          }}
          min={2}
          max={256}
          steps={[0, 2, 4, 8, 16, 32, 64, 128, 256]}
          inputProps={{
            w: "50px",
          }}
          placeholder="Auto"
        />
      )}

      <ControlButtonSelect
        title="Pixel grid size"
        options={PIXEL_GRID_SIZES.map((value) => ({
          value,
          label: `${value}px`,
        }))}
        selectedOption={form.pixelGridSize}
        onOptionSelected={(value) => setValue("pixelGridSize", value)}
      />

      <ControlSwitch
        title="Remove noise"
        isChecked={form.shouldRemoveNoise}
        setIsChecked={(value) => setValue("shouldRemoveNoise", value)}
      />

      <ControlSwitch
        title="Remove background"
        isChecked={form.shouldRemoveBackground}
        setIsChecked={(value) => setValue("shouldRemoveBackground", value)}
      />
    </VStack>
  );
}
