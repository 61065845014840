export const API_TAGS = {
  team: "team",
  project: "project",
  asset: "asset",
  collection: "collection",
  model: "model",
  search: "search",
  subscription: "subscription",
  limits: "limits",
  user: "user",
  job: "job",
} as const;
