import { useCallback } from "react";
import Slider, { SliderProps } from "domains/ui/components/Slider";

import { Box, HStack, Text } from "@chakra-ui/react";

interface SliderStepsPaulProps<T extends string = string>
  extends Omit<SliderProps, "step" | "value" | "onChange" | "min" | "max"> {
  steps: T[];
  value: T | undefined;
  onChange: (value: T) => void;
}

export default function SliderStepsPaul<T extends string>({
  steps,
  value,
  onChange,
  hideValue,
  ...props
}: SliderStepsPaulProps<T>) {
  const selectedIndex = value ? steps.indexOf(value) : -1;

  const handleChange = useCallback(
    (value: number) => {
      onChange(steps[value]);
    },
    [onChange, steps]
  );

  return (
    <HStack>
      <Box flex={1}>
        <Slider
          step={1}
          min={0}
          max={steps.length - 1}
          value={selectedIndex !== -1 ? selectedIndex : 0}
          onChange={handleChange}
          hideValue
          {...props}
        />
      </Box>

      {!hideValue && (
        <Box pos="relative">
          <Box h={0} visibility="hidden">
            {steps.map((step) => (
              <Text key={step} h={0} whiteSpace="nowrap" size="body.md">
                {step}
              </Text>
            ))}
          </Box>

          <Text
            color="inherit"
            textAlign="right"
            whiteSpace="nowrap"
            size="body.md"
          >
            {steps[selectedIndex]}
          </Text>
        </Box>
      )}
    </HStack>
  );
}
