import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useAssetRemoveBackgroundCu } from "domains/assets/hooks/useAssetRemoveBackgroundCu";
import { useAssetUpscaleCu } from "domains/assets/hooks/useAssetUpscaleCu";
import useGetImageDimensions from "domains/commons/hooks/useGetImageDimensions";
import TopIconButton from "domains/models/components/ModelTrain/ElementTopIconButton";
import useRemoveBackgroundFromTrainingImage from "domains/models/hooks/useRemoveBackgroundFromTrainingImage";
import useUpscaleFromTrainingImage from "domains/models/hooks/useUpscaleFromTrainingImage";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Icon from "domains/ui/components/Icon";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  GetModelsByModelIdApiResponse,
  useDeleteModelsTrainingImagesByModelIdAndTrainingImageIdMutation,
  usePutAssetByAssetIdMutation,
} from "infra/api/generated/api";

import {
  Box,
  Editable,
  EditablePreview,
  EditableTextarea,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Skeleton,
  SkeletonText,
  Spinner,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import ElementLowResWarning from "./ElementLowResWarning";

export interface TrainImagesItemProps {
  trainingImage: NonNullable<
    GetModelsByModelIdApiResponse["model"]["trainingImages"]
  >[number];
  model?: GetModelsByModelIdApiResponse["model"];
  disabled?: boolean;
  captionHidden?: boolean;
  lowResMax?: number;
  onChange?: () => void;
  onPreviewClick?: () => void;
  isTraining?: boolean;
}

export default function TrainImagesItem({
  trainingImage,
  model,
  disabled: isDisabled,
  captionHidden: isCaptionHidden,
  lowResMax = 800,
  onChange,
  onPreviewClick,
  isTraining,
}: TrainImagesItemProps) {
  const { selectedProject } = useTeamContext();
  const { successToast, errorToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { width, height } = useGetImageDimensions(trainingImage.downloadUrl);
  const [animateRef] = useAutoAnimate();

  const [updateTrigger] = usePutAssetByAssetIdMutation();
  const [removeTrigger] =
    useDeleteModelsTrainingImagesByModelIdAndTrainingImageIdMutation();
  const removeBackground = useRemoveBackgroundFromTrainingImage();
  const upscale = useUpscaleFromTrainingImage();

  const {
    isOpen: isMenuOpen,
    onOpen: onOpenMenu,
    onClose: onCloseMenu,
  } = useDisclosure();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(
    trainingImage.description ?? ""
  );
  const [descriptionPreview, setDescriptionPreview] = useState<
    string | undefined
  >();

  const canDisplayActions = !isUpdating && !isRemoving && !isDisabled;
  const isLoading = isUpdating || isRemoving;
  const canResetCaption =
    !!trainingImage.automaticCaptioning &&
    trainingImage.automaticCaptioning !== description;
  const isLowRes =
    width !== undefined &&
    height !== undefined &&
    Math.min(width, height) < lowResMax;
  const canUpscale = isLowRes;

  const backgroundRemoveCuCost = useAssetRemoveBackgroundCu(
    isMenuOpen
      ? {
          assetId: trainingImage.id,
        }
      : { skip: true }
  );
  const upscaleCuCost = useAssetUpscaleCu(
    isMenuOpen && canUpscale
      ? {
          type: "image",
          assetId: trainingImage.id,
        }
      : { skip: true }
  );

  // ----------------------------------

  const updateDescription = useCallback(
    async (description: string) => {
      if (!model) return;
      try {
        setIsUpdating(true);

        const updatedImage = await updateTrigger({
          assetId: trainingImage.id,
          projectId: selectedProject.id,
          body: {
            description,
          },
        }).unwrap();

        setDescription(updatedImage.asset.description ?? "");
        Track(AnalyticsEvents.CreateModel.UpdatedTrainingImageDescription, {
          imageId: trainingImage.id,
          modelId: model.id,
        });
      } catch (err) {
        handleApiError(err, "Error saving image description");
      } finally {
        setIsUpdating(false);
      }
    },
    [model, updateTrigger, trainingImage.id, selectedProject.id, handleApiError]
  );

  // ----------------------------------

  const handleRemoveBgClick = useCallback(async () => {
    if (!model) return;
    try {
      setIsUpdating(true);
      await removeBackground({
        trainingImage,
        model,
      });
      onChange?.();
      Track(AnalyticsEvents.CreateModel.TrainingImageBackgroundRemoved, {
        modelId: model.id,
      });
    } catch (_) {
      // toast is handled by removeBackground
    } finally {
      setIsUpdating(false);
    }
  }, [removeBackground, onChange, setIsUpdating, trainingImage, model]);

  const handleUpscaleClick = useCallback(async () => {
    if (!model) return;
    try {
      setIsUpdating(true);
      await upscale({
        trainingImage,
        model,
      });
      onChange?.();
    } catch (_) {
      // toast is handled by upscale
    } finally {
      setIsUpdating(false);
    }
  }, [upscale, onChange, setIsUpdating, trainingImage, model]);

  const handleRemoveClick = useCallback(async () => {
    if (!model) return;
    try {
      setIsRemoving(true);

      await removeTrigger({
        projectId: selectedProject.id,
        modelId: model.id,
        trainingImageId: trainingImage.id,
      }).unwrap();

      Track(AnalyticsEvents.CreateModel.DeletedTrainingImage, {
        imageId: trainingImage.id,
        modelId: model.id,
      });
      successToast({ title: "Image removed" });
      onChange?.();
    } catch (err) {
      errorToast({
        title: "Error deleting image",
        description: "There was an error deleting the image, please try again",
      });
    } finally {
      setIsRemoving(false);
    }
  }, [
    model,
    removeTrigger,
    selectedProject.id,
    trainingImage.id,
    successToast,
    onChange,
    errorToast,
  ]);

  const handleEditableChange = useCallback(
    (nextValue: string) => {
      setDescription(nextValue);
    },
    [setDescription]
  );

  const handleEditableSubmit = useCallback(async () => {
    if (trainingImage.description === description) return;
    await updateDescription(description);
  }, [updateDescription, trainingImage, description]);

  const handleEditableKeyDown: KeyboardEventHandler<HTMLTextAreaElement> =
    useCallback((e) => {
      if (e.key !== "Enter") return;
      e.preventDefault();
      e.stopPropagation();
      e.currentTarget.blur();
    }, []);

  const handleResetCaptionMouseEnter = useCallback(
    () => setDescriptionPreview(trainingImage.automaticCaptioning),
    [setDescriptionPreview, trainingImage.automaticCaptioning]
  );
  const handleResetCaptionMouseLeave = useCallback(
    () => setDescriptionPreview(undefined),
    [setDescriptionPreview]
  );

  const handleResetCaptionClick = useCallback(async () => {
    if (!descriptionPreview || descriptionPreview === description) return;
    await updateDescription(descriptionPreview);
    setDescriptionPreview(undefined);
  }, [
    updateDescription,
    setDescriptionPreview,
    descriptionPreview,
    description,
  ]);

  // ----------------------------------

  useEffect(() => {
    setDescription(trainingImage.description ?? "");
  }, [setDescription, trainingImage.description]);

  return (
    <VStack
      ref={animateRef}
      align="stretch"
      justify="stretch"
      overflow="hidden"
      w="204px"
      h={isCaptionHidden ? "204px" : "300px"}
      borderRadius="lg"
      bgColor="backgroundTertiary.800"
      spacing={0}
    >
      <Box
        pos="relative"
        w="204px"
        h="204px"
        mb={-2}
        p={2}
        pointerEvents={isLoading ? "none" : undefined}
        data-group
      >
        <Image
          w="100%"
          h="100%"
          borderRadius="md"
          cursor="pointer"
          objectFit="contain"
          alt={`Image with name ${trainingImage.name}`}
          bgColor="backgroundTertiary.500"
          fallback={<Skeleton w="100%" h="100%" />}
          onClick={onPreviewClick}
          src={`${trainingImage.downloadUrl}&format=jpeg&quality=80`}
        />

        {isLoading && (
          <HStack
            pos="absolute"
            zIndex="docked"
            top={0}
            left={0}
            justify="center"
            w="100%"
            h="100%"
          >
            <Spinner />
          </HStack>
        )}

        {isLowRes && (
          <Box pos="absolute" top={1} left={1} p={2}>
            <ElementLowResWarning lowResMax={lowResMax} />
          </Box>
        )}

        {canDisplayActions && isTraining && (
          <HStack
            pos="absolute"
            top={1}
            right={1}
            p={2}
            opacity={0}
            _groupHover={{ opacity: 1 }}
            spacing={2}
          >
            <TopIconButton onClick={handleRemoveClick}>
              <Icon id="Ui/TrashMd" h="14px" />
            </TopIconButton>

            <Menu
              isOpen={isMenuOpen}
              onClose={onCloseMenu}
              onOpen={onOpenMenu}
              placement="bottom-end"
            >
              <MenuButton as={TopIconButton}>
                <Flex justify="center" w="100%">
                  <Icon id="Ui/Dots" />
                </Flex>
              </MenuButton>

              <Portal>
                <MenuList>
                  <MenuItem
                    text="Remove Background"
                    cuCost={{
                      cost: backgroundRemoveCuCost.cuCost,
                      isLoading: backgroundRemoveCuCost.isCuLoading,
                    }}
                    onClick={handleRemoveBgClick}
                  />

                  <MenuItem
                    text="Upscale 2x"
                    isDisabled={!canUpscale}
                    cuCost={{
                      cost: upscaleCuCost.cuCost,
                      isLoading: upscaleCuCost.isCuLoading,
                    }}
                    onClick={handleUpscaleClick}
                  />

                  {!isCaptionHidden && (
                    <MenuItem
                      text="Reset Caption"
                      onClick={handleResetCaptionClick}
                      pointerEvents={!canResetCaption ? "none" : undefined}
                      onMouseEnter={handleResetCaptionMouseEnter}
                      onMouseLeave={handleResetCaptionMouseLeave}
                      isDisabled={!canResetCaption}
                    />
                  )}
                </MenuList>
              </Portal>
            </Menu>
          </HStack>
        )}
      </Box>

      {!isCaptionHidden && (
        <Box flex={1} maxH="96px" p={2}>
          {trainingImage.description === undefined ? (
            <Box p={2}>
              <SkeletonText
                endColor="whiteAlpha.300"
                noOfLines={3}
                skeletonHeight="12px"
                spacing={3}
                startColor="whiteAlpha.100"
              />
            </Box>
          ) : (
            <Editable
              w="100%"
              h="100%"
              fontSize={"body.md"}
              fontWeight={"body.md"}
              pointerEvents={isTraining ? "auto" : "none"}
              isDisabled={!canDisplayActions || !isTraining}
              onChange={handleEditableChange}
              onClick={(e) => e.stopPropagation()}
              onSubmit={handleEditableSubmit}
              placeholder={isTraining ? "Image Description" : "Empty caption"}
              value={descriptionPreview ?? description}
            >
              <EditablePreview
                overflow="hidden"
                h="100%"
                p={2}
                py={0}
                color={(() => {
                  if (descriptionPreview) return "primary.500";
                  else if (!description) return "textTertiary";
                  else return undefined;
                })()}
                borderBottom="var(--chakra-space-2) solid transparent"
                borderRadius="md"
                _hover={{
                  bgColor: "backgroundTertiary.500",
                }}
                textOverflow="ellipsis"
                css={`
                  display: -webkit-box;
                  display: -moz-box;
                  display: -ms-box;
                  -webkit-box-orient: vertical;
                  -moz-box-orient: vertical;
                  box-orient: vertical;
                  -webkit-line-clamp: 3;
                `}
              />
              <EditableTextarea
                w="100%"
                h="100%"
                p={2}
                borderRadius="md"
                resize="none"
                bgColor="backgroundTertiary.500"
                onKeyDown={handleEditableKeyDown}
              />
            </Editable>
          )}
        </Box>
      )}

      {isLoading && (
        <Box
          pos="absolute"
          bottom={0}
          left={0}
          w="100%"
          h="100%"
          bgColor="blackAlpha.500"
        />
      )}
    </VStack>
  );
}
