import React, { useCallback } from "react";
import useAssetVectorize from "domains/assets/hooks/useAssetVectorize";
import { useAssetVectorizeCu } from "domains/assets/hooks/useAssetVectorizeCu";
import useRouter from "domains/navigation/hooks/useRouter";
import Button from "domains/ui/components/Button";
import ButtonWithCuIndicator from "domains/ui/components/ButtonWithCuIndicator";
import DoubleOptInButton from "domains/ui/components/DoubleOptInButton";
import VectorizeParams from "domains/ui/components/VectorizeParams";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

import { Text, VStack } from "@chakra-ui/react";

export interface DrawerVectorizeProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  onClose: (options: { avoidRedirect?: boolean }) => void;
}

export default function DrawerVectorize({
  asset,
  onClose,
}: DrawerVectorizeProps) {
  const router = useRouter();

  const {
    form,
    setValue,
    handleVectorizeAsset,
    isVectorizeLoading,
    resetForm,
  } = useAssetVectorize();

  const handleSubmit = useCallback(async () => {
    const newAsset = await handleVectorizeAsset({
      assetId: asset.id,
      trackingExtraParams: {
        origin: "image edit",
      },
    });
    if (newAsset) {
      delete router.query.edit;
      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          openAssetId: newAsset.id,
        },
      });
      onClose({
        avoidRedirect: true,
      });
    }
  }, [asset, handleVectorizeAsset, router, onClose]);

  const { cuCost, isCuLoading } = useAssetVectorizeCu(asset.id, form);

  return (
    <VStack align="start" w="100%" spacing={6}>
      <VectorizeParams form={form} setValue={setValue} />

      <VStack align="stretch" w="100%" spacing={3}>
        <ButtonWithCuIndicator
          cuCost={cuCost}
          isCuLoading={isCuLoading}
          size="sm"
          onClick={handleSubmit}
          isLoading={isVectorizeLoading}
          w="100%"
        >
          Vectorize
        </ButtonWithCuIndicator>

        <DoubleOptInButton
          w="100%"
          placement="top-end"
          body={(onCloseOptIn) => (
            <VStack p={2}>
              <Text>{"You're about to reset values. Are you sure?"}</Text>
              <Button
                size="sm"
                variant="secondary"
                onClick={() => {
                  resetForm();
                  onCloseOptIn();
                }}
              >
                Yes
              </Button>
            </VStack>
          )}
        >
          <Button size="sm" variant="secondary" w="100%">
            Reset Default Values
          </Button>
        </DoubleOptInButton>
      </VStack>
    </VStack>
  );
}
