import DrawerReframe from "domains/assets/components/AssetZoom/Drawer/Reframe";
import {
  ASIDE_EDIT_ACTIONS,
  AsideEditAction,
} from "domains/assets/constants/Aside";
import { AsideDrawer } from "domains/ui/components/Aside";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

import DrawerPixelate from "./Pixelate";
import DrawerRemoveBackground from "./RemoveBackground";
import DrawerSkyboxUpscale from "./SkyboxUpscale";
import DrawerUpscale from "./Upscale";
import DrawerVectorize from "./Vectorize";

export interface AssetZoomAsideDrawerProps {
  action?: AsideEditAction;
  asset: GetAssetsByAssetIdApiResponse["asset"];
  onClose: (options: { avoidRedirect?: boolean }) => void;
}

export default function AssetZoomAsideDrawer({
  action,
  asset,
  onClose,
}: AssetZoomAsideDrawerProps) {
  const actionData = action && ASIDE_EDIT_ACTIONS[action];
  const hasReframeFlag = useUserContext()?.featureFlags?.includes("reframe");

  return (
    <AsideDrawer
      title={actionData?.title}
      open={!!action}
      onClose={() =>
        onClose({
          avoidRedirect: false,
        })
      }
    >
      {action === "removeBg" && (
        <DrawerRemoveBackground asset={asset} onClose={onClose} />
      )}
      {action === "upscale" && (
        <DrawerUpscale asset={asset} onClose={onClose} />
      )}
      {action === "skyboxUpscale" && (
        <DrawerSkyboxUpscale asset={asset} onClose={onClose} />
      )}
      {action === "pixelate" && (
        <DrawerPixelate asset={asset} onClose={onClose} />
      )}
      {action === "vectorize" && (
        <DrawerVectorize asset={asset} onClose={onClose} />
      )}
      {action === "expand" && hasReframeFlag && (
        <DrawerReframe asset={asset} onClose={onClose} />
      )}
    </AsideDrawer>
  );
}
