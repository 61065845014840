import React from "react";
import ButtonSaveModelPreset from "domains/assets/components/AssetZoom/Header/ButtonSaveModelPreset";
import { useAssetCanvasLink } from "domains/assets/hooks/useAssetLink";
import {
  isAssetInferenceTexture,
  isAssetSkybox,
  isAssetUpscaledTexture,
} from "domains/assets/utils/isType";
import useRouter from "domains/navigation/hooks/useRouter";
import Icon from "domains/ui/components/Icon";
import MenuButtonIcon from "domains/ui/components/Menu/MenuButtonIcon";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";
import _ from "lodash";

import { Divider, Menu, MenuList } from "@chakra-ui/react";

export interface ButtonMenuProps {
  asset?: GetAssetsByAssetIdApiResponse["asset"];
  onAction?: () => void;
}

export default function ButtonMenu({ asset, onAction }: ButtonMenuProps) {
  const router = useRouter();
  const editCapabilities = asset?.editCapabilities;
  const isDisabled = !asset;
  const isSkybox = isAssetSkybox(asset);
  const isTexture =
    isAssetInferenceTexture(asset) ||
    isAssetUpscaledTexture(asset) ||
    asset?.metadata?.type.startsWith("texture");

  const linkEditInCanvas = useAssetCanvasLink(asset);

  const canRemoveBackground =
    editCapabilities?.includes("REMOVE_BACKGROUND") && !isSkybox && !isTexture;
  const canPixelate =
    editCapabilities?.includes("PIXELATE") && !isSkybox && !isTexture;
  const hasReframeFlag = useUserContext()?.featureFlags?.includes("reframe");
  const canReframe =
    editCapabilities?.includes("REFRAME") &&
    !isSkybox &&
    !isTexture &&
    hasReframeFlag;
  const canVectorize =
    editCapabilities?.includes("VECTORIZATION") && !isSkybox && !isTexture;

  return (
    <Menu>
      <MenuButtonIcon
        aria-label="More"
        data-testid="asset-zoom-three-dots-menu-button"
      >
        <Icon id="Ui/Dots" />
      </MenuButtonIcon>

      <MenuList>
        <MenuItem
          text="View similar images"
          internalLink={{
            pathname: router.pathname,
            query: {
              ..._.omit(router.query, "searchQuery", "openAssetId"),
              searchAssetId: asset?.id,
            },
          }}
          isDisabled={isDisabled}
        />

        {asset?.metadata.inferenceId && (
          <ButtonSaveModelPreset isDisabled={isDisabled} asset={asset} />
        )}

        {linkEditInCanvas && (
          <MenuItem
            data-testid="three-dots-menu-edit-details"
            internalLink={linkEditInCanvas}
            target="_blank"
            text="Retouch"
          />
        )}

        {canReframe && (
          <MenuItem
            internalLink={{
              pathname: router.pathname,
              query: {
                ...router.query,
                edit: "expand",
              },
            }}
            text="Expand"
            isDisabled={isDisabled}
            onClick={onAction}
          />
        )}

        {canRemoveBackground && (
          <MenuItem
            internalLink={{
              pathname: router.pathname,
              query: {
                ...router.query,
                edit: "removeBg",
              },
            }}
            text="Remove background"
            isDisabled={isDisabled}
            onClick={onAction}
          />
        )}

        {canPixelate && (
          <MenuItem
            internalLink={{
              pathname: router.pathname,
              query: {
                ...router.query,
                edit: "pixelate",
              },
            }}
            text="Pixelate"
            isDisabled={isDisabled}
            onClick={onAction}
          />
        )}

        {canVectorize && (
          <MenuItem
            internalLink={{
              pathname: router.pathname,
              query: {
                ...router.query,
                edit: "vectorize",
              },
            }}
            text="Vectorize"
            isDisabled={isDisabled}
            onClick={onAction}
          />
        )}

        <Divider />

        <MenuItem
          externalLink={`com.unity3d.kharma://${asset?.url}`}
          text="Open in Unity"
          isDisabled={isDisabled}
          onClick={() => {
            Track(AnalyticsEvents.AssetZoom.OpenInUnity, {
              assetId: asset?.id,
            });
          }}
        />
      </MenuList>
    </Menu>
  );
}
