import React, { useMemo } from "react";
import Link from "next/link";
import { InferenceBuilderErrors } from "domains/canvas/hooks/useInferenceBuilderErrors";
import SidebarSection from "domains/inference/components/InferenceGenerator/Sidebar/Section";
import SectionPrompt from "domains/inference/components/InferenceGenerator/Sidebar/SectionPrompt";
import SectionReferenceImageBase from "domains/inference/components/InferenceGenerator/Sidebar/SectionReferenceImageBase";
import { useSectionsContext } from "domains/inference/contexts/SectionsProvider";
import { ReframeForm } from "domains/reframe/interfaces/Reframe";
import Divider from "domains/ui/components/Divider";
import { AnalyticsEvents } from "infra/analytics/constants/Events";

import { Box, Collapse, Text } from "@chakra-ui/react";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import DimensionsControls from "./DimensionsControls";
import GenerateButton from "./GenerateButton";
import ImagePreview from "./ImagePreview";
import InputControls from "./InputControls";
import LocationSelector from "./LocationSelector";
import SettingsSection from "./SettingsSection";

interface ReframeParamsProps {
  form: ReframeForm;
  onFormChange: <T extends keyof ReframeForm>(
    key: T,
    value: ReframeForm[T]
  ) => void;
  isAssetZoom: boolean;
  onGenerate: () => void;
  isGenerating: boolean;
  errors: InferenceBuilderErrors;
  cuCost: number | undefined;
  isCuLoading: boolean;
  isDisabled: boolean;
}

export type AspectRatioValue = `${number}:${number}` | "Default";

export const ratioStringToNumber = (
  ratioString: string,
  { width, height }: { width?: number; height?: number }
): number => {
  if (ratioString === "Default") {
    if (!width || !height) {
      return 1;
    }
    return width / height;
  }
  const [newWidth, newHeight] = ratioString.split(":").map(Number);
  return newWidth / newHeight;
};

export const getRatios = ({
  width,
  height,
}: {
  width?: number;
  height?: number;
}) =>
  (
    [
      "Default",
      "16:9",
      "3:2",
      "5:4",
      "1:1",
      "4:5",
      "2:3",
      "9:16",
      "4:3",
      "3:4",
      "2:1",
      "1:2",
      "21:9",
      "9:21",
    ] satisfies AspectRatioValue[]
  ).sort(
    (a, b) =>
      ratioStringToNumber(a, {
        width,
        height,
      }) -
      ratioStringToNumber(b, {
        width,
        height,
      })
  );

const ReframeParams = ({
  form,
  onFormChange,
  isAssetZoom,
  onGenerate,
  isGenerating,
  errors,
  cuCost,
  isCuLoading,
  isDisabled,
}: ReframeParamsProps) => {
  const [animateRef] = useAutoAnimate();

  const ratios = useMemo(
    () =>
      getRatios({
        width: form.asset?.metadata?.width,
        height: form.asset?.metadata?.height,
      }),
    [form.asset?.metadata]
  );

  const { collapsedSections } = useSectionsContext();

  return (
    <>
      <Box>
        {!isAssetZoom && (
          <SectionReferenceImageBase
            id="referenceImage"
            title="Input Image"
            image={form.asset?.url}
            assetId={form.asset?.id}
            withRemoveBackground={false}
            builderSectionContentBoxStyle={{
              display: form.asset ? "none" : "block",
            }}
            setImage={(image, assetId, asset) => {
              onFormChange(
                "asset",
                asset
                  ? {
                      ...asset,
                      url: asset.url + "&quality=80&format=jpeg",
                    }
                  : undefined
              );
            }}
            resize={{
              trackingEvent: AnalyticsEvents.Reframe.ImportedReferenceImage,
              trackingExtraParams: {},
            }}
            removeBackgroundTrackingExtraParams={{
              origin: "expand page",
            }}
            withSketch="never"
            selectLibrary={{
              tabs: ["image"],
            }}
          />
        )}
      </Box>

      {form.asset && (
        <SidebarSection compact={isAssetZoom} contentBoxStyle={{ pt: "0" }}>
          <Collapse
            in={!collapsedSections.includes("referenceImage")}
            style={{
              // NOTE: This is a workaround because the collapse has overflow: hidden by default, which truncate the form inputs slightly. Only apply this when the section is collapsed.
              overflow: collapsedSections.includes("referenceImage")
                ? "hidden"
                : "visible",
            }}
          >
            <Box
              ref={animateRef}
              w="full"
              maxW={isAssetZoom ? "full" : "320px"}
            >
              <ImagePreview
                form={form}
                isAssetZoom={isAssetZoom}
                onAssetDrop={async (asset) => {
                  onFormChange("asset", {
                    ...asset,
                    url: asset.url + "&quality=80&format=jpeg",
                  });
                }}
              />
              <LocationSelector
                inputLocation={form.inputLocation}
                onLocationChange={(location) =>
                  onFormChange("inputLocation", location)
                }
              />
              <DimensionsControls
                form={form}
                onFormChange={onFormChange}
                aspectRatio={form.aspectRatio ?? "Default"}
                onAspectRatioChange={(newRatio) =>
                  onFormChange("aspectRatio", newRatio)
                }
                ratios={ratios}
              />
              <InputControls form={form} onFormChange={onFormChange} />
            </Box>
          </Collapse>
        </SidebarSection>
      )}

      <Divider />

      <GenerateButton
        isAssetZoom={isAssetZoom}
        onGenerate={onGenerate}
        isGenerating={isGenerating}
        errors={errors}
        cuCost={cuCost}
        isCuLoading={isCuLoading}
        isDisabled={isDisabled}
      />

      <Divider />

      {!isAssetZoom && (
        <SectionPrompt
          id="prompt"
          prompt={form.prompt}
          setPrompt={(prompt) => onFormChange("prompt", prompt)}
          withRandom={false}
          referenceAssetId={form.asset?.id}
        />
      )}

      <Divider />

      <SettingsSection
        form={form}
        onFormChange={onFormChange}
        isAssetZoom={isAssetZoom}
      />

      {isAssetZoom && form.asset?.id && (
        <Link
          href={{
            pathname: "/expand",
            query: {
              importAssetId: form.asset.id,
            },
          }}
        >
          <Text
            textColor="primary.500"
            fontSize="sm"
            textDecoration="underline"
          >
            More settings
          </Text>
        </Link>
      )}
    </>
  );
};

export default ReframeParams;
