import { Box, BoxProps } from "@chakra-ui/react";

interface WithGradientEffectProps extends BoxProps {}

const WithGradientEffect = ({ children }: WithGradientEffectProps) => {
  return (
    <Box
      pos="relative"
      _after={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage:
          "linear-gradient(to top, #121212, rgba(18, 18, 18, 0))",
        zIndex: 1,
      }}
    >
      {children}
    </Box>
  );
};

export default WithGradientEffect;
