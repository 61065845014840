import { useRef, useState } from "react";
import Button from "domains/ui/components/Button";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "domains/ui/components/Modal";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { FormattedMessage } from "react-intl";

import { Link, Text, TextProps, VStack } from "@chakra-ui/react";

export default function PolicyAcceptance() {
  const {
    isOnboardingFormMissing,
    isPolicyAcceptanceMissing,
    lastAcceptedPolicy,
    markPolicyAsRead,
  } = useUserContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const confirmRef = useRef(null);

  const handleSubmitClick = async () => {
    setIsLoading(true);
    await markPolicyAsRead();
    setIsLoading(false);
  };

  if (isOnboardingFormMissing || !isPolicyAcceptanceMissing) {
    return null;
  }

  return (
    <Modal
      initialFocusRef={confirmRef}
      isOpen
      onClose={() => {}}
      variant="modern"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <VStack spacing={4}>
            <Text data-testid="policy-title" size="title.lg">
              {lastAcceptedPolicy ? (
                <FormattedMessage
                  id="modal.policyAcceptance.title.update"
                  defaultMessage="Our Terms and Conditions have evolved"
                />
              ) : (
                <FormattedMessage
                  id="modal.policyAcceptance.title.initial"
                  defaultMessage="Terms and Conditions"
                />
              )}
            </Text>

            <PolicyText data-testid="policy-title" />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            size="lg"
            data-testid="terms-submit-button"
            isLoading={isLoading}
            onClick={handleSubmitClick}
            ref={confirmRef}
          >
            <FormattedMessage
              id="modal.policyAcceptance.submit"
              defaultMessage="Accept and Continue"
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

// ------------------------------------

export function PolicyText(props: TextProps) {
  return (
    <Text
      maxW="400px"
      color="textSecondary"
      textAlign="center"
      size="body.lg"
      {...props}
    >
      <FormattedMessage
        id="modal.policyAcceptance.text"
        defaultMessage="By using Scenario, you agree to our {termsAndConditionLink} and {privacyPolicyLink}."
        values={{
          termsAndConditionLink: (
            <Link
              color="textPrimary"
              textDecoration="underline"
              whiteSpace="nowrap"
              href="https://www.scenario.com/terms-and-conditions"
              target="_blank"
            >
              <FormattedMessage
                id="modal.policyAcceptance.termsAndConditionLink"
                defaultMessage="Terms and Conditions"
              />
            </Link>
          ),
          privacyPolicyLink: (
            <Link
              color="textPrimary"
              textDecoration="underline"
              whiteSpace="nowrap"
              href="https://www.scenario.com/privacy-policy"
              target="_blank"
            >
              <FormattedMessage
                id="modal.policyAcceptance.privacyPolicyLink"
                defaultMessage="Privacy Policy"
              />
            </Link>
          ),
        }}
      />
    </Text>
  );
}
