import { useCallback } from "react";
import { arraysEqualIgnoreOrder } from "domains/commons/utils/arraysEqualIgnoreOrder";
import { FileImageType, FileModelType } from "domains/file-manager/interfaces";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { createTagUpdateMessage } from "domains/tags/utils";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import MenuButtonIcon from "domains/ui/components/Menu/MenuButtonIcon";
import { useHandleApiError } from "infra/api/error";

import { Box, Menu, MenuButton, MenuList, VStack } from "@chakra-ui/react";

import TagDisplayWithRemove from "./TagDisplayWithRemove";
import TagInput from "./TagInput";
import TagSuggestion from "./TagSuggestion";

interface SelectionBarTagsProps {
  compact?: boolean;
  isDisabled?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  tags: string[];
  files: FileModelType[] | FileImageType[];
  updateTags: (fileId: string, newTags: string[], withToast: boolean) => void;
  withoutTagSuggestion?: boolean;
}

const SelectionBarTags = ({
  compact: isCompact = false,
  isDisabled,
  buttonRef,
  tags,
  files,
  updateTags,
  withoutTagSuggestion = false,
}: SelectionBarTagsProps) => {
  const handleApiError = useHandleApiError();
  const { successToast } = useScenarioToast();

  const handleOnUpdateMultipleTag = useCallback(
    (tagsToAdd: string[], tagsToRemove: string[]) => {
      const uniqueTagsAdded = new Set<string>(tagsToAdd);
      const uniqueTagsRemoved = new Set<string>(tagsToRemove);

      const updatePromises = files.map((file) => {
        const { tags: currentTags } = file.meta;

        const newTags = [...new Set([...currentTags, ...tagsToAdd])].filter(
          (tag) => !tagsToRemove.includes(tag)
        );
        if (!arraysEqualIgnoreOrder(newTags, file.meta.tags)) {
          return updateTags(file.id, newTags, false);
        }
        return Promise.resolve();
      });

      Promise.all(updatePromises)
        .then(() => {
          successToast({
            title: createTagUpdateMessage(
              [...uniqueTagsAdded],
              [...uniqueTagsRemoved]
            ),
          });
        })
        .catch((err) => {
          handleApiError(err, "There was an error updating the tags");
        });
    },
    [files, updateTags, successToast, handleApiError]
  );

  return (
    <Box pos="relative">
      <Menu placement="top">
        {isCompact ? (
          <MenuButtonIcon
            isDisabled={isDisabled || files.length > 50}
            tooltip={
              files.length > 50
                ? "You can only tag up to 50 items at a time."
                : "Tags"
            }
            aria-label="Tags"
            ref={buttonRef}
          >
            <Icon id="Ui/AddTag" color="textPrimary" w="18px" />
          </MenuButtonIcon>
        ) : (
          <MenuButton
            as={Button}
            isDisabled={isDisabled || files.length > 50}
            leftIcon={<Icon id="Ui/Plus" color="textPrimary" w="10px" />}
            size="sm"
            tooltip={
              files.length > 50
                ? "You can only tag up to 50 items at a time."
                : undefined
            }
            variant="primary"
          >
            Tags
          </MenuButton>
        )}

        <MenuList zIndex="banner" w="320px" p={4}>
          <VStack align="start" w="full">
            <TagDisplayWithRemove
              tags={tags}
              onRemove={(removedTag) =>
                handleOnUpdateMultipleTag([], [removedTag])
              }
            />
            <TagInput
              currentTags={tags}
              onAddTags={(addedTags) =>
                handleOnUpdateMultipleTag(addedTags, [])
              }
            />
            {!withoutTagSuggestion && (
              <TagSuggestion
                tags={tags}
                onUpdateTags={(newTags: string[]) => {
                  const tagsToAdd = newTags.filter(
                    (tag) => !tags.includes(tag)
                  );
                  const tagsToRemove = tags.filter(
                    (tag) => !newTags.includes(tag)
                  );
                  handleOnUpdateMultipleTag(tagsToAdd, tagsToRemove);
                }}
                closeOnSelect
              />
            )}
          </VStack>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default SelectionBarTags;
