import { Text } from "@chakra-ui/react";

import AsideTitledData, { AsideTitledDataProps } from "./TitledData";

export interface AsideTitledTextProps
  extends Omit<AsideTitledDataProps, "copyableValue"> {
  value: string;
  copyable?: boolean;
}

export default function AsideTitledText({
  value,
  copyable: isCopyable,
  ...props
}: AsideTitledTextProps) {
  return (
    <AsideTitledData {...props} copyableValue={isCopyable ? value : undefined}>
      <Text color="textSecondary" size="body.md">
        {value}
      </Text>
    </AsideTitledData>
  );
}
