import { FmSelectionActionProps } from "domains/file-manager/components/FileManagerV2/FmSelectionActions";
import { FmFileImage } from "domains/file-manager/interfacesV2";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

export type SelectionActionDownloadProps = FmSelectionActionProps<"image"> & {
  isDisabled: boolean;
  onClick: (files: FmFileImage[]) => void;
};

export default function SelectionActionDownload({
  selectedFiles,
  isDisplayed,
  isDisabled,
  onClick,
}: SelectionActionDownloadProps) {
  return (
    <Button
      tooltip="Download"
      variant="secondary"
      colorScheme="danger"
      leftIcon={<Icon id="Ui/Download" h="18px" />}
      onClick={() => onClick(selectedFiles)}
      isDisabled={isDisabled || !isDisplayed}
      data-testid="file-manager-download-button"
      w="36px"
    />
  );
}
