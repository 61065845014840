import React, { useCallback } from "react";
import useAssetRemoveBackground from "domains/assets/hooks/useAssetRemoveBackground";
import { useAssetRemoveBackgroundCu } from "domains/assets/hooks/useAssetRemoveBackgroundCu";
import useRouter from "domains/navigation/hooks/useRouter";
import ButtonWithCuIndicator from "domains/ui/components/ButtonWithCuIndicator";
import RemoveBackgroundParams from "domains/ui/components/RemoveBackgroundParams";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

import { VStack } from "@chakra-ui/react";

export interface DrawerRemoveBackgroundProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  onClose: (options: { avoidRedirect?: boolean }) => void;
}

export default function DrawerRemoveBackground({
  asset,
  onClose,
}: DrawerRemoveBackgroundProps) {
  const router = useRouter();

  const {
    form,
    setValue,
    handleRemoveBackgroundAsset,
    isRemoveBackgroundLoading,
  } = useAssetRemoveBackground();

  const handleSubmit = useCallback(async () => {
    const newAsset = await handleRemoveBackgroundAsset({
      assetId: asset.id,
      trackingExtraParams: {
        origin: "image edit",
      },
    });
    if (newAsset) {
      delete router.query.edit;
      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          openAssetId: newAsset.id,
        },
      });
      onClose({
        avoidRedirect: true,
      });
    }
  }, [asset, handleRemoveBackgroundAsset, router, onClose]);

  const { cuCost, isCuLoading } = useAssetRemoveBackgroundCu({
    assetId: asset.id,
  });

  return (
    <VStack align="start" w="100%" spacing={5}>
      <RemoveBackgroundParams form={form} setValue={setValue} />
      <ButtonWithCuIndicator
        cuCost={cuCost}
        isCuLoading={isCuLoading}
        size="sm"
        onClick={handleSubmit}
        isLoading={isRemoveBackgroundLoading}
        w="100%"
      >
        Remove Background
      </ButtonWithCuIndicator>
    </VStack>
  );
}
