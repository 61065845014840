import { MergingMethod } from "domains/canvas/components/sections/DrawingTools/DrawingOverlay";

export const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

export const mergeCanvas = async ({
  originalSrc: imgTarget,
  overlaySrc: imgToMerge,
  x = 0,
  y = 0,
}: {
  originalSrc?: string | HTMLCanvasElement;
  overlaySrc?: string | HTMLCanvasElement;
  x?: number;
  y?: number;
}) => {
  if (!imgTarget && !imgToMerge) return;
  if (!imgTarget) return imgToMerge;
  if (!imgToMerge) return imgTarget;

  const [imageA, imageB] = await Promise.all([
    imgTarget instanceof HTMLCanvasElement ? imgTarget : loadImage(imgTarget),
    imgToMerge instanceof HTMLCanvasElement
      ? imgToMerge
      : loadImage(imgToMerge),
  ]);

  const width = Math.max(
    imageA.width - Math.min(0, x),
    Math.abs(x) + imageB.width
  );
  const height = Math.max(
    imageA.height - Math.min(0, y),
    Math.abs(y) + imageB.height
  );

  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return;
  }

  ctx.drawImage(imageA, Math.max(-x, 0), Math.max(-y, 0));
  ctx.drawImage(imageB, Math.max(0, x), Math.max(0, y));

  return canvas;
};

export const mergeImage = async (...args: Parameters<typeof mergeCanvas>) => {
  return mergeCanvas(...args).then((canvas) =>
    canvas instanceof HTMLCanvasElement ? canvas.toDataURL() : canvas
  );
};

export const eraseCanvas = async ({
  originalSrc: imgTarget,
  overlaySrc: imgToMerge,
  x = 0,
  y = 0,
}: {
  originalSrc?: string | HTMLCanvasElement;
  overlaySrc?: string | HTMLCanvasElement;
  x?: number;
  y?: number;
}) => {
  if (!imgTarget && !imgToMerge) return;
  if (!imgTarget) return imgToMerge;
  if (!imgToMerge) return imgTarget;

  const [imageA, imageB] = await Promise.all([
    imgTarget instanceof HTMLCanvasElement ? imgTarget : loadImage(imgTarget),
    imgToMerge instanceof HTMLCanvasElement
      ? imgToMerge
      : loadImage(imgToMerge),
  ]);

  const width = Math.max(
    imageA.width - Math.min(0, x),
    Math.abs(x) + imageB.width
  );
  const height = Math.max(
    imageA.height - Math.min(0, y),
    Math.abs(y) + imageB.height
  );

  const imageACanvas = document.createElement("canvas");
  imageACanvas.width = width;
  imageACanvas.height = height;
  const imageACtx = imageACanvas.getContext("2d");
  if (!imageACtx) {
    return;
  }
  imageACtx.drawImage(imageA, Math.max(-x, 0), Math.max(-y, 0));
  const imageAData = imageACtx.getImageData(0, 0, width, height);

  const imageBCanvas = document.createElement("canvas");
  imageBCanvas.width = width;
  imageBCanvas.height = height;
  const imageBCtx = imageBCanvas.getContext("2d");
  if (!imageBCtx) {
    return;
  }
  imageBCtx.drawImage(imageB, Math.max(0, x), Math.max(0, y));
  const imageBData = imageBCtx.getImageData(0, 0, width, height);

  for (let i = 0; i < imageBData.data.length; i += 4) {
    if (imageBData.data[i + 3] > 0) {
      imageAData.data[i] = 0;
      imageAData.data[i + 1] = 0;
      imageAData.data[i + 2] = 0;
      imageAData.data[i + 3] = 0;
    }
  }

  imageACtx.putImageData(imageAData, 0, 0);
  return imageACanvas;
};

export const eraseImage: MergingMethod = async (
  ...args: Parameters<typeof eraseCanvas>
) => {
  return eraseCanvas(...args).then((canvas) =>
    canvas instanceof HTMLCanvasElement ? canvas.toDataURL() : canvas
  );
};

export async function cropTransparentPixels(
  imageSrc: string | HTMLCanvasElement,
  margin = 0
): Promise<{
  croppedImage: string | HTMLCanvasElement;
  x: number;
  y: number;
  width: number;
  height: number;
}> {
  // Create canvas and draw image
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Could not get canvas context");
  }

  // Load image if string
  let imageElement: HTMLImageElement | HTMLCanvasElement;
  if (typeof imageSrc === "string") {
    imageElement = new Image();
    await new Promise((resolve, reject) => {
      imageElement.onload = resolve;
      imageElement.onerror = reject;
      (imageElement as HTMLImageElement).src = imageSrc;
    });
  } else {
    imageElement = imageSrc;
  }

  // Set canvas size and draw image
  canvas.width = imageElement.width;
  canvas.height = imageElement.height;
  ctx.drawImage(imageElement, 0, 0);

  // Get image data
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const { width, height, data } = imageData;

  // Find bounds of non-transparent pixels using inward spiral search
  let minX = width;
  let minY = height;
  let maxX = 0;
  let maxY = 0;

  // Start from outer edges and spiral inward
  let top = 0;
  let bottom = height - 1;
  let left = 0;
  let right = width - 1;

  while (top <= bottom && left <= right) {
    // Check top row
    for (let x = left; x <= right; x++) {
      const i = (top * width + x) * 4;
      const alpha = data[i + 3];
      if (alpha > 0) {
        minX = Math.min(minX, x);
        minY = Math.min(minY, top);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, top);
      }
    }
    top++;

    // Check right column
    for (let y = top; y <= bottom; y++) {
      const i = (y * width + right) * 4;
      const alpha = data[i + 3];
      if (alpha > 0) {
        minX = Math.min(minX, right);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, right);
        maxY = Math.max(maxY, y);
      }
    }
    right--;

    if (top <= bottom) {
      // Check bottom row
      for (let x = right; x >= left; x--) {
        const i = (bottom * width + x) * 4;
        const alpha = data[i + 3];
        if (alpha > 0) {
          minX = Math.min(minX, x);
          minY = Math.min(minY, bottom);
          maxX = Math.max(maxX, x);
          maxY = Math.max(maxY, bottom);
        }
      }
      bottom--;
    }

    if (left <= right) {
      // Check left column
      for (let y = bottom; y >= top; y--) {
        const i = (y * width + left) * 4;
        const alpha = data[i + 3];
        if (alpha > 0) {
          minX = Math.min(minX, left);
          minY = Math.min(minY, y);
          maxX = Math.max(maxX, left);
          maxY = Math.max(maxY, y);
        }
      }
      left++;
    }

    // Early exit if we've found pixels at edges
    if (minX === 0 && minY === 0 && maxX === width - 1 && maxY === height - 1) {
      break;
    }
  }

  // Apply margin
  minX = Math.max(0, minX - margin);
  minY = Math.max(0, minY - margin);
  maxX = Math.min(width - 1, maxX + margin);
  maxY = Math.min(height - 1, maxY + margin);

  if (minX < maxX && minY < maxY) {
    const croppedWidth = maxX - minX + 1;
    const croppedHeight = maxY - minY + 1;

    // Create cropped canvas
    const croppedCanvas = document.createElement("canvas");
    croppedCanvas.width = croppedWidth;
    croppedCanvas.height = croppedHeight;
    const croppedCtx = croppedCanvas.getContext("2d");
    if (!croppedCtx) {
      throw new Error("Could not get canvas context");
    }

    // Draw cropped region
    croppedCtx.drawImage(
      canvas,
      minX,
      minY,
      croppedWidth,
      croppedHeight,
      0,
      0,
      croppedWidth,
      croppedHeight
    );

    return {
      croppedImage: croppedCanvas,
      x: minX,
      y: minY,
      width: croppedWidth,
      height: croppedHeight,
    };
  } else {
    // Return original if no non-transparent pixels found
    return {
      croppedImage: typeof imageSrc === "string" ? canvas : imageSrc,
      x: 0,
      y: 0,
      width,
      height,
    };
  }
}
