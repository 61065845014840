import { useMemo } from "react";
import { useScenarioAssetDrop } from "domains/assets/hooks/useScenarioAssetDrop";
import useImageUploadDragDrop from "domains/image/hooks/useImageUploadDragDrop";
import { ReframeForm } from "domains/reframe/interfaces/Reframe";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

import { Box, Image, Skeleton, Spinner } from "@chakra-ui/react";

interface ImagePreviewProps {
  form: ReframeForm;
  isAssetZoom: boolean;
  onAssetDrop?: (
    asset: GetAssetsByAssetIdApiResponse["asset"]
  ) => Promise<void>;
}

const OverlapBox = ({
  style,
}: {
  style: {
    top?: number | string;
    left?: number | string;
    right?: number | string;
    bottom?: number | string;
    w: number | string;
    h: number | string;
  };
}) => (
  <Box
    className="checkerboard"
    pos="absolute"
    top={style.top}
    right={style.right}
    bottom={style.bottom}
    left={style.left}
    w={style.w}
    h={style.h}
    bgSize="80px 80px"
    bgPosition="0px 0px, 40px 40px"
    opacity={0.3}
  />
);

const ImagePreview = ({
  form,
  isAssetZoom,
  onAssetDrop,
}: ImagePreviewProps) => {
  const { handleAssetUrl, isLoading } = useScenarioAssetDrop({
    onAssetFound: async (asset) => {
      if (asset.url === form.asset?.url) return;
      await onAssetDrop?.(asset);
    },
  });

  const { isDraggingHover, dragFunctions, onDrop } = useImageUploadDragDrop({
    onImageDrop: handleAssetUrl,
  });

  // Keep these memoized values as they're used for UI calculations
  const scaleFactor = useMemo(() => {
    const maxDimension = Math.max(
      form.targetWidth ?? 0,
      form.targetHeight ?? 0
    );
    return maxDimension > 320 ? 320 / maxDimension : 1;
  }, [form.targetWidth, form.targetHeight]);

  const imagePositionStyle = useMemo(() => {
    switch (form.inputLocation) {
      case "top":
        return { top: "0", left: "50%", transform: `translateX(-50%)` };
      case "bottom":
        return { bottom: "0", left: "50%", transform: `translateX(-50%)` };
      case "left":
        return { top: "50%", left: "0", transform: `translateY(-50%)` };
      case "right":
        return { top: "50%", right: "0", transform: `translateY(-50%)` };
      case "middle":
        return { top: "50%", left: "50%", transform: `translate(-50%, -50%)` };
    }
    return {
      top: "50%",
      left: "50%",
      right: "",
      bottom: "",
      transform: `scale(${scaleFactor})`,
    };
  }, [form.inputLocation, scaleFactor]);

  const overlapWidthValueInPx = useMemo(() => {
    return (form.overlapPercentage / 2 / 100) * (form.inputWidth ?? 0);
  }, [form.inputWidth, form.overlapPercentage]);

  const overlapHeightValueInPx = useMemo(() => {
    return (form.overlapPercentage / 2 / 100) * (form.inputHeight ?? 0);
  }, [form.inputHeight, form.overlapPercentage]);

  if (!form.asset) return null;

  const overlaps = [
    // Corners
    { top: 0, left: 0, w: overlapWidthValueInPx, h: overlapHeightValueInPx },
    { top: 0, right: 0, w: overlapWidthValueInPx, h: overlapHeightValueInPx },
    {
      bottom: 0,
      right: 0,
      w: overlapWidthValueInPx,
      h: overlapHeightValueInPx,
    },
    { bottom: 0, left: 0, w: overlapWidthValueInPx, h: overlapHeightValueInPx },

    // Sides
    {
      top: overlapHeightValueInPx,
      left: 0,
      w: overlapWidthValueInPx,
      h: `calc(100% - ${overlapHeightValueInPx * 2}px)`,
    },
    {
      right: 0,
      bottom: overlapHeightValueInPx,
      w: overlapWidthValueInPx,
      h: `calc(100% - ${overlapHeightValueInPx * 2}px)`,
    },

    // Top/Bottom
    {
      bottom: 0,
      left: overlapWidthValueInPx,
      w: `calc(100% - ${overlapWidthValueInPx * 2}px)`,
      h: overlapHeightValueInPx,
    },
    {
      top: 0,
      left: overlapWidthValueInPx,
      w: `calc(100% - ${overlapWidthValueInPx * 2}px)`,
      h: overlapHeightValueInPx,
    },
  ];

  return (
    <Box
      {...dragFunctions}
      pos="relative"
      overflow="hidden"
      w={isAssetZoom ? "full" : "320px"}
      h={isAssetZoom ? "full" : "320px"}
      transition="opacity 0.2s"
      aspectRatio="1 / 1"
      onDrop={onDrop}
      {...(isDraggingHover
        ? {
            borderColor: "primary.500",
            borderWidth: 1,
            borderStyle: "dashed",
          }
        : {})}
    >
      {isLoading ? (
        <Box
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Spinner size="xl" />
        </Box>
      ) : (
        <Box
          pos="absolute"
          top="50%"
          left="50%"
          overflow="hidden"
          w={`${form.targetWidth}px`}
          h={`${form.targetHeight}px`}
          opacity={isDraggingHover ? 0.7 : 1}
          borderRadius="lg"
          transform={`translate(-50%, -50%) scale(${scaleFactor})`}
        >
          <Box
            className="checkerboard"
            w="100%"
            h="100%"
            bgSize="80px 80px"
            opacity={0.5}
            bgColor="gray.200"
            bgPos="0px 0px, 40px 40px"
          />

          <Box
            pos="absolute"
            w={`${form.inputWidth ?? form.asset?.metadata.width}px`}
            h={`${form.inputHeight ?? form.asset?.metadata.height}px`}
            style={imagePositionStyle}
          >
            <Image
              w="100%"
              maxW="none"
              h="100%"
              maxH="none"
              alt="Preview"
              fallback={<Skeleton w="100%" h="100%" opacity={1} />}
              src={form.asset.url}
            />

            {overlaps.map((style, index) => (
              <OverlapBox key={index} style={style} />
            ))}
          </Box>

          <Box
            pos="absolute"
            w={form.inputWidth ?? form.asset?.metadata.width}
            h={form.inputHeight ?? form.asset?.metadata.height}
            style={{
              zIndex: 100,
              ...imagePositionStyle,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ImagePreview;
