import { useCallback } from "react";
import useManagePresets from "domains/inference/hooks/useManagePresets";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import { useHandleApiError } from "infra/api/error";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

interface ButtonSaveModelPresetProps {
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
  isDisabled: boolean;
}

export default function ButtonSaveModelPreset({
  asset,
  isDisabled,
}: ButtonSaveModelPresetProps) {
  const { successToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { createPreset, isCreatePresetLoading } = useManagePresets();

  const handleOnSetDefaultPreset = useCallback(async () => {
    if (!asset) {
      return;
    }
    try {
      await createPreset(asset);
      successToast({
        title: "Default inference parameters set",
      });
    } catch (error) {
      handleApiError(error, "There was an error setting the preset as default");
    }
  }, [createPreset, asset, successToast, handleApiError]);

  return (
    <MenuItem
      isDisabled={isDisabled}
      isLoading={isCreatePresetLoading}
      onClick={handleOnSetDefaultPreset}
      variant="menuItem"
      text="Set inference parameters as default"
    />
  );
}
