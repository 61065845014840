import { useCallback } from "react";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import {
  HStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";

export const MIN_VALUE = 1;
export const MAX_VALUE = 10;

interface FmHeaderColumnProps {
  value: number;
  min?: number;
  onChange: (value: number) => void;
}

export default function FmHeaderColumn({
  value,
  min = MIN_VALUE,
  onChange,
}: FmHeaderColumnProps) {
  const changeValue = useCallback(
    (numberOfColumns: number) => {
      Track(AnalyticsEvents.Gallery.ChangeColumnsNumber, {
        numberOfColumns,
      });
      onChange(Math.min(Math.max(numberOfColumns, MIN_VALUE), MAX_VALUE));
    },
    [onChange]
  );

  const handlePlusClick = useCallback(
    () => changeValue(value - 1),
    [changeValue, value]
  );
  const handleMinusClick = useCallback(
    () => changeValue(value + 1),
    [changeValue, value]
  );
  const handleSliderChange = useCallback(
    (value: number) => changeValue(-value),
    [changeValue]
  );

  useHotkeys(
    "*",
    (e) => {
      if (e.key === "=" || e.key === "+") {
        handlePlusClick();
      } else if (e.key === "-") {
        handleMinusClick();
      }
    },
    [handlePlusClick, handleMinusClick]
  );

  return (
    <HStack spacing={0}>
      <Button
        isDisabled={value === MAX_VALUE}
        onClick={handleMinusClick}
        size="sm"
        variant="unstyled"
      >
        <Icon id="Ui/MinusSm" />
      </Button>

      <Slider
        w="64px"
        colorScheme="white"
        max={-min}
        min={-MAX_VALUE}
        onChange={handleSliderChange}
        step={1}
        value={-value}
      >
        <SliderTrack h="2px">
          <SliderFilledTrack bg="white.500" />
        </SliderTrack>
        <SliderThumb
          boxSize="12px"
          bg="white.500"
          _focusVisible={{ outline: "none" }}
        />
      </Slider>

      <Button
        isDisabled={value === MIN_VALUE}
        onClick={handlePlusClick}
        size="sm"
        variant="unstyled"
      >
        <Icon id="Ui/PlusSm" />
      </Button>
    </HStack>
  );
}
