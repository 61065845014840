import { useEffect, useMemo, useState } from "react";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useQueries } from "infra/api/hooks/useQueries";
import { apiSlice } from "infra/store/apiSlice";
import _ from "lodash";

export function useModelsByIds({ modelIds }: { modelIds: string[] }) {
  const { selectedProject } = useTeamContext();
  const [models, setModels] = useState<{
    [key: string]: {
      name: string | undefined;
    };
  }>({});
  const [chunks, setChunks] = useState<string[][]>([]);

  const uniqueModelIds = useMemo(() => [...new Set(modelIds)], [modelIds]);

  useEffect(() => {
    const chunkSize = 100;
    const newChunks: string[][] = [];
    uniqueModelIds.forEach((id) => {
      // if the id is already in a chunk, skip it
      if (chunks.some((chunk) => chunk.includes(id))) {
        return;
      }
      // if the last chunk is full, create a new one
      if (
        newChunks.length === 0 ||
        newChunks[newChunks.length - 1].length === chunkSize
      ) {
        newChunks.push([]);
      }
      // add the id to the last chunk
      newChunks[newChunks.length - 1].push(id);
    });
    const newValue = [...chunks, ...newChunks];
    if (!_.isEqual(chunks, newValue)) {
      setChunks(newValue);
    }
  }, [uniqueModelIds, chunks]);

  const getModelsByIdsQueries = useQueries(
    apiSlice.endpoints.getModelsBulk,
    chunks.map((chunk) => ({
      projectId: selectedProject.id,
      body: { modelIds: chunk, minimal: true },
    }))
  );

  // ----------------------------------

  useEffect(() => {
    const fulfilledQueries = getModelsByIdsQueries.filter(
      (query) => query.status === "fulfilled"
    );
    const isAllFulfilled =
      getModelsByIdsQueries.length === fulfilledQueries.length;

    if (!isAllFulfilled) {
      return;
    }

    const newModels = fulfilledQueries.reduce<typeof models>((memo, query) => {
      (query.originalArgs.body.modelIds ?? []).forEach((modelId) => {
        if (uniqueModelIds.includes(modelId)) {
          const model = query.data?.models?.find(
            (model) => model.id === modelId
          );
          memo[modelId] = {
            name: model?.name,
          };
        }
      });
      return memo;
    }, {});

    setModels((prev) => {
      if (_.isEqual(prev, newModels)) {
        return prev;
      }
      return newModels;
    });
  }, [uniqueModelIds, getModelsByIdsQueries]);

  const isLoading = useMemo(() => {
    return getModelsByIdsQueries.some((query) => query.isLoading);
  }, [getModelsByIdsQueries]);

  return {
    models,
    isLoading,
  };
}
