export const MODEL_MAIN_TAGS = [
  "sc:style",
  "sc:subject",
  "sc:asset-type",
  "sc:theme",
  "sc:texture",
];

export const MODEL_SECONDARY_TAGS = [
  "sc:style:illustration",
  "sc:style:line-art",
  "sc:style:3D",
  "sc:style:cartoon",
  "sc:style:painting",
  "sc:style:anime",
  "sc:style:comic",
  "sc:style:digital-art",
  "sc:style:realism",
  "sc:style:photography",
  "sc:style:minimalism",
  "sc:style:retro",
  "sc:theme:fantasy",
  "sc:theme:rpg",
  "sc:theme:surreal",
  "sc:theme:scifi",
  "sc:theme:ethereal",
  "sc:theme:holiday",
  "sc:theme:horror",
  "sc:theme:nature",
  "sc:theme:supernatural",
  "sc:asset-type:backgrounds",
  "sc:asset-type:props",
  "sc:asset-type:UI",
  "sc:asset-type:isometric",
  "sc:asset-type:platformer",
  "sc:asset-type:icons",
  "sc:asset-type:concept-art",
  "sc:asset-type:character-art",
];

export type ModelTag =
  | (typeof MODEL_MAIN_TAGS)[number]
  | (typeof MODEL_SECONDARY_TAGS)[number];

// ------------------------------------

export const MODEL_FAMILIES = [
  {
    id: "private",
    label: "My models",
  },
  {
    id: "platform",
    label: "Platform models",
  },
  {
    id: "foundation",
    label: "Base models",
  },
] as const;

export type ModelFamily = (typeof MODEL_FAMILIES)[number]["id"];
