import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { classNameToHumanReadable } from "domains/models/utils/classToReadableName";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import {
  GetModelsClassesByModelIdApiResponse,
  useGetModelsClassesByModelIdQuery,
} from "infra/api/generated/api";

import {
  Divider,
  Menu,
  MenuButton,
  MenuList,
  SkeletonText,
} from "@chakra-ui/react";

interface SelectClassProps {
  selectedClassSlug: string | undefined;
  setSelectedClassSlug: (slug: string | undefined) => void;
}

export default function SelectClass({
  selectedClassSlug,
  setSelectedClassSlug,
}: SelectClassProps) {
  const listRef = useRef<HTMLDivElement>(null);
  const { selectedProject } = useTeamContext();
  const classes = useGetModelsClassesByModelIdQuery({
    projectId: selectedProject.id,
    modelId: "base",
  });
  const [selectedCategory, setSelectedCategory] = useState<
    string | undefined
  >();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const classesByCategories = useMemo(() => {
    const classesByCategories: {
      [key: string]: GetModelsClassesByModelIdApiResponse["classes"];
    } = {};

    classes.data?.classes.forEach((item) => {
      if (!classesByCategories[item.category]) {
        classesByCategories[item.category] = [];
      }
      classesByCategories[item.category].push(item);
    });

    return classesByCategories;
  }, [classes]);

  const selectedFullClass = useMemo(() => {
    if (!selectedClassSlug) {
      return undefined;
    }

    if (selectedClassSlug === "default") {
      return { name: "Default", category: undefined };
    }

    return classes.data?.classes.find(
      (item) => item.slug === selectedClassSlug
    );
  }, [classes, selectedClassSlug]);

  // ----------------------------------

  const handleScroll = useCallback(() => {
    if (!listRef.current) {
      return;
    }
    listRef.current.scrollTop = 0;
  }, []);

  const handleSelectClass = useCallback(
    (slug: string) => {
      handleScroll();
      setSelectedClassSlug(slug);
      setIsMenuOpen(false);
      // timeout to avoid weird flashes in the menu before it closes
      setTimeout(() => {
        setSelectedCategory(undefined);
      }, 100);
    },
    [setIsMenuOpen, setSelectedClassSlug, setSelectedCategory, handleScroll]
  );

  const handleSelectCategory = useCallback(
    (category: string) => {
      handleScroll();
      setSelectedCategory(category);
    },
    [setSelectedCategory, handleScroll]
  );

  const handleGoBack = useCallback(() => {
    handleScroll();
    setSelectedCategory(undefined);
  }, [setSelectedCategory, handleScroll]);

  // ----------------------------------

  useEffect(() => {
    if (
      !(selectedClassSlug && !selectedFullClass && classes.data?.classes.length)
    ) {
      return;
    }

    const nearestClassItem = classes.data?.classes.find((classItem) =>
      classItem.slug.startsWith(selectedClassSlug)
    );

    if (nearestClassItem) {
      setSelectedClassSlug(nearestClassItem.slug);
    }
  }, [
    setSelectedClassSlug,
    selectedClassSlug,
    selectedFullClass,
    classes.data?.classes,
  ]);

  // ----------------------------------

  return (
    <Menu
      closeOnSelect={false}
      isOpen={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
      onOpen={() => setIsMenuOpen(true)}
    >
      <MenuButton
        as={Button}
        w="100%"
        textAlign="start"
        isLoading={!Object.keys(classesByCategories).length}
        rightIcon={<Icon id="Ui/ChevronDown" />}
        size="sm"
        variant="secondary"
      >
        {selectedClassSlug
          ? selectedFullClass?.name ?? (
              <SkeletonText w="50%" noOfLines={1} skeletonHeight="20px" />
            )
          : "Select a class"}
      </MenuButton>
      <MenuList
        ref={listRef}
        zIndex={"sticky"}
        overflowY="auto"
        // w="100%" is just not working, it's 1am, I give up
        w="311px"
        maxH="max(50vh, 340px)"
        rootProps={{
          overflow: "hidden",
          borderRadius: "xl",
        }}
      >
        {!selectedCategory ? (
          <>
            <SelectClassMenuItem
              type="class"
              onClick={() => handleSelectClass("default")}
              isSelected={selectedClassSlug === "default"}
              label="Default"
            />
            <Divider mt={2} />
            {Object.keys(classesByCategories).map((category) => (
              <SelectClassMenuItem
                key={category}
                type={"category"}
                onClick={() => handleSelectCategory(category)}
                isSelected={selectedFullClass?.category === category}
                label={classNameToHumanReadable(category)}
              />
            ))}
          </>
        ) : (
          <>
            <SelectClassMenuItem
              key={"back"}
              type={"back"}
              onClick={handleGoBack}
              isSelected={false}
              label={"Back"}
            />
            {classesByCategories[selectedCategory].map((item) => (
              <SelectClassMenuItem
                key={item.name}
                type={"class"}
                onClick={() => handleSelectClass(item.slug)}
                isSelected={selectedClassSlug === item.slug}
                label={item.name}
              />
            ))}
          </>
        )}
      </MenuList>
    </Menu>
  );
}

interface SelectClassMenuItemProps {
  type: "category" | "class" | "back";
  isSelected: boolean;
  onClick: () => void;
  label: string;
}

function SelectClassMenuItem({
  type,
  isSelected,
  onClick,
  label,
}: SelectClassMenuItemProps) {
  return (
    <MenuItem
      check="icon"
      iconId={
        type === "back"
          ? "Ui/ChevronLeft"
          : type === "class" && isSelected
          ? "Ui/Check"
          : undefined
      }
      onClick={onClick}
      selectedIconId={type === "category" ? "Ui/ChevronRight" : undefined}
      isSelected={isSelected}
      text={label}
    />
  );
}
