import React, { useMemo } from "react";
import { FileManagerProps } from "domains/file-manager/components/FileManager";
import { FileType } from "domains/file-manager/interfaces";

import { Card, Flex, Slide, useToken } from "@chakra-ui/react";

import SectionLeft from "./SectionLeft";

export interface SelectionBarProps {
  files: FileType[];
  selectedFiles: FileType[];
  selectionMax?: number;
  onSelectAll: () => void;
  onDeselectAll: () => void;

  fileHandlers: FileManagerProps["fileHandlers"];
}

export default function SelectionBar({
  files,
  selectedFiles = [],
  selectionMax,

  onSelectAll,
  onDeselectAll,

  fileHandlers,
}: SelectionBarProps) {
  const isCollapsed = useMemo(() => {
    return selectedFiles.length === 0;
  }, [selectedFiles]);

  const selectionBarZIndex = useToken("zIndices", "popover");

  return (
    <Slide
      direction="bottom"
      in={!isCollapsed}
      style={{ zIndex: selectionBarZIndex }}
    >
      <Card
        py={5}
        borderBottomColor="transparent"
        borderX={0}
        borderRadius={0}
        data-outside-click-excluded
        data-testid="file-manager-selection-bar"
      >
        <Flex
          align="center"
          justify="space-between"
          gap={4}
          w="100%"
          h="100%"
          px={6}
        >
          <SectionLeft
            count={selectedFiles.length}
            max={selectionMax}
            onClearClick={selectedFiles.length ? onDeselectAll : undefined}
            onSelectAllClick={
              selectedFiles.length < files.length ? onSelectAll : undefined
            }
          />

          <Flex align={"center"} justify={"center"} gap={2}>
            {Object.values(fileHandlers).map((fileHandler) =>
              fileHandler.actions?.map((action) =>
                action.kind.includes("selectionBar") ? (
                  <action.Component
                    key={action.kind + action.label}
                    files={selectedFiles as any}
                    onAction={action.onAction as any}
                    isViewable={!isCollapsed}
                  />
                ) : null
              )
            )}
          </Flex>
        </Flex>
      </Card>
    </Slide>
  );
}
