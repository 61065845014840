import { ReframeForm } from "domains/reframe/interfaces/Reframe";
import Slider from "domains/ui/components/Slider";
import SliderStepsPaul from "domains/ui/components/Slider/SliderStepsPaul";
import WithLabelAndTooltip from "domains/ui/components/WithLabelAndTooltip";

import { Text } from "@chakra-ui/react";

type AspectRatioValue = `${number}:${number}` | "Default";

interface DimensionsControlsProps {
  form: ReframeForm;
  onFormChange: <T extends keyof ReframeForm>(
    key: T,
    value: ReframeForm[T]
  ) => void;
  aspectRatio: AspectRatioValue;
  onAspectRatioChange: (ratio: AspectRatioValue) => void;
  ratios: AspectRatioValue[];
}

const DimensionsControls = ({
  form,
  onFormChange,
  aspectRatio,
  onAspectRatioChange,
  ratios,
}: DimensionsControlsProps) => {
  return (
    <>
      <WithLabelAndTooltip
        label="New dimensions"
        labelProps={{
          w: "fit-content",
        }}
      >
        <Text color="textSecondary" textAlign="right">
          {`${form.targetWidth}x${form.targetHeight}`}
        </Text>
      </WithLabelAndTooltip>

      <WithLabelAndTooltip
        label="Output Scale"
        tooltip="Adjust the size of the output image"
      >
        <Slider
          min={1}
          max={300}
          withNumberInput
          step={1}
          value={Math.round(form.outputScale * 100)}
          onChange={(value) =>
            onFormChange("outputScale", Math.round(value) / 100)
          }
          valueStyleProps={{
            w: "5ch",
          }}
        />
      </WithLabelAndTooltip>

      <WithLabelAndTooltip
        label="Aspect Ratio"
        tooltip={`Adjust the aspect ratio from ${ratios[1]} to ${
          ratios[ratios.length - 1]
        }`}
      >
        <SliderStepsPaul
          steps={ratios}
          onChange={onAspectRatioChange}
          value={aspectRatio}
        />
      </WithLabelAndTooltip>
    </>
  );
};

export default DimensionsControls;
