import { isAssetSkybox, isAssetTexture } from "domains/assets/utils/isType";
import { capitalizeFirstWord } from "domains/commons/misc";
import { FmSelectionActionProps } from "domains/file-manager/components/FileManagerV2/FmSelectionActions";
import { FmFileImage } from "domains/file-manager/interfacesV2";
import ButtonWithModal from "domains/ui/components/ButtonWithModal";
import Icon from "domains/ui/components/Icon";

export type SelectionActionDeleteProps = FmSelectionActionProps<"image"> & {
  isLoading: boolean;
  isDisabled: boolean;
  buttonRef: React.RefObject<HTMLButtonElement>;
  onClick: (files: FmFileImage[]) => void;
};

export default function SelectionActionDelete({
  selectedFiles,
  isDisplayed,
  isLoading,
  isDisabled,
  buttonRef,
  onClick,
}: SelectionActionDeleteProps) {
  const firstAsset = selectedFiles[0]?.meta;
  const assetType =
    (isAssetTexture(firstAsset) && "textures") ||
    (isAssetSkybox(firstAsset) && "skyboxes") ||
    "images";

  return (
    <ButtonWithModal
      variant="secondary"
      tooltip="Delete"
      leftIcon={<Icon id="Ui/Trash" h="18px" />}
      onConfirm={() => onClick(selectedFiles)}
      modalHeader={`Delete ${capitalizeFirstWord(assetType)}`}
      modalBody={`Are you sure you want to delete these ${assetType}?`}
      modalColorScheme="danger"
      isLoading={isLoading}
      isModalConfirmButtonLoading={isLoading}
      isDisabled={isDisabled || !isDisplayed}
      data-testid="file-manager-delete-button"
      w="36px"
      ref={buttonRef}
    />
  );
}
