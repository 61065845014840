import React from "react";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import { Flex, Spinner, Tooltip } from "@chakra-ui/react";

interface SearchBarFileUploadProps {
  onClickImageUpload: () => void;
  isUploadLoading: boolean;
}

export default function SearchBarFileUpload({
  onClickImageUpload,
  isUploadLoading,
}: SearchBarFileUploadProps) {
  return (
    <>
      {isUploadLoading ? (
        <Flex align={"center"} justify={"center"} w={"28px"}>
          <Spinner w={"12px"} h={"12px"} />
        </Flex>
      ) : (
        <Tooltip
          label="Upload or drag & drop an image to find similar results.
        "
        >
          <Button
            px={0}
            h={"28px"}
            w={"28px"}
            minW={"28px"}
            variant="ghost"
            onClick={onClickImageUpload}
            colorScheme={"white"}
          >
            <Icon color="textSecondary" id="Nav/Image/Outline" h={"12px"} />
          </Button>
        </Tooltip>
      )}
    </>
  );
}
