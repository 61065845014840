import React from "react";
import Button, { ButtonProps } from "domains/ui/components/Button";

const SidebarButtonSectionTopIcon = React.forwardRef(
  function SidebarButtonSectionTopIcon(props: ButtonProps, ref) {
    return (
      <Button
        ref={ref}
        display="flex"
        alignItems="center"
        pos="relative"
        w="28px"
        minW="28px"
        h="28px"
        p={0}
        _hover={{
          bgColor: "backgroundQuaternary.500",
        }}
        bgColor="whiteAlpha.50"
        variant="unstyled"
        {...props}
      />
    );
  }
);

export default SidebarButtonSectionTopIcon;
