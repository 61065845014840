import { useMemo, useState } from "react";
import { useAssetRemoveBackgroundCu } from "domains/assets/hooks/useAssetRemoveBackgroundCu";
import { FmSelectionActionProps } from "domains/file-manager/components/FileManagerV2/FmSelectionActions";
import { FmFileImage } from "domains/file-manager/interfacesV2";
import { mapFmFileImagesToFileImages } from "domains/file-manager/utils/mapFmFileToFile";
import ModalTrainModel from "domains/models/components/ModalTrainModel";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { CuIndicator } from "domains/ui/components/ButtonWithCuIndicator";
import Icon from "domains/ui/components/Icon";
import MenuButtonIcon from "domains/ui/components/Menu/MenuButtonIcon";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import { TagPremium } from "domains/ui/components/TagPremium";

import { Menu, MenuDivider, MenuList } from "@chakra-ui/react";

export type SelectionActionMoreProps = FmSelectionActionProps<"image"> & {
  onDownloadClick: (files: FmFileImage[]) => void;
  onEditTagsClick: (() => void) | undefined;
  onRemoveBgClick: (() => void) | undefined;
  onDeleteClick: () => void;
};

export default function SelectionActionMore({
  selectedFiles,
  isDisplayed,
  onDownloadClick,
  onEditTagsClick,
  onRemoveBgClick,
  onDeleteClick,
}: SelectionActionMoreProps) {
  const { selectedTeam } = useTeamContext();
  const [isTrainModalOpen, setIsTrainModalOpen] = useState<boolean>(false);

  const filesAssetsIdWidthAndHeight = useMemo(
    () =>
      isDisplayed
        ? selectedFiles.map((file) => ({
            assetId: file.id,
            width: file.width,
            height: file.height,
          }))
        : [],
    [selectedFiles, isDisplayed]
  );
  const hasNonCompatibleFiles = useMemo(
    () =>
      selectedFiles.some((file) => file.meta.metadata.type === "vectorization"),
    [selectedFiles]
  );
  const cuRemoveBackground = useAssetRemoveBackgroundCu(
    hasNonCompatibleFiles ? { skip: true } : filesAssetsIdWidthAndHeight
  );

  const initialTrainAssets = useMemo(
    () =>
      mapFmFileImagesToFileImages(
        selectedFiles.filter((file) => file.status === "success")
      ),
    [selectedFiles]
  );

  return (
    <>
      <Menu autoSelect={false}>
        <MenuButtonIcon aria-label="More">
          <Icon id="Ui/Dots" />
        </MenuButtonIcon>

        <MenuList>
          <MenuItem
            iconId="Ui/Download"
            iconH="16px"
            text="Download"
            tooltipProps={{
              placement: "right",
            }}
            onClick={() => onDownloadClick(selectedFiles)}
          />

          {!!onEditTagsClick && (
            <MenuItem
              iconId="Ui/AddTag"
              onClick={onEditTagsClick}
              isDisabled={selectedFiles.length > 50}
              tooltip={
                selectedFiles.length > 50
                  ? "You can only tag up to 50 items at a time."
                  : undefined
              }
              tooltipProps={{
                placement: "right",
              }}
              iconH="16px"
              text="Add Tags"
            />
          )}

          <MenuDivider />

          {!!onRemoveBgClick && (
            <>
              <MenuItem
                iconId="Domains/Tools/RemoveBackground"
                iconH="16px"
                text="Remove Background"
                tooltip={(() => {
                  if (selectedTeam.isFreePlan) {
                    return "Upgrade to enable batch background removal";
                  } else if (cuRemoveBackground.isCuLoading) {
                    return "Estimating Creative Unit Cost";
                  } else if (
                    cuRemoveBackground.hasError ||
                    hasNonCompatibleFiles
                  ) {
                    return "Background removal is unavailable for at least one selected asset";
                  } else {
                    return `Uses ${cuRemoveBackground.cuCost} creative ${
                      (cuRemoveBackground.cuCost ?? 0) > 1 ? "units" : "unit"
                    }`;
                  }
                })()}
                tooltipProps={{
                  placement: "right",
                }}
                isDisabled={
                  selectedTeam.isFreePlan ||
                  !cuRemoveBackground.hasEnoughCu ||
                  cuRemoveBackground.hasError
                }
                rightIcon={
                  selectedTeam.isFreePlan ? (
                    <TagPremium
                      label="Upgrade"
                      containerProps={{
                        pr: 2,
                        mr: -2,
                      }}
                    />
                  ) : (
                    <CuIndicator
                      p={0}
                      cuCost={
                        !cuRemoveBackground.hasError
                          ? cuRemoveBackground.cuCost
                          : undefined
                      }
                      isCuLoading={cuRemoveBackground.isCuLoading}
                      isTooltipDisabled
                    />
                  )
                }
                onClick={onRemoveBgClick}
              />

              <MenuDivider />
            </>
          )}

          <MenuItem
            iconId="Domains/Tools/Blend"
            iconH="16px"
            text="Train a New Model"
            onClick={() => setIsTrainModalOpen(true)}
          />

          <MenuDivider />

          <MenuItem
            iconId="Ui/Trash"
            iconH="16px"
            text="Delete"
            onClick={onDeleteClick}
          />
        </MenuList>
      </Menu>

      <ModalTrainModel
        isOpen={isTrainModalOpen}
        onClose={() => setIsTrainModalOpen(false)}
        initialAssets={initialTrainAssets}
      />
    </>
  );
}
