import FmHeaderSort, {
  FmHeaderSortProps,
} from "domains/file-manager/components/FileManagerV2/FmHeaderSort";
import { GridSortValue } from "domains/file-manager/constants/GridSort";

export type HeaderSortProps = FmHeaderSortProps<GridSortValue[]>;

export default function HeaderSort(props: HeaderSortProps) {
  return <FmHeaderSort {...props} />;
}
