import {
  OutpaintingInputLocation,
  ReframeForm,
} from "domains/reframe/interfaces/Reframe";
import WithLabelAndTooltip from "domains/ui/components/WithLabelAndTooltip";

import { Box, IconButton } from "@chakra-ui/react";

interface LocationSelectorProps {
  inputLocation: ReframeForm["inputLocation"];
  onLocationChange: (location: OutpaintingInputLocation) => void;
}

const LocationSelector = ({
  inputLocation,
  onLocationChange,
}: LocationSelectorProps) => {
  return (
    <WithLabelAndTooltip
      label="Input Location"
      tooltip="Location of the input image in the output"
    >
      <Box pos="relative" w="100px" h="100px" m={4}>
        {[
          { location: "top", top: "10%", left: "50%" },
          { location: "left", top: "50%", left: "10%" },
          { location: "middle", top: "50%", left: "50%" },
          { location: "right", top: "50%", left: "90%" },
          { location: "bottom", top: "90%", left: "50%" },
        ].map(({ location, top, left }) => (
          <IconButton
            key={location}
            pos="absolute"
            top={top}
            left={left}
            w="30px"
            minW="30px"
            h="30px"
            minH="30px"
            transform="translate(-50%, -50%)"
            cursor="pointer"
            aria-label={location}
            onClick={() =>
              onLocationChange(location as OutpaintingInputLocation)
            }
            variant={inputLocation === location ? "solid" : "outline"}
          />
        ))}
      </Box>
    </WithLabelAndTooltip>
  );
};

export default LocationSelector;
