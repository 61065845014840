import {
  UPSCALE_PRESET_LABELS,
  UPSCALE_STYLE_LABELS,
  UpscalePreset,
} from "domains/assets/constants/upscale";
import {
  VECTORIZATION_COLOR_MODE_LABELS,
  VECTORIZATION_MODE_LABELS,
} from "domains/assets/constants/vectorize";
import { rgbToHex } from "domains/commons/utils/rgbToHex";
import {
  SDXL_BASE_MODELS,
  SDXL_DEFAULT_BASE_MODEL,
} from "domains/inference/constants/baseModel";
import { SCHEDULERS } from "domains/inference/constants/Schedulers";
import {
  getIsModelFlux,
  getIsModelFluxPro1_1,
  getIsModelFluxPro1_1Ultra,
  getIsModelWithBase,
} from "domains/models/utils";
import {
  SKYBOX_STYLES,
  SkyboxStyleKey,
} from "domains/skyboxes/constants/Skybox";
import {
  AsideList,
  AsideListItem,
  AsideSection,
} from "domains/ui/components/Aside";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
} from "infra/api/generated/api";

import { Box, HStack, Text, VStack } from "@chakra-ui/react";

export interface AsideSectionParametersProps {
  model: GetModelsByModelIdApiResponse["model"] | undefined;
  inference:
    | GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
    | undefined;
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}

export default function AsideSectionParameters({
  model,
  inference,
  asset,
}: AsideSectionParametersProps) {
  if (getIsModelFluxPro1_1(model) || getIsModelFluxPro1_1Ultra(model)) {
    return null;
  }

  if (inference) {
    return (
      <AsideSection id="parameters" title="Parameters">
        <AsideList>
          <AsideListItem
            label="Steps"
            value={inference?.parameters.numInferenceSteps ?? "-"}
          />

          <AsideListItem
            label="Guidance"
            value={inference?.parameters.guidance ?? "-"}
            copyable
          />

          {getIsModelWithBase(model) && (
            <AsideListItem
              label="Base Model"
              value={
                SDXL_BASE_MODELS.find(
                  (baseModel) =>
                    baseModel.id === inference.parameters.baseModelId
                )?.name ?? SDXL_DEFAULT_BASE_MODEL.name
              }
            />
          )}

          {!getIsModelFlux(model) && (
            <AsideListItem
              label="Scheduler"
              value={
                (inference?.parameters.scheduler &&
                  SCHEDULERS[inference.parameters.scheduler]) ||
                "Default (optimal)"
              }
            />
          )}
        </AsideList>
      </AsideSection>
    );
  }

  if (asset?.metadata.type === "skybox-base-360") {
    return (
      <AsideSection id="parameters" title="Parameters">
        <AsideList variant="50-50">
          <AsideListItem
            label="Style"
            value={
              asset.metadata.style &&
              SKYBOX_STYLES[asset.metadata.style as SkyboxStyleKey]
                ? SKYBOX_STYLES[asset.metadata.style as SkyboxStyleKey].label
                : "Standard"
            }
          />

          <AsideListItem
            label="Steps"
            value={asset.metadata.numInferenceSteps ?? "-"}
            copyable
          />

          <AsideListItem
            label="360 Scale Level"
            value={
              asset.metadata.geometryEnforcement !== undefined
                ? asset.metadata.geometryEnforcement
                : "-"
            }
            copyable
          />
        </AsideList>
      </AsideSection>
    );
  }

  if (asset?.metadata.type === "restyle") {
    return (
      <AsideSection id="parameters" title="Parameters">
        <AsideList variant="50-50">
          <AsideListItem
            label="Steps"
            value={asset.metadata.numInferenceSteps ?? "-"}
            copyable
          />

          <AsideListItem
            label="Guidance"
            value={
              asset.metadata.promptFidelity !== undefined
                ? asset.metadata.promptFidelity / 10
                : "-"
            }
            copyable
          />
        </AsideList>
      </AsideSection>
    );
  }

  if (asset?.metadata.type === "vectorization") {
    return (
      <AsideSection id="parameters" title="Parameters">
        <AsideList variant="50-50">
          <AsideListItem
            label="Color Clustering"
            value={
              (asset.metadata.colorMode &&
                VECTORIZATION_COLOR_MODE_LABELS[
                  asset.metadata
                    .colorMode as keyof typeof VECTORIZATION_COLOR_MODE_LABELS
                ]) ||
              "-"
            }
          />

          <AsideListItem
            label="Filter Speckle"
            value={asset.metadata.filterSpeckle ?? "-"}
          />

          {asset.metadata.colorMode !== "bw" && (
            <>
              <AsideListItem
                label="Color Precision"
                value={asset.metadata.colorPrecision ?? "-"}
              />
              <AsideListItem
                label="Layer Difference"
                value={asset.metadata.layerDifference ?? "-"}
              />
            </>
          )}

          <AsideListItem
            label="Curve Detection"
            value={
              (asset.metadata.mode &&
                VECTORIZATION_MODE_LABELS[
                  asset.metadata.mode as keyof typeof VECTORIZATION_MODE_LABELS
                ]) ||
              "-"
            }
          />

          {asset.metadata.mode === "spline" && (
            <>
              <AsideListItem
                label="Corner Thresh"
                value={asset.metadata.cornerThreshold ?? "-"}
              />
              <AsideListItem
                label="Length Thresh"
                value={asset.metadata.lengthThreshold ?? "-"}
              />
              <AsideListItem
                label="Splice Thresh"
                value={asset.metadata.spliceThreshold ?? "-"}
              />
            </>
          )}
        </AsideList>
      </AsideSection>
    );
  }

  if (asset?.metadata.type === "pixelization") {
    const metadata = asset.metadata as any; // TODO REMOVE THIS WHEN SWAGGER IS FIXED https://scenario.slack.com/archives/C04BLL3TELF/p1703077168715869
    const colorPaletteAsHex = metadata.colorPalette?.map((color: number[]) =>
      rgbToHex(color)
    );
    return (
      <AsideSection id="parameters" title="Parameters">
        <AsideList variant="70-30">
          {colorPaletteAsHex && (
            <AsideListItem
              label="Color Palette"
              value={
                <VStack align="start" spacing={0}>
                  {colorPaletteAsHex.map((color: string, index: number) => (
                    <HStack key={color + index} spacing={1}>
                      <Box
                        w="12px"
                        h="12px"
                        mt={"-2px"}
                        borderRadius="full"
                        bgColor={color}
                      />
                      <Text color="textPrimary" size="body.md">
                        {color}
                      </Text>
                    </HStack>
                  ))}
                </VStack>
              }
            />
          )}
          {metadata.colorPaletteSize !== undefined && (
            <AsideListItem
              label="Color Palette Size"
              value={metadata.colorPaletteSize}
            />
          )}
          <AsideListItem
            label="Pixel Grid Size"
            value={
              (metadata.pixelGridSize && `${metadata.pixelGridSize}px`) ?? "-"
            }
          />
          <AsideListItem
            label="Remove Noise"
            value={metadata.removeNoise ? "Yes" : "No"}
          />
          <AsideListItem
            label="Remove Background"
            value={metadata.removeBackground ? "Yes" : "No"}
          />
        </AsideList>
      </AsideSection>
    );
  }

  if (asset?.metadata.type === "upscale") {
    return (
      <AsideSection id="parameters" title="Parameters">
        <AsideList variant="50-50">
          <AsideListItem
            label="Scaling Factor"
            value={
              (asset.metadata.scalingFactor &&
                `x${asset.metadata.scalingFactor}`) ||
              "-"
            }
          />
          <AsideListItem
            label="Style"
            value={
              UPSCALE_STYLE_LABELS[
                asset.metadata.style as keyof typeof UPSCALE_STYLE_LABELS
              ] ?? "-"
            }
          />
          <AsideListItem
            label="Preset"
            value={
              UPSCALE_PRESET_LABELS[asset.metadata.preset as UpscalePreset] ??
              "Custom"
            }
          />
          <AsideListItem
            label="Prompt Fidelity"
            value={
              asset.metadata.promptFidelity !== undefined
                ? Math.round(asset.metadata.promptFidelity)
                : "-"
            }
          />
          <AsideListItem
            label="Image Fidelity"
            value={
              asset.metadata.imageFidelity !== undefined
                ? Math.round(asset.metadata.imageFidelity)
                : "-"
            }
          />
          <AsideListItem
            label="Creativity"
            value={
              asset.metadata.creativity !== undefined
                ? Math.round(asset.metadata.creativity)
                : "-"
            }
          />
          <AsideListItem
            label="Fractality"
            value={
              asset.metadata.fractality !== undefined
                ? Math.round(asset.metadata.fractality)
                : "-"
            }
          />
          <AsideListItem
            label="Detail Intensity"
            value={
              asset.metadata.detailsLevel !== undefined
                ? Math.round(asset.metadata.detailsLevel)
                : "-"
            }
          />
          <AsideListItem
            label="Creativity Decay"
            value={
              asset.metadata.creativityDecay !== undefined
                ? Math.round(asset.metadata.creativityDecay)
                : "-"
            }
          />
          <AsideListItem
            label="Override Style Embeddings"
            value={asset.metadata.overrideEmbeddings ? "Yes" : "No"}
          />
        </AsideList>
      </AsideSection>
    );
  }

  if (asset?.metadata.type === "background-removal") {
    const metadata = asset.metadata as any; // TODO REMOVE THIS WHEN SWAGGER IS FIXED https://scenario.slack.com/archives/C04BLL3TELF/p1703077168715869
    return (
      <AsideSection id="parameters" title="Parameters">
        <AsideList variant="50-50">
          <AsideListItem
            label="Background Color"
            value={
              metadata.backgroundColor ? (
                <HStack spacing={1}>
                  <Box
                    className="checkerboard"
                    w="12px"
                    h="12px"
                    mt={"-2px"}
                    bg="white"
                    borderRadius="full"
                  >
                    <Box
                      w="12px"
                      h="12px"
                      borderRadius="full"
                      bgColor={metadata.backgroundColor}
                    />
                  </Box>
                  <Text color="textPrimary" size="body.md">
                    {metadata.backgroundColor}
                  </Text>
                </HStack>
              ) : (
                "Transparent"
              )
            }
            copyable
            valueToCopy={metadata.backgroundColor}
          />
        </AsideList>
      </AsideSection>
    );
  }

  if (asset?.metadata.type === "texture") {
    return (
      <AsideSection id="parameters" title="Parameters">
        <AsideList variant="50-50">
          <AsideListItem label="Raised" value={asset.metadata.raised} />
          <AsideListItem label="Shiny" value={asset.metadata.shiny} />
          <AsideListItem label="Polished" value={asset.metadata.polished} />
          <AsideListItem label="Angular" value={asset.metadata.angular} />
          <AsideListItem
            label="Inverted"
            value={asset.metadata.invert ? "Yes" : "No"}
          />
        </AsideList>
      </AsideSection>
    );
  }

  if (asset?.metadata.type === "upscale-skybox") {
    return (
      <AsideSection id="parameters" title="Parameters">
        <AsideList variant="50-50">
          <AsideListItem
            label="Details Level"
            value={
              asset.metadata.detailsLevel !== undefined
                ? asset.metadata.detailsLevel
                : "-"
            }
          />
        </AsideList>
      </AsideSection>
    );
  }

  if (asset?.metadata.type === "reframe") {
    return (
      <AsideSection id="parameters" title="Parameters">
        <AsideList variant="50-50">
          {asset?.metadata.overlapPercentage && (
            <AsideListItem
              label="Overlap"
              value={`${Math.round(
                asset.metadata.overlapPercentage * 2 * 100
              )}%`}
            />
          )}
          {asset?.metadata.resizeOption && (
            <AsideListItem
              label="Input scale"
              value={`${Math.round(asset.metadata.resizeOption * 100)}%`}
            />
          )}
          {asset?.metadata.numInferenceSteps && (
            <AsideListItem
              label="Sampling steps"
              value={`${asset.metadata.numInferenceSteps}`}
            />
          )}
        </AsideList>
      </AsideSection>
    );
  }

  return null;
}
