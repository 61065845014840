import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import Icon from "domains/ui/components/Icon";

import { HStack, SimpleGrid, Text, Tooltip, VStack } from "@chakra-ui/react";

interface AsideListContextValue {
  variant: "30-70" | "50-50" | "70-30";
}

export const AsideListContext = createContext<AsideListContextValue>({
  variant: "30-70",
});

// ------------------------------------

export interface AsideListProps extends PropsWithChildren {
  variant?: AsideListContextValue["variant"];
}

export function AsideList({ variant = "30-70", children }: AsideListProps) {
  const listContextValue = useMemo(
    () => ({
      variant,
    }),
    [variant]
  );

  return (
    <AsideListContext.Provider value={listContextValue}>
      <VStack align="stretch" spacing={2}>
        {children}
      </VStack>
    </AsideListContext.Provider>
  );
}

// ------------------------------------

export interface AsideListItemProps {
  label: React.ReactNode;
  value?: string | number | React.ReactNode;
  copyable?: boolean;
  valueToCopy?: string;
  isTruncated?: boolean;
}

export function AsideListItem({
  label,
  value,
  copyable: isCopyable,
  valueToCopy,
  isTruncated,
}: AsideListItemProps) {
  const listContext = useContext(AsideListContext);
  const { infoToast } = useScenarioToast();

  const handleCopyClick = useCallback(async () => {
    if (!isCopyable) return;
    await navigator.clipboard.writeText(valueToCopy ?? String(value));

    infoToast({
      title: "Copied to clipboard",
    });
  }, [infoToast, isCopyable, value, valueToCopy]);

  return (
    <Tooltip
      isDisabled={!isCopyable}
      label="Copy to clipboard"
      openDelay={500}
      placement="left"
    >
      <SimpleGrid
        gap={2}
        templateColumns={
          {
            "30-70": "1fr 2fr",
            "50-50": "1fr 1fr",
            "70-30": "2fr 1fr",
          }[listContext.variant]
        }
        columns={2}
        {...(isCopyable
          ? {
              mx: -3,
              my: -1,
              px: 3,
              py: 1,
              borderRadius: "md",
              _hover: { bgColor: "background.500", cursor: "pointer" },
              onClick: handleCopyClick,
            }
          : {})}
        alignItems="start"
        data-group
      >
        <HStack>
          <Text color="textSecondary" size="body.md">
            {label}
          </Text>

          {isCopyable && (
            <Icon
              h="12px"
              _groupHover={{ visibility: "visible" }}
              visibility="hidden"
              id="Ui/Copy"
              color="textSecondary"
            />
          )}
        </HStack>

        {typeof value === "object" ? (
          value
        ) : (
          <Text color="textPrimary" isTruncated={isTruncated} size="body.md">
            {value}
          </Text>
        )}
      </SimpleGrid>
    </Tooltip>
  );
}
