import { useRef } from "react";

export const useAvoidFlicker = (item: JSX.Element) => {
  const previousRender = useRef<JSX.Element | undefined>();

  if (
    item.props.children[0] &&
    item.props.children[0].props.style.zIndex === -1_000
  ) {
    return (
      <>
        {previousRender.current}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1_000,
          }}
        >
          {item}
        </div>
      </>
    );
  } else {
    previousRender.current = item;
    return item;
  }
};
