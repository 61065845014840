import React from "react";
import InternalLink, { LinkProps as InternalLinkProps } from "next/link";
import Icon from "domains/ui/components/Icon";

import {
  Box,
  HStack,
  Image,
  ImageProps,
  StackProps,
  Text,
  VStack,
} from "@chakra-ui/react";

export interface AsideCardProps extends Omit<StackProps, "title"> {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  icon?: React.ReactNode;
  avatarUrl?: ImageProps["src"];
  variant?: "horizontal" | "square";
  size?: "sm" | "md";
  internalLink?: InternalLinkProps["href"];
  onClick?: () => void;
  target?: InternalLinkProps["target"];
}

export default function AsideCard({
  title,
  subTitle,
  avatarUrl,
  icon,
  variant = "horizontal",
  size = "md",
  internalLink,
  onClick,
  target,
  ...props
}: AsideCardProps) {
  const isClickable = internalLink || onClick;
  const sizeProps = (() => {
    switch (size) {
      case "sm":
        return { padding: 1, avatarSize: "24px", transform: "scale(0.75)" };
      case "md":
      default:
        return { padding: 2, avatarSize: "32px" };
    }
  })();

  return (
    <VStack
      as={internalLink ? InternalLink : undefined}
      align="stretch"
      p={sizeProps.padding}
      borderRadius="lg"
      _hover={isClickable ? { opacity: 0.8 } : undefined}
      _active={isClickable ? { opacity: 0.9 } : undefined}
      cursor={isClickable ? "pointer" : "default"}
      bgColor="backgroundQuaternary.500"
      href={internalLink}
      onClick={onClick}
      spacing={2}
      target={target}
      {...props}
    >
      <HStack px={1} spacing={2}>
        {variant === "horizontal" && avatarUrl && (
          <Image
            w={sizeProps.avatarSize}
            h={sizeProps.avatarSize}
            ml={-1}
            borderRadius="md"
            objectFit="cover"
            alt="avatar image"
            src={avatarUrl}
          />
        )}
        {variant === "horizontal" && icon && (
          <HStack
            justify="center"
            w={sizeProps.avatarSize}
            h={sizeProps.avatarSize}
            ml={-1}
            borderRadius="md"
            bgColor="backgroundSecondary.500"
          >
            <Box transform={sizeProps.transform}>{icon}</Box>
          </HStack>
        )}

        <Text
          as={React.isValidElement(title) ? "div" : undefined}
          flex={1}
          overflow="hidden"
          color="textSecondary"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          size="body.md"
        >
          {title}
        </Text>
        <Text
          as={React.isValidElement(title) ? "div" : undefined}
          color="textTertiary"
          size="body.md"
        >
          {subTitle}
        </Text>

        {isClickable && (
          <Icon id="Ui/ChevronRight" h="9px" color="textSecondary" />
        )}
      </HStack>

      {variant === "square" && (
        <Box pos="relative" w="100%" pt="100%">
          <Image
            pos="absolute"
            top={0}
            left={0}
            w="100%"
            h="100%"
            borderRadius="md"
            objectFit="cover"
            alt="avatar image"
            src={avatarUrl}
          />
        </Box>
      )}
    </VStack>
  );
}
