import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import Button, { ButtonProps } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import _ from "lodash";
import { areEqual, VariableSizeList } from "react-window";

import {
  Box,
  BoxProps,
  Divider,
  HStack,
  Input,
  InputProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { COUNTRIES, Country } from "./constants";

type PhoneInputProps = {
  onChange: (phoneNumber: string, countryCode: string) => void;
  inputProps?: InputProps;
  menuProps?: ButtonProps;
};

export default function PhoneInput({
  onChange,
  inputProps,
  menuProps,
}: PhoneInputProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [search, setSearch] = useState<string>("");
  const [localNumber, setLocalNumber] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>(
    getDefaultCountryCode()
  );
  const virtualListRef = useRef<VariableSizeList>(null);

  const mainListIds = useMemo(
    () => _.union(["us", getDefaultCountryCode()]),
    []
  );
  const mainList = useMemo(() => {
    return _.intersection(COUNTRIES.ids, mainListIds).map(
      (id) => COUNTRIES.hash[id]
    );
  }, [mainListIds]);
  const restList = useMemo(() => {
    return _.difference(COUNTRIES.ids, mainListIds).map(
      (id) => COUNTRIES.hash[id]
    );
  }, [mainListIds]);

  const handleOpen = useCallback(() => {
    setSearch("");
    onOpen();
  }, [setSearch, onOpen]);
  const handleCountryCodeChange = useCallback(
    (newCountryCode: string) => {
      setCountryCode(newCountryCode);
      const newPhoneNumber = formatValue(localNumber, newCountryCode);
      onChange(newPhoneNumber, newCountryCode);
    },
    [setCountryCode, onChange, localNumber]
  );
  const handleLocalNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newLocalNumber = e.target.value;
      setLocalNumber(newLocalNumber);
      const newPhoneNumber = formatValue(newLocalNumber, countryCode);
      onChange(newPhoneNumber, countryCode);
    },
    [setLocalNumber, onChange, countryCode]
  );

  const selectedOption = countryCode ? COUNTRIES.hash[countryCode] : undefined;
  type VirtualListDivider = { id: string; type: "divider" };
  type VirtualListItem = Country & { type: "item"; selected?: boolean };
  const virtualListData = useMemo((): {
    onCountryCodeChange: (value: string) => void;
    items: (VirtualListDivider | VirtualListItem)[];
  } => {
    return {
      onCountryCodeChange: handleCountryCodeChange,
      items: [
        ...mainList.map(
          (item): VirtualListItem => ({
            ...item,
            type: "item",
          })
        ),
        { id: "divider-1", type: "divider" },
        ...restList.map(
          (item): VirtualListItem => ({
            ...item,
            type: "item",
          })
        ),
      ].map((item) => {
        if (item.type === "divider") {
          return item as VirtualListDivider;
        } else if (item.type === "item" && item.id === selectedOption?.id) {
          return { ...item, selected: true } as VirtualListItem;
        } else {
          return item as VirtualListItem;
        }
      }),
    };
  }, [mainList, restList, selectedOption?.id, handleCountryCodeChange]);

  useEffect(() => {
    if (!search) return;

    const itemIndex = virtualListData.items.findIndex((item) => {
      if (item.type !== "item") return false;
      if (search.match(/\d/)) {
        return String(item.phoneCode).indexOf(search) === 0;
      } else {
        return item.label.toLowerCase().indexOf(search.toLowerCase()) === 0;
      }
    });
    if (itemIndex !== -1 && virtualListRef.current) {
      virtualListRef.current.scrollToItem(itemIndex, "start");
    }

    const timeout = setTimeout(() => setSearch(""), 1_000);
    return () => clearTimeout(timeout);
  }, [search, setSearch, virtualListData.items]);
  useHotkeys(
    "*",
    (e) => {
      if (e.key.length > 1) return;
      if (e.key.match(/\D/)) {
        setSearch((search) =>
          search.match(/\d+/) ? e.key : `${search}${e.key}`
        );
      } else if (e.key.match(/\d/)) {
        setSearch((search) =>
          search.match(/\D+/) ? e.key : `${search}${e.key}`
        );
      }
    },
    { enabled: isOpen }
  );

  const getItemSize = useCallback(
    (index: number) => {
      switch (virtualListData.items[index].type) {
        case "divider":
          return 9;
        case "item":
        default:
          return 44;
      }
    },
    [virtualListData.items]
  );

  const MenuListRow = React.memo(({ data, index, style }: any) => {
    const { items, onCountryCodeChange } = data;
    const item = items[index];
    return (
      <CountryMenuItem
        {...item}
        style={style}
        onCountryCodeChange={onCountryCodeChange}
        onClick={onClose}
      />
    );
  }, areEqual);

  return (
    <HStack w="100%" spacing={2}>
      <Popover
        isLazy
        isOpen={isOpen}
        onClose={onClose}
        onOpen={handleOpen}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <Button
            px={3}
            leftIcon={
              selectedOption ? (
                <span className={`fi fi-${selectedOption.id.toLowerCase()}`} />
              ) : undefined
            }
            rightIcon={<Icon id="Ui/ChevronDownSm" />}
            variant="inputSelect"
            {...menuProps}
            alignSelf="stretch"
            w="160px"
            h="auto"
            borderRadius="md"
          >
            <Box as="span" flex={1} textAlign="left">
              {!!selectedOption && `+${selectedOption.phoneCode}`}
            </Box>
          </Button>
        </PopoverTrigger>

        <PopoverContent
          w="300px"
          _focusVisible={{ outline: "none" }}
          bgColor="background.500"
        >
          <PopoverBody overflow="hidden" p={0}>
            <VariableSizeList
              ref={virtualListRef}
              height={300}
              width={300}
              innerElementType={VariableSizeListInner}
              itemData={virtualListData}
              itemCount={virtualListData.items.length}
              itemSize={getItemSize}
            >
              {MenuListRow}
            </VariableSizeList>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <Input
        {...inputProps}
        onChange={handleLocalNumberChange}
        value={localNumber}
      />
    </HStack>
  );
}

// ------------------------------------

const VariableSizeListInner = React.forwardRef<HTMLDivElement>(
  function VariableSizeListInner({ style, ...rest }: BoxProps, ref) {
    return (
      <Box
        ref={ref}
        style={{
          ...style,
          height: `${parseFloat(String(style?.height || 0)) + 4 * 2}px`,
        }}
        {...rest}
      />
    );
  }
);

// ------------------------------------

type CountryMenuItemProps = {
  id: string;
  type: "item" | "divider";
  label?: string;
  phoneCode?: number;
  selected?: boolean;
  onCountryCodeChange?: (value: string) => void;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style: any;
};

function CountryMenuItem({
  id,
  type,
  label,
  phoneCode,
  selected: isSelected,
  onCountryCodeChange,
  onClick,
  style,
}: CountryMenuItemProps) {
  const boxStyle = {
    ...style,
    top: `${parseFloat(style.top) + 4}px`,
  };

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onCountryCodeChange) onCountryCodeChange(id);
      if (onClick) onClick(e);
    },
    [id, onCountryCodeChange, onClick]
  );

  if (type === "divider") {
    return (
      <Box py={1} style={boxStyle}>
        <Divider m={0} />
      </Box>
    );
  }

  return (
    <HStack px={2} style={boxStyle}>
      <Button
        w="100%"
        variant="menuItem"
        bg={isSelected ? "whiteAlpha.300" : "transparent"}
        color={isSelected ? "textPrimary" : "textSecondary"}
        onClick={handleClick}
      >
        <HStack justifyContent="flex-start" w="100%" spacing={2}>
          <span className={`fi fi-${id.toLowerCase()}`} />
          <Text
            overflow="hidden"
            color="textPrimary"
            textAlign="left"
            textOverflow="ellipsis"
            size="body.md"
          >
            {label}
          </Text>
          <Text color="textSecondary" size="body.md">{`+${phoneCode}`}</Text>
        </HStack>
      </Button>
    </HStack>
  );
}

// ------------------------------------

export function getDefaultCountryCode() {
  const code = (navigator.language || "en-US").slice(-2).toLowerCase();
  return COUNTRIES.hash[code] ? code : "us";
}

function formatValue(localNumber: string, countryCode: string) {
  const country = COUNTRIES.hash[countryCode];
  return `+${country.phoneCode}${localNumber}`;
}
