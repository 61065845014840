import { sizes } from "domains/theme/components/text";

import {
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
} from "@chakra-ui/react";

export interface AvatarProps extends ChakraAvatarProps {
  singleLetter?: boolean;
  label: string;
}

const colorPalette = [
  "#F8BA01",
  "#0088CC",
  "#5BAE75",
  "#DD441D",
  "#B3B3B3",
  "#FFFFFF",
];
const pickPalette = (name: string) => {
  const index = name.charCodeAt(0) % colorPalette.length;
  return colorPalette[index];
};

export default function Avatar({
  label,
  singleLetter,
  size,
  ...props
}: AvatarProps) {
  return (
    <ChakraAvatar
      sx={
        size === "md" || !size
          ? {
              "& div": {
                fontSize: sizes["body.lessbold.lg"].fontSize,
                fontWeight: sizes["body.lessbold.lg"].fontWeight,
                lineHeight: "24px",
              },
            }
          : {}
      }
      bgColor={pickPalette(label)}
      name={singleLetter ? label.charAt(0) : label}
      size={size}
      variant="squared"
      {...props}
    />
  );
}
