import React, { ReactNode } from "react";
import { GuideKeys } from "domains/guide/constants/guides";
import { extraTheme } from "domains/theme";
import { sizes } from "domains/theme/components/text";
import Icon from "domains/ui/components/Icon";
import Select from "domains/ui/components/Select";
import TourGuideIcon from "domains/ui/components/TourGuideIcon";

import {
  Box,
  BoxProps,
  Flex,
  FormControl,
  FormLabel,
  FormLabelProps,
  HStack,
  Text,
  Tooltip,
  TooltipProps,
} from "@chakra-ui/react";
export interface WithLabelAndTooltipProps {
  label?: ReactNode;
  rightLabel?: ReactNode;
  tooltip?: React.ReactNode;
  premiumTooltip?: React.ReactNode;
  guide?: GuideKeys;
  direction?: "row" | "column";
  children: React.ReactNode;
  labelProps?: FormLabelProps;
  tooltipProps?: Omit<TooltipProps, "children">;
  contentProps?: Omit<BoxProps, "children">;
  isAutoWidth?: boolean;
  textSize?: keyof typeof sizes;
}

const WithLabelAndTooltip = React.memo(function WithLabelAndTooltip({
  label,
  rightLabel,
  tooltip,
  premiumTooltip,
  guide,
  direction = "row",
  children,
  labelProps,
  tooltipProps,
  contentProps,
  isAutoWidth = false,
  textSize = "body.md",
}: WithLabelAndTooltipProps) {
  // check if one is Select component
  const hasChildrenSelect = React.Children.toArray(children).some(
    (child) => React.isValidElement(child) && child.type === Select
  );

  return (
    <Flex
      as={FormControl}
      align={direction === "row" ? "center" : "flex-start"}
      direction={direction}
      gap={1}
      h="min-content"
      minH={direction === "row" ? "28px" : ""}
      color="textSecondary"
      _hover={{
        color: "textPrimary",
      }}
      data-group
    >
      {label && (
        <FormLabel
          justifyContent="space-between"
          display="flex"
          w={
            hasChildrenSelect || isAutoWidth
              ? "auto"
              : direction === "column"
              ? "full"
              : "45%"
          }
          my={0}
          color="inherit"
          textAlign="center"
          {...labelProps}
        >
          <HStack color="inherit">
            {typeof label === "string" ? (
              <Text color="inherit" isTruncated size={textSize}>
                {label}
              </Text>
            ) : (
              label
            )}

            {premiumTooltip && (
              <Tooltip
                hasArrow
                label={premiumTooltip}
                placement="bottom"
                {...tooltipProps}
              >
                <Box m={-1} p={1}>
                  <Icon id="Ui/Stars" color="primary.500" />
                </Box>
              </Tooltip>
            )}

            {tooltip && (
              <Tooltip
                hasArrow
                label={tooltip}
                placement="bottom"
                {...tooltipProps}
              >
                <Box m={-1} p={1}>
                  <Icon
                    id="Ui/Help"
                    opacity={0}
                    _groupHover={{ opacity: 1 }}
                    transition={extraTheme.transitions.fast}
                    color="textTertiary"
                  />
                </Box>
              </Tooltip>
            )}

            {guide && (
              <TourGuideIcon
                guide={guide}
                opacity={0}
                _groupHover={{ opacity: 1 }}
              />
            )}
          </HStack>

          {typeof rightLabel === "string" ? (
            <Text color="inherit" size={textSize}>
              {rightLabel}
            </Text>
          ) : (
            rightLabel
          )}
        </FormLabel>
      )}

      <Box flex={1} w="full" color="inherit" {...contentProps}>
        {children}
      </Box>
    </Flex>
  );
});

export default WithLabelAndTooltip;
