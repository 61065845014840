import { memo, useMemo } from "react";
import useAllCollections from "domains/collections/hooks/useAllCollections";
import MenuListMultipleChoice from "domains/ui/components/Menu/MenuListMultipleChoice";

export interface FilterCollectionsProps {
  value: string[];
  setValue: (value: string[]) => void;
  facets?: {
    [key: string]: number;
  };
}

function getLabel(label: string, count?: number) {
  if (!count) {
    return label;
  }
  return `${label} (${count})`;
}

export default memo(function FilterCollections({
  value,
  setValue,
  facets,
}: FilterCollectionsProps) {
  const { collections } = useAllCollections();

  const options = useMemo(() => {
    return collections
      .map((collection) => ({
        value: collection.id,
        label: getLabel(collection.name, facets?.[collection.id]),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [collections, facets]);

  return (
    <MenuListMultipleChoice
      label="Collections"
      options={options}
      setSelectedValues={setValue}
      selectedValues={value}
    />
  );
});
