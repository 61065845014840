import React, { useCallback, useMemo } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { useHandleApiError } from "infra/api/error";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  useGetModelsExamplesByModelIdQuery,
  usePutModelsExamplesByModelIdMutation,
} from "infra/api/generated/api";

import { skipToken } from "@reduxjs/toolkit/dist/query";

export interface ButtonPinProps {
  model: GetModelsByModelIdApiResponse["model"] | undefined;
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}

export default function ButtonPin({ model, asset }: ButtonPinProps) {
  const { selectedProject } = useTeamContext();
  const { successToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const [putModelsExamplesByModelId] = usePutModelsExamplesByModelIdMutation();

  const isModelOwned = model?.ownerId === selectedProject.id;

  const { data: modelExamples, isLoading: isLoadingModelExamples } =
    useGetModelsExamplesByModelIdQuery(
      model?.id && isModelOwned
        ? { modelId: model.id, projectId: selectedProject.id }
        : skipToken
    );

  const isPinned = useMemo(() => {
    if (!asset || !modelExamples?.examples) return false;
    return modelExamples.examples.some(
      (example) => example.asset.id === asset.id
    );
  }, [asset, modelExamples]);

  const handleTogglePin = useCallback(async () => {
    if (!asset || !model || !modelExamples?.examples) return;

    try {
      const currentExampleIds = modelExamples.examples.map(
        (example) => example.asset.id
      );

      const newExampleIds = isPinned
        ? currentExampleIds.filter((id) => id !== asset.id)
        : [...currentExampleIds, asset.id];

      await putModelsExamplesByModelId({
        modelId: model.id,
        projectId: selectedProject.id,
        body: {
          assetIds: newExampleIds,
        },
      }).unwrap();

      successToast({
        title: isPinned ? "The pinned image has been removed" : "Image pinned",
      });
    } catch (error) {
      handleApiError(
        error,
        `There was an error ${isPinned ? "unpinning" : "pinning"} the image`
      );
    }
  }, [
    asset,
    model,
    modelExamples?.examples,
    isPinned,
    putModelsExamplesByModelId,
    selectedProject.id,
    successToast,
    handleApiError,
  ]);

  if (!model || !isModelOwned) return null;

  return (
    <Button
      onClick={handleTogglePin}
      variant="secondary"
      size="sm"
      p={2}
      tooltip={isPinned ? "Remove from pinned images" : "Add to pinned images"}
      isLoading={isLoadingModelExamples}
    >
      <Icon id={isPinned ? "Ui/Pin/Solid" : "Ui/Pin/Outline"} h="18px" />
    </Button>
  );
}
