import {
  GetAssetsApiArg,
  GetAssetsByAssetIdApiResponse,
} from "infra/api/generated/api";
import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { AppRootState } from "infra/store/store";

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

const shouldUpdateQuery = (
  originalArgs: GetAssetsApiArg,
  collectionIds: string[]
) => {
  const args = {
    ...originalArgs,
    projectId: undefined,
    sortBy: undefined,
    pageSize: undefined,
    paginationToken: undefined,
  };
  const argsKeys = Object.keys(args).filter(
    (key) => args[key as keyof typeof args] !== undefined
  );

  // match image page get assets query
  if (
    (argsKeys.length === 1 &&
      argsKeys[0] === "sortDirection" &&
      args["sortDirection"] === "desc") ||
    (argsKeys.length === 2 &&
      argsKeys.includes("sortDirection") &&
      argsKeys.includes("types") &&
      args["sortDirection"] === "desc" &&
      args["types"]?.includes("uploaded"))
  ) {
    return true;
  }

  if (
    originalArgs.collectionId &&
    collectionIds.includes(originalArgs.collectionId)
  ) {
    return true;
  }
};

export const updateAssetsQueryByUploadingAsset = (
  asset: GetAssetsByAssetIdApiResponse["asset"],
  collectionIds: string[],
  {
    dispatch,
    getState,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    getState: () => AppRootState;
  }
) => {
  for (const {
    endpointName,
    originalArgs,
  } of apiSlice.util.selectInvalidatedBy(getState(), [
    API_TAGS.asset,
  ]) as SelectedInvalidatedByTag[]) {
    if (
      endpointName === "getAssets" &&
      shouldUpdateQuery(originalArgs, collectionIds)
    ) {
      dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (draft) {
            const assetExists = draft.assets.some(
              (item) => item.id === asset.id
            );
            if (!assetExists) {
              draft.assets.unshift(asset);
            }
          }
        })
      );
    }
  }
};
